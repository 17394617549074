import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../enums/device-types";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import OverrideDeviceLimitationCard from "../../../ui-elements/override-device-limitation-card/override-device-limitation-card";
import styles from "./notification-content.module.scss";

interface IProps {
  onBack: () => void;
}

const devices = [
  {
    deviceType: EDeviceTypes.COMPUTER,
  },
  {
    deviceType: EDeviceTypes.FAN,
  },
  {
    deviceType: EDeviceTypes.FAN,
  },
  {
    deviceType: EDeviceTypes.FAN,
  },
  {
    deviceType: EDeviceTypes.FAN,
  },
];

const schedules = [
  {
    schedule: "Schedule 5",
    time: "05.45-06.15",
  },
  {
    schedule: "Schedule 5",
    time: "05.45-06.15",
  },
  {
    schedule: "Schedule 5",
    time: "05.45-06.15",
  },
  {
    schedule: "Schedule 5",
    time: "05.45-06.15",
  },
  {
    schedule: "Schedule 5",
    time: "05.45-06.15",
  },
  {
    schedule: "Schedule 5",
    time: "05.45-06.15",
  },
];

const NotificationContent = (props: IProps) => {
  return (
    <>
      <Row className="m-4">
        <Col>
          <AppButton
            text="arrow_left"
            iconOnly
            iconName="arrow_left"
            variant="blue"
            onClick={() => props.onBack()}
            size="extra-small"
          />
        </Col>
      </Row>
      <Row className="mx-4 mx-sm-5 ms-sm-5 ps-sm-3 mt-5">
        <Col className="col-12 col-sm-auto pe-0">
          <MaterialIcon
            className="font-size-36 "
            icon="error"
            color=" #E8C304"
          />
        </Col>
        <Col>
          <Row className="mt-2 mt-sm-0">
            <Col>
              <div className="font-weight-300 text-light font-size-12 fst-italic">
                Admin
              </div>
              <div className="font-weight-600 font-size-16">
                Reduction of Usage is Recommended
              </div>
              <div className="font-weight-300 text-light font-size-10 fst-italic">
                3 hours ago
              </div>
              <div className="font-size-14 font-weight-400 text-light mt-3">
                Usage of selected device types are recommended to be reduced
                within 8.00pm to 9.00pm.
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="font-weight-400 font-size-12">
              Affected Device Types:
            </Col>
          </Row>
          <Row className="ms-0 mt-1">
            {devices.map((d, index) => {
              return (
                <Col className="col-6 py-1" key={index}>
                  <OverrideDeviceLimitationCard deviceType={d.deviceType} />
                </Col>
              );
            })}
          </Row>
          <Row className="mt-5">
            <Col className="font-weight-400 font-size-12 text-start">
              Affected Schedules
            </Col>
          </Row>
          <Row className="ms-0 mt-1 gap-2">
            {schedules.map((schedules, index) => (
              <Col key={index} className={`col-3 px-2 py-1 ${styles.label}`}>
                <div className="font-weight-400 text-light font-size-10 ">
                  {schedules.schedule}
                </div>
                <div className={`font-weight-500 ${styles.time}`}>
                  {schedules.time}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default NotificationContent;
