import moment from "moment";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import EnergyUsageValue from "./energy-usage-value";
import PowerUsageValue from "./power-usage-value";
import styles from "./static-info-card.module.scss";

interface IProps {
  type: "POWER_USAGE" | "ENERGY_USAGE";
  spaceName: string;
  date: Date;
  onMoreInfoClick: () => void;
  averageConsumption?: number;
  highestConsumption?: number;
  todayUsage?: number;
  currentMonthUsage?: number;
  dailyAverageUsage?: number;
  monthlyAverageUsage?: number;
  isFetching: boolean;
}

const StaticInfoCard = (props: IProps) => {
  return (
    <div className={`h-100 bg-white p-4 ${styles.staticInfoCard}`}>
      {props.isFetching ? (
        <SpinnerModal show={props.isFetching} withOverlay={false} size="lg" />
      ) : (
        <>
          <Row className="align-items-center">
            <Col>
              <Row className="flex-column justify-content-center">
                <Col className={`font-size-14 font-weight-500 text-dark`}>
                  {props.type === "POWER_USAGE"
                    ? "Power Usage"
                    : "Energy Usage"}
                </Col>
                <Col className="text-light font-size-12 font-weight-500">
                  {props.spaceName}
                  <span className={`text-primary ps-2`}>
                    {moment(props.date).format("MMM yyyy")}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col className="col-auto">
              <AppButton
                text="More Info"
                size="medium"
                onClick={props.onMoreInfoClick}
              />
            </Col>
          </Row>
          {props.type === "POWER_USAGE" ? (
            <Row className="mt-4  justify-content-between">
              <Col className="col-12 col-sm-auto  text-center text-sm-start mt-4">
                <PowerUsageValue
                  value={props.averageConsumption || 0}
                  title="Average Consumption"
                />
              </Col>
              <Col className="col-12 col-sm-auto text-center text-sm-start mt-4">
                <PowerUsageValue
                  value={props.highestConsumption || 0}
                  title="Highest Consumption"
                />
              </Col>
            </Row>
          ) : (
            <Row className="mt-4 justify-content-between">
              <Col className="col-12 col-sm-auto text-center text-sm-start mt-4">
                <EnergyUsageValue
                  value={props.todayUsage || 0}
                  title="Today Usage"
                  subText={`Daily Average ${
                    props.dailyAverageUsage || 0
                  } units`}
                  trending={
                    (props.dailyAverageUsage || 0) > (props.todayUsage || 0)
                      ? "DOWN"
                      : (props.dailyAverageUsage || 0) ===
                        (props.todayUsage || 0)
                      ? "EQUAL"
                      : "UP"
                  }
                />
              </Col>
              <Col className="col-12 col-sm-auto text-center text-sm-start mt-4">
                <EnergyUsageValue
                  value={props.currentMonthUsage || 0}
                  title="Current Month Usage"
                  subText={`Monthly Average ${
                    props.monthlyAverageUsage || 0
                  } units`}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default StaticInfoCard;
