import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useUnlinkAcControllerMutation,
  useUnlinkedLinkPowerConsumerMutation,
  useUpdateAcControllerMutation,
  useUpdateControllerMutation,
} from "../../../../redux/api/controller/controllerAPI";
import DeviceLinkModal from "../../../../screens/dashboard/controllers/device-link-modal/device-link-modal";
import DeviceUnlinkModal from "../../../../screens/dashboard/space-clusters/components/models/device-unlink-modal/device-unlink-modal";
import { DeviceStatus } from "../../../oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../oversight-core/enums/device-types";
import { AppRoute } from "../../../oversight-core/interfaces/app-routes";
import ActiveInactiveIndicator from "../../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../oversight-core/utils/findIcon";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../oversight-core/utils/toast";
import AcController from "../../components/ac-controller/ac-controller";
import { ActiveStatus } from "../../enums/active-status";
import { ConnectionState } from "../../enums/connection-status";
import { ControllerType } from "../../enums/controller-type";
import ISmartDevice from "../../interfaces/smart-devices";
import { ISpaceClusterShallowHierarchyView } from "../../interfaces/space-hierarchy";
import AppDropDown from "../app-dropdown/app-drop-down";
import AppToggle from "../app-toggle/app-toggle";
import styles from "./controller-card.module.scss";

export interface IExtendedSmartDevice extends ISmartDevice {
  clusterId: string;
  spaceId: string;
}

interface IProps {
  smartDevice: IExtendedSmartDevice;
  path?: ISpaceClusterShallowHierarchyView;
  updateControllerStatus: (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => void;
  setIsControllerUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  onAcTemperatureIncrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  onAcTemperatureDecrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
}

const ControllerCard = (props: IProps) => {
  const navigate = useNavigate();
  const {
    smartDevice,
    path,
    updateControllerStatus,
    onAcTemperatureDecrease,
    onAcTemperatureIncrease,
  } = props;
  const [isOn, setIsOn] = useState(false);
  const [breadCrumbPath, setBreadCrumbPath] = useState("");
  const [deviceUnlinkModal, setDeviceUnlinkModal] = useState(false);
  const [deviceLinkModal, setDeviceLinkModal] = useState(false);
  const [isDeviceUpdating, setIsDeviceUpdating] = useState(false);
  const [scheduleIdList, setScheduleIdList] = useState<string[]>([]);

  const [updateController] = useUpdateControllerMutation();
  const [updateAcController] = useUpdateAcControllerMutation();
  const [unlinkedLinkPowerConsumer] = useUnlinkedLinkPowerConsumerMutation();
  const [unlinkAcController] = useUnlinkAcControllerMutation();

  useEffect(() => {
    if (smartDevice.dynamicState?.powerState === DeviceStatus.ON) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [smartDevice.dynamicState?.powerState]);

  useEffect(() => {
    let paths = "";
    if (path) {
      path.ancestorSpaces
        .slice()
        .reverse()
        .forEach((ancestor, index) => {
          paths +=
            index !== path.ancestorSpaces.length - 1
              ? `${ancestor.name} > `
              : `${ancestor.name}`;
        });
    }

    setBreadCrumbPath(paths);
  }, [path]);

  const linkDevice = (
    spaceClusterId: string,
    spaceId: string,
    smartDeviceId: string,
    name: string,
    linkedPowerConsumerIdL: string
  ) => {
    setIsDeviceUpdating(true);

    if (smartDevice.smartDeviceType === ControllerType.SMART_PLUG) {
      updateController({
        spaceClusterId: spaceClusterId,
        spaceId: spaceId,
        smartPlugId: smartDeviceId,
        name: name,
        linkedPowerConsumerId: linkedPowerConsumerIdL,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device linked Successfully");
          updateControllerStatus(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice.id,
            smartDevice.dynamicState?.powerState === DeviceStatus.ON
              ? DeviceStatus.OFF
              : DeviceStatus.ON
          );
          setIsDeviceUpdating(false);
          props.setIsControllerUpdated &&
            props.setIsControllerUpdated((ps) => !ps);
        })
        .catch(() => {
          showErrorMessage("Device linked Unsuccessful");
        });

      return;
    }

    updateAcController({
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
      acControllerId: smartDeviceId,
      name: name,
      linkedAcId: linkedPowerConsumerIdL,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device linked Successfully");
        updateControllerStatus(
          smartDevice.clusterId,
          smartDevice.spaceId,
          smartDevice.id,
          smartDevice.dynamicState?.powerState === DeviceStatus.ON
            ? DeviceStatus.OFF
            : DeviceStatus.ON
        );
        setIsDeviceUpdating(false);
        props.setIsControllerUpdated &&
          props.setIsControllerUpdated((ps) => !ps);
      })
      .catch(() => {
        showErrorMessage("Device linked Unsuccessful");
      });
  };

  const unlinkDevice = (
    spaceClusterId: string,
    spaceId: string,
    smartPlugId: string,
    linkedPowerConsumerId: string,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    setIsDeviceUpdating(true);
    if (smartDevice.smartDeviceType === ControllerType.AC_CONTROLLER) {
      unlinkAcController({
        spaceClusterId,
        spaceId,
        acControllerId: smartPlugId,
        linkedAcId: linkedPowerConsumerId,
        excludedSemiAutomatedSchedules,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device Unlinked Successfully");
          updateControllerStatus(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice.id,
            smartDevice.dynamicState?.powerState === DeviceStatus.ON
              ? DeviceStatus.OFF
              : DeviceStatus.ON
          );
          setIsDeviceUpdating(false);
          props.setIsControllerUpdated &&
            props.setIsControllerUpdated((ps) => !ps);
        })
        .catch(() => {
          showErrorMessage("Device Unlinked Unsuccessful");
        });
      return;
    }

    unlinkedLinkPowerConsumer({
      spaceClusterId,
      spaceId,
      smartPlugId,
      linkedPowerConsumerId,
      excludedSemiAutomatedSchedules,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Unlinked Successfully");
        updateControllerStatus(
          smartDevice.clusterId,
          smartDevice.spaceId,
          smartDevice.id,
          smartDevice.dynamicState?.powerState === DeviceStatus.ON
            ? DeviceStatus.OFF
            : DeviceStatus.ON
        );
        setIsDeviceUpdating(false);
        props.setIsControllerUpdated &&
          props.setIsControllerUpdated((ps) => !ps);
      })
      .catch(() => {
        showErrorMessage("Device Unlinked Unsuccessful");
      });
  };

  const controllerCardDropDownItems = (smartDevice: ISmartDevice) => {
    const commonItems = [
      {
        text: "Edit Controller",
        onClick: () => {
          navigate(AppRoute.ADD_CONTROLLER, {
            state: {
              smartDevice,
            },
          });
        },
      },
      {
        text: "Remove Controller",
        onClick: () => {
          console.log();
        },
      },
      {
        text: "Move Controller",
        onClick: () => {
          console.log();
        },
      },
    ];

    if (smartDevice.activeStatus === ActiveStatus.ENABLED) {
      return [
        ...commonItems,
        {
          text: "Disable Controller",
          onClick: () => {
            console.log();
          },
        },
        {
          text: "Automation",
          onClick: () => {
            console.log();
          },
          label: "Manual",
        },
        {
          text: "Share Access",
          onClick: () => {
            console.log();
          },
        },
      ];
    } else {
      return [
        ...commonItems,
        {
          text: "Share Access",
          onClick: () => {
            console.log();
          },
        },
      ];
    }
  };

  const onIncrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureIncrease &&
      smartDevice &&
      smartDevice.dynamicState.temperature < 30
    ) {
      onAcTemperatureIncrease(
        smartDevice.clusterId,
        smartDevice.spaceId,
        smartDevice.id,
        smartDevice.dynamicState?.temperature + 1
      );
    }
  };

  const onDecrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureDecrease &&
      smartDevice &&
      smartDevice.dynamicState.temperature > 16
    ) {
      onAcTemperatureDecrease(
        smartDevice.clusterId,
        smartDevice.spaceId,
        smartDevice.id,
        smartDevice.dynamicState?.temperature - 1
      );
    }
  };

  return (
    <>
      <div
        className={`${
          smartDevice.activeStatus !== ActiveStatus.DISABLED
            ? styles.controllerCardContainer
            : styles.unLinkControllerCardContainer
        }`}
      >
        <Row
          className={`align-items-center justify-content-between mx-0 p-3 h-100`}
        >
          <Col
            className={`${
              smartDevice.activeStatus !== ActiveStatus.DISABLED
                ? ``
                : `opacity-50`
            } ps-0`}
          >
            <Row className="align-items-center">
              <Col
                className={`text-dark font-size-16 font-weight-500 ${
                  path ? "col-auto" : ""
                }`}
              >
                {smartDevice.name}
              </Col>
              {path && (
                <>
                  <Col className="col-auto text-light font-size-14 font-weight-400">
                    {smartDevice.serialKey}
                  </Col>
                  <Col className="col-auto ps-0">
                    <div
                      className={`${styles.controllerStatus} ${
                        smartDevice.deviceConnectionState ===
                        ConnectionState.CONNECTED
                          ? styles.controllerOnStatus
                          : styles.controllerOffStatus
                      }`}
                    ></div>
                  </Col>
                </>
              )}
            </Row>
            {!path && (
              <Row className="align-items-center">
                <Col className="col-auto text-light font-size-14 font-weight-400">
                  {smartDevice.serialKey}
                </Col>
                <Col className="col-auto ps-0">
                  <ActiveInactiveIndicator
                    isActive={
                      smartDevice.deviceConnectionState ===
                      ConnectionState.CONNECTED
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
            )}
            {path && (
              <Row className="align-items-center">
                <Col className="col-auto text-light font-size-10 font-weight-400">
                  {breadCrumbPath}
                </Col>
              </Row>
            )}
          </Col>
          <Col>
            {isDeviceUpdating ? (
              <SpinnerModal withOverlay={false} show={true} size="sm" />
            ) : (
              <>
                {!smartDevice.linkedPowerConsumer &&
                (smartDevice.activeStatus === ActiveStatus.DISABLED ||
                  smartDevice.smartDeviceType ===
                    ControllerType.AC_CONTROLLER) ? (
                  <Row className="align-items-center">
                    <Col
                      className="text-primary font-size-12 font-weight-500 col-auto cursor-pointer pe-0"
                      onClick={() => {
                        setDeviceLinkModal(true);
                      }}
                    >
                      Link Device
                    </Col>
                    <Col className="ps-1 cursor-pointer">
                      <MaterialIcon
                        icon="link"
                        color="#2f2a89"
                        onClick={() => {
                          setDeviceLinkModal(true);
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row className="align-items-center">
                    <Col
                      className={`${
                        !smartDevice.linkedPowerConsumer
                          ? styles.generalController
                          : smartDevice.linkedPowerConsumer.deviceType
                      } p-1 rounded col-auto`}
                    >
                      <MaterialIcon
                        icon={
                          !smartDevice.linkedPowerConsumer
                            ? "smart_outlet"
                            : findIcon(
                                smartDevice.linkedPowerConsumer
                                  .deviceType as EDeviceTypes
                              )
                        }
                      />
                    </Col>
                    <Col className="col-auto">
                      <Row className="justify-content-center flex-column">
                        <Col
                          className={`col-auto ${
                            !smartDevice.linkedPowerConsumer
                              ? "text-light"
                              : "text-dark"
                          } font-size-12 ${
                            !smartDevice.linkedPowerConsumer
                              ? "font-weight-400"
                              : "font-weight-500"
                          }`}
                        >
                          {!smartDevice.linkedPowerConsumer ? (
                            <>
                              <span>
                                General
                                <br />
                              </span>
                              <span>Controller</span>
                            </>
                          ) : (
                            smartDevice.linkedPowerConsumer.name
                          )}
                        </Col>
                        {smartDevice.linkedPowerConsumer && (
                          <Col className="col-auto text-light font-weight-400 font-size-10">
                            {smartDevice.linkedPowerConsumer.powerUsageInWatt}W
                          </Col>
                        )}
                      </Row>
                    </Col>
                    {smartDevice.linkedPowerConsumer && (
                      <Col className="ps-0 col-auto">
                        <MaterialIcon
                          icon="link_off"
                          color="#2f2a89"
                          onClick={() => {
                            setDeviceUnlinkModal(true);
                          }}
                          className="cursor-pointer"
                        />
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
          </Col>
          {(smartDevice.smartDeviceType === ControllerType.SMART_PLUG ||
            !smartDevice.linkedPowerConsumer) && (
            <Col className="col-12 col-sm-auto col-md-12 col-lg-auto col-xl-12 col-xxl-auto mt-md-3 mt-3 mt-sm-0 mt-lg-0 mt-xl-3 mt-xxl-0 pe-0">
              <Row className="align-items-center g-4">
                <Col
                  className={`col-auto ${
                    smartDevice.deviceConnectionState ===
                      ConnectionState.DISCONNECTED &&
                    smartDevice.dynamicState?.powerState === DeviceStatus.OFF
                      ? `opacity-50`
                      : ``
                  }`}
                >
                  <AppToggle
                    isOn={
                      smartDevice.dynamicState?.powerState === DeviceStatus.ON
                        ? isOn
                        : false
                    }
                    onSwitch={() => {
                      if (
                        smartDevice.deviceConnectionState ===
                        ConnectionState.CONNECTED
                      ) {
                        updateControllerStatus(
                          smartDevice.clusterId,
                          smartDevice.spaceId,
                          smartDevice.id,
                          smartDevice.dynamicState?.powerState ===
                            DeviceStatus.ON
                            ? DeviceStatus.OFF
                            : DeviceStatus.ON
                        );
                      }
                    }}
                    isDisable={
                      smartDevice.deviceConnectionState ===
                      ConnectionState.CONNECTED
                        ? false
                        : true
                    }
                    size="sm"
                  />
                </Col>
                <Col className={styles.dropdown}>
                  <AppDropDown
                    items={controllerCardDropDownItems(smartDevice)}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        {smartDevice.smartDeviceType === ControllerType.AC_CONTROLLER &&
          smartDevice.linkedPowerConsumer && (
            <Row className="align-items-center px-4">
              <Col className="my-2">
                <AcController
                  temperature={smartDevice.dynamicState?.temperature}
                  onIncrease={onIncrease}
                  onDecrease={onDecrease}
                  isShowTemperatureText={true}
                />
              </Col>
              <Col className="col-auto my-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <AppToggle
                      isOn={
                        smartDevice.dynamicState?.powerState === DeviceStatus.ON
                          ? isOn
                          : false
                      }
                      onSwitch={() => {
                        if (
                          smartDevice.deviceConnectionState ===
                          ConnectionState.CONNECTED
                        ) {
                          updateControllerStatus(
                            smartDevice.clusterId,
                            smartDevice.spaceId,
                            smartDevice.id,
                            smartDevice.dynamicState?.powerState ===
                              DeviceStatus.ON
                              ? DeviceStatus.OFF
                              : DeviceStatus.ON
                          );
                        }
                      }}
                      isDisable={
                        smartDevice.deviceConnectionState ===
                        ConnectionState.CONNECTED
                          ? false
                          : true
                      }
                      size="sm"
                    />
                  </Col>
                  <Col className={styles.dropdown}>
                    <AppDropDown
                      items={controllerCardDropDownItems(smartDevice)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
      </div>
      <DeviceLinkModal
        show={deviceLinkModal}
        onClose={() => {
          setDeviceLinkModal(false);
        }}
        onCancel={() => {
          setDeviceLinkModal(false);
        }}
        serialNumber={smartDevice.serialKey}
        controllerName={smartDevice.name}
        sendSelectedDevice={(device) => {
          linkDevice(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice.id,
            smartDevice.name,
            device.powerConsumer.id
          );
          setDeviceLinkModal(false);
        }}
        deviceConnectionState={smartDevice.deviceConnectionState}
        serialKeyDeviceType={smartDevice.smartDeviceType}
      />
      <DeviceUnlinkModal
        show={deviceUnlinkModal}
        deviceName={smartDevice.linkedPowerConsumer?.name || ""}
        controllerName={smartDevice.name}
        onCancel={() => setDeviceUnlinkModal(false)}
        onClose={() => setDeviceUnlinkModal(false)}
        onConfirm={() => {
          unlinkDevice(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice?.id,
            smartDevice?.linkedPowerConsumer?.id || "",
            scheduleIdList
          );
          setDeviceUnlinkModal(false);
        }}
        spaceClusterId={smartDevice.clusterId}
        linkedPowerConsumerId={smartDevice.linkedPowerConsumer?.id || ""}
        setScheduleIdList={setScheduleIdList}
      />
    </>
  );
};

export default ControllerCard;
