import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store"; 

type AuthState = {
  token: string | null
}

const token = localStorage.getItem('token')

const initialState: AuthState = {
  token: null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState: { ...initialState, token },
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }, 
  }, 
});

export const { setToken } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;