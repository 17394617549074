import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../oversight-core/ui-elements/buttons/app-button/app-button";
interface IProps {
  path: string;
  isPathIcon?: boolean;
}

const SpacePathView = (props: IProps) => {
  const { path, isPathIcon = true } = props;
  const navigate = useNavigate();

  return (
    <Row className="align-items-center">
      {isPathIcon && (
        <Col xs={"auto"} className="pe-0">
          <AppButton
            text="arrow_left"
            iconOnly
            iconName="arrow_left"
            variant="blue"
            onClick={() => {
              navigate(-1);
            }}
          />
        </Col>
      )}
      <Col>
        <Row>
          <Col className="text-muted font-weight-400 font-size-14">
            {path.split("split")}
          </Col>
        </Row>
        <Row>
          <Col className="text-dark font-weight-500 font-size-16">
            {path.split("split")[1]}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SpacePathView;
