import { useLocation } from "react-router";
import LoginLayout from "../../shared-components/login-layout/login-layout";
import MobileNumberOtpVerificationForm from "./mobile-number-otp-verification-form/mobile-number-otp-verification-form";

const MobileNumberOtpVerification = () => {
  const { state } = useLocation();
  const { number, whereTo } = state;
  return (
    <LoginLayout
      title={"Verify Mobile Number"}
      subTitle={
        <>
          An OTP has been sent to{" "}
          <span style={{ color: "#2F2A89" }}>{number}</span> with 6 digits.
          Enter the OTP below to confirm Mobile Number.
        </>
      }
      showBackButton={false}
    >
      <MobileNumberOtpVerificationForm whereTo={whereTo} number={number} />
    </LoginLayout>
  );
};

export default MobileNumberOtpVerification;
