import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwitchSmartControllerMutation } from "../../../redux/api/controller/controllerAPI";
import {
  useLazyListPowerConsumersQuery,
  useRemoveDeviceMutation,
} from "../../../redux/api/device/deviceAPI";
import { useLazyProfileQuery } from "../../../redux/api/user/userAPI";
import {
  selectFilter,
  setFilter,
  setSelectedFilterSpace,
} from "../../../redux/features/filter/filter-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import { DeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import IconButton from "../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";
import ListCard from "../../../shared/oversight-general-core/components/list-card/list-card";
import SpaceDeviceFilters from "../../../shared/oversight-general-core/components/space-device-filters/space-device-filters";
import {
  IListPowerConsumersGroupedResponseDTO,
  IListPowerConsumersResponseDTO,
} from "../../../shared/oversight-general-core/dtos/list-power-consumers-response-dto";
import { IGroupedPowerConsumers } from "../../../shared/oversight-general-core/interfaces/grouped-power-consumer";
import { ISelectedSpace } from "../../../shared/oversight-general-core/interfaces/selected-space";
import { ISpaceClusterPowerConsumerViews } from "../../../shared/oversight-general-core/interfaces/space-cluster-power-consumer-views";
import AddUpdateSpaceModal from "./components/models/add-update-space-modal/add-update-space-modal";
import styles from "./space-clusters.module.scss";

const SpaceClusters = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const [deviceListGrouped, setDeviceListGrouped] = useState<
    IGroupedPowerConsumers[]
  >([]);
  const [deviceListNotGrouped, setDeviceListNotGrouped] = useState<
    ISpaceClusterPowerConsumerViews[]
  >([]);
  const [groupedCollapsed, setGroupedCollapsed] = useState<boolean[]>([]);
  const [isPowerConsumerUpdated, setIsPowerConsumerUpdated] = useState(false);

  const [switchControllerState, { isLoading: isLoadingSwitchControllerState }] =
    useSwitchSmartControllerMutation();

  const [getProfile, { isFetching: isFetchingProfile }] = useLazyProfileQuery();

  const updateCurrentState = async () => {
    if (profile && profile.serviceProvider === null) {
      await getProfile();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateCurrentState();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const closeAddUpdateSpaceModal = () => {
    setShowAddUpdateSpaceModal(false);
  };

  const [removeDevice, { isLoading: isLoadingRemoveDevice }] =
    useRemoveDeviceMutation();

  const [
    triggerListPowerConsumers,
    { isFetching: isFetchingListPowerConsumers },
  ] = useLazyListPowerConsumersQuery();

  const deleteDevice = (
    clusterId: string,
    spaceId: string,
    deviceId: string
  ) => {
    removeDevice({ clusterId, spaceId, deviceId })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Removed Successfully");
      })
      .catch(() => {
        showErrorMessage("Removing Device Unsuccessful");
      });
  };

  useEffect(() => {
    if (filtersStore.selectedGroupBy === "Type" || isPowerConsumerUpdated) {
      if (
        filtersStore.selectedSpace.clusterId &&
        filtersStore.selectedSpace.id
      ) {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          groupByType: true,
          spaceClusterId: filtersStore.selectedSpace.clusterId,
          subRootSpaceId: filtersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersGroupedResponseDTO) => {
            setDeviceListGrouped(response.deviceList.elements);

            const temp: boolean[] = [];
            response.deviceList.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setDeviceListGrouped([]);
          });
      } else {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          groupByType: true,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersGroupedResponseDTO) => {
            setDeviceListGrouped(response.deviceList.elements);

            const temp: boolean[] = [];
            response.deviceList.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setDeviceListGrouped([]);
          });
      }
    } else {
      if (
        filtersStore.selectedSpace.clusterId &&
        filtersStore.selectedSpace.id
      ) {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          type: filtersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          spaceClusterId: filtersStore.selectedSpace.clusterId,
          subRootSpaceId: filtersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersResponseDTO) => {
            setDeviceListNotGrouped(response.deviceList.elements);
          })
          .catch(() => {
            setDeviceListNotGrouped([]);
          });
      } else {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          type: filtersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersResponseDTO) => {
            setDeviceListNotGrouped(response.deviceList.elements);
          })
          .catch(() => {
            setDeviceListNotGrouped([]);
          });
      }
    }
  }, [triggerListPowerConsumers, filtersStore, isPowerConsumerUpdated]);

  useEffect(() => {
    if (
      !filtersStore.selectedSpace.clusterId ||
      filtersStore.selectedGroupBy === "Type" ||
      filtersStore.selectedDeviceType
    ) {
      return;
    }

    if (filtersStore.selectedSpace.id) {
      navigate(pathname + "/" + filtersStore.selectedSpace.id);
    }
  }, [filtersStore]);

  const updateFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    badgeValue: number
  ) => {
    dispatch(
      setSelectedFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );

    dispatch(
      setFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        badgeValue: badgeValue,
      })
    );
  };

  const updateDeviceStatus = (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => {
    switchControllerState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      smartPlugId: smartDeviceId,
      switchingPowerState: status,
    })
      .unwrap()
      .then(() => {
        updateCurrentState();
        setIsPowerConsumerUpdated(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <SpaceDeviceFilters
        updateFilters={updateFilters}
        isFetching={isFetchingListPowerConsumers}
      />
      <div className="position-relative">
        <div className="my-4">
          {!filtersStore.selectedDeviceType &&
            !filtersStore.searchText &&
            filtersStore.selectedGroupBy === `Space` &&
            !filtersStore.selectedSpace.clusterId && (
              <IconButton
                icon="add"
                text="Create Billing Space"
                className="mt-4"
                onClick={() => setShowAddUpdateSpaceModal(true)}
              />
            )}
        </div>
        {!isFetchingListPowerConsumers &&
          filtersStore.selectedGroupBy === "Type" && (
            <>
              {deviceListGrouped.length !== 0 ? (
                <>
                  {deviceListGrouped.map(
                    (elements: IGroupedPowerConsumers, index) => {
                      return (
                        <Row key={index} className="container-white mx-1 mt-4">
                          <Col>
                            <Row
                              className="cursor-pointer"
                              onClick={() => {
                                setGroupedCollapsed((ps) => {
                                  const temp = [...ps];
                                  temp[index] = !temp[index];
                                  return [...temp];
                                });
                              }}
                            >
                              <Col
                                className={`p-1 ${elements.group} rounded col-auto`}
                              >
                                <MaterialIcon
                                  icon={findIcon(
                                    elements.group as EDeviceTypes
                                  )}
                                  size={19}
                                />
                              </Col>
                              <Col className="text-dark font-size-16 font-weight-500">{`${elements.group}s`}</Col>
                              <Col className="col-auto">
                                <MaterialIcon
                                  icon="expand_more"
                                  className={`cursor-pointer ${styles.dArrow}`}
                                  style={
                                    groupedCollapsed[index]
                                      ? { transform: "rotate(-90deg)" }
                                      : { transform: "rotate(0deg)" }
                                  }
                                />
                              </Col>
                            </Row>
                            {elements.spaceClusterPowerConsumers.map(
                              (spaceClusterPowerConsumer) => {
                                return (
                                  <Row
                                    key={
                                      spaceClusterPowerConsumer.powerConsumer.id
                                    }
                                    className={`${
                                      groupedCollapsed[index] ? "d-none" : ""
                                    } mt-2`}
                                  >
                                    <Col className="px-0">
                                      <ListCard
                                        deleteDevice={deleteDevice}
                                        updateCurrentState={updateCurrentState}
                                        device={{
                                          ...spaceClusterPowerConsumer.powerConsumer,
                                          clusterId:
                                            spaceClusterPowerConsumer.spaceClusterId,
                                          spaceId:
                                            spaceClusterPowerConsumer.spaceId,
                                        }}
                                        mainSpaceClusterId={
                                          spaceClusterPowerConsumer.spaceClusterId
                                        }
                                        mainSpaceId={
                                          spaceClusterPowerConsumer.spaceId
                                        }
                                        isDevicesOnly={true}
                                        path={spaceClusterPowerConsumer.path}
                                        updateDeviceStatus={updateDeviceStatus}
                                        isTextTruncate={false}
                                        setIsPowerConsumerUpdated={
                                          setIsPowerConsumerUpdated
                                        }
                                        smartDevice={{
                                          ...spaceClusterPowerConsumer.linkedSmartDevice,
                                          linkedPowerConsumer:
                                            spaceClusterPowerConsumer.linkedSmartDevice
                                              ? spaceClusterPowerConsumer.powerConsumer
                                              : null,
                                          clusterId:
                                            spaceClusterPowerConsumer.spaceClusterId,
                                          spaceId:
                                            spaceClusterPowerConsumer.spaceId,
                                        }}
                                        isDirectly={true}
                                      />
                                    </Col>
                                  </Row>
                                );
                              }
                            )}
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </>
              ) : (
                <Row className="container-white mx-1 mt-4">
                  <div className="container-dash mt-4">
                    <Col className="text-center text-light font-size-12">
                      There are no devices available for the selected filters.
                    </Col>
                  </div>
                </Row>
              )}
            </>
          )}

        {!isFetchingListPowerConsumers &&
          filtersStore.selectedGroupBy !== "Type" &&
          (filtersStore.selectedDeviceType || filtersStore.searchText) && (
            <Row className="container-white position-relative mx-1 mt-4">
              {deviceListNotGrouped.length !== 0 ? (
                <Col>
                  {filtersStore.selectedDeviceType && (
                    <Row className="align-items-center cursor-pointer">
                      <Col
                        className={`p-1 ${filtersStore.selectedDeviceType} rounded col-auto`}
                      >
                        <MaterialIcon
                          icon={findIcon(
                            filtersStore.selectedDeviceType as EDeviceTypes
                          )}
                          size={19}
                        />
                      </Col>
                      <Col className="text-dark font-size-16 font-weight-500">{`${filtersStore.selectedDeviceType}s`}</Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      {deviceListNotGrouped.map(
                        (
                          spaceClusterPowerConsumer: ISpaceClusterPowerConsumerViews
                        ) => {
                          return (
                            <Row
                              key={spaceClusterPowerConsumer.powerConsumer.id}
                              className="mt-2"
                            >
                              <Col>
                                <ListCard
                                  deleteDevice={deleteDevice}
                                  updateCurrentState={updateCurrentState}
                                  device={{
                                    ...spaceClusterPowerConsumer.powerConsumer,
                                    clusterId:
                                      spaceClusterPowerConsumer.spaceClusterId,
                                    spaceId: spaceClusterPowerConsumer.spaceId,
                                  }}
                                  mainSpaceClusterId={
                                    spaceClusterPowerConsumer.spaceClusterId
                                  }
                                  mainSpaceId={
                                    spaceClusterPowerConsumer.spaceId
                                  }
                                  isDevicesOnly={true}
                                  path={spaceClusterPowerConsumer.path}
                                  updateDeviceStatus={updateDeviceStatus}
                                  isTextTruncate={false}
                                  setIsPowerConsumerUpdated={
                                    setIsPowerConsumerUpdated
                                  }
                                  smartDevice={{
                                    ...spaceClusterPowerConsumer.linkedSmartDevice,
                                    linkedPowerConsumer:
                                      spaceClusterPowerConsumer.linkedSmartDevice
                                        ? spaceClusterPowerConsumer.powerConsumer
                                        : null,
                                    clusterId:
                                      spaceClusterPowerConsumer.spaceClusterId,
                                    spaceId: spaceClusterPowerConsumer.spaceId,
                                  }}
                                  isDirectly={true}
                                />
                              </Col>
                            </Row>
                          );
                        }
                      )}
                    </Col>
                  </Row>
                </Col>
              ) : (
                <div className="container-dash mt-4">
                  <Col className="text-center text-light font-size-12">
                    There are no devices available for the selected filters.
                  </Col>
                </div>
              )}
            </Row>
          )}
        <SpinnerModal
          show={
            isLoadingRemoveDevice ||
            isFetchingProfile ||
            isLoadingSwitchControllerState ||
            isFetchingListPowerConsumers
          }
        />
      </div>
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceCreationType={"spaceCluster"}
        updateCurrentState={updateCurrentState}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        closeAddUpdateSpaceModal={closeAddUpdateSpaceModal}
        spaceClusterId={""}
        parentSpaceId={""}
      />
    </>
  );
};

export default SpaceClusters;
