import styles from "./card.module.scss";

interface IProps {
  children: JSX.Element;
}

const Card = (props: IProps) => {
  return <div className={styles.cordContainer}>{props.children}</div>;
};

export default Card;
