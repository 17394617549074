import { slide as Menu } from "react-burger-menu";
import IPageInfo from "../../interfaces/page-info";
import AppMenuContent from "../../ui-elements/app-menu-content/app-menu-content";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";

interface IProps {
  isOpen: boolean;
  closeOpen: () => void;
  routes: IPageInfo[];
}

const MobileNavBar = (props: IProps) => {
  return (
    <Menu
      isOpen={props.isOpen}
      right
      noOverlay={true}
      customBurgerIcon={false}
      customCrossIcon={
        <MaterialIcon icon="close" style={{ cursor: "pointer" }} />
      }
      className="bg-white"
      onClose={props.closeOpen}
      width="90%"
    >
      <AppMenuContent closeMobileNav={props.closeOpen} routes={props.routes} />
    </Menu>
  );
};

export default MobileNavBar;
