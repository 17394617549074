import React from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import IconButton from "../../oversight-core/ui-elements/buttons/icon-button/icon-button";
import Helper from "../../oversight-core/utils/helpers";

export interface IPanelProps {
  id: string;
  wattage: number | undefined;
  numberOfPanels: number | undefined;
  wattageError: string;
  quantityError: string;
}

interface IAddPanelsProps {
  className?: string;
  panels: IPanelProps[];
  setPanels: React.Dispatch<React.SetStateAction<IPanelProps[]>>;
}

export const initialPanel: IPanelProps = {
  id: uuid(),
  wattage: undefined,
  numberOfPanels: undefined,
  wattageError: "",
  quantityError: "",
};

const AddPanelData = (props: IAddPanelsProps) => {
  const panelUpdateHandler = (panelData: IPanelProps) => {
    props.setPanels((prevState) => {
      const newPrevState = [...prevState];
      const index = newPrevState.findIndex(
        (value) => value.id === panelData.id
      );
      newPrevState[index] = panelData;
      return newPrevState;
    });
  };

  const concatNewPanelHandler = () => {
    const newPanels = [...props.panels];

    if (newPanels.length > 0) {
      const lastPanel = newPanels[newPanels.length - 1];
      let hasError = false;

      if (!lastPanel.wattage || lastPanel?.wattage <= 0) {
        lastPanel.wattageError = "Wattage must be required";
        hasError = true;
      }

      if (!lastPanel.numberOfPanels || lastPanel.numberOfPanels <= 0) {
        lastPanel.quantityError = "Number of panels must be required";
        hasError = true;
      }

      if (hasError) {
        panelUpdateHandler(lastPanel);
        return;
      }
    }
    newPanels.push({
      ...initialPanel,
      id: uuid(),
    });

    props.setPanels(newPanels);
  };

  const panelRemoveHandler = (id: string | number) => {
    props.setPanels((prevState) => {
      const newState = [...prevState].filter((value) => value.id !== id);

      return newState;
    });
  };

  const wattageChangeHandler = (panelData: IPanelProps, value: string) => {
    const newWattage = +value;
    panelData.wattage = +value;
    if (newWattage <= 0) {
      panelData.wattageError = "Wattage must be greater required";
    } else {
      panelData.wattageError = "";
    }
    panelUpdateHandler(panelData);
  };

  const panelChangeHandler = (panelData: IPanelProps, value: string) => {
    const newPanel = +value;
    panelData.numberOfPanels = +value;
    if (newPanel <= 0) {
      panelData.quantityError = "Number of panels must be required";
    } else {
      panelData.quantityError = "";
    }
    panelUpdateHandler(panelData);
  };

  const enterPanel = (panelData: IPanelProps, index: number) => {
    return (
      <>
        <Row className="align-items-start">
          <Col xs={6}>
            {index === 0 && (
              <>
                <label className="mb-1 ms-1">Wattage of a panel (W)</label>
                <label style={{ color: "red" }}>*</label>
              </>
            )}
          </Col>
          <Col xs={6}>
            {index === 0 && (
              <>
                <label className="mb-1 ms-1">Number of panels</label>
                <label style={{ color: "red" }}>*</label>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className="app-input">
              <input
                value={panelData.wattage || ""}
                placeholder="Wattage"
                onChange={(event) => {
                  wattageChangeHandler(
                    panelData,
                    Helper.roundTo2(Number(event.target.value))
                  );
                }}
                type="number"
                min="0"
                onKeyDown={(event) => {
                  ["e", "E", "+", "-"].includes(event.key) &&
                    event.preventDefault();
                }}
                style={
                  panelData.wattageError
                    ? { border: "1px solid #d74242ed" }
                    : {}
                }
              />
            </div>
            <p className="mb-0 text-danger font-size-12">
              {panelData.wattageError}
            </p>
          </Col>
          <Col xs={6}>
            <div className="app-input">
              <input
                value={panelData.numberOfPanels || ""}
                placeholder="Number of Panels"
                onChange={(event) => {
                  panelChangeHandler(panelData, event.target.value);
                }}
                type="number"
                min="0"
                onKeyDown={(event) => {
                  ["e", "E", "+", "-", "."].includes(event.key) &&
                    event.preventDefault();
                }}
                style={
                  panelData.quantityError
                    ? { border: "1px solid #d74242ed" }
                    : {}
                }
              />
            </div>
            <p className="mb-0 text-danger font-size-12">
              {panelData.quantityError}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const actionButtons = (panelData: IPanelProps) => {
    return (
      <>
        {props.panels.length > 1 && (
          <AppButton
            text="Remove"
            iconOnly
            iconName="close"
            variant="red"
            onClick={() => panelRemoveHandler(panelData.id)}
          />
        )}
      </>
    );
  };

  const panelRow = (panelData: IPanelProps, index: number) => (
    <React.Fragment key={panelData.id}>
      <Row className="my-2 align-items-center justify-content-between h-100">
        <Col className="col-10 col-sm-11">{enterPanel(panelData, index)}</Col>
        <Col className={`${index === 0 && `mt-5 mt-sm-4`} col-2 col-sm-1`}>
          <Row className="justify-content-center">
            <Col>{actionButtons(panelData)}</Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );

  return (
    <div>
      <div className={props.className}>
        {props.panels.map((panelData, index) => panelRow(panelData, index))}
      </div>
      <Row>
        <Col className="col-auto">
          <IconButton
            icon="add"
            text="Add Panel"
            onClick={concatNewPanelHandler}
          />
        </Col>
      </Row>
    </div>
  );
};
export default AddPanelData;
