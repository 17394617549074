import LoginLayout from "../../shared/oversight-core/shared-components/login-layout/login-layout";
import RegistrationForm from "./registration-form/registration-form";

const Register = () => {
  return (
    <LoginLayout
      title={"Create New Account"}
      subTitle={"Welcome to oversight, Please enter your details"}
      showBackButton={false}
    >
      <RegistrationForm />
    </LoginLayout>
  );
};

export default Register;
