import { Col, Row } from "react-bootstrap";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import styles from "./highest-power-usage-devices.module.scss";

interface IProps {
  onViewClick: () => void;
}

const HighestPowerUsageDevices = (props: IProps) => {
  return (
    <Row
      className={`align-items-center bg-white ${styles.highestPowerUsageDevices} px-2 py-3`}
    >
      <Col>
        <Row className="align-items-center flex-nowrap">
          <Col className="col-auto pe-0">
            <div className={`bg-red ${styles.circle}`}></div>
          </Col>
          <Col className="font-size-14 font-weight-500 text-dark">
            Devices with Highest Power Usage&nbsp;[Live]
          </Col>
        </Row>
      </Col>
      <Col className="col-auto">
        <AppButton text="View" size="medium" onClick={props.onViewClick} />
      </Col>
    </Row>
  );
};

export default HighestPowerUsageDevices;
