import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useLazyProfileQuery } from "../../../../redux/api/user/userAPI";
import { selectToken } from "../../../../redux/features/auth/auth-slice";
import { selectProfile } from "../../../../redux/features/user/user-slice";
import { UserRole } from "../../enums/user-roles";
import { AppRoute } from "../../interfaces/app-routes";
import { IHttpError } from "../../interfaces/http-errror";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import isMobileNumberVerified from "../../utils/isMobileNumberVerified";

interface IProps {
  role: UserRole;
}

const PrivateRoute = (props: IProps) => {
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const [getProfile, { isFetching, isLoading }] = useLazyProfileQuery();
  const profile = useSelector(selectProfile);

  useEffect(() => {
    getProfile()
      .unwrap()
      .catch((error: IHttpError) => {
        if (isMobileNumberVerified(error)) {
          navigate(AppRoute.VERIFY_MOBILE_NUMBER);
        }
      });
  }, []);

  useEffect(() => {
    if (!token) {
      navigate(AppRoute.LOGIN);
      return;
    }

    if (isLoading || isFetching) {
      return;
    }

    if (profile && profile.role !== props.role) {
      if (profile.role === UserRole.ROLE_ADMIN) {
        navigate(AppRoute.ADMIN);
      } else if (profile.role === UserRole.ROLE_USER) {
        navigate(AppRoute.DASHBOARD);
      } else {
        navigate(AppRoute.UNAUTHORIZED);
      }
      return;
    }
  }, [token, profile, isLoading, isFetching]);

  return (
    <>
      <SpinnerModal show={isFetching || isLoading} />
      {!isFetching && !isLoading && <Outlet />}
    </>
  );
};

export default PrivateRoute;
