import { format } from "date-fns";
import { Option } from "../../oversight-core/ui-elements/app-select/app-select";
import { RepetitionMode } from "../enums/repetition-mode";

export const generateRepetitionOptions = (date: Date) => {
  return [
    {
      value: RepetitionMode.DO_NOT_REPEAT,
      label: "Doesn't Repeat",
    },
    { value: RepetitionMode.DAILY, label: "Daily" },
    {
      value: RepetitionMode.WEEKLY,
      label: `Weekly on ${format(date, "EEEE")}`,
    },
    {
      value: RepetitionMode.MONTHLY,
      label: `Monthly on ${format(date, "dd")}`,
    },
    // {
    //   value: RepetitionMode.YEARLY,
    //   label: `Annually on ${format(date, "MMMM dd")}`,
    // },
  ];
};

export const findRepetition = (
  repetition: RepetitionMode,
  options: Option[]
) => {
  switch (repetition) {
    case RepetitionMode.DO_NOT_REPEAT:
      return options[0];
    case RepetitionMode.DAILY:
      return options[1];
    case RepetitionMode.WEEKLY:
      return options[2];
    case RepetitionMode.MONTHLY:
      return options[3];
    case RepetitionMode.YEARLY:
      return options[4];
    default:
      return options[0];
  }
};
