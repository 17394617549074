import {
  IPowerConsumerViewWithSpaceIdAndClusterId,
  IScheduledSpaceViewWithDeviceStatus,
} from "../../../screens/dashboard/schedule/components/add-update-schedule/space-hierarchy/space-hierarchy";
import { IPowerConsumerTypeAvailabilityViews } from "../dtos/view-semi-automation-schedule-device-availability-response-dto";
import { LimitationType } from "../enums/limitation-type";
import { EManagementMode } from "../enums/management-mode";
import { IDateTimeRangeView } from "../interfaces/date-time-range-view";

export const findSpace = (
  spaces: IScheduledSpaceViewWithDeviceStatus[],
  spaceId: string
): IScheduledSpaceViewWithDeviceStatus | undefined => {
  const space = spaces.find((spaceItem) => spaceItem.id === spaceId);

  if (space) {
    return space;
  } else {
    for (const spaceItem of spaces) {
      const tempSpace = findSpace(spaceItem.childSpaces, spaceId);
      if (tempSpace) {
        return tempSpace;
      }
    }
  }
};

export const findTotalCountAndAlreadyScheduledDevices = (
  space: IScheduledSpaceViewWithDeviceStatus
): {
  selected: number;
  total: number;
  excluded: number;
  alreadyScheduledDevices: IPowerConsumerViewWithSpaceIdAndClusterId[];
  selectedDevices: string[];
} => {
  if (space.childSpaces.length === 0) {
    if (
      (space.powerConsumers.length !== 0 || space.childSpaces.length !== 0) &&
      !space.isInitiallySelected
    ) {
      return {
        selected: space.powerConsumers.filter((pc) => pc.isSelected).length,
        excluded: space.powerConsumers.filter((pc) => pc.isExcluded).length,
        total: space.powerConsumers.length,
        alreadyScheduledDevices: space.powerConsumers.filter(
          (pc) => pc.isScheduled && pc.isSelected
        ),
        selectedDevices: space.powerConsumers
          .filter((pc) => pc.isSelected)
          .map((pc) => pc.id),
      };
    } else {
      return {
        selected: 0,
        excluded: 0,
        total: 0,
        alreadyScheduledDevices: [],
        selectedDevices: [],
      };
    }
  } else {
    let selected = 0;
    let excluded = 0;
    let total = 0;
    const alreadyScheduledDevices: IPowerConsumerViewWithSpaceIdAndClusterId[] =
      [];
    const selectedDevices: string[] = [];

    if (
      (space.powerConsumers.length !== 0 || space.childSpaces.length !== 0) &&
      !space.isInitiallySelected
    ) {
      for (const cs of space.childSpaces) {
        const tempFindTotalCountAndAlreadyScheduledDevicesData =
          findTotalCountAndAlreadyScheduledDevices(cs);
        selected += tempFindTotalCountAndAlreadyScheduledDevicesData.selected;
        excluded += tempFindTotalCountAndAlreadyScheduledDevicesData.excluded;
        total += tempFindTotalCountAndAlreadyScheduledDevicesData.total;
        alreadyScheduledDevices.push(
          ...tempFindTotalCountAndAlreadyScheduledDevicesData.alreadyScheduledDevices
        );
        selectedDevices.push(
          ...tempFindTotalCountAndAlreadyScheduledDevicesData.selectedDevices
        );
      }

      return {
        selected:
          space.powerConsumers.filter((pc) => pc.isSelected).length + selected,
        excluded:
          space.powerConsumers.filter((pc) => pc.isExcluded).length + excluded,
        total: space.powerConsumers.length + total,
        alreadyScheduledDevices: [
          ...space.powerConsumers.filter(
            (pc) => pc.isScheduled && pc.isSelected
          ),
          ...alreadyScheduledDevices,
        ],
        selectedDevices: [
          ...space.powerConsumers
            .filter((pc) => pc.isSelected)
            .map((pc) => pc.id),
          ...selectedDevices,
        ],
      };
    } else {
      return {
        selected,
        excluded,
        total,
        alreadyScheduledDevices,
        selectedDevices,
      };
    }
  }
};

export const updateTopToBottomIsScheduled = (
  space: IScheduledSpaceViewWithDeviceStatus,
  availabilityPowerConsumerList?: {
    deviceId: string;
    dateTimeRange: IDateTimeRangeView[];
  }[]
): IScheduledSpaceViewWithDeviceStatus => {
  if (space.childSpaces.length === 0) {
    const powerConsumers = space.powerConsumers.map((powerConsumer) => {
      const tempDevice = availabilityPowerConsumerList?.find((pc) => {
        return pc.deviceId === powerConsumer.id;
      });

      return {
        ...powerConsumer,
        isScheduled: tempDevice ? true : false,
      };
    });

    let isScheduled = false;

    for (const powerConsumer of powerConsumers) {
      if (powerConsumer.isScheduled) {
        isScheduled = true;
        break;
      }
    }
    return {
      ...space,
      powerConsumers: powerConsumers,
      isScheduled: isScheduled,
    };
  } else {
    const powerConsumers = space.powerConsumers.map((powerConsumer) => {
      const tempDevice = availabilityPowerConsumerList?.find((pc) => {
        return pc.deviceId === powerConsumer.id;
      });

      return {
        ...powerConsumer,
        isScheduled: tempDevice ? true : false,
      };
    });

    let isScheduled = false;

    for (const powerConsumer of powerConsumers) {
      if (powerConsumer.isScheduled) {
        isScheduled = true;
        break;
      }
    }

    const childSpaces: IScheduledSpaceViewWithDeviceStatus[] = [];

    for (const cs of space.childSpaces) {
      childSpaces.push(updateTopToBottomIsScheduled(cs));
    }

    let isChildSpacesScheduled = false;

    for (const childSpace of childSpaces) {
      if (childSpace.isScheduled) {
        isChildSpacesScheduled = true;
        break;
      }
    }

    return {
      ...space,
      powerConsumers: powerConsumers,
      childSpaces: childSpaces,
      isScheduled: isScheduled || isChildSpacesScheduled,
    };
  }
};

export const updateTopToBottomHelper = (
  space: IScheduledSpaceViewWithDeviceStatus,
  spaceStatus: boolean,
  isInitiallySelected: boolean,
  powerConsumerUsageGuide: IPowerConsumerTypeAvailabilityViews[],
  selectedManagementMode: EManagementMode
): IScheduledSpaceViewWithDeviceStatus => {
  const isSemiAutoMode = selectedManagementMode === EManagementMode.SEMI_AUTO;

  if (space.childSpaces.length === 0) {
    const powerConsumers: IPowerConsumerViewWithSpaceIdAndClusterId[] =
      space.powerConsumers.map((powerConsumer) => {
        const filteredPowerUsageGuide = powerConsumerUsageGuide?.filter(
          (usageGuide) =>
            Object.hasOwn(
              usageGuide.powerConsumerTypeAvailability,
              powerConsumer.deviceCategory
            )
        );

        if (filteredPowerUsageGuide?.length === 0) {
          return {
            ...powerConsumer,
            isSelected: spaceStatus || isInitiallySelected,
            isExcluded: false,
          };
        }

        const filteredExcludeFromSchedule = filteredPowerUsageGuide?.filter(
          (usageGuide) =>
            Object.hasOwn(
              usageGuide.powerConsumerTypeAvailability[
                powerConsumer.deviceCategory
              ],
              LimitationType.EXCLUDE_FROM_SCHEDULE
            )
        );

        return {
          ...powerConsumer,
          isSelected:
            (isSemiAutoMode
              ? spaceStatus && filteredExcludeFromSchedule?.length === 0
              : spaceStatus) || isInitiallySelected,
          isExcluded: filteredExcludeFromSchedule?.length !== 0,
        };
      });

    let isSelected = true;

    for (const powerConsumer of powerConsumers) {
      if (
        isSemiAutoMode
          ? !powerConsumer.isSelected && !powerConsumer.isExcluded
          : !powerConsumer.isSelected
      ) {
        isSelected = false;
        break;
      }
    }

    return {
      ...space,
      isSelected: (spaceStatus && isSelected) || isInitiallySelected,
      powerConsumers: powerConsumers,
    };
  } else {
    const powerConsumers: IPowerConsumerViewWithSpaceIdAndClusterId[] =
      space.powerConsumers.map((powerConsumer) => {
        const filteredPowerUsageGuide = powerConsumerUsageGuide?.filter(
          (usageGuide) =>
            Object.hasOwn(
              usageGuide.powerConsumerTypeAvailability,
              powerConsumer.deviceCategory
            )
        );

        if (filteredPowerUsageGuide?.length === 0) {
          return {
            ...powerConsumer,
            isSelected: spaceStatus || isInitiallySelected,
            isExcluded: false,
          };
        }

        const filteredExcludeFromSchedule = filteredPowerUsageGuide?.filter(
          (usageGuide) =>
            Object.hasOwn(
              usageGuide.powerConsumerTypeAvailability[
                powerConsumer.deviceCategory
              ],
              LimitationType.EXCLUDE_FROM_SCHEDULE
            )
        );

        return {
          ...powerConsumer,
          isSelected:
            (isSemiAutoMode
              ? spaceStatus && filteredExcludeFromSchedule?.length === 0
              : spaceStatus) || isInitiallySelected,
          isExcluded: filteredExcludeFromSchedule?.length !== 0,
        };
      });

    const childSpaces: IScheduledSpaceViewWithDeviceStatus[] = [];
    for (const cs of space.childSpaces) {
      childSpaces.push(
        updateTopToBottomHelper(
          { ...cs, isSelected: spaceStatus || cs.isInitiallySelected },
          spaceStatus,
          cs.isInitiallySelected,
          powerConsumerUsageGuide,
          selectedManagementMode
        )
      );
    }

    let isSelected = true;

    for (const powerConsumer of powerConsumers) {
      if (
        isSemiAutoMode
          ? !powerConsumer.isSelected && !powerConsumer.isExcluded
          : !powerConsumer.isSelected
      ) {
        isSelected = false;
      }
    }

    return {
      ...space,
      powerConsumers: powerConsumers,
      childSpaces: childSpaces,
      isSelected: (spaceStatus && isSelected) || isInitiallySelected,
    };
  }
};
