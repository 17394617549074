import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import {
  useAddSolarPVMutation,
  useUpdateSolarPVMutation,
} from "../../../../../../redux/api/solar/solarAPI";
import { useGetSpaceClustersQuery } from "../../../../../../redux/api/space/spaceAPI";
import AddInverters, {
  InverterProps,
  initialInverter,
} from "../../../../../../shared/components/add-inverters/add-inverters";
import AddPanelData, {
  IPanelProps,
  initialPanel,
} from "../../../../../../shared/components/add-panel-data/add-panel-data";
import AddUpdateSolarDeviceDTO from "../../../../../../shared/oversight-core/dtos/add-update-solar-device-dto";
import InstallSolarPVRequestDTO from "../../../../../../shared/oversight-core/dtos/install-solar-pv-request-dto";
import { OvstErrorCode } from "../../../../../../shared/oversight-core/enums/ovst-error-codes";
import AppSelect, {
  Option,
} from "../../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import {
  noSpecialCharsNoWhitespaceStartEndAllowCommaRegex,
  noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
} from "../../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IFormInput
  extends Omit<
    InstallSolarPVRequestDTO,
    "spaceClusterId" | "panels" | "inverterCapacities"
  > {
  spaceClusterId: Option | Option[] | undefined | [];
}

const defaultFormValues: IFormInput = {
  name: "",
  locationName: "",
  spaceClusterId: [],
};

interface IProps extends ModelContainerProps {
  closeAddUpdateSolarDeviceModal: () => void;
  device?: AddUpdateSolarDeviceDTO;
  updateSolarPV: (id: string) => void;
  isEdit: boolean;
  solarSpaceClusterList: string[];
  show: boolean;
}

const AddUpdateSolarDeviceModal = (props: IProps) => {
  const { ...rest } = props;

  const [panelsList, setPanelsList] = useState<IPanelProps[]>([
    { ...initialPanel },
  ]);

  const [inverters, setInverters] = useState<InverterProps[]>([
    { ...initialInverter },
  ]);

  const [affectedSpaceOptions, setAffectedSpaceOptions] = useState<Option[]>(
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
  });

  const { data, isSuccess, refetch } = useGetSpaceClustersQuery();
  const [addSolar, { isLoading: isLoadingAddSolar }] = useAddSolarPVMutation();

  const [updateSolar, { isLoading: isLoadingUpdateSolar }] =
    useUpdateSolarPVMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (props.show && isSuccess && data) {
      const tempSpaceClusters: Option[] = data.spaceClusters.map(
        (spaceCluster) => {
          return {
            value: spaceCluster.id,
            label: spaceCluster.rootSpace.name,
          };
        }
      );

      const filteredSolarClusters = tempSpaceClusters.filter((cluster) => {
        return (
          !props.solarSpaceClusterList.includes(cluster.value) ||
          (props.isEdit && props.device?.spaceClusterId === cluster.value)
        );
      });

      setAffectedSpaceOptions(filteredSolarClusters);
    }
  }, [data, isSuccess, props.show]);

  useEffect(() => {
    reset(defaultFormValues);
    setPanelsList([{ ...initialPanel }]);
    setInverters([{ ...initialInverter }]);
    if (props.device && props.isEdit) {
      const device = props.device;
      setValue("name", device.name);
      setValue("locationName", device.locationName);
      setValue(
        "spaceClusterId",
        affectedSpaceOptions.find(
          (option) => option.value === device.spaceClusterId
        ) || []
      );
      const panelList = device.panels.map((panel) => {
        return {
          id: uuid(),
          wattage: panel.panelWattage,
          numberOfPanels: panel.quantity,
          wattageError: "",
          quantityError: "",
        };
      });

      const inverters = device.inverterCapacities.map((inverter) => {
        return { id: uuid(), inverterCapacity: inverter, inverterError: "" };
      });

      setPanelsList(panelList);
      setInverters(inverters);
    }
  }, [props.device, props.show, affectedSpaceOptions]);

  const requestSuccessHandler = (message: string, id: string) => {
    showSuccessMessage(message);
    reset(defaultFormValues);
    setPanelsList([{ ...initialPanel }]);
    setInverters([{ ...initialInverter }]);
    props.updateSolarPV(id);
  };

  const onSubmit = (data: IFormInput) => {
    let panels;
    let inverterCapacities;

    const isValid = checkPanelInverterValidations();

    if (isValid) {
      panels = panelsList.map((panel) => {
        return {
          panelWattage: panel.wattage || 0,
          quantity: panel.numberOfPanels || 0,
        };
      });
      inverterCapacities = inverters.map((inverter) => {
        return inverter.inverterCapacity || 0;
      });

      const spaceClusterId = (data.spaceClusterId as Option).value;
      const name = data.name;
      const locationName = data.locationName;
      const id = props.device?.id || "";
      const type = data.type || "SolarPV";
      const category = data.category || "Solar";
      if (!props.isEdit) {
        addSolar({
          spaceClusterId,
          name,
          panels,
          inverterCapacities,
          locationName,
          type,
          category,
        })
          .unwrap()
          .then((response) => {
            requestSuccessHandler(
              "Solar Device Added Successfully",
              response.powerGeneratorId
            );
          })
          .catch((error) => {
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0016
            ) {
              showErrorMessage("This solar name is already in use");
              return;
            }
          });
      } else if (props.device && props.device.id) {
        updateSolar({
          id,
          name,
          type,
          category,
          panels,
          inverterCapacities,
          locationName,
          oldSpaceClusterId: spaceClusterId,
          newSpaceClusterId: spaceClusterId,
        })
          .unwrap()
          .then(() => {
            requestSuccessHandler("Solar Device Updated Successfully", id);
          })
          .catch((error) => {
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0016
            ) {
              showErrorMessage("This solar name is already in use");
              return;
            }
          });
      }
    }
  };

  const checkPanelInverterValidations = () => {
    let tempValid = true;

    const tempPanelsList = panelsList.map((panel) => {
      if (
        (panel.wattage && panel.wattage < 1) ||
        (panel.numberOfPanels && panel.numberOfPanels < 1)
      ) {
        tempValid = false;
      }

      return {
        ...panel,
        wattageError:
          !panel.wattage || panel.wattage < 1 ? "Wattage must be required" : "",
        quantityError:
          !panel.numberOfPanels || panel.numberOfPanels < 1
            ? "Number of panels must be required"
            : "",
      };
    });

    const tempInverters = inverters.map((inverter) => {
      if (inverter.inverterCapacity && inverter.inverterCapacity < 1) {
        tempValid = false;
      }
      return {
        ...inverter,
        inverterError:
          !inverter.inverterCapacity || inverter.inverterCapacity < 1
            ? "Inverter capacity must be required"
            : "",
      };
    });

    setPanelsList(tempPanelsList);
    setInverters(tempInverters);

    return tempValid;
  };

  return (
    <ModalContainer
      {...rest}
      title={props.isEdit ? "Edit Solar Device" : "Add Solar Device"}
      size="modal-lg"
      onConfirm={handleSubmit(onSubmit, checkPanelInverterValidations)}
      isLoading={isLoadingAddSolar || isLoadingUpdateSolar}
      onClose={() => {
        if (props.onClose) {
          setPanelsList([{ ...initialPanel }]);
          setInverters([{ ...initialInverter }]);
          props.onClose();
        }
      }}
      onCancel={() => {
        if (props.onCancel) {
          setPanelsList([{ ...initialPanel }]);
          setInverters([{ ...initialInverter }]);
          props.onCancel();
        }
      }}
    >
      <>
        <AppInput
          className="mt-1"
          label="Solar Name"
          placeholder="Enter Solar Name"
          name="name"
          register={register("name", {
            required: "Solar name is required",
            pattern: {
              value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
              message:
                "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
            },
          })}
          errors={errors}
        />
        <AppSelect
          className="mt-4"
          defaultValue={""}
          label="Affected Space"
          options={affectedSpaceOptions}
          control={control}
          name="spaceClusterId"
          placeholder="Enter Affected Space"
          register={register("spaceClusterId", {
            required: "Please select an affected space",
          })}
          errors={errors}
          isRequired={true}
        />
        <div className="mt-4">
          <AddPanelData panels={panelsList} setPanels={setPanelsList} />
        </div>
        <div className="mt-4">
          <AddInverters inverters={inverters} setInverters={setInverters} />
        </div>

        <AppInput
          className="mt-4"
          label="Location"
          placeholder="Location"
          icon="location_on"
          name="locationName"
          register={register("locationName", {
            required: "Location is required",
            pattern: {
              value: noSpecialCharsNoWhitespaceStartEndAllowCommaRegex,
              message:
                "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
            },
          })}
          errors={errors}
        />
      </>
    </ModalContainer>
  );
};

export default AddUpdateSolarDeviceModal;
