import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { OvstErrorCode } from "../enums/ovst-error-codes";
import { IHttpError } from "../interfaces/http-errror";

export const transformErrorResponse = (
  error: FetchBaseQueryError
): IHttpError => {
  if (!window.navigator.onLine) {
    return {
      status: +error.status,
      message: "No Internet Connection",
      ovstErrorCode: OvstErrorCode.NO_INTERNET_CONNECTION,
    };
  }

  return {
    status: +error.status,
    message: (error.data as any).message,
    ovstErrorCode: (error.data as any).ovstErrorCode,
  };
};
