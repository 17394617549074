import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useSwitchSmartControllerMutation,
  useSwitchSpaceMutation,
  useUpdateAcTemperatureMutation,
} from "../../../../../redux/api/controller/controllerAPI";
import { useLazyGetSpaceQuery } from "../../../../../redux/api/space/spaceAPI";
import {
  selectFilter,
  setFilter,
  setFilterSelectedSpace,
  setSelectedFilterSpace,
} from "../../../../../redux/features/filter/filter-slice";
import { DeviceStatus } from "../../../../../shared/oversight-core/enums/device-status";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import { ISpaceShallowHierarchyView } from "../../../../../shared/oversight-core/interfaces/space-shallow-hierarchy-view";
import IconButton from "../../../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import SpaceDeviceFilters from "../../../../../shared/oversight-general-core/components/space-device-filters/space-device-filters";
import SpacePathViewBreadcrumb from "../../../../../shared/oversight-general-core/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import { ISelectedSpace } from "../../../../../shared/oversight-general-core/interfaces/selected-space";
import AddUpdateSpaceModal from "../models/add-update-space-modal/add-update-space-modal";
import { SpaceBreadCrumbPath } from "../space-breadcrumb/space-breadcrumb";
import SpaceContainer from "../space-container/space-container";

export const initialBreadcrumbPath: SpaceBreadCrumbPath = {
  clusterId: "",
  id: "",
  name: "All Billing Spaces",
};

const SpaceCluster = () => {
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);
  const [space, setSpace] = useState<ISpaceView[]>([]);
  const [spaceId, setSpaceId] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [isPowerConsumerUpdated, setIsPowerConsumerUpdated] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [switchControllerState, { isLoading: isLoadingSwitchControllerState }] =
    useSwitchSmartControllerMutation();
  const [switchSpaceState, { isLoading: isLoadingSwitchSpaceState }] =
    useSwitchSpaceMutation();
  const closeAddUpdateSpaceModal = () => {
    setShowAddUpdateSpaceModal(false);
  };
  const [updateAcTemperature, { isLoading: isLoadingUpdateAcTemperature }] =
    useUpdateAcTemperatureMutation();

  const [
    triggerGetSpace,
    {
      data: spaceData,
      isSuccess: getSpaceSuccess,
      isFetching: isFetchingSpace,
    },
  ] = useLazyGetSpaceQuery();

  useEffect(() => {
    if (filtersStore.selectedSpace.clusterId && filtersStore.selectedSpace.id) {
      setClusterId(filtersStore.selectedSpace.clusterId);
      setSpaceId(filtersStore.selectedSpace.id);
    }
  }, [filtersStore.selectedSpace]);

  useEffect(() => {
    if (clusterId && spaceId) {
      triggerGetSpace({ clusterId, spaceId });
    }
  }, [triggerGetSpace, clusterId, spaceId, isPowerConsumerUpdated]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clusterId && spaceId) {
        triggerGetSpace({ clusterId, spaceId });
        setIsFirstTime(false);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [triggerGetSpace, clusterId, spaceId]);

  useEffect(() => {
    if (getSpaceSuccess && spaceData) {
      setSpace([
        {
          ...spaceData.subSpaceCluster.rootSpace,
          clusterId: spaceData.subSpaceCluster.id,
          accountNumber:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.accountNumber
              : "",
          accountNumberLabel:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.label
              : "",
        },
      ]);
    }
  }, [getSpaceSuccess, spaceData]);

  const updateCurrentState = () => {
    triggerGetSpace({ clusterId, spaceId });
  };

  useEffect(() => {
    if (
      filtersStore.selectedSpace.id !==
      getSplitPath()[getSplitPath().length - 1]
    ) {
      navigate(AppRoute.SPACE_CLUSTERS);
    }
  }, [filtersStore]);

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setFilterSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const updateFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    badgeValue: number
  ) => {
    const currentClusterId = filtersStore.selectedSpace.clusterId;
    const currentSpaceId = filtersStore.selectedSpace.id;

    dispatch(
      setSelectedFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );

    dispatch(
      setFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        badgeValue: badgeValue,
      })
    );
    if (
      !selectedSpace.clusterId ||
      selectedSpace.clusterId !== currentClusterId ||
      selectedSpace.id !== currentSpaceId ||
      selectedDeviceType ||
      selectedGroupBy !== "Space"
    ) {
      navigate(AppRoute.SPACE_CLUSTERS);
    }
  };

  const updateDeviceStatus = (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => {
    switchControllerState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      smartPlugId: smartDeviceId,
      switchingPowerState: status,
    })
      .unwrap()
      .then(() => {
        updateCurrentState();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSpaceStatus = (
    clusterId: string,
    spaceId: string,
    status: DeviceStatus
  ) => {
    switchSpaceState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      switchingPowerState: status,
    })
      .unwrap()
      .then(() => {
        updateCurrentState();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendSelectedSpace = (
    space: ISpaceView,
    breadcrumb?: ISpaceShallowHierarchyView[]
  ) => {
    dispatch(
      setFilterSelectedSpace({
        clusterId: space.clusterId,
        id: space.id,
        name: space.name,
      })
    );

    let path = "";

    breadcrumb?.forEach((bi) => {
      path += `${path}/${bi.id}`;
    });

    navigate(AppRoute.SPACE_CLUSTERS + path);
  };

  useEffect(() => {
    if (!isFetchingSpace) setIsPowerConsumerUpdated(false);
  }, [isFetchingSpace]);

  const onAcTemperatureIncrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };
  const onAcTemperatureDecrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };

  return (
    <>
      <SpaceDeviceFilters
        updateFilters={updateFilters}
        isFetching={isFetchingSpace && isFirstTime}
      />
      <IconButton
        icon="add"
        text="Create Billing Space"
        className="ms-2 mt-4"
        onClick={() => setShowAddUpdateSpaceModal(true)}
      />
      {space && (
        <div className="bg-white rounded-3 p-2 p-md-4 position-relative mt-4">
          <SpacePathViewBreadcrumb
            selectedSpace={{
              clusterId: filtersStore.selectedSpace.clusterId,
              spaceId: filtersStore.selectedSpace.id,
            }}
            sendSelectedSpace={sendSelectedSpace}
          />
          {(!isFetchingSpace && isFirstTime) ||
            (!spaceData && (
              <div className="container-dash mt-4">
                <Row>
                  <Col className="text-center text-light font-size-12">
                    Cannot find the space. Go to{" "}
                    <Link to={"/dashboard/space-clusters"}>
                      All Billing Spaces.
                    </Link>
                  </Col>
                </Row>
              </div>
            ))}
          {space.map((space) => {
            return (
              <SpaceContainer
                key={space.id}
                mainSpace={space}
                expandable={true}
                isShowIcon={true}
                size={16}
                updateCurrentState={updateCurrentState}
                collapsed={false}
                onSelectSpace={selectSpaceHandler}
                isPowerConsumerUpdated={isPowerConsumerUpdated}
                setIsPowerConsumerUpdated={setIsPowerConsumerUpdated}
                updateSpaceStatus={updateSpaceStatus}
                updateDeviceStatus={updateDeviceStatus}
                isUpdateDeviceStateLoading={isLoadingSwitchControllerState}
                onAcTemperatureDecrease={onAcTemperatureDecrease}
                onAcTemperatureIncrease={onAcTemperatureIncrease}
                id={`space-dropdown-id-${space.clusterId}-${space.id}`}
              />
            );
          })}
          <SpinnerModal
            show={
              (isFetchingSpace && isFirstTime) ||
              isLoadingSwitchSpaceState ||
              isLoadingSwitchControllerState ||
              isLoadingUpdateAcTemperature
            }
          />
        </div>
      )}
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceCreationType={"spaceCluster"}
        updateCurrentState={updateCurrentState}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        closeAddUpdateSpaceModal={closeAddUpdateSpaceModal}
        spaceClusterId={""}
        parentSpaceId={""}
      />
    </>
  );
};

export default SpaceCluster;
