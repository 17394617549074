import { Col, Row } from "react-bootstrap";
import SpinnerModal from "../spinner/spinner";
import styles from "./stats-views-value-with-label.module.scss";

interface IProps {
  variant: "bg-icon-2";
  labelValue: string;
  title: string;
  fontStyle?: "normal" | "bold" | "small";
  isFetching: boolean;
}

const StatsViewValueWithLabel = (props: IProps) => {
  const {
    variant: variant,
    labelValue,
    title,
    fontStyle = "bold",
    isFetching,
  } = props;
  return (
    <div className={`${styles.label} ${variant}`}>
      {isFetching ? (
        <SpinnerModal show={isFetching} withOverlay={false} size="sm" />
      ) : (
        <>
          <Row>
            <Col className="text-center">
              <div className={`${styles[fontStyle]}`}>{labelValue}</div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <div className={`${styles.title}`}> {title}</div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default StatsViewValueWithLabel;
