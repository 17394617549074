import LoginLayout from "../../shared-components/login-layout/login-layout";
import ForgotPasswordForm from "./forgot-password-form/forgot-password-form";

const ForgotPassword = () => {
  return (
    <LoginLayout
      title="Forgot Password"
      subTitle="Enter the mobile number associated with your account."
      showBackButton={true}
    >
      <ForgotPasswordForm />
    </LoginLayout>
  );
};

export default ForgotPassword;
