import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLazyViewWeeklyUsageGuideQuery } from "../../../redux/api/usage-guide/usageGuideAPI";
import {
  selectUsageGuideFilter,
  setUsageGuideFilter,
} from "../../../redux/features/usage-guide-filter/usage-guide-filter-slice";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  DataColumn,
  Header,
  TimeGridEvent,
} from "../../../shared/oversight-general-core/components/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../../shared/oversight-general-core/components/calendar/useTimeGrid";
import ViewWeeklyUsageGuideResponseDTO from "../../../shared/oversight-general-core/dtos/view-weekly-usage-guide-response-dto";
import { LimitationType } from "../../../shared/oversight-general-core/enums/limitation-type";
import { IShallowPowerUsageGuideView } from "../../../shared/oversight-general-core/interfaces/shallow-power-usage-guide-view";
import NavigateWeeks from "../../../shared/oversight-general-core/ui-elements/navigate-weeks/navigate-weeks";
import {
  getMonthRangeToDisplay,
  getWeekRange,
} from "../../../shared/oversight-general-core/utils/date-util";
import CreateModal from "./components/create-modal/create-modal";
import styles from "./usage-guide.module.scss";

type ColumnDataType = Date | string; // x-axis can be Date or Device ID

const UsageGuide = () => {
  const dispatch = useDispatch();
  const usageGuideFilterStore = useSelector(selectUsageGuideFilter);

  const [events, setEvents] = useState<
    DataColumn<Date, IShallowPowerUsageGuideView>[]
  >([]);
  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), usageGuideFilterStore.selectedDate)
  );
  const [usageGuideResponse, setUsageGuideResponse] =
    useState<IShallowPowerUsageGuideView[]>();
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [triggerWeeklyGuideQuery, { isFetching: loadingViewWeeklyUsageGuide }] =
    useLazyViewWeeklyUsageGuideQuery();

  const startDate = weekRange[0];

  useEffect(() => {
    setIsFirstTime(true);
    triggerWeeklyGuideQuery({
      startDate: moment(startDate).startOf("day").toISOString(true),
    })
      .unwrap()
      .then((res: ViewWeeklyUsageGuideResponseDTO) => {
        setUsageGuideResponse(res.weeklyPowerUsageGuides);
        setIsFirstTime(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerWeeklyGuideQuery, startDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerWeeklyGuideQuery({
        startDate: moment(startDate).startOf("day").toISOString(true),
      });
      setIsFirstTime(false);
    }, 2000);

    return () => clearInterval(interval);
  }, [triggerWeeklyGuideQuery, startDate]);

  useEffect(() => {
    setWeekRange(
      getWeekRange(
        new Date(usageGuideFilterStore.selectedDate),
        usageGuideFilterStore.selectedDate
      )
    );
  }, [usageGuideFilterStore.selectedDate]);

  const headers: Header<Date>[] = [];

  for (let cIndex = 0; cIndex < 7; cIndex++) {
    headers.push({
      columnId: moment(weekRange[cIndex]).toDate(),
      title: moment(weekRange[cIndex]).format("DD"),
      value: (
        <div className="d-flex flex-column">
          <div>{moment(weekRange[cIndex]).format("ddd")}</div>
          <div
            className={`${
              moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? `today-grid-style`
                : ``
            } px-1`}
          >
            {moment(weekRange[cIndex]).format("DD")}
          </div>
        </div>
      ),
      column: cIndex + 1, // +1 for row header
      header: true,
      row: 0,
    });
  }

  let isCurrentWeek = false;

  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const isShowTimeBar = isCurrentWeek;

  const { calendarContent } = useTimeGrid<
    ColumnDataType,
    IShallowPowerUsageGuideView
  >(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        isDisableCreatingOverlay: true,
        viewEventModalTemplate: (events, onClose) => {
          return (
            <CreateModal<ColumnDataType>
              id=""
              events={events}
              onClose={onClose}
            />
          );
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: TimeGridEvent<ColumnDataType, IShallowPowerUsageGuideView>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                {!isGrouped && event.data?.forceLevel && (
                  <Col className="col-auto">
                    <MaterialIcon
                      icon={
                        event.data?.forceLevel ===
                        LimitationType.ONLY_GIVE_WARNING
                          ? "error"
                          : "release_alert"
                      }
                      color={
                        event.data?.forceLevel ===
                        LimitationType.ONLY_GIVE_WARNING
                          ? "#E8C304"
                          : "#EC7575"
                      }
                      size={16}
                    />
                  </Col>
                )}
                <Col
                  className={`${
                    !isGrouped && event.data?.forceLevel ? "ps-0" : ""
                  } ${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>

              {!isGrouped && event.data?.isDraft && (
                <Row className="mt-2">
                  <Col>Draft</Col>
                </Row>
              )}
              {!isGrouped && (
                <Row>
                  <Col>
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Guides
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [headers, events, isShowTimeBar])
  );

  useEffect(() => {
    if (usageGuideResponse) {
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<Date, IShallowPowerUsageGuideView>
      > = {};
      usageGuideResponse.forEach((sp) => {
        sp.affectingPeriods.forEach((s) => {
          const fromDate = moment(new Date(s.fromDate));
          const toDate = moment(new Date(s.toDate));

          const day = fromDate.day();
          const columnId = fromDate.toDate().toISOString();
          const columnData = fromDate.toDate();

          const generated = {
            title: sp.title,
            columnData,
            startTime: fromDate
              .clone()
              .diff(fromDate.clone().startOf("day"), "minutes"),
            endTime: toDate
              .clone()
              .diff(toDate.clone().startOf("day"), "minutes"),
            date: fromDate.toDate(),
            data: sp,
          };

          if (generated) {
            // need to collect the event since event is generated
            if (!groupByDateOrDevId[day]) {
              // create a column if not exist
              groupByDateOrDevId[day] = {
                columnId,
                columnData,
                columnIndex: sundayStartToMondayStart(day),
                events: [],
              };
            }
            // then collect the event
            groupByDateOrDevId[day].events.push(generated);
          }
        });
      });
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [usageGuideResponse, startDate]);

  const onFilterChange = (date: Date, effectLevel: LimitationType) => {
    dispatch(
      setUsageGuideFilter({
        effectLevel,
        selectedDate: moment(date).valueOf(),
      })
    );
  };

  return (
    <div className="container-white">
      <Row className="align-items-center">
        <Col>
          <Row className="align-items-center">
            <Col className="col-auto">
              <AppDatePicker
                selectedDate={new Date(usageGuideFilterStore.selectedDate)}
                onChange={(date: Date) => {
                  onFilterChange(date, usageGuideFilterStore.effectLevel);
                }}
                isInput={true}
                showDate={false}
              />
            </Col>
            <Col className="col-auto">
              <NavigateWeeks
                selectedDate={new Date(usageGuideFilterStore.selectedDate)}
                handleDateChange={(date: Date) => {
                  onFilterChange(date, usageGuideFilterStore.effectLevel);
                }}
              />
            </Col>
            <Col className="font-size-12 font-weight-500 text-light">
              {getMonthRangeToDisplay(weekRange)}
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="position-relative mt-4">
        <Row>
          <Col className="px-2">{calendarContent}</Col>
        </Row>
        <SpinnerModal show={loadingViewWeeklyUsageGuide && isFirstTime} />
      </div>
    </div>
  );
};

export default UsageGuide;
