import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  deviceName: string;
}

const RemoveDeviceModal = (props: IProps) => {
  const { ...rest } = props;
  return (
    <ModalContainer
      {...rest}
      title="Remove Device"
      confirmButtonVariant="red"
      confirmButtonText="Remove"
      size="modal-md"
    >
      <p style={{ color: "#69768B" }} className="font-size-14 m-0">
        {props.deviceName} will be removed permanently, Are you sure you want to
        delete this device? This action cannot be undone and all associated data
        will be permanently deleted.
      </p>
    </ModalContainer>
  );
};

export default RemoveDeviceModal;
