import { Col, Row } from "react-bootstrap";
import PuffLoader from "react-spinners/PuffLoader";
import styles from "./spinner.module.scss";

interface IProps {
  show: boolean;
  withOverlay?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
}

const SpinnerModal = (props: IProps) => {
  const { withOverlay = true, size = "lg" } = props;

  const getSize = () => {
    switch (size) {
      case "sm":
        return 20;
      case "md":
        return 30;
      case "lg":
        return 40;
      case "xl":
        return 60;
      default:
        return 60;
    }
  };

  if (!withOverlay) {
    return (
      <Row className="h-100 align-items-center justify-content-center">
        <Col className="col-auto">
          <PuffLoader color="#110740" size={getSize()} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      {props.show && (
        <div
          className={[
            styles.spinnerContainer,
            "w-100 h-100 d-flex align-items-center justify-content-center",
          ].join(" ")}
        >
          <PuffLoader color="#110740" />
        </div>
      )}
    </>
  );
};
export default SpinnerModal;
