import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useDeleteUserMutation,
  useLazyViewMobileNumberQuery,
  useUpdateMobileNumberMutation,
} from "../../../../../redux/api/user/userAPI";
import { setToken } from "../../../../../redux/features/auth/auth-slice";
import { AppRoute } from "../../../interfaces/app-routes";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import AppInput from "../../../ui-elements/input/app-input";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import { mobileNumberRegex } from "../../../utils/regex";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";
import { IChangeMobileNumberFormInput } from "../../change-mobile-number/change-mobile-number-form/change-mobile-number-form";
import UserAccountCancelModal from "../user-account-cancel-modal/user-account-cancel-modal";

const defaultFormValues: IChangeMobileNumberFormInput = {
  phoneNumber: "",
};

const MobileNumberVerificationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteUser] = useDeleteUserMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IChangeMobileNumberFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const [triggerViewMobileNumber, { isFetching: isFetchingViewMobileNumber }] =
    useLazyViewMobileNumberQuery();
  const [updateMobileNumber, { isLoading: isLoadingUpdateMobileNumber }] =
    useUpdateMobileNumberMutation();
  const [userAccountCancelModal, setUserAccountCancelModal] = useState(false);

  useEffect(() => {
    triggerViewMobileNumber()
      .unwrap()
      .then((response) => {
        setValue("phoneNumber", response.mobileNumber);
      })
      .catch((error) => {
        showErrorMessage(error.message || "Something went wrong");
      });
  }, [triggerViewMobileNumber, setValue]);

  const onSubmit = (data: IChangeMobileNumberFormInput) => {
    updateMobileNumber({ contactNumber: data.phoneNumber })
      .unwrap()
      .then(() => {
        navigate(AppRoute.VERIFY_MOBILE_NUMBER_OTP, {
          state: {
            number: data.phoneNumber,
            whereTo: "dashboard/user-profile",
          },
        });
        reset({ ...defaultFormValues });
      })
      .catch((error) => {
        showErrorMessage(error.message || "Something went wrong");
      });
  };
  const onCancelAccountCreation = () => {
    deleteUser()
      .unwrap()
      .then(() => {
        showSuccessMessage("Account Creation Successfully Canceled.");
        localStorage.clear();
        dispatch(setToken(""));
      })
      .catch((error) => {
        showErrorMessage(error.message || "Failed to delete user");
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <AppInput
              name="phoneNumber"
              placeholder="Mobile Number"
              label="New Mobile Number"
              register={register("phoneNumber", {
                required: "Mobile number is required",
                pattern: {
                  value: mobileNumberRegex,
                  message: "Mobile number is invalid",
                },
              })}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <AppButton
              text={`Send OTP`}
              size="medium"
              type="submit"
              isLoading={isLoadingUpdateMobileNumber}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4 ">
          <Col
            className="text-decoration-underline cursor-pointer text-center"
            onClick={() => setUserAccountCancelModal(true)}
            style={{ color: "#2F2A89" }}
          >
            Cancel Account Creation
          </Col>
        </Row>
      </form>
      <UserAccountCancelModal
        show={userAccountCancelModal}
        onCancel={() => setUserAccountCancelModal(false)}
        onClose={() => setUserAccountCancelModal(false)}
        onConfirm={() => {
          setUserAccountCancelModal(false);
          onCancelAccountCreation();
        }}
      />
      <SpinnerModal show={isFetchingViewMobileNumber} />
    </div>
  );
};

export default MobileNumberVerificationForm;
