import moment from "moment";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { IPowerConsumerTypeAvailabilityViews } from "../../../../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-device-availability-response-dto";
import { LimitationType } from "../../../../../../shared/oversight-general-core/enums/limitation-type";
import styles from "./usage-guide-modal.module.scss";

interface IProps extends ModelContainerProps {
  show: boolean;
  deviceType: string;
  limitationType: LimitationType;
  usageGuideTimeList: IPowerConsumerTypeAvailabilityViews[];
}

const UsageGuideModal = (props: IProps) => {
  const { limitationType, deviceType, usageGuideTimeList, show, ...rest } =
    props;

  return (
    <ModalContainer
      {...rest}
      show={show}
      size="modal-md"
      isButtonsVisible={false}
    >
      <>
        <Row className="align-items-center">
          <Col className="col-auto font-size-22 font-weight-500 text-dark-2">
            {limitationType === LimitationType.ONLY_GIVE_WARNING
              ? "Reduction of Usage is Recommended"
              : "Usage is Limited "}
          </Col>
          {limitationType && (
            <Col className="col-auto">
              <div className={styles.limitationWrapper}>
                <MaterialIcon
                  icon={
                    limitationType === LimitationType.ONLY_GIVE_WARNING
                      ? "error"
                      : "release_alert"
                  }
                  color={
                    limitationType === LimitationType.ONLY_GIVE_WARNING
                      ? "#E8C304"
                      : "#EC7575"
                  }
                />
              </div>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          {limitationType === LimitationType.EXCLUDE_FROM_SCHEDULE ? (
            <Col>
              Usage of {deviceType} device type is blocked for semi-automated
              scheduling within following hours
            </Col>
          ) : (
            <Col>
              Usage of {deviceType} device type is recommended to be reduced for
              semi-automated scheduling within following hours
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          {usageGuideTimeList
            .filter((powerConsumerTypeAvailabilityView) =>
              Object.hasOwn(
                powerConsumerTypeAvailabilityView.powerConsumerTypeAvailability,
                deviceType
              )
            )
            .map((powerConsumerTypeAvailabilityView, index) => {
              return powerConsumerTypeAvailabilityView.powerConsumerTypeAvailability[
                deviceType
              ][limitationType].map((usageGuideTime) => {
                return (
                  <Col
                    className="col-auto font-size-12 font-weight-500 text-gray"
                    key={index}
                  >
                    <div
                      className={`p-2 ${
                        limitationType === LimitationType.EXCLUDE_FROM_SCHEDULE
                          ? styles.timeExclude
                          : styles.timeWarning
                      }`}
                    >
                      <Row className="flex-column align-items-center">
                        <Col className="col-auto">
                          {moment(usageGuideTime.fromDate).format("YYYY-MM-DD")}
                        </Col>
                        <Col className="col-auto">
                          {moment(usageGuideTime.fromDate).format("HH:mm")}
                          {" - "}
                          {moment(usageGuideTime.toDate).format("HH:mm")}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              });
            })}
        </Row>
      </>
    </ModalContainer>
  );
};

export default UsageGuideModal;
