import AddScheduleRequestDTO from "../../../shared/oversight-core/dtos/add-schedule-dto";
import ViewScheduleResponseDTO from "../../../shared/oversight-core/response-dto/view-schedule-dto";
import ViewScheduledSpaceClusterWithScheduledSpaceResponseDTO from "../../../shared/oversight-core/response-dto/view-scheduled-space-cluster-with-scheduled-space-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import AddManualScheduleRequestDTO from "../../../shared/oversight-general-core/dtos/add-manual-schedule-request-dto";
import AddManualScheduledResponseDTO from "../../../shared/oversight-general-core/dtos/add-manual-schedule-response-dto";
import AddSemiAutomatedScheduledRequestDTO from "../../../shared/oversight-general-core/dtos/add-semi-automated-scheduled-request-dto";
import AddSemiAutomatedScheduledResponseDTO from "../../../shared/oversight-general-core/dtos/add-semi-automated-scheduled-response-dto";
import ViewManualScheduleDeviceAvailabilityRequestDTO from "../../../shared/oversight-general-core/dtos/view-manual-schedule-device-availability-request-dto";
import ViewManualScheduleDeviceAvailabilityResponseDTO from "../../../shared/oversight-general-core/dtos/view-manual-schedule-device-availability-response-dto";
import ViewManualScheduleMonthlyEnergyForSubSpaceClusterResponseDTO from "../../../shared/oversight-general-core/dtos/view-manual-schedule-monthly-energy-for-sub-space-cluster-response-dto";
import ViewManualScheduleResponseDTO from "../../../shared/oversight-general-core/dtos/view-manual-schedule-response-dto";
import ViewSemiAutomationScheduleDeviceAvailabilityRequestDTO from "../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-device-availability-request-dto";
import ViewSemiAutomationScheduleDeviceAvailabilityResponseDTO from "../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-device-availability-response-dto";
import ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO from "../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-monthly-energy-for-sub-space-cluster-response-dto";
import ViewSemiAutomationScheduleResponseDTO from "../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-response-dto";
import ViewSemiAutomationSchedulesByPowerConsumerResponseDTO from "../../../shared/oversight-general-core/dtos/view-semi-automation-schedules-by-power-consumer-response-dto";
import ViewSubSpaceClustersWithScheduleAvailabilityRequestDTO from "../../../shared/oversight-general-core/dtos/view-sub-space-clusters-with-schedule-availability-request-dto";
import ViewSubSpaceClustersWithScheduleAvailabilityResponseDTO from "../../../shared/oversight-general-core/dtos/view-sub-space-clusters-with-schedule-availability-response-dto";
import ViewWeeklyManualSchedulesResponseDTO from "../../../shared/oversight-general-core/dtos/view-weekly-manual-schedules-response-dto";
import ViewWeeklySemiAutomationSchedulesResponseDTO from "../../../shared/oversight-general-core/dtos/view-weekly-semi-automation-schedules-response-dto";
import { rootApi } from "../apiManager";

export const scheduleAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addSemiAutomationSchedule: builder.mutation<
      AddSemiAutomatedScheduledResponseDTO,
      AddSemiAutomatedScheduledRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/sub-root/${data.subRootSpaceId}/power-consumer-usage/semi-automated/schedule`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    addManualSchedule: builder.mutation<
      AddManualScheduledResponseDTO,
      AddManualScheduleRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/sub-root/${data.subRootSpaceId}/power-consumer-usage/manual/schedule`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    updateSemiAutomationSchedule: builder.mutation<
      AddSemiAutomatedScheduledResponseDTO,
      AddSemiAutomatedScheduledRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/sub-root/${data.subRootSpaceId}/power-consumer-usage-schedule/semi-automated/${data.semiAutomatedScheduleId}/update`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    updateManualSchedule: builder.mutation<
      AddManualScheduledResponseDTO,
      AddManualScheduleRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/sub-root/${data.subRootSpaceId}/power-consumer-usage-schedule/manual/${data.manualScheduleId}/update`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    removeSemiAutomationSchedule: builder.mutation<
      void,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        scheduleId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, scheduleId }) {
        return {
          url: `/space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/semi-automated/${scheduleId}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    removeManualSchedule: builder.mutation<
      void,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        scheduleId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, scheduleId }) {
        return {
          url: `/space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/manual/${scheduleId}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    viewSubSpaceClusterWithSemiAutomationScheduleAvailability: builder.query<
      ViewSubSpaceClustersWithScheduleAvailabilityResponseDTO,
      ViewSubSpaceClustersWithScheduleAvailabilityRequestDTO
    >({
      transformErrorResponse,
      query({
        spaceClusterId,
        subRootSpaceId,
        date,
        requiredDepth,
        category,
        repeatMode,
        expireDate,
      }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/semi-automated/availability/view`,
          method: "GET",
          params: { requiredDepth, date, category, repeatMode, expireDate },
        };
      },
    }),
    viewSubSpaceClusterWithManualScheduleAvailability: builder.query<
      ViewSubSpaceClustersWithScheduleAvailabilityResponseDTO,
      ViewSubSpaceClustersWithScheduleAvailabilityRequestDTO
    >({
      transformErrorResponse,
      query({
        spaceClusterId,
        subRootSpaceId,
        date,
        requiredDepth,
        category,
        repeatMode,
        expireDate,
      }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/manual/availability/view`,
          method: "GET",
          params: { requiredDepth, date, category, repeatMode, expireDate },
        };
      },
    }),
    viewSemiAutomationScheduledSpaceCluster: builder.query<
      ViewSemiAutomationScheduleResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        scheduleId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, scheduleId }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/semi-automated/${scheduleId}/view`,
          method: "GET",
        };
      },
    }),
    viewManualScheduledSpaceCluster: builder.query<
      ViewManualScheduleResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        scheduleId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, scheduleId }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/manual/${scheduleId}/view`,
          method: "GET",
        };
      },
    }),
    viewSchedule: builder.query<
      ViewScheduledSpaceClusterWithScheduledSpaceResponseDTO,
      { spaceClusterId: string; spaceId: string; year: number; month: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${spaceId}/power-consumer-usage-schedule/by-year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
    updateSchedule: builder.query<
      ViewScheduleResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        year: number;
        month: number;
        data: AddScheduleRequestDTO;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, year, month, data }) {
        return {
          url: `space-cluster/${spaceClusterId}/space/${spaceId}/power-consumer-usage/schedule?year=${year}&month=${month}`,
          method: "POST",
          body: data,
        };
      },
    }),
    viewWeeklySemiAutomationSchedules: builder.query<
      ViewWeeklySemiAutomationSchedulesResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        startDate: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, startDate }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/semi-automated/weekly/view`,
          method: "GET",
          params: {
            startDate,
          },
        };
      },
      providesTags: () => {
        return ["Schedule"];
      },
    }),
    viewWeeklyManualSchedules: builder.query<
      ViewWeeklyManualSchedulesResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        startDate: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, startDate }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/manual/weekly/view`,
          method: "GET",
          params: {
            startDate,
          },
        };
      },
      providesTags: () => {
        return ["Schedule"];
      },
    }),
    viewSemiAutomationScheduleAvailability: builder.query<
      ViewSemiAutomationScheduleDeviceAvailabilityResponseDTO,
      ViewSemiAutomationScheduleDeviceAvailabilityRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `semi-automation/schedule/availability`,
          method: "POST",
          body: {
            selectedPeriods: data.selectedPeriods,
          },
          params: {
            scheduleAvailabilityType: data.scheduleAvailabilityType,
            spaceClusterId: data.spaceClusterId,
            subRootSpaceId: data.subRootSpaceId,
            requiredDepth: data.requiredDepth,
            fromDate: data.fromDate,
            toDate: data.toDate,
            category: data.category,
            repeatMode: data.repeatMode,
            expireDate: data.expireDate,
            excludeScheduleId: data.excludeScheduleId,
          },
        };
      },
      providesTags: () => {
        return ["Schedule"];
      },
    }),
    viewManualScheduleAvailability: builder.query<
      ViewManualScheduleDeviceAvailabilityResponseDTO,
      ViewManualScheduleDeviceAvailabilityRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `manual/schedule/availability`,
          method: "POST",
          body: {
            selectedPeriods: data.selectedPeriods,
          },
          params: {
            scheduleAvailabilityType: data.scheduleAvailabilityType,
            spaceClusterId: data.spaceClusterId,
            subRootSpaceId: data.subRootSpaceId,
            requiredDepth: data.requiredDepth,
            fromDate: data.fromDate,
            toDate: data.toDate,
            category: data.category,
            repeatMode: data.repeatMode,
            expireDate: data.expireDate,
            excludeScheduleId: data.excludeScheduleId,
          },
        };
      },
      providesTags: () => {
        return ["Schedule"];
      },
    }),
    viewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster: builder.query<
      ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        year: number;
        month: number;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/semi-automated/energy/by-year-month/view`,
          method: "GET",
          params: {
            year,
            month,
          },
        };
      },
    }),
    viewManualScheduleMonthlyEnergyForSubSpaceCluster: builder.query<
      ViewManualScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
      {
        spaceClusterId: string;
        subRootSpaceId: string;
        year: number;
        month: number;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/power-consumer-usage-schedule/manual/energy/by-year-month/view`,
          method: "GET",
          params: {
            year,
            month,
          },
        };
      },
    }),
    viewSemiAutomationSchedulesByPowerConsumer: builder.query<
      ViewSemiAutomationSchedulesByPowerConsumerResponseDTO,
      {
        spaceClusterId: string;
        linkedPowerConsumerId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, linkedPowerConsumerId }) {
        return {
          url: `space-cluster/${spaceClusterId}/power-consumer-usage-schedule/semi-automated/by-power-consumer/view`,
          method: "GET",
          params: {
            linkedPowerConsumerId,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddSemiAutomationScheduleMutation,
  useAddManualScheduleMutation,
  useUpdateSemiAutomationScheduleMutation,
  useUpdateManualScheduleMutation,
  useRemoveSemiAutomationScheduleMutation,
  useRemoveManualScheduleMutation,
  useLazyViewSubSpaceClusterWithSemiAutomationScheduleAvailabilityQuery,
  useLazyViewSubSpaceClusterWithManualScheduleAvailabilityQuery,
  useLazyViewSemiAutomationScheduledSpaceClusterQuery,
  useLazyViewManualScheduledSpaceClusterQuery,
  useViewWeeklySemiAutomationSchedulesQuery,
  useViewWeeklyManualSchedulesQuery,
  useLazyViewScheduleQuery,
  useLazyUpdateScheduleQuery,
  useLazyViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useLazyViewSemiAutomationScheduleAvailabilityQuery,
  useLazyViewManualScheduleAvailabilityQuery,
  useLazyViewSemiAutomationSchedulesByPowerConsumerQuery,
} = scheduleAPI;
