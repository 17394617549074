import styles from "./active-inactive-indicator.module.scss";

interface IProps {
  isActive?: boolean;
}

const ActiveInactiveIndicator = (props: IProps) => {
  const { isActive = true } = props;
  return (
    <div
      className={`${styles.indicator} ${
        isActive ? styles.activeColor : styles.inactiveColor
      }`}
    ></div>
  );
};

export default ActiveInactiveIndicator;
