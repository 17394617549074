import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyGetSpacesQuery } from "../../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { selectSmartControllersFilter } from "../../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import { EDeviceTypes } from "../../../oversight-core/enums/device-types";
import { AppRoute } from "../../../oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../oversight-core/interfaces/entities/space";
import FilteringSidePanel from "../../../oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect, {
  Option,
} from "../../../oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../oversight-core/ui-elements/buttons/app-button/app-button";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../../oversight-core/utils/getDeviceType";
import { ISelectedSpace } from "../../interfaces/selected-space";
import ButtonWithBadge from "../../ui-elements/button-with-badge/button-with-badge";
import Search from "../search/search";
import SelectedFiltersView from "../selected-filters-view/selected-filters-view";
import SpaceSelectorDropdown from "../space-selector-dropdown/space-selector-dropdown";
import styles from "./smart-controllers-filter.module.scss";

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const defaultDeviceType: Option = deviceTypes[0];

interface IProps {
  updateFilters: (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    selectedSearchTextType: string,
    badgeValue: number
  ) => void;
  isLoading: boolean;
}

const groupBy = [
  { label: "Space", value: "Space" },
  { label: "Type", value: "Type" },
  { label: "Status", value: "Status" },
];

const controllerOptions: Option[] = [
  {
    value: "Controller",
    label: "Controller",
  },
  {
    value: "Device",
    label: "Device",
  },
];

const SmartControllersFilter = (props: IProps) => {
  const navigate = useNavigate();
  const { updateFilters, isLoading } = props;
  const smartControllersFiltersStore = useSelector(
    selectSmartControllersFilter
  );
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    ...smartControllersFiltersStore.selectedFilterSpace,
  });
  const [searchText, setSearchText] = useState(
    smartControllersFiltersStore.searchText
  );
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...(deviceTypes.find(
      (deviceType) =>
        deviceType.value === smartControllersFiltersStore.selectedDeviceType
    ) || defaultDeviceType),
  });
  const [selectedGroupBy, setSelectedGroupBy] = useState<Option>({
    ...(groupBy.find(
      (groupByItem) =>
        groupByItem.value === smartControllersFiltersStore.selectedGroupBy
    ) || groupBy[0]),
  });
  const [badgeValue, setBadgeValue] = useState(
    smartControllersFiltersStore.badgeValue
  );
  const [selectedSearchTextType, setSelectedSearchTextType] = useState<Option>({
    ...(controllerOptions.find(
      (controllerOption) =>
        controllerOption.value ===
        smartControllersFiltersStore.selectedSearchTextType
    ) || controllerOptions[0]),
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);

  const [triggerGetSpaces] = useLazyGetSpacesQuery();

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        let tempArray: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          tempArray.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        tempArray = tempArray.sort((a, b) =>
          a.name.trim().localeCompare(b.name.trim())
        );
        setSpaceClusters([...tempArray]);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  const handleSearchChange = () => {
    updateFilters(
      {
        clusterId: selectedSpace.clusterId,
        id: selectedSpace.id,
        name: selectedSpace.name,
      },
      searchText,
      selectedDeviceType.value,
      selectedGroupBy.value,
      selectedSearchTextType.value,
      badgeValue
    );
  };

  useEffect(() => {
    if (
      selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id &&
      selectedDeviceType.value &&
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(3);
    } else if (
      (selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id &&
        selectedDeviceType.value) ||
      (selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id &&
        selectedGroupBy.value !== "Space" &&
        selectedGroupBy.value !== "Type") ||
      (selectedDeviceType.value && selectedGroupBy.value !== "Space") ||
      (selectedSpace.clusterId &&
        selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id &&
        selectedGroupBy.value !== "Space")
    ) {
      setBadgeValue(2);
    } else if (
      selectedSpace.id !== billingSpaceFilter.spaceCluster.rootSpace.id ||
      selectedDeviceType.value ||
      selectedGroupBy.value !== "Space"
    ) {
      setBadgeValue(1);
    } else {
      setBadgeValue(0);
    }
  }, [
    selectedSpace.clusterId,
    selectedSpace.id,
    selectedDeviceType.value,
    selectedGroupBy.value,
  ]);

  useEffect(() => {
    setSelectedSpace(smartControllersFiltersStore.selectedFilterSpace);
  }, [smartControllersFiltersStore.selectedFilterSpace]);

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <Col>
          <Row>
            <Col className="col-5 col-lg-2 pe-0">
              <AppSelect
                selectedValue={selectedSearchTextType}
                options={controllerOptions}
                borderRadiusStyle={"Right"}
                className="font-size-12 font-weight-500 text-light"
                onChangeOption={(selectedOption) => {
                  setSelectedSearchTextType(selectedOption);
                }}
              />
            </Col>
            <Col className="col-7 col-lg-5 col-xl-3 ps-0 pe-lg-0">
              <Search
                onSearch={handleSearchChange}
                onChange={(searchText) => {
                  setSearchText(searchText);
                }}
                value={searchText}
                placeholder="Search Controller"
                isWithOptions={true}
              />
            </Col>
            <Col className="col-auto d-none d-lg-block">
              <AppButton
                text="Add&nbsp;Controller"
                onClick={() => {
                  navigate(AppRoute.ADD_CONTROLLER);
                }}
                size="small"
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-lg-auto mt-4 mt-lg-0">
          <Row className="justify-content-between">
            <Col className="d-block d-lg-none">
              <AppButton
                text="Add&nbsp;Controller"
                onClick={() => {
                  navigate(AppRoute.ADD_CONTROLLER);
                }}
                className="mb-2"
              />
            </Col>
            <Col className={`${styles.FiltersView} col-auto`}>
              <Row className={`${styles.filter}`}>
                <Col className="col-auto ">
                  <SelectedFiltersView
                    spaceName={smartControllersFiltersStore.selectedSpace.name}
                    filters={[
                      {
                        icon: smartControllersFiltersStore.selectedDeviceType
                          ? (smartControllersFiltersStore.selectedDeviceType as EDeviceTypes)
                          : EDeviceTypes.ALL_DEVICES,
                        filterName:
                          smartControllersFiltersStore.selectedDeviceType ||
                          "All Devices",
                      },
                    ]}
                  />
                </Col>
                <Col className="col-auto">
                  <ButtonWithBadge
                    text="Filter"
                    icon="filter_alt"
                    onClick={() => {
                      setShowFilters(true);
                    }}
                    badgeValue={badgeValue}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <FilteringSidePanel
        isOpen={showFilters && !isLoading}
        onClose={() => setShowFilters(false)}
        onFilter={() => {
          setShowFilters(false);
          updateFilters(
            {
              clusterId: selectedSpace.clusterId,
              id: selectedSpace.id,
              name: selectedSpace.name,
            },
            searchText,
            selectedDeviceType.value,
            selectedGroupBy.value,
            selectedSearchTextType.value,
            badgeValue
          );
        }}
        onClearAll={() => {
          setShowFilters(false);
          updateFilters(
            {
              clusterId: billingSpaceFilter.spaceCluster.id,
              id: billingSpaceFilter.spaceCluster.rootSpace.id,
              name: billingSpaceFilter.spaceCluster.label,
            },
            "",
            "",
            "",
            "Space",
            0
          );
          setSelectedSpace({
            ...smartControllersFiltersStore.selectedSpace,
          });
          setSelectedDeviceType({ ...defaultDeviceType });
          setSelectedGroupBy({ ...groupBy[0] });
          setBadgeValue(0);
        }}
      >
        <>
          <SpaceSelectorDropdown
            dropdownOptions={[
              ...spaceClusters
                .filter(
                  (space) =>
                    space.clusterId ===
                    smartControllersFiltersStore.selectedFilterSpace.clusterId
                )
                .map((space) => {
                  return {
                    clusterId: space.clusterId,
                    id: space.id,
                    name: space.name,
                    childSpaces: space.childSpaces,
                  };
                }),
            ]}
            disabled={false}
            selectedSubSpace={selectedSpace.name}
            updateSelectedSubSpaceId={(
              selectedSubSpaceId: string,
              selectedSubSpaceName: string,
              clusterId?: string
            ) => {
              setSelectedSpace({
                id: selectedSubSpaceId,
                name: selectedSubSpaceName,
                clusterId: clusterId || "",
              });
            }}
            label="Space"
            className="font-weight-500 text-light"
          />
          <AppSelect
            label="Device Type"
            options={[...deviceTypes]}
            className="mt-4"
            selectedValue={selectedDeviceType}
            onChangeOption={(selectedDeviceTypeOption) =>
              setSelectedDeviceType(selectedDeviceTypeOption)
            }
            disabled={
              selectedGroupBy.value === "Type" ||
              selectedGroupBy.value === "Status"
            }
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />

          <AppSelect
            label="Group By"
            options={groupBy}
            className="mt-4"
            selectedValue={selectedGroupBy}
            onChangeOption={(selectedGroupByOption) => {
              setSelectedGroupBy(selectedGroupByOption);
            }}
            disabled={selectedDeviceType.value ? true : false}
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
        </>
      </FilteringSidePanel>
    </>
  );
};

export default SmartControllersFilter;
