export interface ITableRowProps {
  data: JSX.Element[];
}

const TableRow = (props: ITableRowProps) => {
  const { data } = props;
  return (
    <tr style={{ verticalAlign: "middle" }}>
      <>
        {data.map((item, index) => (
          <td key={index} className="text-light font-size-12 font-weight-400">
            {item}
          </td>
        ))}
      </>
    </tr>
  );
};

export default TableRow;
