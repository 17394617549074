import NotificationsCard from "./notification-card/notification-card";
import styles from "./notifications-panel.module.scss";

interface IProps {
  showNotifications: boolean;
}

const notifications = [
  {
    from: "Admin",
    title: "Reduction of Usage is Recommended",
    time: "3 hours ago",
    description:
      "Usage of selected device types are recommended to be reduced within 8.00pm to 9.00pm.",
  },
  {
    from: "Admin",
    title: "Usage is Limited",
    time: "2 Days ago",
    description:
      "Usage of selected device types are recommended to be reduced within 8.00pm to 9.00pm.",
  },
  {
    from: "Admin",
    title: "Usage is Limited",
    time: "5 Days ago",
    description:
      "Usage of selected device types are recommended to be reduced within 8.00pm to 9.00pm.",
  },
];

const NotificationsPanel = (props: IProps) => {
  return (
    <div
      className={`mx-2 ${styles["side-drawer"]} ${
        props.showNotifications ? styles.open : styles.close
      }`}
    >
      <NotificationsCard notifications={notifications} />
    </div>
  );
};

export default NotificationsPanel;
