import Tooltip from "rc-tooltip";
import { Col, Row } from "react-bootstrap";
import { IEnergyView } from "../../../../../shared/oversight-core/interfaces/entities/energy-view";
import { IScheduledSpaceClusterAverageWeeklyEnergy } from "../../../../../shared/oversight-core/interfaces/scheduled-space-cluster-average-weekly-energy";
import AppDatePicker from "../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AverageUsageUnits from "../../../../../shared/oversight-core/ui-elements/average-usage-units/average-usage-units";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import StatsViewTypeTwo from "../../../../../shared/oversight-core/ui-elements/stats-view/stats-view-type-two";
import Helper from "../../../../../shared/oversight-core/utils/helpers";
import { EManagementMode } from "../../../../../shared/oversight-general-core/enums/management-mode";
import styles from "./schedule-tab-view.module.scss";

interface IProps {
  selectedDate: Date;
  selectedManagementMode: Option;
  isFetching: boolean;
  scheduledSpaceClusterAverageWeeklyEnergy: IScheduledSpaceClusterAverageWeeklyEnergy;
  spaceClusterEstimatedConsumption: IEnergyView;
  setSelectedManagementMode: React.Dispatch<React.SetStateAction<Option>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

const managementModes: Option[] = [
  {
    value: EManagementMode.SEMI_AUTO,
    label: "Semi-Auto",
  },
  { value: EManagementMode.MANUAL, label: "Manual" },
  {
    value: EManagementMode.AUTO_AI,
    label: "Auto-AI",
    data: { isDisabled: true },
  },
];

const ScheduleTabView = (props: IProps) => {
  const {
    selectedDate,
    isFetching,
    scheduledSpaceClusterAverageWeeklyEnergy,
    spaceClusterEstimatedConsumption,
    selectedManagementMode,
    setSelectedDate,
    setSelectedManagementMode,
  } = props;

  return (
    <Row className={`align-items-center justify-content-center`}>
      <Col className="col-12 col-lg">
        <Row className="align-items-center">
          <Col className="col-10 col-sm-5 col-lg-8 col-xl-5 col-xxl-3">
            <AppSelect
              icon="autoplay"
              selectedValue={selectedManagementMode}
              options={[...managementModes]}
              onChangeOption={(selectOption) => {
                setSelectedManagementMode(selectOption);
              }}
              fontSize="12px"
              labelFontSize="font-size-14"
              fontWeight="500"
              fontColor="#69768b"
              isOptionsDisabled={(option) => option?.data?.isDisabled}
            />
          </Col>
          <Col className="col-auto px-0">
            <Tooltip
              placement="top"
              trigger={["hover"]}
              overlayInnerStyle={{
                borderColor: "#F8FA9C",
                backgroundColor: "#F8F9EB",
              }}
              overlay={
                <div className="text-light font-size-14 font-weight-400">
                  {selectedManagementMode.value === EManagementMode.MANUAL ? (
                    <>
                      Manual mode data is only used to create a schedule of
                      device usage, <br />
                      because devices in manual mode do not turn on
                      automatically
                    </>
                  ) : selectedManagementMode.value ===
                    EManagementMode.SEMI_AUTO ? (
                    <>
                      Once a semi-auto schedule is added, devices will be
                      controlled <br /> automatically according to the schedule
                    </>
                  ) : (
                    ``
                  )}
                </div>
              }
            >
              <div className={`${styles.instruction} p-1 user-select-none`}>
                <MaterialIcon icon="live_help" color="#cbcd68" size={20} />
              </div>
            </Tooltip>
          </Col>
          <Col className="col-xl-6 col-xxl-4 mt-2 mt-sm-0 mt-lg-2 mt-xl-0">
            <AppDatePicker
              monthYearPicker
              onChange={(date) => {
                setSelectedDate(date);
              }}
              selectedDate={selectedDate}
              fontSize={12}
              datePickerTextClassName="text-app-select font-weight-400"
            />
          </Col>
        </Row>
      </Col>
      <Col className="col-auto">
        <Row className="align-items-center justify-content-lg-end mt-3 mt-xl-0">
          <Col md={12} lg={"auto"} className="px-4">
            <AverageUsageUnits
              variant="bg-icon-2"
              title="Average Usage Units"
              labelValue={{
                weekDay:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weekdayDailyEnergy
                      .energyInUnits
                  ) + "",
                weekEnd:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weekendDailyEnergy
                      .energyInUnits
                  ) + "",
                weekly:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weeklyEnergy
                      .energyInUnits
                  ) + "",
              }}
              titleStyle="jakarta font-weight-500 text-dark"
              isFetching={isFetching}
            />
          </Col>
          <Col sm={6} lg="auto" className="mt-3 mt-lg-0">
            <StatsViewTypeTwo
              isFetching={isFetching}
              variant="bg-icon-2"
              labelValue={
                Helper.roundTo2(
                  spaceClusterEstimatedConsumption.energyInUnits
                ) + ""
              }
              title="Estimated Units"
            />
          </Col>
          <Col sm={6} lg="auto" className="mt-3 mt-lg-0">
            <StatsViewTypeTwo
              isFetching={isFetching}
              variant="bg-icon-2"
              labelValue={
                "Rs." +
                Helper.roundTo2(spaceClusterEstimatedConsumption.energyBill) +
                ""
              }
              title="Estimated Bill"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ScheduleTabView;
