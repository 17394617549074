import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import { IPowerConsumerTypeAvailabilityViews } from "../../../../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-device-availability-response-dto";
import { LimitationType } from "../../../../../../shared/oversight-general-core/enums/limitation-type";
import { EManagementMode } from "../../../../../../shared/oversight-general-core/enums/management-mode";
import { IDateTimeRangeView } from "../../../../../../shared/oversight-general-core/interfaces/date-time-range-view";
import { ISchedulePowerConsumerView } from "../../../../../../shared/oversight-general-core/interfaces/schedule-power-consumer-view";
import { IScheduledSpaceView } from "../../../../../../shared/oversight-general-core/interfaces/scheduled-space-view";
import AppSwitch from "../../../../../../shared/oversight-general-core/ui-elements/app-switch/app-switch";
import DeviceCard from "../../../../../../shared/oversight-general-core/ui-elements/device-card/device-card";
import styles from "./space-hierarchy.module.scss";

export interface IPowerConsumerViewWithSpaceIdAndClusterId
  extends ISchedulePowerConsumerView {
  spaceId: string;
  clusterId: string;
  isExcluded?: boolean;
}
export interface IScheduledSpaceViewWithDeviceStatus
  extends Omit<IScheduledSpaceView, "childSpaces" | "powerConsumers"> {
  powerConsumers: IPowerConsumerViewWithSpaceIdAndClusterId[];
  childSpaces: IScheduledSpaceViewWithDeviceStatus[];
  clusterId: string;
  isSelected: boolean;
  isScheduled: boolean;
  isInitiallySelected: boolean;
}

interface IProps {
  mainSpace: IScheduledSpaceViewWithDeviceStatus;
  expandable: boolean;
  collapsed: boolean;
  isExpanded: boolean;
  indent: boolean;
  updateDeviceStatus: (
    spaceClusterId: string,
    spaceId: string,
    deviceId: string,
    deviceStatus: boolean
  ) => void;
  checkAllChildSpacesActivated?: () => void;
  updateSpaceStatus: (spaceClusterId: string, spaceId: string) => void;
  updateTopToBottom: (
    spaceClusterId: string,
    spaceId: string,
    spaceStatus: boolean
  ) => void;
  isApplyForChildSpaces: boolean;
  powerConsumerUsageGuide: IPowerConsumerTypeAvailabilityViews[];
  conflictedScheduleList: {
    deviceId: string;
    dateTimeRange: IDateTimeRangeView[];
  }[];
  selectedManagementMode: EManagementMode;
}

const SpaceHierarchy = (props: IProps) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  useEffect(() => {
    if (props.checkAllChildSpacesActivated) {
      props.checkAllChildSpacesActivated();
    }
  }, [props.mainSpace.isSelected]);

  const checkAllChildSpacesActivated = () => {
    props.updateSpaceStatus(props.mainSpace.clusterId, props.mainSpace.id);
  };

  const updateDeviceStatus = (
    spaceClusterId: string,
    spaceId: string,
    deviceId: string,
    deviceStatus: boolean
  ) => {
    props.updateDeviceStatus(spaceClusterId, spaceId, deviceId, deviceStatus);
  };
  return (
    <>
      <div
        className={`${props.indent ? "ps-3" : ""} ${
          props.indent && styles.leftBorder
        }`}
      >
        <Row className="align-items-center">
          <Col
            xs={"auto"}
            className="mb-4 text-light font-weight-400 font-size-14"
          >
            {props.mainSpace.name}
          </Col>
          <Col xs={"auto"} className="mb-4">
            <AppSwitch
              isOn={props.mainSpace.isSelected}
              size="sm"
              onSwitch={() => {
                props.updateTopToBottom(
                  props.mainSpace.clusterId,
                  props.mainSpace.id,
                  !props.mainSpace.isSelected
                );
              }}
            />
          </Col>
          <Col className="col-auto ps-4 mb-4">
            <MaterialIcon
              icon="expand_more"
              className={`${styles["d-arrow"]} cursor-pointer`}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                setCollapsed(!collapsed);
              }}
              style={
                collapsed
                  ? { transform: "rotate(-90deg)" }
                  : { transform: "rotate(0deg)" }
              }
              color="#69768B"
            />
          </Col>
          {props.mainSpace.isScheduled &&
            (props.isApplyForChildSpaces
              ? true
              : props.mainSpace.powerConsumers.find(
                  (pc) => pc.isScheduled
                )) && (
              <Col xs={"auto"} className="mb-4">
                <Col className="col-auto px-1">
                  <MaterialIcon icon="warning" color="#CB343D" />
                </Col>
              </Col>
            )}
        </Row>
        <div className={`${props.indent && styles.leftBorder}`}>
          <Row className={`${collapsed ? "d-none" : ""} mx-2`}>
            {props.mainSpace.powerConsumers.map((d, index) => {
              const filteredPowerUsageGuide =
                props.powerConsumerUsageGuide?.filter((usageGuide) =>
                  Object.hasOwn(
                    usageGuide.powerConsumerTypeAvailability,
                    d.deviceCategory
                  )
                );

              if (filteredPowerUsageGuide?.length === 0) {
                return (
                  <Col key={index} className="col-12 mt-2">
                    <DeviceCard
                      device={{
                        ...d,
                        clusterId: props.mainSpace.clusterId,
                        spaceId: props.mainSpace.id,
                      }}
                      updateDeviceStatus={updateDeviceStatus}
                      conflictedScheduleList={props.conflictedScheduleList}
                    />
                  </Col>
                );
              }

              const filteredExcludeFromSchedule =
                filteredPowerUsageGuide?.filter((usageGuide) =>
                  Object.hasOwn(
                    usageGuide.powerConsumerTypeAvailability[d.deviceCategory],
                    LimitationType.EXCLUDE_FROM_SCHEDULE
                  )
                );

              return (
                <Col key={index} className="col-12 mt-2">
                  <DeviceCard
                    device={{
                      ...d,
                      clusterId: props.mainSpace.clusterId,
                      spaceId: props.mainSpace.id,
                    }}
                    updateDeviceStatus={updateDeviceStatus}
                    limitationType={
                      filteredExcludeFromSchedule?.length === 0
                        ? LimitationType.ONLY_GIVE_WARNING
                        : LimitationType.EXCLUDE_FROM_SCHEDULE
                    }
                    conflictedScheduleList={props.conflictedScheduleList}
                    selectedManagementMode={props.selectedManagementMode}
                    usageGuideTimeList={props.powerConsumerUsageGuide}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        <Row className={`${collapsed ? "d-none" : ""} mt-4`}>
          <Col>
            {props.mainSpace.childSpaces &&
              props.mainSpace.childSpaces.length > 0 && (
                <div>
                  {props.mainSpace.childSpaces.map(
                    (ss, index) =>
                      (ss.powerConsumers.length !== 0 ||
                        ss.childSpaces.length !== 0) &&
                      !ss.isInitiallySelected &&
                      props.isApplyForChildSpaces && (
                        <SpaceHierarchy
                          key={index}
                          mainSpace={{
                            ...ss,
                            clusterId: props.mainSpace.clusterId,
                          }}
                          indent={true}
                          expandable={true}
                          collapsed={!props.isExpanded}
                          isExpanded={props.isExpanded}
                          updateDeviceStatus={props.updateDeviceStatus}
                          updateSpaceStatus={props.updateSpaceStatus}
                          checkAllChildSpacesActivated={
                            checkAllChildSpacesActivated
                          }
                          updateTopToBottom={props.updateTopToBottom}
                          isApplyForChildSpaces={props.isApplyForChildSpaces}
                          powerConsumerUsageGuide={
                            props.powerConsumerUsageGuide
                          }
                          conflictedScheduleList={props.conflictedScheduleList}
                          selectedManagementMode={props.selectedManagementMode}
                        />
                      )
                  )}
                </div>
              )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SpaceHierarchy;
