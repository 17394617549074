import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserProfile } from "../../../shared/oversight-core/interfaces/user-profile";
import { RootState } from "../../store";

type UserState = {
  profile?: IUserProfile;
};

const initialState: UserState = {
  profile: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState: { ...initialState },
  reducers: {
    setProfile: (state, action: PayloadAction<IUserProfile>) => {
      state.profile = action.payload;
    },
  },
});

export const { setProfile } = userSlice.actions;

export const selectProfile = (state: RootState) => state.user.profile;

export default userSlice.reducer;
