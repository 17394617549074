import AddUpdateEnergyGenerationRequestDTO from "../../../shared/oversight-core/dtos/add-update-energy-generation-request-dto";
import InstallSolarPVRequestDTO from "../../../shared/oversight-core/dtos/install-solar-pv-request-dto";
import UpdateSolarPVRequestDTO from "../../../shared/oversight-core/dtos/update-solar-pv-request-dto";
import InstallSolarPVResponseDTO from "../../../shared/oversight-core/response-dto/add-solar-pv-response-dto";
import AddUpdateEnergyGenerationResponseDTO from "../../../shared/oversight-core/response-dto/add-update-energy-generation-responseDTO";
import IUpdateSolarPVResponseDTO from "../../../shared/oversight-core/response-dto/update-solar-pv-response-dto";
import ViewAllPowerGeneratorsResponseDTO from "../../../shared/oversight-core/response-dto/view-all-power-generators-response-dto";
import ViewEnergyGenerationResponseDTO from "../../../shared/oversight-core/response-dto/view-energy-generation-response-dto";
import ViewPredictedEnergyGenerationResponseDTO from "../../../shared/oversight-core/response-dto/view-predicted-energy-generation-response-dto";
import IViewSolarPVResponseDTO from "../../../shared/oversight-core/response-dto/view-solar-pv-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const solarAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addSolarPV: builder.mutation<
      InstallSolarPVResponseDTO,
      InstallSolarPVRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/power-generator/solar-pv/install`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateSolarPV: builder.mutation<
      IUpdateSolarPVResponseDTO,
      UpdateSolarPVRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `power-generator/solar-pv/${data.id}/update`,
          method: "PUT",
          body: data,
        };
      },
    }),
    getSolarPV: builder.query<
      IViewSolarPVResponseDTO,
      {
        solarId: string;
      }
    >({
      transformErrorResponse,
      query({ solarId }) {
        return {
          url: `power-generator/solar-pv/${solarId}/view`,
          method: "GET",
        };
      },
    }),
    removeSolarPV: builder.mutation<void, { solarId: string }>({
      transformErrorResponse,
      query({ solarId }) {
        return {
          url: `power-generator/solar-pv/${solarId}/delete`,
          method: "DELETE",
        };
      },
    }),
    getAllPowerGenerators: builder.query<
      ViewAllPowerGeneratorsResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `power-generator/view`,
          method: "GET",
        };
      },
    }),
    addEnergyGeneration: builder.mutation<
      AddUpdateEnergyGenerationResponseDTO,
      AddUpdateEnergyGenerationRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `power-generator/solar-pv/${data.powerGeneratorId}/energy-generation/add`,
          method: "POST",
          body: {
            generatedEnergyInKiloWattHours: data.generatedEnergyInKiloWattHours,
          },
          params: { year: data.year, month: data.month },
        };
      },
    }),
    updateEnergyGeneration: builder.mutation<
      AddUpdateEnergyGenerationResponseDTO,
      AddUpdateEnergyGenerationRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `power-generator/solar-pv/${data.powerGeneratorId}/energy-generation/update`,
          method: "PUT",
          body: {
            generatedEnergyInKiloWattHours: data.generatedEnergyInKiloWattHours,
          },
          params: { year: data.year, month: data.month },
        };
      },
    }),
    getEnergyGenerationData: builder.query<
      ViewEnergyGenerationResponseDTO,
      {
        powerGeneratorId: string;
        year?: number;
      }
    >({
      transformErrorResponse,
      query({ powerGeneratorId, year }) {
        return {
          url: `power-generator/${powerGeneratorId}/energy-generation/view`,
          method: "GET",
          params: { year },
        };
      },
    }),
    getMonthlyEnergyGeneration: builder.query<
      ViewPredictedEnergyGenerationResponseDTO,
      {
        powerGeneratorId: string;
        year: number;
        month: number;
      }
    >({
      transformErrorResponse,
      query({ powerGeneratorId, year, month }) {
        return {
          url: `power-generator/${powerGeneratorId}/energy-generation/per-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddSolarPVMutation,
  useUpdateSolarPVMutation,
  useGetAllPowerGeneratorsQuery,
  useRemoveSolarPVMutation,
  useGetSolarPVQuery,
  useAddEnergyGenerationMutation,
  useUpdateEnergyGenerationMutation,
  useLazyGetEnergyGenerationDataQuery,
  useGetMonthlyEnergyGenerationQuery,
  useLazyGetSolarPVQuery,
  useLazyGetAllPowerGeneratorsQuery,
  useLazyGetMonthlyEnergyGenerationQuery,
} = solarAPI;
