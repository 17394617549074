import { useState } from "react";
import { EConsumptionViewTypes } from "../../../oversight-core/enums/consumption-view-types";
import AppSelect, {
  Option,
} from "../../../oversight-core/ui-elements/app-select/app-select";
import ModalContainer, {
  ModelContainerProps,
} from "../../../oversight-core/ui-elements/modal-container/modal-container";

const predictionOptions: Option[] = [
  { value: EConsumptionViewTypes.CONSUMED, label: "Monthly Actual Bill" },
  { value: EConsumptionViewTypes.SCHEDULED, label: "Monthly Estimation" },
  { value: EConsumptionViewTypes.ALL, label: "Both" },
];

interface IProps extends ModelContainerProps {
  updateViewConsumptionAs: (viewConsumptionAs: EConsumptionViewTypes) => void;
}

const ChartOptionsModal = (props: IProps) => {
  const { ...rest } = props;
  const [viewConsumptionAs, setViewConsumptionAs] = useState<Option>(
    predictionOptions[0]
  );
  return (
    <ModalContainer
      {...rest}
      title={"Table Options"}
      size="modal-lg"
      onConfirm={() => {
        if (props.onConfirm) {
          props.updateViewConsumptionAs(
            viewConsumptionAs.value as EConsumptionViewTypes
          );
          props.onConfirm();
        }
      }}
    >
      <AppSelect
        label="Plot"
        placeholder="Select Predictions"
        options={predictionOptions}
        selectedValue={viewConsumptionAs}
        onChangeOption={(selectedOption: Option) => {
          setViewConsumptionAs(selectedOption);
        }}
      />
    </ModalContainer>
  );
};

export default ChartOptionsModal;
