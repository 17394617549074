export enum GroupBy {
  STATUS = "STATUS",
  DEVICE_TYPE = "DEVICE_TYPE",
  DEVICE_CATEGORY = "DEVICE_CATEGORY",
}

export default interface ListSmartControllersRequestDTO {
  searchField?: string;
  searchValue?: string;
  spaceClusterId?: string;
  subRootSpaceId?: string;
  type?: string;
  groupBy?: GroupBy;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  ascending?: boolean;
}
