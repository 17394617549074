import moment from "moment";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import OverrideDeviceLimitationCard from "../../../../../shared/oversight-core/ui-elements/override-device-limitation-card/override-device-limitation-card";
import { TimeGridEvent } from "../../../../../shared/oversight-general-core/components/calendar/models";
import { LimitationType } from "../../../../../shared/oversight-general-core/enums/limitation-type";
import { RepetitionMode } from "../../../../../shared/oversight-general-core/enums/repetition-mode";
import { IShallowPowerUsageGuideView } from "../../../../../shared/oversight-general-core/interfaces/shallow-power-usage-guide-view";
import styles from "./create-modal.module.scss";

interface IProps<ColumnIdType, T> {
  id: string;
  events: TimeGridEvent<ColumnIdType, T>[];
  onClose: () => void;
}

const CreateModal = <Date,>(
  props: IProps<Date, IShallowPowerUsageGuideView>
) => {
  const { events, onClose } = props;
  const findSubtitle = (mode: string) => {
    switch (mode as RepetitionMode) {
      case RepetitionMode.DAILY:
        return `Daily`;
      case RepetitionMode.DO_NOT_REPEAT:
        return "Once";
      case RepetitionMode.MONTHLY:
        return "Monthly";
      case RepetitionMode.WEEKLY:
        return "Weekly";
      case RepetitionMode.YEARLY:
        return "Annually";
    }
  };

  return (
    <div
      className={`${styles.container} ${
        events.length > 1 ? styles.containerBoxShadow : ""
      } py-3 px-1`}
    >
      <>
        {events.length > 1 && (
          <Row className="align-items-center px-4 pt-2">
            <Col className="font-size-16 font-weight-500 text-dark">
              {events.length} Events
            </Col>
            <Col className="col-auto">
              <MaterialIcon
                icon="close"
                color="#383941"
                className="cursor-pointer"
                onClick={onClose}
              />
            </Col>
          </Row>
        )}
        <div className={styles.scrollableArea}>
          {events.map((event, eventIndex) => {
            return (
              <div
                key={eventIndex}
                className={`${events.length !== 1 ? "mt-4" : ""} px-4 py-2 ${
                  styles.eventContainer
                }`}
              >
                <Row>
                  <Col>
                    <MaterialIcon
                      icon={
                        event.data?.forceLevel ===
                        LimitationType.ONLY_GIVE_WARNING
                          ? "error"
                          : "release_alert"
                      }
                      color={
                        event.data?.forceLevel ===
                        LimitationType.ONLY_GIVE_WARNING
                          ? "#E8C304"
                          : "#EC7575"
                      }
                    />
                  </Col>
                  {events.length === 1 && (
                    <Col xs={"auto"}>
                      <MaterialIcon
                        icon="close"
                        color="#383941"
                        className="cursor-pointer"
                        onClick={onClose}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mt-2">
                  <Col className="font-size-16 font-weight-500 text-dark">
                    {event.title}
                  </Col>
                </Row>
                <Row>
                  <Col className="font-size-12 font-weight-500 text-light">
                    {(event.date ? moment(event.date) : moment()).format(
                      "ddd(DD), MMM, yyyy"
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="font-size-12 font-weight-500 text-light">
                    {findSubtitle(event.data?.repetitionMode || "")}
                  </Col>
                </Row>
                <Row className="mt-4 align-items-center">
                  <Col xs={`auto`} className="pe-0">
                    <MaterialIcon icon="schedule" color="#69768B" />
                  </Col>
                  <Col>
                    <Row>
                      <Col className="font-size-12 font-weight-400 text-light">
                        <div>
                          {(event.date ? moment(event.date) : moment())
                            .startOf("day")
                            .add(event.startTime, "minutes")
                            .format("hh:mm A")}{" "}
                          -{" "}
                          {(event.date ? moment(event.date) : moment())
                            .startOf("day")
                            .add(event.endTime, "minutes")
                            .format("hh:mm A")}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="font-size-12 font-weight-500">
                    Device Categories
                  </Col>
                </Row>
                <Row>
                  {event.data?.affectingPowerConsumerTypes.map(
                    (deviceType, index) => {
                      return (
                        <Col key={index} className="px-4 col-6 mt-2">
                          <OverrideDeviceLimitationCard
                            deviceType={deviceType}
                          />
                        </Col>
                      );
                    }
                  )}
                </Row>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default CreateModal;
