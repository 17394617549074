import { useDeleteAccountNumberMutation } from "../../../../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../../../../enums/ovst-error-codes";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../ui-elements/modal-container/modal-container";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../utils/toast";

interface IProps extends ModelContainerProps {
  accountNumber: string;
  getAccountNumbers: () => void;
}

const RemoveAccountNumberModal = (props: IProps) => {
  const { ...rest } = props;

  const [deleteAccountNumber, { isLoading: isLoadingDeleteAccountNumber }] =
    useDeleteAccountNumberMutation();

  const removeAccountNumber = () => {
    deleteAccountNumber({ accountNumber: props.accountNumber })
      .unwrap()
      .then(() => {
        props.getAccountNumbers();
        showSuccessMessage("Account Number Deleted Successfully");
      })
      .catch((error) => {
        if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001) {
          showErrorMessage("Invalid Account Number");
        } else if (error.ovstErrorCode === OvstErrorCode.OVST_0034) {
          showErrorMessage("User doesn't exist");
        } else if (error.ovstErrorCode === OvstErrorCode.OVST_0040) {
          showErrorMessage(
            "Can't delete the account number because it is associated with a space cluster"
          );
        } else {
          showErrorMessage(
            error.message || "Deleting account number is unsuccessful"
          );
        }
      });
  };

  return (
    <ModalContainer
      {...rest}
      title="Remove Account Number"
      confirmButtonVariant="red"
      confirmButtonText="Remove"
      size="modal-md"
      onConfirm={() => {
        removeAccountNumber();
        if (props.onConfirm) {
          props.onConfirm();
        }
      }}
      isLoading={isLoadingDeleteAccountNumber}
    >
      <p style={{ color: "#69768B" }} className="font-size-14 m-0">
        {props.accountNumber} will be removed permanently, Are you sure you want
        to delete this account number? This action cannot be undone and all
        associated data will be permanently deleted.
      </p>
    </ModalContainer>
  );
};

export default RemoveAccountNumberModal;
