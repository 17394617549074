import { format } from "date-fns";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAddBillMutation } from "../../../../../../redux/api/energy-consumption/energyConsumptionAPI";
import IEnergySummaryView from "../../../../../../shared/oversight-core/interfaces/energy-summary-view";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { decimalRegex } from "../../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IFormInput {
  units: number | undefined;
  billCost: number | undefined;
}

const defaultFormValues: IFormInput = {
  units: undefined,
  billCost: undefined,
};

interface IProps extends ModelContainerProps {
  closeAddUpdateBillDetailsDataModal: () => void;
  spaceClusterId?: string;
  year: string;
  month: Date;
  rootSpaceId: string;
  rootSpaceName: string;
  isEditMode: boolean;
  show: boolean;
  actualConsumption?: IEnergySummaryView;
  updateActualConsumption: () => void;
  accountNumber: string;
}

const AddUpdateBillDetailsModal = (props: IProps) => {
  const { ...rest } = props;
  const [
    addBill,
    { isSuccess: isSuccessAddBill, isLoading: isLoadingAddBill },
  ] = useAddBillMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    if (props.isEditMode) {
      setValue(
        "units",
        props.actualConsumption?.consumedEnergySummary.energyInUnits || 0
      );
      setValue(
        "billCost",
        props.actualConsumption?.consumedEnergySummary.energyBill || 0
      );
    } else {
      setValue("units", undefined);
      setValue("billCost", undefined);
    }
  }, [props.actualConsumption, props.show]);

  const onSubmit = (data: IFormInput) => {
    const spaceClusterId: string = props.rootSpaceId;
    const month = parseInt(format(props.month, "M"));
    const year = parseInt(props.year);
    addBill({
      spaceClusterId,
      units: data.units || 0,
      billCost: data.billCost || 0,
      month,
      year,
    })
      .unwrap()
      .catch(() => {
        showErrorMessage(
          props.isEditMode
            ? "Updating Bill Unsuccessful"
            : "Adding Bill Unsuccessful"
        );
      });
  };

  useEffect(() => {
    if (isSuccessAddBill) {
      const message = props.isEditMode
        ? "Bill Updated Successfully"
        : "Bill Added Successfully";
      showSuccessMessage(message);
      reset({ ...defaultFormValues });
      props.closeAddUpdateBillDetailsDataModal();
      props.updateActualConsumption();
    }
  }, [isSuccessAddBill]);

  return (
    <ModalContainer
      {...rest}
      title={props.isEditMode ? "Edit Bill Details" : "Add Bill Details"}
      size="modal-lg"
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoadingAddBill}
      onCancel={() => {
        reset({ ...defaultFormValues });
        if (props.onCancel) {
          props.onCancel();
        }
      }}
      onClose={() => {
        reset({ ...defaultFormValues });
        if (props.onClose) {
          props.onClose();
        }
      }}
    >
      <>
        <Row className="justify-content-between text-muted font-size-20 font-weight-500">
          <Col className="col-12 col-sm-auto col-md-auto mb-4 mb-md-0">
            <div>{props.rootSpaceName}</div>
            <div className="text-muted font-size-14 font-weight-500">
              {props.accountNumber}
            </div>
          </Col>
          <Col className="col-12 col-sm-auto col-md-auto">
            {format(props.month, "MMMM")} {props.year}
          </Col>
        </Row>
        <AppInput
          className="mt-4"
          label="Units"
          placeholder="Units"
          name="units"
          type="number"
          register={register("units", {
            required: "Units is required",
            min: {
              value: 0.0001,
              message: "Please enter a value greater than 0",
            },
            pattern: {
              value: decimalRegex,
              message: "Can't be more than two decimal places",
            },
          })}
          errors={errors}
        />
        <AppInput
          className="mt-4"
          label="Bill Value (Rs)"
          placeholder="Bill Value"
          name="billCost"
          type="number"
          register={register("billCost", {
            required: "Bill Value is required",
            min: {
              value: 0.0001,
              message: "Please enter a value greater than 0",
            },
            pattern: {
              value: decimalRegex,
              message: "Can't be more than two decimal places",
            },
          })}
          errors={errors}
        />
      </>
    </ModalContainer>
  );
};

export default AddUpdateBillDetailsModal;
