import { useRemoveSolarPVMutation } from "../../../../../../redux/api/solar/solarAPI";
import AddUpdateSolarDeviceDTO from "../../../../../../shared/oversight-core/dtos/add-update-solar-device-dto";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IProps extends ModelContainerProps {
  device?: AddUpdateSolarDeviceDTO;
  updateSolarPV: (id: string) => void;
}

const RemoveSolarDeviceModal = (props: IProps) => {
  const [removeSolarPV, { isLoading: isRemoveSolarPVLoading }] =
    useRemoveSolarPVMutation();

  const requestSuccessHandler = (message: string) => {
    showSuccessMessage(message);
    props.updateSolarPV("");
  };

  const handleOnConfirm = () => {
    removeSolarPV({
      solarId: props.device?.id || "",
    })
      .unwrap()
      .then(() => {
        requestSuccessHandler("Solar Device Removed Successfully");
      })
      .catch(() => {
        showErrorMessage("Removing Solar Device Unsuccessful");
      });
  };

  const { ...rest } = props;
  return (
    <ModalContainer
      {...rest}
      title="Remove Solar Device"
      confirmButtonVariant="red"
      confirmButtonText="Remove"
      size="modal-md"
      onConfirm={handleOnConfirm}
      isLoading={isRemoveSolarPVLoading}
    >
      <p style={{ color: "#69768B" }} className="font-size-14 m-0">
        {props.device?.name || ""} will be removed permanently, Are you sure you
        want to remove this device? This action cannot be undone and all
        associated data will be permanently deleted.
      </p>
    </ModalContainer>
  );
};

export default RemoveSolarDeviceModal;
