import { v4 as uuid } from "uuid";
import { formatTime } from "./time-utils";

export const convertIsoStringToTimeList = (
  ISOTimeList: { fromDate: string; toDate: string }[]
) => {
  return ISOTimeList.map((timeItem) => {
    const fromDate = new Date(timeItem.fromDate);
    const toDate = new Date(timeItem.toDate);

    const fromString = formatTime(fromDate);
    const toString = formatTime(toDate);

    return {
      id: uuid(),
      startTime: { value: fromString, label: fromString },
      endTime: { value: toString, label: toString },
      startTimeError: "",
      endTimeError: "",
    };
  });
};
