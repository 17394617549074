import { Col, Row } from "react-bootstrap";
import SpinnerModal from "../spinner/spinner";
import styles from "./average-usage-units.module.scss";

interface IProps {
  variant: "bg-icon-2" | "bg-icon-3" | "bg-icon-4";
  labelValue: {
    weekDay: string;
    weekEnd: string;
    weekly: string;
  };
  title: string;
  fontStyle?: "normal" | "bold";
  titleStyle?: string;
  isFetching?: boolean;
}

const AverageUsageUnits = (props: IProps) => {
  const { variant, labelValue, title, fontStyle = "bold", isFetching } = props;

  return (
    <Row>
      <Col className="text-center">
        <Row className="justify-content-center flex-nowrap">
          {isFetching ? (
            <Col
              className={`${styles.label} ${variant} text-center ${
                styles[`border-radius`]
              }`}
            >
              <SpinnerModal show={isFetching} withOverlay={false} size="sm" />
            </Col>
          ) : (
            <>
              <Col className={`col-4 col-lg-auto px-0 `}>
                <div
                  className={`${styles.label} ${variant} ${
                    styles[fontStyle]
                  } text-center ${styles[`border-radius-left`]}`}
                >
                  {labelValue.weekDay}
                  <p className="mb-0 text-dark font-size-8 jakarta font-weight-500">
                    WEEKDAY
                  </p>
                </div>
              </Col>
              <Col className="col-4 col-lg-auto px-0">
                <div
                  className={`${styles.label} ${variant} ${styles[fontStyle]} text-center`}
                >
                  {labelValue.weekEnd}
                  <p className="mb-0 text-dark font-size-8 jakarta font-weight-500">
                    WEEKEND
                  </p>
                </div>
              </Col>
              <Col className="col-4 col-lg-auto px-0">
                <div
                  className={`${styles.label} ${variant} ${
                    styles[fontStyle]
                  } text-center ${styles[`border-radius-right`]}`}
                >
                  {labelValue.weekly}
                  <p className="mb-0 text-dark font-size-8 jakarta font-weight-500">
                    WEEKLY&nbsp;
                  </p>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-1">
          <Col className={styles.title}>{title}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AverageUsageUnits;
