import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import styles from "./filter-search.module.scss";

interface IProps {
  onChange: (searchText: string) => void;
  placeholder: string;
  value: string;
}

const FilterSearch = (props: IProps) => {
  const { onChange, placeholder, value } = props;

  return (
    <div className={styles.searchWrapper}>
      <Row className="align-items-center px-2 py-1">
        <Col>
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value);
            }}
            className={`${styles[`search-input`]} w-100`}
          />
        </Col>
        <Col className="col-auto">
          <MaterialIcon icon="search" color="#69768B" size={20} />
        </Col>
      </Row>
    </div>
  );
};

export default FilterSearch;
