import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetControllerStatisticsQuery,
  useLazyGetEnergyConsumptionStatisticsByDateQuery,
  useLazyGetGraphQuery,
  useLazyGetPowerUsageStatisticsByYearMonthQuery,
} from "../../../redux/api/controller/controllerAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import HeightPowerUsageDevices from "../../../shared/components/height-power-usage-devices/highest-power-usage-devices";
import RealTimePowerUsageChartView from "../../../shared/components/real-time-power-usage-chart-view/real-time-power-usage-chart-view";
import StaticInfoCard from "../../../shared/components/static-info-card/static-info-card";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { Option } from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import ControllerStatisticsResponseDTO from "../../../shared/oversight-general-core/dtos/controller-statistics-response-dto";
import EnergyConsumptionStatisticsByDateResponseDTO from "../../../shared/oversight-general-core/dtos/energy-consumption-statistics-by-date-response-dto";
import PowerUsageStatisticsByYearMonthResponseDTO from "../../../shared/oversight-general-core/dtos/power-usage-statistics-by-year-month-response-dto";
import { ISelectedSpace } from "../../../shared/oversight-general-core/interfaces/selected-space";

const defaultStatistics: ControllerStatisticsResponseDTO = {
  latestPowerInWatt: 0,
  maxPowerInWatt: 0,
  averagePowerInWatt: 0,
};

const defaultPowerUsage: PowerUsageStatisticsByYearMonthResponseDTO = {
  maxPowerInWatt: 0,
  averagePowerInWatt: 0,
};

const defaultEnergyConsumption: EnergyConsumptionStatisticsByDateResponseDTO = {
  consumedEnergyInUnits: 0,
  dailyAverageConsumedEnergyInUnits: 0,
  monthlyConsumedEnergyInUnits: 0,
  monthlyAverageConsumedEnergyInUnits: 0,
};

const defaultGraph = [0, 0, 0, 0, 0];

const Usage = () => {
  const navigate = useNavigate();
  const billingSpaceFilterStore = useSelector(selectBillingSpaceFilter);
  const [filters, setFilters] = useState<
    | {
        selectedSpace: ISelectedSpace;
        selectedDeviceType: Option;
        selectedDevice: Option;
      }
    | undefined
  >(undefined);
  const [graphData, setGraphData] = useState<number[]>([...defaultGraph]);
  const [statistics, setStatistics] = useState<ControllerStatisticsResponseDTO>(
    { ...defaultStatistics }
  );
  const [powerUsage, setPowerUsage] =
    useState<PowerUsageStatisticsByYearMonthResponseDTO>({
      ...defaultPowerUsage,
    });
  const [energyConsumption, setEnergyConsumption] =
    useState<EnergyConsumptionStatisticsByDateResponseDTO>({
      ...defaultEnergyConsumption,
    });

  const onFilter = (
    selectedSpace: ISelectedSpace,
    selectedDeviceType: Option,
    selectedDevice: Option
  ) => {
    setFilters({ selectedSpace, selectedDeviceType, selectedDevice });
  };

  const [triggerGetControllerGraph, { isFetching: isFetchingControllerGraph }] =
    useLazyGetGraphQuery();
  const [
    triggerGetControllerStatistics,
    { isFetching: isFetchingControllerStatistics },
  ] = useLazyGetControllerStatisticsQuery();
  const [
    triggerGetPowerUsageStatisticsByYearMonth,
    { isFetching: isFetchingPowerUsageStatisticsByYearMonth },
  ] = useLazyGetPowerUsageStatisticsByYearMonthQuery();
  const [
    triggerGetEnergyConsumptionStatisticsByDate,
    { isFetching: isFetchingEnergyConsumptionStatisticsByDate },
  ] = useLazyGetEnergyConsumptionStatisticsByDateQuery();

  useEffect(() => {
    if (
      filters &&
      filters.selectedSpace.clusterId &&
      filters.selectedSpace.id &&
      filters.selectedDevice?.data?.id
    ) {
      triggerGetControllerGraph({
        spaceClusterId: filters.selectedSpace.clusterId,
        spaceId: filters.selectedSpace.id,
        smartPlugId: filters.selectedDevice.data.id,
        from: moment().toISOString(),
      })
        .unwrap()
        .then((res) => {
          const data = res.powerUsage.map((dataItem) => dataItem.powerInWatt);
          setGraphData(data);
        })
        .catch(() => {
          setGraphData([...defaultGraph]);
        });
    } else {
      triggerGetControllerGraph({
        spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
        spaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
        from: moment().toISOString(),
      })
        .unwrap()
        .then((res) => {
          const data = res.powerUsage.map((dataItem) => dataItem.powerInWatt);
          setGraphData(data);
        })
        .catch(() => {
          setGraphData([...defaultGraph]);
        });
    }
  }, [filters, billingSpaceFilterStore.spaceCluster]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        filters &&
        filters.selectedSpace.clusterId &&
        filters.selectedSpace.id &&
        filters.selectedDevice?.data?.id
      ) {
        triggerGetControllerGraph({
          spaceClusterId: filters.selectedSpace.clusterId,
          spaceId: filters.selectedSpace.id,
          smartPlugId: filters.selectedDevice.data.id,
          from: moment().toISOString(),
        })
          .unwrap()
          .then((res) => {
            const data = res.powerUsage.map((dataItem) => dataItem.powerInWatt);
            setGraphData(data);
          })
          .catch(() => {
            setGraphData([...defaultGraph]);
          });
      } else {
        triggerGetControllerGraph({
          spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
          spaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
          from: moment().toISOString(),
        })
          .unwrap()
          .then((res) => {
            const data = res.powerUsage.map((dataItem) => dataItem.powerInWatt);
            setGraphData(data);
          })
          .catch(() => {
            setGraphData([...defaultGraph]);
          });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [filters, billingSpaceFilterStore.spaceCluster]);

  useEffect(() => {
    if (
      filters &&
      filters.selectedSpace.clusterId &&
      filters.selectedSpace.id &&
      filters.selectedDevice?.data?.id
    ) {
      triggerGetControllerStatistics({
        spaceClusterId: filters.selectedSpace.clusterId,
        spaceId: filters.selectedSpace.id,
        smartPlugId: filters.selectedDevice.data.id,
        date: moment().toISOString(),
      })
        .unwrap()
        .then((res) => {
          setStatistics(res);
        })
        .catch(() => {
          setStatistics({ ...defaultStatistics });
        });
    } else {
      triggerGetControllerStatistics({
        spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
        spaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
        date: moment().toISOString(),
      })
        .unwrap()
        .then((res) => {
          setStatistics(res);
        })
        .catch(() => {
          setStatistics({ ...defaultStatistics });
        });
    }
  }, [filters, billingSpaceFilterStore.spaceCluster]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        filters &&
        filters.selectedSpace.clusterId &&
        filters.selectedSpace.id &&
        filters.selectedDevice?.data?.id
      ) {
        triggerGetControllerStatistics({
          spaceClusterId: filters.selectedSpace.clusterId,
          spaceId: filters.selectedSpace.id,
          smartPlugId: filters.selectedDevice.data.id,
          date: moment().toISOString(),
        })
          .unwrap()
          .then((res) => {
            setStatistics(res);
          })
          .catch(() => {
            setStatistics({ ...defaultStatistics });
          });
      } else {
        triggerGetControllerStatistics({
          spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
          spaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
          date: moment().toISOString(),
        })
          .unwrap()
          .then((res) => {
            setStatistics(res);
          })
          .catch(() => {
            setStatistics({ ...defaultStatistics });
          });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [filters, billingSpaceFilterStore.spaceCluster]);

  useEffect(() => {
    if (
      filters &&
      filters.selectedSpace.clusterId &&
      filters.selectedSpace.id &&
      filters.selectedDevice?.data?.id
    ) {
      triggerGetPowerUsageStatisticsByYearMonth({
        spaceClusterId: filters.selectedSpace.clusterId,
        spaceId: filters.selectedSpace.id,
        smartPlugId: filters.selectedDevice.data.id,
        year: moment().year(),
        month: moment().month() + 1,
      })
        .unwrap()
        .then((res) => {
          setPowerUsage(res);
        })
        .catch(() => {
          setPowerUsage({ ...defaultPowerUsage });
        });
    } else {
      triggerGetPowerUsageStatisticsByYearMonth({
        spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
        spaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
        year: moment().year(),
        month: moment().month() + 1,
      })
        .unwrap()
        .then((res) => {
          setPowerUsage(res);
        })
        .catch(() => {
          setPowerUsage({ ...defaultPowerUsage });
        });
    }
  }, [filters, billingSpaceFilterStore.spaceCluster]);

  useEffect(() => {
    if (
      filters &&
      filters.selectedSpace.clusterId &&
      filters.selectedSpace.id &&
      filters.selectedDevice?.data?.id
    ) {
      triggerGetEnergyConsumptionStatisticsByDate({
        spaceClusterId: filters.selectedSpace.clusterId,
        spaceId: filters.selectedSpace.id,
        smartPlugId: filters.selectedDevice.data.id,
        date: moment().toISOString(),
      })
        .unwrap()
        .then((res) => {
          setEnergyConsumption(res);
        })
        .catch(() => {
          setEnergyConsumption({ ...defaultEnergyConsumption });
        });
    } else {
      triggerGetEnergyConsumptionStatisticsByDate({
        spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
        spaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
        date: moment().toISOString(),
      })
        .unwrap()
        .then((res) => {
          setEnergyConsumption(res);
        })
        .catch(() => {
          setEnergyConsumption({ ...defaultEnergyConsumption });
        });
    }
  }, [filters, billingSpaceFilterStore.spaceCluster]);

  return (
    <>
      <RealTimePowerUsageChartView
        deviceName={
          filters?.selectedDevice?.data?.linkedPowerConsumer?.name || ""
        }
        deviceType={
          filters?.selectedDevice?.data?.linkedPowerConsumer?.deviceType
        }
        spaceName={filters?.selectedSpace.name || ""}
        graphData={graphData}
        statistics={statistics}
        onFilter={onFilter}
        isFetching={isFetchingControllerGraph || isFetchingControllerStatistics}
      />
      <Row className="mt-4">
        <Col className="col-12" lg={6}>
          <StaticInfoCard
            type="POWER_USAGE"
            spaceName="Home"
            date={new Date()}
            onMoreInfoClick={() => {
              console.log();
            }}
            averageConsumption={+powerUsage.averagePowerInWatt.toFixed(2)}
            highestConsumption={+powerUsage.maxPowerInWatt.toFixed(2)}
            isFetching={isFetchingPowerUsageStatisticsByYearMonth}
          />
        </Col>
        <Col className="col-12 mt-4 mt-lg-0" lg={6}>
          <StaticInfoCard
            type="ENERGY_USAGE"
            spaceName="Home"
            date={new Date()}
            onMoreInfoClick={() => {
              navigate(AppRoute.ENERGY_USAGE);
            }}
            todayUsage={+energyConsumption.consumedEnergyInUnits.toFixed(2)}
            dailyAverageUsage={
              +energyConsumption.dailyAverageConsumedEnergyInUnits.toFixed(2)
            }
            currentMonthUsage={
              +energyConsumption.monthlyConsumedEnergyInUnits.toFixed(2)
            }
            monthlyAverageUsage={
              +energyConsumption.monthlyAverageConsumedEnergyInUnits.toFixed(2)
            }
            isFetching={isFetchingEnergyConsumptionStatisticsByDate}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col className="px-4">
          <HeightPowerUsageDevices
            onViewClick={() => {
              navigate(AppRoute.HIGHEST_POWER_CONSUMPTION);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Usage;
