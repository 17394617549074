import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLazyListSmartControllersQuery,
  useSwitchSmartControllerMutation,
} from "../../../redux/api/controller/controllerAPI";
import {
  selectSmartControllersFilter,
  setSelectedSmartControllerFilterSpace,
  setSmartControllersFilter,
} from "../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import { DeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import SmartControllersFilter from "../../../shared/oversight-general-core/components/smart-controllers-filter/smart-controllers-filter";
import { GroupBy } from "../../../shared/oversight-general-core/dtos/list-smart-controllers-request-dto";
import {
  IListSmartControllersGroupedResponseDTO,
  IListSmartControllersResponseDTO,
} from "../../../shared/oversight-general-core/dtos/list-smart-controllers-response-dto";
import { IGroupedSmartControllers } from "../../../shared/oversight-general-core/interfaces/grouped-smart-controllers";
import { ISelectedSpace } from "../../../shared/oversight-general-core/interfaces/selected-space";
import { ISpaceClusterSmartPlugViews } from "../../../shared/oversight-general-core/interfaces/space-cluster-smart-plug-views";
import ControllerCard from "../../../shared/oversight-general-core/ui-elements/controller-card/controller-card";
import styles from "./controllers.module.scss";

const Controllers = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const smartControllersFiltersStore = useSelector(
    selectSmartControllersFilter
  );

  const [smartControllersListGrouped, setSmartControllersListGrouped] =
    useState<IGroupedSmartControllers[]>([]);
  const [smartControllersListNotGrouped, setSmartControllersListNotGrouped] =
    useState<ISpaceClusterSmartPlugViews[]>([]);
  const [groupedCollapsed, setGroupedCollapsed] = useState<boolean[]>([]);
  const [isControllerUpdated, setIsControllerUpdated] = useState(false);

  const [
    triggerListSmartControllers,
    { isFetching: isFetchingListSmartControllers },
  ] = useLazyListSmartControllersQuery();
  const [switchControllerState, { isLoading: isLoadingSwitchControllerState }] =
    useSwitchSmartControllerMutation();

  useEffect(() => {
    if (smartControllersFiltersStore.selectedGroupBy === "Space") {
      if (
        smartControllersFiltersStore.selectedSpace.clusterId &&
        smartControllersFiltersStore.selectedSpace.id
      ) {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          type: smartControllersFiltersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          spaceClusterId: smartControllersFiltersStore.selectedSpace.clusterId,
          subRootSpaceId: smartControllersFiltersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersResponseDTO) => {
            setSmartControllersListNotGrouped(response.smartDevices.elements);
          })
          .catch(() => {
            setSmartControllersListNotGrouped([]);
          });
      } else {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          type: smartControllersFiltersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersResponseDTO) => {
            setSmartControllersListNotGrouped(response.smartDevices.elements);
          })
          .catch(() => {
            setSmartControllersListNotGrouped([]);
          });
      }
    } else {
      if (
        smartControllersFiltersStore.selectedSpace.clusterId &&
        smartControllersFiltersStore.selectedSpace.id
      ) {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          groupBy:
            smartControllersFiltersStore.selectedGroupBy === "Type"
              ? GroupBy.DEVICE_CATEGORY
              : GroupBy.STATUS,
          spaceClusterId: smartControllersFiltersStore.selectedSpace.clusterId,
          subRootSpaceId: smartControllersFiltersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersGroupedResponseDTO) => {
            setSmartControllersListGrouped(response.smartDevices.elements);

            const temp: boolean[] = [];
            response.smartDevices.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setSmartControllersListGrouped([]);
          });
      } else {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          groupBy:
            smartControllersFiltersStore.selectedGroupBy === "Type"
              ? GroupBy.DEVICE_CATEGORY
              : GroupBy.STATUS,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersGroupedResponseDTO) => {
            setSmartControllersListGrouped(response.smartDevices.elements);

            const temp: boolean[] = [];
            response.smartDevices.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setSmartControllersListGrouped([]);
          });
      }
    }
  }, [
    triggerListSmartControllers,
    smartControllersFiltersStore,
    isControllerUpdated,
  ]);

  const updateSmartControllersFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    selectedSearchTextType: string,
    badgeValue: number
  ) => {
    dispatch(
      setSelectedSmartControllerFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );
    dispatch(
      setSmartControllersFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        selectedSearchTextType: selectedSearchTextType,
        badgeValue: badgeValue,
      })
    );
  };

  useEffect(() => {
    if (
      !smartControllersFiltersStore.selectedSpace.clusterId ||
      smartControllersFiltersStore.selectedGroupBy === "Type" ||
      smartControllersFiltersStore.selectedDeviceType
    ) {
      return;
    }

    if (smartControllersFiltersStore.selectedSpace.id) {
      navigate(pathname + "/" + smartControllersFiltersStore.selectedSpace.id);
    }
  }, [smartControllersFiltersStore]);

  const updateControllerStatus = (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => {
    switchControllerState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      smartPlugId: smartDeviceId,
      switchingPowerState: status,
    })
      .unwrap()
      .then(() => {
        setIsControllerUpdated((ps) => !ps);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <SmartControllersFilter
        updateFilters={updateSmartControllersFilters}
        isLoading={isFetchingListSmartControllers}
      />
      <div className="position-relative">
        {!isFetchingListSmartControllers &&
          (smartControllersFiltersStore.selectedGroupBy === "Type" ||
            smartControllersFiltersStore.selectedGroupBy === "Status") && (
            <>
              {smartControllersListGrouped.length !== 0 ? (
                <>
                  {smartControllersListGrouped.map(
                    (elements: IGroupedSmartControllers, index) => {
                      return (
                        <Row key={index} className="container-white mx-1 mt-4">
                          <Col>
                            <Row
                              className="cursor-pointer"
                              onClick={() => {
                                setGroupedCollapsed((ps) => {
                                  const temp = [...ps];
                                  temp[index] = !temp[index];
                                  return [...temp];
                                });
                              }}
                            >
                              {smartControllersFiltersStore.selectedGroupBy ===
                                "Type" && (
                                <Col
                                  className={`p-1 ${elements.group} rounded col-auto`}
                                >
                                  <MaterialIcon
                                    icon={findIcon(
                                      elements.group as EDeviceTypes
                                    )}
                                    size={19}
                                  />
                                </Col>
                              )}
                              <Col className="text-dark font-size-16 font-weight-500">{`${
                                smartControllersFiltersStore.selectedGroupBy ===
                                "Status"
                                  ? "Status "
                                  : ""
                              }${elements.group}${
                                smartControllersFiltersStore.selectedGroupBy ===
                                "Type"
                                  ? "s"
                                  : ""
                              }`}</Col>
                              <Col className="col-auto">
                                <MaterialIcon
                                  icon="expand_more"
                                  className={`cursor-pointer ${styles.dArrow}`}
                                  style={
                                    groupedCollapsed[index]
                                      ? { transform: "rotate(-90deg)" }
                                      : { transform: "rotate(0deg)" }
                                  }
                                />
                              </Col>
                            </Row>
                            {elements.spaceClusterSmartDevices.map(
                              (spaceClusterSmartController) => {
                                return (
                                  <Row
                                    key={
                                      spaceClusterSmartController.smartDevice.id
                                    }
                                    className={`${
                                      groupedCollapsed[index] ? "d-none" : ""
                                    } mt-2`}
                                  >
                                    <Col className="px-0">
                                      <ControllerCard
                                        smartDevice={{
                                          ...spaceClusterSmartController.smartDevice,
                                          clusterId:
                                            spaceClusterSmartController.spaceClusterId,
                                          spaceId:
                                            spaceClusterSmartController.spaceId,
                                        }}
                                        path={spaceClusterSmartController.path}
                                        updateControllerStatus={
                                          updateControllerStatus
                                        }
                                        setIsControllerUpdated={
                                          setIsControllerUpdated
                                        }
                                      />
                                    </Col>
                                  </Row>
                                );
                              }
                            )}
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </>
              ) : (
                <Row className="container-white mx-1 mt-4">
                  <div className="container-dash mt-4">
                    <Col className="text-center text-light font-size-12">
                      There are no devices available for the selected filters.
                    </Col>
                  </div>
                </Row>
              )}
            </>
          )}
        {!isFetchingListSmartControllers &&
          smartControllersFiltersStore.selectedGroupBy !== "Type" &&
          (smartControllersFiltersStore.selectedDeviceType ||
            smartControllersFiltersStore.searchText) && (
            <Row className="container-white mx-1 mt-4">
              {smartControllersListNotGrouped.length !== 0 ? (
                <Col>
                  {smartControllersFiltersStore.selectedDeviceType && (
                    <Row className="align-items-center cursor-pointer">
                      <Col
                        className={`p-1 ${smartControllersFiltersStore.selectedDeviceType} rounded col-auto`}
                      >
                        <MaterialIcon
                          icon={findIcon(
                            smartControllersFiltersStore.selectedDeviceType as EDeviceTypes
                          )}
                          size={19}
                        />
                      </Col>
                      <Col className="text-dark font-size-16 font-weight-500">{`${smartControllersFiltersStore.selectedDeviceType}s`}</Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      {smartControllersListNotGrouped.map(
                        (
                          spaceClusterSmartController: ISpaceClusterSmartPlugViews
                        ) => {
                          return (
                            <Row
                              key={spaceClusterSmartController.smartDevice.id}
                              className="mt-2"
                            >
                              <Col>
                                <ControllerCard
                                  smartDevice={{
                                    ...spaceClusterSmartController.smartDevice,
                                    clusterId:
                                      spaceClusterSmartController.spaceClusterId,
                                    spaceId:
                                      spaceClusterSmartController.spaceId,
                                  }}
                                  path={spaceClusterSmartController.path}
                                  updateControllerStatus={
                                    updateControllerStatus
                                  }
                                  setIsControllerUpdated={
                                    setIsControllerUpdated
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        }
                      )}
                    </Col>
                  </Row>
                </Col>
              ) : (
                <div className="container-dash mt-4">
                  <Col className="text-center text-light font-size-12">
                    There are no devices available for the selected filters.
                  </Col>
                </div>
              )}
            </Row>
          )}
        <SpinnerModal
          show={
            isLoadingSwitchControllerState || isFetchingListSmartControllers
          }
        />
      </div>
    </>
  );
};

export default Controllers;
