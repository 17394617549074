import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useLazyProfileQuery,
  useUpdateEmailMutation,
} from "../../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../../enums/ovst-error-codes";
import { AppRoute } from "../../../interfaces/app-routes";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import AppInput from "../../../ui-elements/input/app-input";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import { emailRegex } from "../../../utils/regex";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";

interface IChangeEmailFormInput {
  email: string;
}

const defaultFormValues: IChangeEmailFormInput = {
  email: "",
};

const ChangeEmailForm = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IChangeEmailFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const [updateEmail, { isLoading: isLoadingUpdateEmail }] =
    useUpdateEmailMutation();
  const [getProfile, { isFetching: isGetProfileFetching }] =
    useLazyProfileQuery();

  const onSubmit = (data: IChangeEmailFormInput) => {
    updateEmail({ email: data.email })
      .unwrap()
      .then(() => {
        return getProfile().unwrap();
      })
      .then(() => {
        navigate(AppRoute.USER_PROFILE);
        showSuccessMessage("Email updated successfully");
        reset({ ...defaultFormValues });
      })
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
        ) {
          setIsError(true);
        } else {
          showErrorMessage(error.message || "Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (errors.email) {
      setIsError(false);
    }
  }, [errors.email]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <AppInput
              name="email"
              placeholder="New Email"
              label="New Email"
              register={register("email", {
                required: "Email is required",
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid email",
                },
              })}
              errors={errors}
            />
          </Col>
        </Row>
        <Row>
          {isError && (
            <Col className="error justify-content-center font-size-14 mt-2 text-center">
              Email is already associated with an account
            </Col>
          )}
        </Row>
        <Row className="mt-5">
          <Col>
            <AppButton
              text={`Confirm`}
              size="medium"
              type="submit"
              isLoading={isLoadingUpdateEmail}
            />
          </Col>
        </Row>
      </form>
      <SpinnerModal show={isGetProfileFetching} />
    </div>
  );
};

export default ChangeEmailForm;
