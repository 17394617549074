import { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { EConsumptionValueComparison } from "../../../enums/consumption-comparison";
import AppSelect, { Option } from "../../../ui-elements/app-select/app-select";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import Helper from "../../../utils/helpers";
import styles from "./usage.module.scss";

interface IProps {
  cost: number;
  units: number;
  title: string;
  comparisonWithLastMonth?: EConsumptionValueComparison;
  isFetching?: boolean;
  usageViewType: Option[];
  selectedUsageViewType: Option;
  setSelectedUsageViewType: React.Dispatch<React.SetStateAction<Option>>;
}

const Usage = (props: IProps) => {
  const { usageViewType, selectedUsageViewType, setSelectedUsageViewType } =
    props;
  const switchIcon = useCallback(() => {
    switch (props.comparisonWithLastMonth) {
      case EConsumptionValueComparison.HIGH:
        return <MaterialIcon icon="trending_up" color="#E75B74" />;
      case EConsumptionValueComparison.LOW:
        return <MaterialIcon icon="trending_down" color="#59C270" />;
      case EConsumptionValueComparison.EQUAL:
        return (
          <MaterialIcon icon="check_indeterminate_small" color="#819EB9" />
        );
      default:
        return <div className="py-2 mt-2"></div>;
    }
  }, [props.comparisonWithLastMonth]);

  return (
    <div
      className={`${
        props.isFetching && `justify-content-center`
      } container-white h-100 d-flex align-items-center`}
    >
      {props.isFetching ? (
        <SpinnerModal withOverlay={false} show={props.isFetching} size="md" />
      ) : (
        <div className={`${styles.container} py-2`}>
          <Row>
            <Col className={styles.title}>{props.title}</Col>
          </Row>
          <Row>
            <Col className="ps-1">
              <AppSelect
                isWithOutBorder={true}
                options={[...usageViewType]}
                className={styles.title}
                selectedValue={selectedUsageViewType}
                onChangeOption={(selectedUsageOption) => {
                  setSelectedUsageViewType(selectedUsageOption);
                }}
              />
            </Col>
          </Row>
          <div className={`${styles.value} d-flex align-items-center`}>
            <label>Rs {Helper.roundTo2(props.cost)}</label>
            {switchIcon()}
          </div>
          <Row className={styles.units}>
            <Col>{Helper.roundTo2(props.units)} Units</Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Usage;
