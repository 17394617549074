import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useLazyGetSpaceQuery,
  useLazyGetSpacesQuery,
} from "../../../redux/api/space/spaceAPI";
import { selectFilter } from "../../../redux/features/filter/filter-slice";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { ISpaceView } from "../../oversight-core/interfaces/entities/space";
import FilteringSidePanel from "../../oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../oversight-core/utils/getDeviceType";
import SelectedFiltersView from "../../oversight-general-core/components/selected-filters-view/selected-filters-view";
import SpaceSelectorDropdown from "../../oversight-general-core/components/space-selector-dropdown/space-selector-dropdown";
import ControllerStatisticsResponseDTO from "../../oversight-general-core/dtos/controller-statistics-response-dto";
import { ISelectedSpace } from "../../oversight-general-core/interfaces/selected-space";
import ISmartDevice from "../../oversight-general-core/interfaces/smart-devices";
import ButtonWithBadge from "../../oversight-general-core/ui-elements/button-with-badge/button-with-badge";
import RealTimeAreaChart from "../../oversight-general-core/ui-elements/real-time-area-chart/realtime-area-chart";
import styles from "./real-time-power-usage-chart-view.module.scss";

interface IProps {
  statistics: ControllerStatisticsResponseDTO;
  spaceName: string;
  deviceName: string;
  deviceType: EDeviceTypes;
  graphData: number[];
  onFilter: (
    selectedSpace: ISelectedSpace,
    selectedDeviceType: Option,
    selectedDevice: Option
  ) => void;
  isFetching: boolean;
}

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const defaultDeviceType: Option = deviceTypes[0];

const RealTimePowerUsageChartView = (props: IProps) => {
  const filtersStore = useSelector(selectFilter);
  const [showFilter, setShowFilter] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    ...filtersStore.selectedSpace,
  });
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...(deviceTypes.find(
      (deviceType) => deviceType.value === filtersStore.selectedDeviceType
    ) || defaultDeviceType),
  });
  const [selectedDevice, setSelectedDevice] = useState<Option>({
    label: "",
    value: "",
  });
  const [smartDevices, setSmartDevices] = useState<ISmartDevice[]>([]);
  const [triggerGetSpace, { isFetching: isFetchingSpace }] =
    useLazyGetSpaceQuery();
  const [triggerGetSpaces] = useLazyGetSpacesQuery();

  useEffect(() => {
    if (selectedSpace.clusterId && selectedSpace.id) {
      triggerGetSpace({
        clusterId: selectedSpace.clusterId,
        spaceId: selectedSpace.id,
      })
        .unwrap()
        .then((res) => {
          setSmartDevices(res.subSpaceCluster.rootSpace.smartDevices);
          setSelectedDevice({
            label: "",
            value: "",
          });
        })
        .catch(() => {
          setSmartDevices([]);
          setSelectedDevice({
            label: "",
            value: "",
          });
        });
    }
  }, [selectedSpace.clusterId, selectedSpace.id]);

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        const tempArray: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          tempArray.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        setSpaceClusters(tempArray);
        setSelectedSpace(tempArray[0]);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  return (
    <div className={styles.realTimePowerUsageChart}>
      <Row className="align-items-center p-4">
        <Col>
          <Row className="align-items-center g-0 g-sm-4">
            <Col className="col-12 col-sm-auto">
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  <div className={`bg-background-3 ${styles.circle}`}></div>
                </Col>
                <Col className="text-dark font-size-14 font-weight-500 col-sm-auto pe-4">
                  Live Power Usage
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-sm-auto mt-4">
              <Row className="align-items-center g-3 g-sm-4">
                <Col className="text-dark font-size-24 font-weight-600 jakarta col-auto">
                  {props.isFetching ? (
                    <SpinnerModal
                      show={props.isFetching}
                      withOverlay={false}
                      size="sm"
                    />
                  ) : (
                    <>{props.statistics.latestPowerInWatt.toFixed(2)}W</>
                  )}
                </Col>
                <Col className="text-light font-weight-500 col-auto">
                  {props.isFetching ? (
                    <SpinnerModal
                      show={props.isFetching}
                      withOverlay={false}
                      size="sm"
                    />
                  ) : (
                    <Row className="flex-column">
                      <Col className="font-size-12">
                        {props.statistics.maxPowerInWatt.toFixed(2)}W
                      </Col>
                      <Col className="font-size-10">Today&rsquo;s Maximum</Col>
                    </Row>
                  )}
                </Col>
                <Col className="text-light font-weight-500 col-auto">
                  {props.isFetching ? (
                    <SpinnerModal
                      show={props.isFetching}
                      withOverlay={false}
                      size="sm"
                    />
                  ) : (
                    <Row className="flex-column">
                      <Col className="font-size-12">
                        {props.statistics.averagePowerInWatt.toFixed(2)}W
                      </Col>
                      <Col className="font-size-10">Today&rsquo;s Average</Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-xl-auto mt-4 mt-xl-0">
          <Row className="align-items-center justify-content-between justify-content-sm-start">
            <Col className="col-auto">
              <SelectedFiltersView
                spaceName={filtersStore.selectedSpace.name}
                filters={[
                  {
                    icon: filtersStore.selectedDeviceType
                      ? (filtersStore.selectedDeviceType as EDeviceTypes)
                      : EDeviceTypes.ALL_DEVICES,
                    filterName:
                      filtersStore.selectedDeviceType || "All Devices",
                    subText: "Today",
                  },
                ]}
              />
            </Col>
            <Col className="col-auto">
              <ButtonWithBadge
                text="Filter"
                icon="filter_alt"
                onClick={() => {
                  setShowFilter(true);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <RealTimeAreaChart
        borderColor1={"#D84560"}
        borderColor2={"#D84560"}
        backgroundColor1={"rgba(204, 41, 41, 0.09)"}
        backgroundColor2={"rgba(204, 41, 41, 0.09)"}
        label1={"Usage Prediction"}
        yAxesUnit="W"
        data1={props.graphData}
        hasSecondDataSet={false}
        isFetching={props.isFetching}
      />
      <FilteringSidePanel
        isOpen={showFilter}
        onClose={() => {
          setShowFilter(false);
        }}
        onFilter={() => {
          props.onFilter(selectedSpace, selectedDeviceType, selectedDevice);
          setShowFilter(false);
        }}
        isLoading={isFetchingSpace}
      >
        <>
          <SpaceSelectorDropdown
            dropdownOptions={[
              ...spaceClusters
                .filter(
                  (space) =>
                    space.clusterId === filtersStore.selectedSpace.clusterId
                )
                .map((space) => {
                  return {
                    clusterId: space.clusterId,
                    id: space.id,
                    name: space.name,
                    childSpaces: space.childSpaces,
                  };
                }),
            ]}
            disabled={false}
            selectedSubSpace={selectedSpace.name}
            updateSelectedSubSpaceId={(
              selectedSubSpaceId: string,
              selectedSubSpaceName: string,
              clusterId?: string
            ) => {
              setSelectedSpace({
                id: selectedSubSpaceId,
                name: selectedSubSpaceName,
                clusterId: clusterId || "",
              });
            }}
            label="Space"
            className="font-weight-500 text-light"
          />

          <AppSelect
            label="Device Type"
            options={[...deviceTypes]}
            className="mt-4"
            selectedValue={selectedDeviceType}
            onChangeOption={(selectedDeviceTypeOption) =>
              setSelectedDeviceType(selectedDeviceTypeOption)
            }
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />

          <AppSelect
            label="Device"
            selectedValue={selectedDevice}
            options={[
              { label: "", value: "" },
              ...smartDevices.map((smartDevice) => {
                return {
                  label: `${smartDevice.name} ${
                    smartDevice.linkedPowerConsumer ? "|" : ""
                  } ${smartDevice.linkedPowerConsumer?.name || ""}`,
                  value: smartDevice.id,
                  data: smartDevice,
                };
              }),
            ]}
            onChangeOption={(selectedDeviceOption) =>
              setSelectedDevice(selectedDeviceOption)
            }
            className="mt-4"
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
        </>
      </FilteringSidePanel>
    </div>
  );
};

export default RealTimePowerUsageChartView;
