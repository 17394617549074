import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../oversight-core/enums/device-types";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../oversight-core/utils/findIcon";
import styles from "./override-device-limitation-card.module.scss";

interface IProps {
  deviceType: string;
}

const OverrideDeviceLimitationCard = (props: IProps) => {
  const { deviceType } = props;

  return (
    <Row
      className={`${deviceType} ${styles.overrideDeviceCard} py-2 align-items-center justify-content-between h-100`}
    >
      <Col>
        <Row className="align-items-center">
          <Col className="col-auto pe-2">
            <MaterialIcon
              icon={findIcon(deviceType as EDeviceTypes)}
              className="font-size-20"
            />
          </Col>
          <Col className="ps-0 font-size-12 font-weight-400 text-light">
            {deviceType}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OverrideDeviceLimitationCard;
