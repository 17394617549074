import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import SomethingWentWrongImg from "./assets/something-went-wrong.png";
import styles from "./something-went-wrong.module.scss";

const SomethingWentWrong = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <div>
            <img
              src={SomethingWentWrongImg}
              className="img-fluid"
              alt="Something went wrong"
            />
          </div>
          <div className={[styles.swrHeading, "display-6"].join(" ")}>
            SOMETHING WENT WRONG
          </div>
          <p className={[styles.swrParagraph, " fs-6"].join(" ")}>
            We encountered an error. Please try again later
          </p>
          <div className="d-flex justify-content-center gap-2">
            <div>
              <AppButton
                text="Bring Me To Home Page"
                variant="blue"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SomethingWentWrong;
