import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import styles from "./app-options.module.scss";

interface IProps {
  isFiltersApplied: boolean;
  onClick: () => void;
}

const AppOptions = (props: IProps) => {
  const { isFiltersApplied, onClick } = props;
  return (
    <Row onClick={onClick}>
      <Col className={`${styles.chartFilter} p-2`}>
        {isFiltersApplied && <div className={styles.filterDot}></div>}
        <MaterialIcon icon="discover_tune" />
      </Col>
    </Row>
  );
};

export default AppOptions;
