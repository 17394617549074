import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/login_pg.svg";
import logo from "../../assets/images/logo_bw.svg";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import styles from "./login-layout.module.scss";

interface IProps {
  title: string;
  subTitle?: string | JSX.Element;
  children: JSX.Element;
  showBackButton?: boolean;
  rightSideContent?: JSX.Element | undefined;
  wrapperClassName?: string;
}

const LoginLayout = (props: IProps) => {
  const {
    rightSideContent = <img src={image} alt="login image" className="w-100" />,
  } = props;

  const navigate = useNavigate();

  return (
    <Container fluid>
      <Row
        className={`justify-content-center ${styles.loginWrapper} ${props.wrapperClassName}`}
      >
        <Col className="col-md-11">
          <Row className="align-items-center min-vh-100">
            <Col md={12} lg={6} className="py-5">
              <Row className="justify-content-center">
                <Col className="col-auto">
                  <img src={logo} alt="oversight_logo" />
                </Col>
              </Row>
              <Row className="mt-4 align-items-center justify-content-center">
                {props.showBackButton && (
                  <Col className="col-auto">
                    <AppButton
                      text="arrow_left"
                      iconName="arrow_left"
                      iconOnly
                      size="medium"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </Col>
                )}
                <Col className={`${styles.title} col-auto`}>{props.title}</Col>
              </Row>
              {props.subTitle && (
                <Row className="mt-4 justify-content-center">
                  <Col className={`${styles.subTitle} col-10 text-center`}>
                    {props.subTitle}
                  </Col>
                </Row>
              )}
              <Row className="mt-3 justify-content-center">
                <Col className="col-9">{props.children}</Col>
              </Row>
            </Col>
            <Col md={12} lg={6} className="d-none d-lg-block">
              {rightSideContent}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginLayout;
