import React from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import IconButton from "../../oversight-core/ui-elements/buttons/icon-button/icon-button";

export interface InverterProps {
  id: string;
  inverterCapacity: number | undefined;
  inverterError: string;
}

interface IAddInverterProps {
  className?: string;
  inverters: InverterProps[];
  setInverters: React.Dispatch<React.SetStateAction<InverterProps[]>>;
}

export const initialInverter: InverterProps = {
  id: uuid(),
  inverterCapacity: undefined,
  inverterError: "",
};

const AddInverters = (props: IAddInverterProps) => {
  const inverterUpdateHandler = (inverterData: InverterProps) => {
    props.setInverters((prevState) => {
      const newPrevState = [...prevState];
      const index = newPrevState.findIndex(
        (value) => value.id === inverterData.id
      );
      newPrevState[index] = inverterData;
      return newPrevState;
    });
  };

  const concatNewInverterHandler = () => {
    const newInverters = [...props.inverters];

    if (newInverters.length > 0) {
      const lastInverter = newInverters[newInverters.length - 1];
      let hasError = false;

      if (
        !lastInverter.inverterCapacity ||
        lastInverter?.inverterCapacity <= 0
      ) {
        lastInverter.inverterError = "Inverter capacity must be required";
        hasError = true;
      }

      if (hasError) {
        inverterUpdateHandler(lastInverter);
        return;
      }
    }

    newInverters.push({
      ...initialInverter,
      id: uuid(),
    });

    props.setInverters(newInverters);
  };

  const inverterRemoveHandler = (id: string | number) => {
    props.setInverters((prevState) => {
      const newState = [...prevState].filter((value) => value.id !== id);

      return newState;
    });
  };

  const inverterCapacityChangeHandler = (
    inverterData: InverterProps,
    value: string
  ) => {
    const newInverterCapacity = +value;
    inverterData.inverterCapacity = +value;

    if (newInverterCapacity <= 0) {
      inverterData.inverterError = "Inverter capacity must be required";
    } else {
      inverterData.inverterError = "";
    }
    inverterUpdateHandler(inverterData);
  };

  const enterInverter = (inverterData: InverterProps, index: number) => {
    return (
      <>
        <Row className="align-items-center">
          <Col>
            {index === 0 && (
              <>
                <label className="mb-1 ms-1">Inverter Capacity (kW)</label>
                <label style={{ color: "red" }}>*</label>
              </>
            )}
            <div className="app-input">
              <input
                value={inverterData.inverterCapacity || ""}
                placeholder="Inverter Capacity"
                onChange={(event) => {
                  inverterCapacityChangeHandler(
                    inverterData,
                    Number(event.target.value).toFixed(2)
                  );
                }}
                style={
                  inverterData.inverterError
                    ? { border: "1px solid #d74242ed" }
                    : {}
                }
                type="number"
                min="0"
                onKeyDown={(event) => {
                  ["e", "E", "+", "-"].includes(event.key) &&
                    event.preventDefault();
                }}
              />
            </div>
            <p className="mb-0 text-danger font-size-12">
              {inverterData.inverterError}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const actionButtons = (inverterData: InverterProps) => {
    return (
      <>
        {props.inverters.length > 1 && (
          <AppButton
            text="Remove"
            iconOnly
            iconName="close"
            variant="red"
            onClick={() => inverterRemoveHandler(inverterData.id)}
          />
        )}
      </>
    );
  };

  const inverterRow = (inverterData: InverterProps, index: number) => (
    <React.Fragment key={inverterData.id}>
      <Row className="my-2 align-items-center justify-content-between h-100">
        <Col className="mb-2 mb-md-0">{enterInverter(inverterData, index)}</Col>
        <Col className={`${index === 0 && `mt-4`} col-2 col-sm-1`}>
          <Row className="justify-content-center">
            <Col>{actionButtons(inverterData)}</Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );

  return (
    <div>
      <div className={props.className}>
        {props.inverters.map((inverterData, index) =>
          inverterRow(inverterData, index)
        )}
      </div>
      <Row>
        <Col className="col-auto">
          <IconButton
            icon="add"
            text="Add Inverter"
            onClick={concatNewInverterHandler}
          />
        </Col>
      </Row>
    </div>
  );
};
export default AddInverters;
