import { Navigate, useLocation } from "react-router-dom";

const RemoveTrailingSlash = ({ ...rest }) => {
  const { pathname, search } = useLocation();
  if (pathname.match("/.*/$")) {
    return (
      <Navigate
        {...rest}
        to={{
          pathname: pathname.replace(/\/+$/, ""),
          search: search,
        }}
      />
    );
  } else return null;
};

export default RemoveTrailingSlash;
