import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../../interfaces/app-routes";
import MaterialIcon, {
  MtIcon,
} from "../../../../ui-elements/material-icon/material-icon";
import styles from "./menu-item.module.scss";

interface IProps {
  active: boolean;
  collapsed?: boolean;
  to?: AppRoute | string;
  icon?: MtIcon;
  text?: string;
  onClick?: () => void;
  enableRoute?: boolean;
  bgColor?: string;
  bgColorIcon?: string;
}

const MenuItem = (props: IProps) => {
  const { enableRoute = true } = props;

  const navigate = useNavigate();

  return (
    <div
      className={`${
        props.active && enableRoute
          ? `${styles[`active`]} bg-${props.bgColor}`
          : ``
      }  p-1`}
      onClick={
        enableRoute
          ? (e) => {
              props.onClick && props.onClick();
            }
          : undefined
      }
    >
      <div
        onClick={() =>
          enableRoute && props.to ? navigate(props.to) : undefined
        }
        className={`text-decoration-none d-flex align-items-center text-dark font-size-16 font-weight-400 cursor-pointer ${
          props.collapsed ? "p-2" : "gap-2"
        }p-2 ${!enableRoute && styles.disable}`}
        style={props.collapsed ? {} : { width: "155px" }}
      >
        <MaterialIcon
          icon={props.icon}
          size={20}
          color={
            props.active && enableRoute ? `${props.bgColorIcon}` : "#383941"
          }
        />
        {!props.collapsed && <span className="ms-2">{props.text}</span>}
      </div>
    </div>
  );
};

export default MenuItem;
