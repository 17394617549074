import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import NotificationContent from "../notification-content/notification-content";
import styles from "./notification-card.module.scss";

interface INotification {
  from: string;
  title: string;
  time: string;
  description: string;
}

interface IProps {
  notifications: INotification[];
}

const NotificationsCard = (props: IProps) => {
  const [showNotificationsView, setShowNotificationsView] = useState(false);
  const [showNotifications, setShowNotifications] = useState(true);
  const [isViewNotification, setIsViewNotification] = useState(false);

  const handleViewClick = () => {
    setShowNotificationsView(true);
    setShowNotifications(false);
    setIsViewNotification(true);
  };

  return (
    <>
      {showNotifications &&
        props.notifications.map((notification, index) => (
          <div
            key={index}
            className={`${
              isViewNotification ? `` : styles[`notification-card`]
            }`}
          >
            <Row className="mx-4">
              <Col className="col-12 col-sm-1 mt-4 pt-sm-3">
                <MaterialIcon icon="error" color=" #E8C304" />
              </Col>
              <Col className="col-12 col-sm-11 mt-2 mt-sm-4 mb-0 mb-sm-4">
                <Row className="align-items-center">
                  <Col className="col-12 col-sm-10">
                    <div className="font-weight-300 text-light font-size-10 fst-italic">
                      {notification.from}
                    </div>
                    <div className="font-weight-600 font-size-14">
                      {notification.title}
                    </div>
                    <div className="font-weight-300 text-light font-size-10 fst-italic">
                      {notification.time}
                    </div>
                    <div className="font-weight-400 font-size-12 mt-1">
                      {notification.description}
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <AppButton
                      text="View"
                      variant="transparent"
                      size="medium"
                      className="font-weight-500 px-0"
                      onClick={handleViewClick}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="my-0" />
          </div>
        ))}
      {showNotificationsView && (
        <NotificationContent
          onBack={() => {
            setShowNotifications(true);
            setShowNotificationsView(false);
          }}
        />
      )}
    </>
  );
};

export default NotificationsCard;
