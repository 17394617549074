import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ListCard from "../../../shared/oversight-general-core/components/list-card/list-card";
import ButtonWithBadge from "../../../shared/oversight-general-core/ui-elements/button-with-badge/button-with-badge";

const DevicesWithHighestPowerConsumption = () => {
  const [isMoreDevicesAvailable] = useState(true);
  return (
    <>
      <Row>
        <Col>
          <Row className="justify-content-end align-items-center">
            <Col className="col-auto font-size-14 font-weight-500">
              Olivera&rsquo;s House
            </Col>
            <Col className="col-auto">
              <ButtonWithBadge
                text="Filter"
                icon="filter_alt"
                onClick={() => {
                  console.log();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <ListCard
            showSocketInformation={true}
            isDevicesOnly={true}
            deleteDevice={(
              clusterId: string,
              spaceId: string,
              deviceId: string
            ) => {
              console.log(clusterId, spaceId, deviceId);
            }}
            updateCurrentState={() => {
              console.log();
            }}
            device={{
              clusterId: "",
              spaceId: "",
              id: "",
              name: "Olivera's Space",
              deviceType: EDeviceTypes.TV,
              deviceCategory: "TV",
              powerUsageInWatt: 500,
              brand: "LG",
              modelNumber: "ABC123",
              serialKey: "114",
            }}
            mainSpaceClusterId=""
            mainSpaceId=""
            path={{
              id: "",
              label: "",
              ancestorSpaces: [
                {
                  id: "",
                  name: "Olivera's Space",
                  parentSpaceId: "",
                },
              ],
            }}
            isTextTruncate={false}
          />
        </Col>
      </Row>

      {isMoreDevicesAvailable && (
        <Row className="justify-content-center mt-4">
          <Col className="col-auto cursor-pointer">
            <Row className="align-items-center">
              <Col className="text-primary col-auto font-weight-500">
                Show More
              </Col>
              <Col className="col-auto">
                <MaterialIcon
                  icon="keyboard_arrow_down"
                  className="text-primary"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DevicesWithHighestPowerConsumption;
