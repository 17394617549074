import LoginLayout from "../../shared/oversight-core/shared-components/login-layout/login-layout";
import LoginForm from "./login-form/login-form";

const Login = () => {
  return (
    <LoginLayout title="Login to Your Account">
      <LoginForm />
    </LoginLayout>
  );
};

export default Login;
