import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IPowerConsumerViewWithSpaceIdAndClusterId } from "../../../../screens/dashboard/schedule/components/add-update-schedule/space-hierarchy/space-hierarchy";
import ScheduleConflictModal from "../../../../screens/dashboard/schedule/components/modals/schedule-conflict-modal/schedule-conflict-modal";
import UsageGuideModal from "../../../../screens/dashboard/schedule/components/modals/usage-guide-modal/usage-guide-modal";
import { EDeviceTypes } from "../../../oversight-core/enums/device-types";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../oversight-core/utils/findIcon";
import { IPowerConsumerTypeAvailabilityViews } from "../../dtos/view-semi-automation-schedule-device-availability-response-dto";
import { LimitationType } from "../../enums/limitation-type";
import { EManagementMode } from "../../enums/management-mode";
import { IDateTimeRangeView } from "../../interfaces/date-time-range-view";
import AppSwitch from "../app-switch/app-switch";
import styles from "./device-card.module.scss";

interface IProps {
  device: IPowerConsumerViewWithSpaceIdAndClusterId;
  updateDeviceStatus?: (
    spaceClusterId: string,
    spaceId: string,
    deviceId: string,
    deviceStatus: boolean
  ) => void;
  limitationType?: LimitationType;
  path?: string;
  showToggler?: boolean;
  conflictedScheduleList?: {
    deviceId: string;
    dateTimeRange: IDateTimeRangeView[];
  }[];
  selectedManagementMode?: EManagementMode;
  usageGuideTimeList?: IPowerConsumerTypeAvailabilityViews[];
}

const DeviceCard = (props: IProps) => {
  const {
    device,
    path,
    showToggler = true,
    limitationType,
    conflictedScheduleList = [],
    usageGuideTimeList = [],
    selectedManagementMode,
  } = props;
  const [showUsageGuideModal, setShowUsageGuideModal] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [showScheduleConflictModal, setShowScheduleConflictModal] =
    useState(false);

  const isSemiAutoMode = selectedManagementMode === EManagementMode.SEMI_AUTO;

  useEffect(() => {
    if (props.device.isSelected !== undefined) {
      setIsOn(props.device.isSelected);
    }
  }, [props.device.isSelected]);

  return (
    <>
      <Row
        className={`${styles["device-card"]} ${
          styles["overflow-horizontal"]
        } align-items-center justify-content-between p-2 ${
          isSemiAutoMode && limitationType
            ? `${
                limitationType === LimitationType.ONLY_GIVE_WARNING
                  ? styles.deviceCardGradient
                  : styles.deviceCardOpacity
              }`
            : ``
        }`}
      >
        <Col>
          <Row className="align-items-center">
            <Col className={`${device.deviceType} p-3 rounded`} xs={"auto"}>
              <MaterialIcon
                icon={findIcon(device.deviceType as EDeviceTypes)}
              />
            </Col>
            <Col xs={"auto"}>
              <Row>
                <Col className={`${styles["device-name"]} pe-0`} xs={"auto"}>
                  {device.name}
                </Col>
              </Row>
              {!path && (
                <Row>
                  <Col className={styles["device-energy"]} xs={"auto"}>
                    {device.powerUsageInWatt}W
                  </Col>
                  <Col className={styles["device-outlet"]}>
                    <Row className="align-items-center">
                      {device.smartDevice && (
                        <Col className="pe-0" xs={"auto"}>
                          <MaterialIcon icon="smart_outlet" size={16} />
                        </Col>
                      )}
                      <Col className="ps-1">{device.smartDevice?.name}</Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={"auto"}>
          <Row className="align-items-center">
            {props.device.isScheduled && (
              <Col xs={"auto"}>
                <div
                  onClick={() => {
                    if (showToggler) {
                      setShowScheduleConflictModal(true);
                    }
                  }}
                  className={showToggler ? styles.limitationWrapper : ``}
                >
                  <MaterialIcon icon="warning" color="#CB343D" />
                </div>
              </Col>
            )}
            {isSemiAutoMode && limitationType && showToggler && (
              <Col
                className={
                  limitationType === LimitationType.ONLY_GIVE_WARNING
                    ? "col-auto"
                    : ""
                }
              >
                <div
                  className={styles.limitationWrapper}
                  onClick={() => {
                    setShowUsageGuideModal(true);
                  }}
                >
                  <MaterialIcon
                    icon={
                      limitationType === LimitationType.ONLY_GIVE_WARNING
                        ? "error"
                        : "release_alert"
                    }
                    color={
                      limitationType === LimitationType.ONLY_GIVE_WARNING
                        ? "#E8C304"
                        : "#EC7575"
                    }
                  />
                </div>
              </Col>
            )}
            {showToggler &&
              (isSemiAutoMode && limitationType
                ? limitationType === LimitationType.ONLY_GIVE_WARNING
                : true) && (
                <Col>
                  <AppSwitch
                    isOn={isOn}
                    onSwitch={(value) => {
                      if (props.updateDeviceStatus) {
                        props.updateDeviceStatus(
                          props.device.clusterId,
                          props.device.spaceId,
                          props.device.id,
                          value
                        );
                      }

                      setIsOn(value);
                    }}
                    size="sm"
                  />
                </Col>
              )}
          </Row>
        </Col>
      </Row>
      <UsageGuideModal
        show={showUsageGuideModal}
        limitationType={limitationType || LimitationType.ONLY_GIVE_WARNING}
        usageGuideTimeList={usageGuideTimeList}
        onClose={() => {
          setShowUsageGuideModal(false);
        }}
        deviceType={device.deviceCategory}
      />
      <ScheduleConflictModal
        show={showScheduleConflictModal}
        deviceName={device.name}
        conflictedTimeList={
          conflictedScheduleList?.find(
            (conflict) => conflict.deviceId === device.id
          )?.dateTimeRange || []
        }
        onClose={() => {
          setShowScheduleConflictModal(false);
        }}
      />
    </>
  );
};

export default DeviceCard;
