import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import styles from "./ac-controller.module.scss";

interface IProps {
  temperature: number;
  onIncrease: (event: React.MouseEvent<HTMLElement>) => void;
  onDecrease: (event: React.MouseEvent<HTMLElement>) => void;
  isShowTemperatureText?: boolean;
}

const AcController = (props: IProps) => {
  const { temperature, onIncrease, onDecrease, isShowTemperatureText } = props;

  return (
    <Row className="align-items-center flex-nowrap">
      <Col className="col-auto ps-0">
        <div className={`${styles.remove} p-2 cursor-pointer`}>
          <MaterialIcon
            icon="remove"
            color="#69768B"
            size={12}
            onClick={onDecrease}
          />
        </div>
      </Col>
      <Col className="col-auto">
        <Row className="flex-column align-items-center justify-content-center">
          <Col className="col-auto">
            <Row className="align-items-center">
              <Col className="col-auto px-0">
                <MaterialIcon
                  icon="device_thermostat"
                  color="#69768B"
                  size={12}
                />
              </Col>
              <Col className="px-0 font-weight-600 text-light col-auto">
                {temperature}
              </Col>
              <Col className="px-0 font-weight-400 text-light col-auto">
                &#8451;
              </Col>
            </Row>
          </Col>
          {isShowTemperatureText && (
            <Col className="text-light font-weight-400 font-size-14">
              Temperature
            </Col>
          )}
        </Row>
      </Col>
      <Col className="col-auto pe-4">
        <div className={`${styles.remove} p-2 cursor-pointer`}>
          <MaterialIcon
            icon="add"
            color="#69768B"
            size={12}
            onClick={onIncrease}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AcController;
