import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from "./mini-calendar.module.scss";

interface IProps {
  startDate: Date;
  selectedDate: Date;
  handleDateChange: (date: Date) => void;
}

const MiniCalendar = (props: IProps) => {
  const { startDate, selectedDate, handleDateChange } = props;
  return (
    <>
      <Row className="align-items-center flex-nowrap justify-content-center justify-content-sm-start">
        {Array.from({ length: 7 }).map((item, index) => {
          return (
            <Col
              key={uuid()}
              className={`col-auto ${
                moment(startDate).add(index, "day").day() ===
                moment(selectedDate).day()
                  ? styles.selectedDate
                  : styles.nonSelectedDate
              } px-4 py-1 mx-1 ${
                index > 3 ? `d-none d-sm-block` : ``
              } cursor-pointer`}
              onClick={() => {
                handleDateChange(moment(startDate).add(index, "day").toDate());
              }}
            >
              <Row className="flex-column">
                <Col className="text-center font-size-10 font-weight-700">
                  {moment(startDate).add(index, "day").format("ddd")}
                </Col>
                <Col className="text-center font-size-10 font-weight-700">
                  {moment(startDate).add(index, "day").format("DD")}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
      <Row className="align-items-center flex-nowrap mt-2 justify-content-center justify-content-sm-start">
        {Array.from({ length: 7 }).map((item, index) => {
          return (
            <Col
              key={uuid()}
              className={`col-auto ${
                moment(startDate).add(index, "day").day() ===
                moment(selectedDate).day()
                  ? styles.selectedDate
                  : `text-gray`
              } px-4 py-1 mx-1 ${
                index < 4 ? `d-none` : `d-sm-none`
              } cursor-pointer`}
              onClick={() => {
                handleDateChange(moment(startDate).add(index, "day").toDate());
              }}
            >
              <Row className="flex-column">
                <Col className="text-center font-size-10 font-weight-700">
                  {moment(startDate).add(index, "day").format("ddd")}
                </Col>
                <Col className="text-center font-size-10 font-weight-700">
                  {moment(startDate).add(index, "day").format("DD")}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default MiniCalendar;
