import ModalContainer, {
  ModelContainerProps,
} from "../../../ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
}

const UserAccountCancelModal = (props: IProps) => {
  const { show, ...rest } = props;
  return (
    <ModalContainer
      {...rest}
      title={"Cancel Account Creation ?"}
      show={show}
      size="modal-md"
      confirmButtonText="Yes"
      confirmButtonVariant="red"
      cancelButtonText="No"
    >
      <>
        <div className="text-light font-size-14">
          <div>
            <p>Are you sure you want to cancel your account creation?</p>
          </div>
        </div>
      </>
    </ModalContainer>
  );
};

export default UserAccountCancelModal;
