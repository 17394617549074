import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  useUnlinkAcControllerMutation,
  useUnlinkedLinkPowerConsumerMutation,
} from "../../../../../../redux/api/controller/controllerAPI";
import { DeviceStatus } from "../../../../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../../../../shared/oversight-core/enums/device-types";
import { IPowerConsumerView } from "../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import AppModal from "../../../../../../shared/oversight-core/ui-elements/app-modal/app-modal";
import MaterialIcon, {
  MtIcon,
} from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";
import AcController from "../../../../../../shared/oversight-general-core/components/ac-controller/ac-controller";
import { ControllerType } from "../../../../../../shared/oversight-general-core/enums/controller-type";
import ISmartDevice from "../../../../../../shared/oversight-general-core/interfaces/smart-devices";
import ControllerLinkModal from "../controller-link-modal/controller-link-modal";
import DeviceUnlinkModal from "../device-unlink-modal/device-unlink-modal";
import ViewDeviceModalHeader from "./view-device-modal-header/view-device-modal-header";
import styles from "./view-device-modal.module.scss";

interface IProps {
  device: IPowerConsumerView;
  smartDevice?: ISmartDevice;
  show: boolean;
  size?: "modal-sm" | "modal-lg" | "modal-md";
  setShowAddUpdateModal: (show: boolean) => void;
  setShowRemoveDevice: (show: boolean) => void;
  onClose: () => void;
  icon: MtIcon;
  isPowerConsumerUpdated?: boolean;
  setIsPowerConsumerUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  spaceClusterId: string;
  spaceId: string;
  updateDeviceStatus?: (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => void;
  onIncrease?: (event: React.MouseEvent<HTMLElement>) => void;
  onDecrease?: (event: React.MouseEvent<HTMLElement>) => void;
  smartDeviceType: ControllerType;
}

const ViewDeviceModal = (props: IProps) => {
  const { smartDevice, smartDeviceType, onIncrease, onDecrease } = props;

  const [controllerLinkModal, setControllerLinkModal] = useState(false);
  const [deviceUnlinkModal, setDeviceUnlinkModal] = useState(false);
  const [scheduleIdList, setScheduleIdList] = useState<string[]>([]);
  const [linkSmartDeviceType, setLinkSmartDeviceType] =
    useState<ControllerType>(ControllerType.SMART_PLUG);
  const [unlinkedLinkPowerConsumer] = useUnlinkedLinkPowerConsumerMutation();
  const [unlinkAcController] = useUnlinkAcControllerMutation();

  const unlinkDevice = (
    spaceClusterId: string,
    spaceId: string,
    smartPlugId: string,
    linkedPowerConsumerId: string,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    if (smartDeviceType === ControllerType.AC_CONTROLLER) {
      unlinkAcController({
        spaceClusterId,
        spaceId,
        acControllerId: smartPlugId,
        linkedAcId: linkedPowerConsumerId,
        excludedSemiAutomatedSchedules,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device Unlinked Successfully");
          props.setIsPowerConsumerUpdated &&
            props.setIsPowerConsumerUpdated((ps) => !ps);
        })
        .catch(() => {
          showErrorMessage("Device Unlinked Unsuccessful");
        });

      return;
    }

    unlinkedLinkPowerConsumer({
      spaceClusterId,
      spaceId,
      smartPlugId,
      linkedPowerConsumerId,
      excludedSemiAutomatedSchedules,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Unlinked Successfully");
        props.setIsPowerConsumerUpdated &&
          props.setIsPowerConsumerUpdated((ps) => !ps);
      })
      .catch(() => {
        showErrorMessage("Device Unlinked Unsuccessful");
      });
  };

  return (
    <>
      <AppModal show={props.show}>
        <Container fluid>
          <Row className="justify-content-between align-items-center">
            <Col>
              <ViewDeviceModalHeader
                device={props.device}
                icon={props.icon}
                setShowAddUpdateModal={props.setShowAddUpdateModal}
                setShowRemoveDevice={props.setShowRemoveDevice}
                smartDevice={smartDevice as ISmartDevice}
                updateDeviceStatus={props.updateDeviceStatus}
                spaceClusterId={props.spaceClusterId}
                spaceId={props.spaceId}
              />
            </Col>
            <Col xs="auto" className="p-0">
              <MaterialIcon
                icon="close"
                size={28}
                className="cursor-pointer"
                onClick={props.onClose}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={3} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Device Type</div>
              <div className={styles.deviceValue}>
                {props.device.deviceType}
              </div>
            </Col>
            <Col xs={12} md={2} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Brand</div>
              <div className={styles.deviceValue}>{props.device.brand}</div>
            </Col>
            <Col xs={12} md={2} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Model</div>
              <div
                className={styles.deviceValue}
                style={{
                  wordWrap: "break-word",
                }}
              >
                {props.device.modelNumber}
              </div>
            </Col>
            <Col xs={12} md={5} className="mt-4 mt-md-0">
              <div className={styles.deviceTitle}>Average Energy Usage (W)</div>
              <div className={styles.deviceValue}>
                {props.device.powerUsageInWatt}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>Smart Controller</Col>
          </Row>
          {props.isPowerConsumerUpdated ? (
            <SpinnerModal
              withOverlay={false}
              show={props.isPowerConsumerUpdated}
              size="sm"
            />
          ) : (
            <>
              {smartDevice?.linkedPowerConsumer || smartDevice?.id ? (
                <Row
                  className={`${styles.socket} align-items-center justify-content-between mt-1 p-2 mx-0`}
                >
                  <Col>
                    <Row className="align-items-center">
                      <Col
                        xs={"auto"}
                        className="text-dark font-size-14 font-weight-500"
                      >
                        {smartDevice?.name}
                      </Col>
                      <Col className="text-light font-size-12 font-weight-400">
                        {smartDevice?.serialKey}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"}>
                    <MaterialIcon
                      icon="link_off"
                      color="#2F2A89"
                      className="cursor-pointer"
                      onClick={() => setDeviceUnlinkModal(true)}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="align-items-center mt-1 g-2">
                  <Col
                    className="cursor-pointer"
                    onClick={() => {
                      setLinkSmartDeviceType(ControllerType.SMART_PLUG);
                      setControllerLinkModal(true);
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <MaterialIcon icon="link" color="#2F2A89" />
                      </Col>
                      <Col className="text-light font-size-14 font-weight-500">
                        Link Smart Plug
                      </Col>
                    </Row>
                  </Col>
                  {props.device.deviceCategory ===
                    EDeviceTypes.AIR_CONDITIONER && (
                    <Col
                      className="cursor-pointer"
                      onClick={() => {
                        setControllerLinkModal(true);
                        setLinkSmartDeviceType(ControllerType.AC_CONTROLLER);
                      }}
                    >
                      <Row className="align-items-center">
                        <Col xs={"auto"}>
                          <MaterialIcon icon="link" color="#2F2A89" />
                        </Col>
                        <Col className="text-light font-size-14 font-weight-500">
                          Link AC Controller
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              )}
            </>
          )}
          {smartDevice?.dynamicState?.temperature !== undefined &&
            onIncrease &&
            onDecrease && (
              <Row className="mt-4">
                <Col>
                  <AcController
                    temperature={smartDevice?.dynamicState.temperature}
                    onIncrease={onIncrease}
                    onDecrease={onDecrease}
                    isShowTemperatureText={true}
                  />
                </Col>
              </Row>
            )}
        </Container>
      </AppModal>
      <ControllerLinkModal
        show={controllerLinkModal}
        onCancel={() => setControllerLinkModal(false)}
        onClose={() => setControllerLinkModal(false)}
        device={props.device}
        setIsPowerConsumerUpdated={props.setIsPowerConsumerUpdated}
        deviceConnectionState={
          smartDevice?.deviceConnectionState ||
          props.device?.deviceConnectionState
        }
        smartDeviceType={linkSmartDeviceType}
      />
      <DeviceUnlinkModal
        show={deviceUnlinkModal}
        onCancel={() => setDeviceUnlinkModal(false)}
        onClose={() => setDeviceUnlinkModal(false)}
        onConfirm={() => {
          unlinkDevice(
            props.spaceClusterId,
            props.spaceId,
            smartDevice?.id || "",
            smartDevice?.linkedPowerConsumer?.id || props.device.id,
            scheduleIdList
          );
          props.setIsPowerConsumerUpdated &&
            props.setIsPowerConsumerUpdated((ps) => !ps);
          setDeviceUnlinkModal(false);
        }}
        deviceName={props.device.name}
        controllerName={smartDevice?.name || ""}
        spaceClusterId={props.spaceClusterId}
        linkedPowerConsumerId={
          smartDevice?.linkedPowerConsumer?.id || props.device.id
        }
        setScheduleIdList={setScheduleIdList}
      />
    </>
  );
};

export default ViewDeviceModal;
