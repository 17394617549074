import { DeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import ControllerGraphRequestDTO from "../../../shared/oversight-general-core/dtos/controller-graph-request-dto";
import ControllerGraphResponseDTO from "../../../shared/oversight-general-core/dtos/controller-graph-response-dto";
import ControllerStatisticsRequestDTO from "../../../shared/oversight-general-core/dtos/controller-statistics-request-dto";
import ControllerStatisticsResponseDTO from "../../../shared/oversight-general-core/dtos/controller-statistics-response-dto";
import EnergyConsumptionStatisticsByDateRequestDTO from "../../../shared/oversight-general-core/dtos/energy-consumption-statistics-by-date-request-dto";
import EnergyConsumptionStatisticsByDateResponseDTO from "../../../shared/oversight-general-core/dtos/energy-consumption-statistics-by-date-response-dto";
import ListSmartControllersRequestDTO from "../../../shared/oversight-general-core/dtos/list-smart-controllers-request-dto";
import {
  IListSmartControllersGroupedResponseDTO,
  IListSmartControllersResponseDTO,
} from "../../../shared/oversight-general-core/dtos/list-smart-controllers-response-dto";
import ListUnlinkedDevicesRequestDTO from "../../../shared/oversight-general-core/dtos/list-unlinked-devices-request-dto";
import ListUnlinkedDevicesResponseDTO from "../../../shared/oversight-general-core/dtos/list-unlinked-devices-response-dto";
import ListUnlinkedSmartPlugRequestDTO from "../../../shared/oversight-general-core/dtos/list-unlinked-smart-plugs-request-dto";
import ListUnlinkedSmartPlugsResponseDTO from "../../../shared/oversight-general-core/dtos/list-unlinked-smart-plugs-response-dto";
import MoveSmartPlugResponseDTO from "../../../shared/oversight-general-core/dtos/move-smart-plug-response-dto";
import PowerUsageStatisticsByYearMonthRequestDTO from "../../../shared/oversight-general-core/dtos/power-usage-statistics-by-year-month-request-dto";
import PowerUsageStatisticsByYearMonthResponseDTO from "../../../shared/oversight-general-core/dtos/power-usage-statistics-by-year-month-response-dto";
import RegisterAcControllerRequestDTO from "../../../shared/oversight-general-core/dtos/register-ac-controller-request-dto";
import RegisterAcControllerResponseDTO from "../../../shared/oversight-general-core/dtos/register-ac-controller-response-dto";
import { default as RegisterControllerRequestDTO } from "../../../shared/oversight-general-core/dtos/register-controller-request-dto";
import {
  default as RegisterControllerResponseDTO,
  default as RegisterSmartDevicesResponseDTO,
} from "../../../shared/oversight-general-core/dtos/register-controller-response-dto";
import SwitchSmartPlugPowerStateByIdResponseDTO from "../../../shared/oversight-general-core/dtos/switch-smart-plug-power-state-by-id-response-dto";
import UnlinkAcControllerRequestDTO from "../../../shared/oversight-general-core/dtos/unlink-ac-controller-request-dto";
import UnlinkAcControllerResponseDTO from "../../../shared/oversight-general-core/dtos/unlink-ac-controller-response-dto";
import UnlinkSmartPlugRequestDTO from "../../../shared/oversight-general-core/dtos/unlink-smart-plug-request-dto";
import UnlinkSmartPlugResponseDTO from "../../../shared/oversight-general-core/dtos/unlink-smart-plug-response-dto";
import UpdateAcControllerRequestDTO from "../../../shared/oversight-general-core/dtos/update-ac-controller-request-dto";
import UpdateControllerRequestDTO from "../../../shared/oversight-general-core/dtos/update-controller-request-dto";
import IViewManufacturedDeviceBySerialKeyRequestDTO from "../../../shared/oversight-general-core/dtos/view-manufactured-device-by-serial-key-request-dto";
import IViewManufacturedDeviceBySerialKeyResponseDTO from "../../../shared/oversight-general-core/dtos/view-manufactured-device-by-serial-key-response-dto";
import { rootApi } from "../apiManager";

export const controllerAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    switchSmartController: builder.mutation<
      SwitchSmartPlugPowerStateByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        smartPlugId: string;
        switchingPowerState: DeviceStatus;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}/power/switch`,
          method: "PUT",
          params: {
            switchingPowerState: data.switchingPowerState,
          },
        };
      },
    }),
    switchSpace: builder.mutation<
      SwitchSmartPlugPowerStateByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        switchingPowerState: DeviceStatus;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/power/switch`,
          method: "PUT",
          params: {
            switchingPowerState: data.switchingPowerState,
          },
        };
      },
    }),
    unLinkPowerConsumerList: builder.query<
      ListUnlinkedDevicesResponseDTO,
      ListUnlinkedDevicesRequestDTO
    >({
      transformErrorResponse,
      query(data: ListUnlinkedDevicesRequestDTO) {
        return {
          url: `power-consumer/unlinked/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    unLinkSmartPlugList: builder.query<
      ListUnlinkedSmartPlugsResponseDTO,
      ListUnlinkedSmartPlugRequestDTO
    >({
      transformErrorResponse,
      query(data: ListUnlinkedSmartPlugRequestDTO) {
        return {
          url: `smart-device/unlinked/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    addController: builder.mutation<
      RegisterControllerResponseDTO,
      RegisterControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-device/smart-plug/register`,
          method: "POST",
          body: {
            registeringSmartPlugs: data.registeringSmartPlugs,
          },
        };
      },
    }),
    addAcController: builder.mutation<
      RegisterAcControllerResponseDTO,
      RegisterAcControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-device/ac-controller/register`,
          method: "POST",
          body: {
            registeringAcControllers: data.registeringAcControllers,
          },
        };
      },
    }),
    updateController: builder.mutation<
      RegisterSmartDevicesResponseDTO,
      UpdateControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}`,
          method: "PUT",
          body: {
            name: data.name,
            linkedPowerConsumerId: data.linkedPowerConsumerId,
          },
        };
      },
    }),
    updateAcController: builder.mutation<
      RegisterAcControllerResponseDTO,
      UpdateAcControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}`,
          method: "PUT",
          body: {
            name: data.name,
            linkedAcId: data.linkedAcId,
          },
        };
      },
    }),
    getGraph: builder.query<
      ControllerGraphResponseDTO,
      ControllerGraphRequestDTO
    >({
      transformErrorResponse,
      query(data: ControllerGraphRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/list`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            from: data.from,
          },
        };
      },
    }),
    getControllerStatistics: builder.query<
      ControllerStatisticsResponseDTO,
      ControllerStatisticsRequestDTO
    >({
      transformErrorResponse,
      query(data: ControllerStatisticsRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/statistics/by-date`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            date: data.date,
          },
        };
      },
    }),
    getPowerUsageStatisticsByYearMonth: builder.query<
      PowerUsageStatisticsByYearMonthResponseDTO,
      PowerUsageStatisticsByYearMonthRequestDTO
    >({
      transformErrorResponse,
      query(data: PowerUsageStatisticsByYearMonthRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/stats/by-year-month`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            year: data.year,
            month: data.month,
          },
        };
      },
    }),
    getEnergyConsumptionStatisticsByDate: builder.query<
      EnergyConsumptionStatisticsByDateResponseDTO,
      EnergyConsumptionStatisticsByDateRequestDTO
    >({
      transformErrorResponse,
      query(data: EnergyConsumptionStatisticsByDateRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/energy-consumption/statistics/by-date`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            date: data.date,
          },
        };
      },
    }),
    listSmartControllers: builder.query<
      IListSmartControllersResponseDTO &
        IListSmartControllersGroupedResponseDTO,
      ListSmartControllersRequestDTO
    >({
      transformErrorResponse,
      query(data: ListSmartControllersRequestDTO) {
        return {
          url: `smart-device/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    viewManufacturedDeviceBySerialKey: builder.query<
      IViewManufacturedDeviceBySerialKeyResponseDTO,
      IViewManufacturedDeviceBySerialKeyRequestDTO
    >({
      transformErrorResponse,
      query(data: IViewManufacturedDeviceBySerialKeyRequestDTO) {
        return {
          url: `smart-device/manufactured-device/${data.serialKey}`,
          method: "GET",
        };
      },
    }),
    unlinkedLinkPowerConsumer: builder.mutation<
      UnlinkSmartPlugResponseDTO,
      UnlinkSmartPlugRequestDTO
    >({
      transformErrorResponse,
      query(data: UnlinkSmartPlugRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}/unlink`,
          method: "PUT",
          body: {
            linkedPowerConsumerId: data.linkedPowerConsumerId,
            excludedSemiAutomatedSchedules: data.excludedSemiAutomatedSchedules,
          },
        };
      },
    }),
    unlinkAcController: builder.mutation<
      UnlinkAcControllerResponseDTO,
      UnlinkAcControllerRequestDTO
    >({
      transformErrorResponse,
      query(data: UnlinkAcControllerRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}/unlink`,
          method: "PUT",
          body: {
            linkedAcId: data.linkedAcId,
            excludedSemiAutomatedSchedules: data.excludedSemiAutomatedSchedules,
          },
        };
      },
    }),
    moveController: builder.mutation<
      MoveSmartPlugResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        smartPlugId: string;
        destinationSpaceId: string;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}/move`,
          method: "PUT",
          params: { destinationSpaceId: data.destinationSpaceId },
        };
      },
    }),
    updateAcTemperature: builder.mutation<
      MoveSmartPlugResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        acControllerId: string;
        updatingTemperature: number;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}/temperature`,
          method: "PUT",
          params: { updatingTemperature: data.updatingTemperature },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyUnLinkPowerConsumerListQuery,
  useLazyUnLinkSmartPlugListQuery,
  useLazyGetGraphQuery,
  useLazyGetControllerStatisticsQuery,
  useLazyGetPowerUsageStatisticsByYearMonthQuery,
  useLazyGetEnergyConsumptionStatisticsByDateQuery,
  useSwitchSmartControllerMutation,
  useSwitchSpaceMutation,
  useAddControllerMutation,
  useAddAcControllerMutation,
  useUpdateControllerMutation,
  useLazyListSmartControllersQuery,
  useUnlinkedLinkPowerConsumerMutation,
  useMoveControllerMutation,
  useLazyViewManufacturedDeviceBySerialKeyQuery,
  useUpdateAcTemperatureMutation,
  useUpdateAcControllerMutation,
  useUnlinkAcControllerMutation,
} = controllerAPI;
