import Card from "../../../ui-elements/card/card";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import styles from "./info.module.scss";

const Info = () => {
  return (
    <Card>
      <div className={styles.container}>
        <div className="d-flex justify-content-center">
          <MaterialIcon icon="emoji_objects" size={36} color="#D2CA7A" />
        </div>
        <p>
          Installing an on-grid solar system helps save money by reducing the
          usage of on-grid energy{" "}
        </p>
      </div>
    </Card>
  );
};

export default Info;
