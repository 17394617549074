import moment from "moment";
import { Option } from "../../oversight-core/ui-elements/app-select/app-select";
import { ITimeRangeProps } from "../components/add-time-range/add-time-range";

const mutateDateToCurrentTimeZoneISOString = (date: Date) => {
  return moment(date).toISOString(true);
};

export const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  return `${formattedHours < 10 ? "0" : ""}${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${period}`;
};

export const formatTimeSlots = (date: Date, timeList: ITimeRangeProps[]) => {
  if (
    timeList[timeList.length - 1].startTime.value &&
    timeList[timeList.length - 1].endTime.value
  ) {
    const tempTimeList = timeList.map((timeRange) => {
      const startTime = timeRange.startTime.value.split(" ");
      const endTime = timeRange.endTime.value.split(" ");
      const startTimeHour = startTime[0].split(":");
      const endTimeHour = endTime[0].split(":");
      let from = "";
      let to = "";
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();

      if (startTime[1] === "AM" && startTimeHour[0] === "12") {
        from = mutateDateToCurrentTimeZoneISOString(
          new Date(year, month, day, 0, +startTimeHour[1])
        );
      } else if (startTime[1] === "PM" && startTimeHour[0] !== "12") {
        from = mutateDateToCurrentTimeZoneISOString(
          new Date(year, month, day, +startTimeHour[0] + 12, +startTimeHour[1])
        );
      } else {
        from = mutateDateToCurrentTimeZoneISOString(
          new Date(year, month, day, +startTimeHour[0], +startTimeHour[1])
        );
      }

      if (endTime[1] === "AM" && endTimeHour[0] === "12") {
        to = mutateDateToCurrentTimeZoneISOString(
          new Date(year, month, day, 0, +endTimeHour[1])
        );
      } else if (endTime[1] === "PM" && endTimeHour[0] !== "12") {
        to = mutateDateToCurrentTimeZoneISOString(
          new Date(year, month, day, +endTimeHour[0] + 12, +endTimeHour[1])
        );
      } else {
        to = mutateDateToCurrentTimeZoneISOString(
          new Date(year, month, day, +endTimeHour[0], +endTimeHour[1])
        );
      }
      return { from: from, to: to };
    });
    return tempTimeList;
  }
  return [];
};

export const generateTimeRangeList = (
  startTime: Date,
  endTime: Date,
  intervalMinutes: number
) => {
  const TimeRangeList: Option[] = [];

  const currentTime = new Date(startTime);
  while (currentTime <= endTime) {
    const timeString = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    TimeRangeList.push({ label: timeString, value: timeString });
    currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
  }

  return TimeRangeList;
};
