import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useLazyForgotPasswordMobileNumberQuery,
  useLazyRegistrationResendOTPQuery,
} from "../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../enums/ovst-error-codes";
import { AppRoute } from "../../interfaces/app-routes";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import styles from "./mobile-number-otp-verification-footer.module.scss";

interface Iprops {
  isEmail?: boolean;
  whereTo?: string;
  number?: string;
  handleOTP?: (otp: string) => void;
  resetError?: () => void;
  setIsExpired?: () => void;
}

const MobileNumberOtpVerificationFooter = (props: Iprops) => {
  const [attemptCount, setAttemptCount] = useState(2);
  const [showAttemptCount, setShowAttemptCount] = useState(false);
  const [duration, setDuration] = useState<moment.Duration>();
  const [resendTimer, setResendTimer] = useState(90);
  const [errorMessage, setErrorMessage] = useState("");
  const [triggerForgotPasswordMobileNumber] =
    useLazyForgotPasswordMobileNumberQuery();
  const [resendCountExceeded, setResendCountExceeded] = useState(false);
  const [triggerRegistrationResendOtp] = useLazyRegistrationResendOTPQuery();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (resendTimer > 0) {
        const time = resendTimer - 1;
        setResendTimer(time);
        setDuration(moment.duration(time, "seconds"));
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const attemptHandler = () => {
    if (props.number && props.handleOTP && props.whereTo) {
      (props.whereTo === "reset"
        ? triggerForgotPasswordMobileNumber
        : triggerRegistrationResendOtp)({ mobileNumber: props.number })
        .unwrap()
        .then(() => {
          const attempt = attemptCount - 1;
          setAttemptCount(attempt);
          setResendTimer(90);
          setErrorMessage("");
          setShowAttemptCount(true);
          setResendCountExceeded(false);
          props.resetError && props.resetError();
        })
        .catch((error) => {
          if (error.status === 404) {
            setErrorMessage("");
          } else if (error.status === 412) {
            if (error.ovstErrorCode === OvstErrorCode.OVST_APP_SEC_0001) {
              setErrorMessage("Please wait 1:30 minutes before trying again.");
              setResendCountExceeded(false);
            } else if (
              error.ovstErrorCode === OvstErrorCode.OVST_APP_SEC_0002
            ) {
              setErrorMessage(
                `${
                  props.isEmail ? `Email` : `OTP`
                } resend attempts are over. Please contact admins for more details.`
              );
              setShowAttemptCount(false);
              setResendCountExceeded(true);
            }
          }
        });

      props.handleOTP("");
    }
  };

  return (
    <>
      <Row>
        <Col className={`${styles.title} text-center`}>
          Didn&apos;t Receive {props.isEmail ? "Email" : "OTP"} ?{" "}
          {!props.isEmail &&
            (resendTimer === 0 && !resendCountExceeded ? (
              <span
                className={styles.resend}
                onClick={() => {
                  attemptHandler();
                  props.setIsExpired && props.setIsExpired();
                }}
              >
                Resend{" "}
              </span>
            ) : (
              <span>Resend in </span>
            ))}
          {duration && resendTimer > 0 && (
            <>
              {duration.minutes()}m {duration.seconds()}s
            </>
          )}
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          {showAttemptCount && (
            <div className={styles.resendAttempts}>
              {attemptCount} Resend Attempts Left
            </div>
          )}
          {errorMessage && (
            <div className={styles.errorTitle}>{errorMessage}</div>
          )}
        </Col>
      </Row>

      {props.isEmail && (
        <Row className="justify-content-center">
          <Col md={10}>
            <AppButton
              text="Resend Email"
              className={`mt-3`}
              variant="blue"
              onClick={attemptHandler}
              disabled={resendTimer === 0 ? false : true}
            />
          </Col>
        </Row>
      )}
      <Row className="justify-content-center mt-4 ">
        <Col className="text-center">
          <Link to={AppRoute.VERIFY_MOBILE_NUMBER}>Change Mobile Number</Link>
        </Col>
      </Row>
    </>
  );
};

export default MobileNumberOtpVerificationFooter;
