import ModalContainer, {
  ModelContainerProps,
} from "../../ui-elements/modal-container/modal-container";

type IProps = ModelContainerProps;

const LogoutModal = (props: IProps) => {
  return (
    <ModalContainer
      {...props}
      title="Logout"
      confirmButtonText="Logout"
      size="modal-md"
    >
      <p style={{ color: "#69768B" }} className="font-size-14 m-0">
        Are you sure you want to logout now?
      </p>
    </ModalContainer>
  );
};

export default LogoutModal;
