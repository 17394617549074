import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useLazyForgotPasswordMobileNumberQuery } from "../../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../../enums/ovst-error-codes";
import { AppRoute } from "../../../interfaces/app-routes";
import AppTimer from "../../../ui-elements/app-timer/app-timer";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import AppInput from "../../../ui-elements/input/app-input";
import { mobileNumberRegex } from "../../../utils/regex";
interface IForgotPassword {
  email: string;
  phoneNumber: string;
}

const defaultFormValues: IForgotPassword = {
  email: "",
  phoneNumber: "",
};

const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IForgotPassword>({
    defaultValues: { ...defaultFormValues },
  });

  const [showAccountNotFound, setShowAccountNotFound] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const [
    triggerForgotPasswordMobileNumber,
    { isLoading: loadingForgotPasswordEmail },
  ] = useLazyForgotPasswordMobileNumberQuery();

  const offTimer = () => {
    setShowTimer(false);
  };

  const onSubmit = (data: IForgotPassword) => {
    if (data.phoneNumber) {
      triggerForgotPasswordMobileNumber({ mobileNumber: data.phoneNumber })
        .unwrap()
        .then(() => {
          setErrorMessage("");
          setShowAccountNotFound(false);
          navigate(AppRoute.MOBILE_NUMBER_VERIFICATION, {
            state: {
              number: data.phoneNumber,
              whereTo: "reset",
            },
          });
        })
        .catch((error) => {
          if (error.status === 412 && OvstErrorCode.OVST_0001) {
            setErrorMessage("");
            setShowAccountNotFound(true);
          } else if (error.status === 412) {
            setShowAccountNotFound(false);
            if (error.ovstErrorCode === OvstErrorCode.OVST_APP_SEC_0001) {
              setErrorMessage("Please wait 1:30 minutes before trying again.");
              setShowTimer(true);
            } else if (
              error.ovstErrorCode === OvstErrorCode.OVST_APP_SEC_0002
            ) {
              setErrorMessage(
                "You have exceeded maximum limit per hour. Please try again in one hour"
              );
            }
          }
        });
    }
    reset({ ...defaultFormValues });
  };

  useEffect(() => {
    if (errors.phoneNumber) {
      setShowAccountNotFound(false);
    }
  }, [errors.phoneNumber]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <AppInput
              name="phoneNumber"
              placeholder="Mobile Number"
              label="Mobile Number"
              type="number"
              register={register("phoneNumber", {
                required: "Mobile number is required",
                pattern: {
                  value: mobileNumberRegex,
                  message: "Mobile number is invalid",
                },
              })}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col className="col-auto">
            <AppTimer showTimer={showTimer} offTimer={offTimer} />
          </Col>
        </Row>
        <Row className="mt-4">
          {showAccountNotFound && (
            <Col className="text-center text-danger">
              we couldn&apos;t find an account associated with the mobile number
              you entered.
            </Col>
          )}
          {errorMessage && (
            <Col className="text-center text-danger">{errorMessage}</Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col>
            <AppButton
              text={`Send OTP`}
              size="medium"
              type="submit"
              isLoading={loadingForgotPasswordEmail}
              disabled={showTimer}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
