import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../oversight-core/enums/device-types";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../oversight-core/utils/findIcon";
import styles from "./selected-filters-view.module.scss";

interface IProps {
  spaceName: string;
  filters: {
    icon?: EDeviceTypes;
    filterName?: string;
    subText?: string;
    date?: string;
  }[];
}

const SelectedFiltersView = (props: IProps) => {
  const { spaceName, filters } = props;

  return (
    <>
      <Row>
        <Col className={styles.spaceName}>{spaceName}</Col>
      </Row>
      <Row className="align-items-center">
        {filters.map((filter, index) => (
          <Col key={index} className="col-auto">
            <Row className="align-items-center">
              {filter.icon && (
                <Col className={`col-auto pe-0`}>
                  <div className={`p-1 ${filter.icon} rounded`}>
                    <MaterialIcon icon={findIcon(filter.icon)} size={16} />
                  </div>
                </Col>
              )}
              {filter.filterName && (
                <Col className={`text-light font-size-12 font-weight-500 ps-2`}>
                  {filter.filterName}
                </Col>
              )}
              {filter.subText && (
                <Col
                  className={`text-primary font-size-12 font-weight-500 ps-0`}
                >
                  {filter.subText}
                </Col>
              )}
              {filter.date && (
                <Col
                  className={`text-primary font-size-12 font-weight-500 ps-0`}
                >
                  {filter.date}
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SelectedFiltersView;
