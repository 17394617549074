import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useLazyViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useViewWeeklyManualSchedulesQuery,
  useViewWeeklySemiAutomationSchedulesQuery,
} from "../../../redux/api/schedule/scheduleAPI";
import { useLazyGetSpaceQuery } from "../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  selectScheduleFilter,
  setScheduleFilter,
  setScheduleFiltersSpaceAndSpaceDetails,
} from "../../../redux/features/schedule-filter/schedule-filter-slice";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import { Option } from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsView from "../../../shared/oversight-core/ui-elements/stats-view/stats-view";
import Helper from "../../../shared/oversight-core/utils/helpers";
import {
  DataColumn,
  Header,
  TimeGridEvent,
} from "../../../shared/oversight-general-core/components/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../../shared/oversight-general-core/components/calendar/useTimeGrid";
import SpacePathViewBreadcrumb from "../../../shared/oversight-general-core/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import {
  default as IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
  default as ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
} from "../../../shared/oversight-general-core/dtos/view-semi-automation-schedule-monthly-energy-for-sub-space-cluster-response-dto";
import { EManagementMode } from "../../../shared/oversight-general-core/enums/management-mode";
import { ETimeGridYAxisType } from "../../../shared/oversight-general-core/enums/time-grid-y-axis-type";
import { IScheduledShallowView } from "../../../shared/oversight-general-core/interfaces/scheduled-shallow-view";
import NavigateWeeks from "../../../shared/oversight-general-core/ui-elements/navigate-weeks/navigate-weeks";
import {
  getMonthRangeToDisplay,
  getWeekRange,
} from "../../../shared/oversight-general-core/utils/date-util";
import { IAddScheduleProps } from "./components/add-update-schedule/add-update-schedule";
import MiniCalendar from "./components/mini-calendar/mini-calendar";
import CreateModal from "./components/modals/create-modal/create-modal";
import ScheduleDeleteModal from "./components/modals/schedule-delete-modal/schedule-delete-modal";
import ScheduleTabView from "./components/schedule-tab-view/schedule-tab-view";
import styles from "./schedule.module.scss";

type ColumnDataType = Date | string; // x-axis can be Date or Device ID

const navigateToScheduleAddEditPage = (
  selectedDate: Date | undefined,
  event: TimeGridEvent<ColumnDataType, IScheduledShallowView>,
  yAxis: ETimeGridYAxisType,
  spaceClusterId: string,
  subRootSpaceId: string,
  managementMode: EManagementMode
) => {
  const dateToUse =
    yAxis === ETimeGridYAxisType.DATE ? event.columnData : selectedDate;
  const initialIsoTimeList =
    !event.data?.id && dateToUse
      ? [
          {
            fromDate: moment(dateToUse)
              .startOf("day")
              .add(event.startTime, "minute")
              .toISOString(),
            toDate: moment(dateToUse)
              .startOf("day")
              .add(event.endTime, "minute")
              .toISOString(),
          },
        ]
      : undefined;

  const initialDeviceIds =
    !event.data?.id && event.columnData && yAxis === ETimeGridYAxisType.DEVICE
      ? [event.columnData.toString()]
      : event.data
      ? event.data.powerConsumers.map((pc) => pc.id)
      : undefined;

  const addScheduleState: IAddScheduleProps = {
    scheduleId: event.data?.id,
    spaceClusterId,
    subRootSpaceId,
    navigateLocation: [-1],
    initialIsoTimeList,
    initialDeviceIds,
    managementMode,
  };
  const routePath = event.data?.id
    ? AppRoute.EDIT_SCHEDULE
    : AppRoute.ADD_SCHEDULE;
  return {
    routePath,
    addScheduleState,
  };
};

const defaultScheduleMonthlyEnergyForSubSpaceCluster: IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO =
  {
    spaceClusterEnergy: {
      energyBill: 0,
      energyInUnits: 0,
    },
    spaceClusterAverageWeeklyEnergy: {
      weekdayDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weekendDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weeklyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
    },
  };

const Schedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scheduleFilterStore = useSelector(selectScheduleFilter);
  const billingSpaceFilterStore = useSelector(selectBillingSpaceFilter);
  const [noDevices, setNoDevices] = useState(false);
  const [noSmartDevices, setNoSmartDevices] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteScheduleData, setDeleteScheduleData] = useState({
    scheduleId: "",
    scheduleTitle: "",
  });
  const [isScheduleDeleted, setIsScheduleDeleted] = useState(false);
  const [deviceHeaders, setDeviceHeaders] = useState<
    { deviceName: string; deviceId: string }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date(scheduleFilterStore.selectedDate)
  );
  const [selectedManagementMode, setSelectedManagementMode] = useState<Option>(
    scheduleFilterStore.managementMode
  );
  const [events, setEvents] = useState<
    DataColumn<ColumnDataType, IScheduledShallowView>[]
  >([]);
  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), scheduleFilterStore.selectedDate)
  );
  const [
    scheduleMonthlyEnergyForSubSpaceCluster,
    setScheduleMonthlyEnergyForSubSpaceCluster,
  ] =
    useState<ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO>(
      { ...defaultScheduleMonthlyEnergyForSubSpaceCluster }
    );
  const [loading, setIsLoading] = useState(true);

  const [triggerGetSpace, { isFetching: isFetchingSpace }] =
    useLazyGetSpaceQuery();
  const [
    triggerViewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster,
    { isFetching: isFetchingViewSemiAutomationScheduleMonthlyEnergy },
  ] = useLazyViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterQuery();

  const [
    triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster,
    { isFetching: isFetchingViewManualScheduleMonthlyEnergy },
  ] = useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery();

  // Build grid headers. This can be Dates of week or Devices
  const headers: Header<ColumnDataType>[] = [];

  switch (scheduleFilterStore.timeGridYAxis) {
    case ETimeGridYAxisType.DATE:
      for (let cIndex = 0; cIndex < 7; cIndex++) {
        headers.push({
          columnId: moment(weekRange[cIndex]).toDate(),
          title: moment(weekRange[cIndex]).format("DD"),
          value: (
            <div className="d-flex flex-column">
              <div>{moment(weekRange[cIndex]).format("ddd")}</div>
              <div
                className={`${
                  moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                    ? `today-grid-style`
                    : ``
                } px-1`}
              >
                {moment(weekRange[cIndex]).format("DD")}
              </div>
            </div>
          ),
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
    case ETimeGridYAxisType.DEVICE:
      for (let cIndex = 0; cIndex < deviceHeaders.length; cIndex++) {
        headers.push({
          columnId: deviceHeaders[cIndex].deviceId,
          title: deviceHeaders[cIndex].deviceName,
          value: <div>{deviceHeaders[cIndex].deviceName}</div>,
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
  }

  useEffect(() => {
    if (
      scheduleFilterStore.selectedSpaceDetails.spaceClusterId &&
      scheduleFilterStore.selectedSpaceDetails.spaceId
    ) {
      triggerGetSpace({
        clusterId: scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
        spaceId: scheduleFilterStore.selectedSpaceDetails.spaceId,
      })
        .unwrap()
        .then((response) => {
          setNoDevices(
            response.subSpaceCluster.rootSpace.powerConsumers.length < 1
          );
          setNoSmartDevices(
            response.subSpaceCluster.rootSpace.smartDevices.filter(
              (smartDevice) => smartDevice.linkedPowerConsumer
            ).length === 0
          );
          const specificSmartDevices =
            response.subSpaceCluster.rootSpace.smartDevices.filter(
              (smartDevice) => smartDevice.linkedPowerConsumer
            );
          const devices = response.subSpaceCluster.rootSpace.powerConsumers;

          const deviceHeaders: { deviceName: string; deviceId: string }[] = [];

          if (selectedManagementMode.value === EManagementMode.SEMI_AUTO) {
            specificSmartDevices.forEach((smartDevice) => {
              const matchingPowerConsumer =
                response.subSpaceCluster.rootSpace.powerConsumers.find(
                  (powerConsumer) =>
                    powerConsumer.id === smartDevice.linkedPowerConsumer?.id
                );

              if (matchingPowerConsumer) {
                deviceHeaders.push({
                  deviceName: matchingPowerConsumer.name,
                  deviceId: matchingPowerConsumer.id,
                });
              }
            });
          } else {
            devices.forEach((device) => {
              deviceHeaders.push({
                deviceName: device.name,
                deviceId: device.id,
              });
            });
          }

          setDeviceHeaders(deviceHeaders);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    scheduleFilterStore.timeGridYAxis,
    scheduleFilterStore.selectedSpaceDetails,
    selectedManagementMode.value,
  ]);

  let isCurrentWeek = false;
  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const isShowTimeBar =
    isCurrentWeek &&
    scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DATE;

  const { calendarContent } = useTimeGrid<
    ColumnDataType,
    IScheduledShallowView
  >(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        viewEventModalTemplate: (events, onClose) => {
          return (
            <CreateModal<ColumnDataType>
              id=""
              events={events}
              onEditClick={(event) => {
                const { routePath, addScheduleState } =
                  navigateToScheduleAddEditPage(
                    new Date(scheduleFilterStore.selectedDate),
                    event,
                    scheduleFilterStore.timeGridYAxis,
                    scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
                    scheduleFilterStore.selectedSpaceDetails.spaceId,
                    scheduleFilterStore.managementMode.value
                  );
                navigate(routePath, {
                  state: addScheduleState,
                });
              }}
              onDelete={(event) => {
                onClose();
                setDeleteScheduleData({
                  scheduleId: event.data?.id || "",
                  scheduleTitle: event.data?.title || "",
                });
                setShowDeleteModal(true);
              }}
              onClose={onClose}
            />
          );
        },
        onEventChanged: (event) => {
          const { routePath, addScheduleState } = navigateToScheduleAddEditPage(
            new Date(scheduleFilterStore.selectedDate),
            event,
            scheduleFilterStore.timeGridYAxis,
            scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
            scheduleFilterStore.selectedSpaceDetails.spaceId,
            scheduleFilterStore.managementMode.value
          );
          navigate(routePath, {
            state: addScheduleState,
          });
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: TimeGridEvent<ColumnDataType, IScheduledShallowView>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                <Col
                  className={`${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>
              {event.data?.isDraft && (
                <Row>
                  <Col
                    className={`${
                      isGrouped ? `font-size-9` : `font-size-10`
                    } font-weight-400 text-white`}
                  >
                    (Draft)
                  </Col>
                </Row>
              )}
              {!isGrouped && (
                <Row>
                  <Col className="font-size-9 font-weight-500">
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Events
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [
      headers,
      scheduleFilterStore.selectedSpaceDetails,
      events,
      scheduleFilterStore.timeGridYAxis,
      isShowTimeBar,
    ])
  );

  const startDate = weekRange[0];
  const validForRequest =
    scheduleFilterStore.managementMode.value &&
    scheduleFilterStore.selectedSpaceDetails.spaceClusterId &&
    scheduleFilterStore.selectedSpaceDetails.spaceId &&
    ((deviceHeaders.length > 0 &&
      scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE) ||
      scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DATE);
  const {
    data: semiAutomationScheduleResponse,
    isFetching: isFetchingViewWeeklySemiAutomationSchedules,
  } = useViewWeeklySemiAutomationSchedulesQuery(
    validForRequest
      ? {
          spaceClusterId:
            scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
          subRootSpaceId: scheduleFilterStore.selectedSpaceDetails.spaceId,
          startDate: moment(startDate).startOf("day").toISOString(true),
        }
      : skipToken
  );
  const {
    data: manualScheduleResponse,
    isFetching: isFetchingViewWeeklyManualSchedules,
  } = useViewWeeklyManualSchedulesQuery(
    validForRequest
      ? {
          spaceClusterId:
            scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
          subRootSpaceId: scheduleFilterStore.selectedSpaceDetails.spaceId,
          startDate: moment(startDate).startOf("day").toISOString(true),
        }
      : skipToken
  );

  useEffect(() => {
    if (
      validForRequest &&
      (scheduleFilterStore.managementMode.value === EManagementMode.SEMI_AUTO
        ? semiAutomationScheduleResponse
        : manualScheduleResponse)
    ) {
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<ColumnDataType, IScheduledShallowView>
      > = {};
      (scheduleFilterStore.managementMode.value === EManagementMode.SEMI_AUTO
        ? semiAutomationScheduleResponse?.shallowSemiAutomatedScheduleViews
        : manualScheduleResponse?.shallowScheduleViews
      )?.forEach((sp: IScheduledShallowView) => {
        switch (scheduleFilterStore.timeGridYAxis) {
          case ETimeGridYAxisType.DATE:
            sp.scheduledPeriods.forEach((s) => {
              const fromDate = moment(new Date(s.fromDate));
              const toDate = moment(new Date(s.toDate));

              const day = fromDate.day();
              const columnId = fromDate.toDate().toISOString();
              const columnData = fromDate.toDate();
              const generated = {
                title: sp.title,
                columnData,
                startTime: fromDate
                  .clone()
                  .diff(fromDate.clone().startOf("day"), "minutes"),
                endTime: toDate
                  .clone()
                  .diff(toDate.clone().startOf("day"), "minutes"),
                date: fromDate.toDate(),
                data: sp,
              };

              if (generated) {
                // need to collect the event since event is generated
                if (!groupByDateOrDevId[day]) {
                  // create a column if not exist
                  groupByDateOrDevId[day] = {
                    columnId,
                    columnData,
                    columnIndex: sundayStartToMondayStart(day),
                    events: [],
                  };
                }
                // then collect the event
                groupByDateOrDevId[day].events.push(generated);
              }
            });
            break;
          case ETimeGridYAxisType.DEVICE:
            sp.scheduledPeriods.forEach((s) => {
              sp.powerConsumers.forEach((device) => {
                const fromDate = moment(new Date(s.fromDate));
                const toDate = moment(new Date(s.toDate));
                const columnIndex = deviceHeaders.findIndex(
                  (deviceHeader) => device.id === deviceHeader.deviceId
                );
                if (columnIndex > -1) {
                  const matchedDeviceHeader = deviceHeaders[columnIndex];
                  const columnId = matchedDeviceHeader.deviceId;
                  const columnData = matchedDeviceHeader.deviceId;
                  const generated = {
                    title: sp.title,
                    columnData,
                    startTime: fromDate
                      .clone()
                      .diff(fromDate.clone().startOf("day"), "minutes"),
                    endTime: toDate
                      .clone()
                      .diff(toDate.clone().startOf("day"), "minutes"),
                    date: fromDate.toDate(),
                    data: sp,
                  };

                  if (
                    generated &&
                    moment(s.fromDate)
                      .startOf("day")
                      .isSame(
                        moment(scheduleFilterStore.selectedDate).startOf("day")
                      )
                  ) {
                    // need to collect the event since event is generated
                    if (!groupByDateOrDevId[matchedDeviceHeader.deviceId]) {
                      // create a column if not exist
                      groupByDateOrDevId[matchedDeviceHeader.deviceId] = {
                        columnId,
                        columnData,
                        columnIndex,
                        events: [],
                      };
                    }
                    // then collect the event
                    groupByDateOrDevId[
                      matchedDeviceHeader.deviceId
                    ].events.push(generated);
                  }
                }
              });
            });
            break;
        }
      });
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [
    semiAutomationScheduleResponse,
    manualScheduleResponse,
    validForRequest,
    scheduleFilterStore.selectedDate,
    startDate,
    isScheduleDeleted,
    scheduleFilterStore.timeGridYAxis,
    scheduleFilterStore.managementMode.value,
    deviceHeaders,
  ]);

  const onFilter = (
    selectedSpace: ISpaceView,
    date: Date,
    managementMode: { value: EManagementMode; label: string },
    timeGridYAxis?: ETimeGridYAxisType
  ) => {
    const filterSelectedSpace = scheduleFilterStore.selectedSpace;

    const isSelectedSpaceEqual =
      filterSelectedSpace.clusterId === selectedSpace.clusterId &&
      filterSelectedSpace.id === selectedSpace.id;

    dispatch(
      setScheduleFilter({
        ...scheduleFilterStore,
        selectedSpace,
        selectedDate: moment(date).valueOf(),
        selectedSpaceDetails: !isSelectedSpaceEqual
          ? {
              spaceClusterId: selectedSpace.clusterId,
              spaceId: selectedSpace.id,
            }
          : scheduleFilterStore.selectedSpaceDetails,
        managementMode: managementMode,
        timeGridYAxis: timeGridYAxis || scheduleFilterStore.timeGridYAxis,
      })
    );
  };

  useEffect(() => {
    if (selectedManagementMode || selectedDate) {
      onFilter(scheduleFilterStore.selectedSpace, selectedDate, {
        value: selectedManagementMode.value as EManagementMode,
        label: selectedManagementMode.label as string,
      });
    }
  }, [selectedManagementMode, selectedDate]);

  useEffect(() => {
    if (
      billingSpaceFilterStore.spaceCluster.id &&
      billingSpaceFilterStore.spaceCluster.rootSpace.id
    ) {
      (scheduleFilterStore.managementMode.value === EManagementMode.SEMI_AUTO
        ? triggerViewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster
        : triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster)({
        spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
        subRootSpaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
        year: +moment(scheduleFilterStore.selectedDate).format("yy"),
        month: +moment(scheduleFilterStore.selectedDate).format("MM"),
      })
        .unwrap()
        .then((res) => {
          setScheduleMonthlyEnergyForSubSpaceCluster(res);
          setIsLoading(true);
        })
        .catch(() => {
          setScheduleMonthlyEnergyForSubSpaceCluster({
            ...defaultScheduleMonthlyEnergyForSubSpaceCluster,
          });
        });
    }
  }, [
    billingSpaceFilterStore.spaceCluster.id,
    billingSpaceFilterStore.spaceCluster.rootSpace.id,
    scheduleFilterStore.selectedDate,
    scheduleFilterStore.managementMode.value,
  ]);

  useEffect(() => {
    setWeekRange(
      getWeekRange(
        new Date(scheduleFilterStore.selectedDate),
        scheduleFilterStore.selectedDate
      )
    );
  }, [scheduleFilterStore.selectedDate]);

  const sendSelectedSpace = (space: ISpaceView) => {
    dispatch(
      setScheduleFiltersSpaceAndSpaceDetails({
        selectedSpace: space,
        selectedSpaceDetails: {
          spaceClusterId: space.clusterId,
          spaceId: space.id,
        },
      })
    );
  };

  return (
    <>
      <div className="container-white">
        <ScheduleTabView
          selectedDate={selectedDate}
          isFetching={
            (isFetchingViewSemiAutomationScheduleMonthlyEnergy && loading) ||
            (isFetchingViewManualScheduleMonthlyEnergy && loading)
          }
          scheduledSpaceClusterAverageWeeklyEnergy={
            scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterAverageWeeklyEnergy
          }
          spaceClusterEstimatedConsumption={
            scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterEnergy
          }
          selectedManagementMode={selectedManagementMode}
          setSelectedDate={setSelectedDate}
          setSelectedManagementMode={setSelectedManagementMode}
        />
      </div>
      <div className="container-white position-relative mt-4">
        <Row>
          <Col>
            <SpacePathViewBreadcrumb
              selectedSpace={{
                clusterId: scheduleFilterStore.selectedSpace.clusterId,
                spaceId: scheduleFilterStore.selectedSpace.id,
              }}
              sendSelectedSpace={sendSelectedSpace}
            />
          </Col>
        </Row>
        <Row className="align-items-center my-3">
          <Col
            className={`col-12 ${
              scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DATE
                ? `col-xl-6`
                : `col-xl-auto`
            }`}
          >
            <Row className="align-items-center">
              <Col sm={6} className="col-xl-auto">
                <Row className="align-items-center flex-nowrap">
                  <Col className="col-auto">
                    <AppDatePicker
                      selectedDate={new Date(scheduleFilterStore.selectedDate)}
                      onChange={(date: Date) => {
                        onFilter(scheduleFilterStore.selectedSpace, date, {
                          value: scheduleFilterStore.managementMode.value,
                          label: scheduleFilterStore.managementMode.label,
                        });
                      }}
                      isInput={true}
                      showDate={false}
                    />
                  </Col>
                  <Col className="col-xl-auto">
                    <NavigateWeeks
                      selectedDate={new Date(scheduleFilterStore.selectedDate)}
                      handleDateChange={(date: Date) => {
                        onFilter(scheduleFilterStore.selectedSpace, date, {
                          value: scheduleFilterStore.managementMode.value,
                          label: scheduleFilterStore.managementMode.label,
                        });
                        setIsLoading(false);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6} className="mt-2 mt-sm-0 col-xl-auto">
                <Row className="align-items-center flex-nowrap">
                  <Col className="text-light font-weight-500 font-size-12 col-auto">
                    {getMonthRangeToDisplay(weekRange)}
                  </Col>
                  <Col
                    className={`col-auto ${styles.miniCalendarToggler} py-2 cursor-pointer`}
                    onClick={() => {
                      onFilter(
                        scheduleFilterStore.selectedSpace,
                        new Date(scheduleFilterStore.selectedDate),
                        {
                          value: scheduleFilterStore.managementMode.value,
                          label: scheduleFilterStore.managementMode.label,
                        },
                        scheduleFilterStore.timeGridYAxis ===
                          ETimeGridYAxisType.DEVICE
                          ? ETimeGridYAxisType.DATE
                          : ETimeGridYAxisType.DEVICE
                      );
                    }}
                  >
                    <Row className="align-items-center">
                      <Col>
                        <MaterialIcon icon="swap_horiz" />
                      </Col>
                      <Col className="text-light font-size-12 font-weight-500 ps-0">
                        {scheduleFilterStore.timeGridYAxis ===
                        ETimeGridYAxisType.DEVICE
                          ? ETimeGridYAxisType.DATE
                          : ETimeGridYAxisType.DEVICE}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DATE ? (
            <Col className="col-12 mt-2 mt-xl-0" xl={6}>
              <Row className="align-items-center justify-content-start justify-content-xl-end">
                <Col className="col-12 col-sm col-lg-3 col-xl-4 mt-2 mt-sm-0 d-flex justify-content-center justify-content-sm-start">
                  <StatsView
                    variant="bg-icon-2"
                    labelValue={Helper.roundTo2(10) + ""}
                    title="ESTIMATED UNITS"
                  />
                </Col>
                <Col className="col-12 col-sm col-lg-3 col-xl-4 mt-2 mt-sm-0 d-flex justify-content-center justify-content-sm-start">
                  <StatsView
                    variant="bg-icon-2"
                    labelValue={Helper.roundTo2(15) + "%"}
                    title="OF TOTAL UNITS"
                  />
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              className="col-12 mt-2 mt-xl-0 ps-xl-4 d-xl-flex align-items-center"
              xl={6}
            >
              <MiniCalendar
                startDate={weekRange[0]}
                selectedDate={new Date(scheduleFilterStore.selectedDate)}
                handleDateChange={(date: Date) => {
                  onFilter(scheduleFilterStore.selectedSpace, date, {
                    value: scheduleFilterStore.managementMode.value,
                    label: scheduleFilterStore.managementMode.label,
                  });
                }}
              />
            </Col>
          )}
        </Row>
        {scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE && (
          <Row className="mt-2">
            <Col className="col-12 col-sm-6 col-xl-3">
              <StatsView
                variant="bg-icon-2"
                labelValue={Helper.roundTo2(10) + ""}
                title="ESTIMATED UNITS"
              />
            </Col>
            <Col className="col-12 col-sm-6 col-xl-3 mt-2 mt-sm-0">
              <StatsView
                variant="bg-icon-2"
                labelValue={Helper.roundTo2(15) + "%"}
                title="OF TOTAL UNITS"
              />
            </Col>
          </Row>
        )}
        <Row
          className={`${
            scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE
              ? `mt-4`
              : `mt-2`
          }`}
        >
          <Col>
            {scheduleFilterStore.managementMode.value ===
            EManagementMode.SEMI_AUTO ? (
              <>
                {!noSmartDevices ? (
                  <div>{calendarContent}</div>
                ) : (
                  <div className="container-dash mt-4">
                    <Row>
                      <Col className="text-center text-light font-size-12">
                        This space does not contain any smart devices.
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            ) : (
              <>
                {!noDevices ? (
                  <div>{calendarContent}</div>
                ) : (
                  <div className="container-dash mt-4">
                    <Row>
                      <Col className="text-center text-light font-size-12">
                        This space does not contain any devices.
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
        <SpinnerModal
          show={
            isFetchingViewSemiAutomationScheduleMonthlyEnergy ||
            isFetchingViewManualScheduleMonthlyEnergy ||
            isFetchingViewWeeklySemiAutomationSchedules ||
            isFetchingViewWeeklyManualSchedules ||
            isFetchingSpace
          }
        />
      </div>

      {/* <div className="container-dash mt-4">
          <Row>
            <Col className="text-center text-light font-size-12">
              You have not created any billing spaces.{" "}
              <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>{" "}
            </Col>
          </Row>
        </div> */}

      <ScheduleDeleteModal
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        spaceClusterId={scheduleFilterStore.selectedSpaceDetails.spaceClusterId}
        subRootSpaceId={scheduleFilterStore.selectedSpaceDetails.spaceId}
        deleteScheduleData={deleteScheduleData}
        onSuccess={() => {
          setIsScheduleDeleted((ps) => !ps);
        }}
        selectedManagementMode={scheduleFilterStore.managementMode.value}
      />
    </>
  );
};

export default Schedule;
