import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import { ListSpace } from "../../interfaces/list-space";
import SpaceSelectorDropdownOptions from "./space-selector-dropdown-options/space-selector-dropdown-options";
import styles from "./space-selector-dropdown.module.scss";
interface IProps {
  dropdownOptions: ListSpace[];
  updateSelectedSubSpaceId?: (
    selectedSubSpaceId: string,
    selectedSubSpaceName: string,
    clusterId: string
  ) => void;
  selectedSubSpace?: string;
  label?: string;
  disabled: boolean;
  fontSize?: number;
  className?: string;
  maxHeight?: string;
  isRequired?: boolean;
}
const SpaceSelectorDropdown = (props: IProps) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if (
      menuButtonRef.current &&
      !menuButtonRef.current.contains(e.target as Node)
    ) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuButtonRef]);

  const handleSpaceSelectionChange = (
    subSpaceId: string,
    subSpaceName: string,
    clusterId: string
  ) => {
    if (props.updateSelectedSubSpaceId) {
      props.updateSelectedSubSpaceId(subSpaceId, subSpaceName, clusterId);
    }
    setShowDropDown(false);
  };

  return (
    <>
      {props.label && (
        <>
          <label className="font-size-14">{props.label}</label>
          <label className="asterisk">{props.isRequired && "*"}</label>
        </>
      )}
      <div
        className={`${styles["space-selector-dropdown-select"]} cursor-${
          props.disabled ? `disabled` : `pointer`
        }`}
        ref={menuButtonRef}
        onClick={() => {
          if (!props.disabled) {
            setShowDropDown((ps) => !ps);
          }
        }}
      >
        <Row className="align-items-center w-100">
          <Col>
            <span
              className={`mb-0 text-dark font-weight-400 ${props.className}`}
              style={{ fontSize: `${props.fontSize ? props.fontSize : 12}px` }}
            >
              {props.selectedSubSpace || ""}
            </span>
          </Col>
          <Col className="col-auto px-2">
            <MaterialIcon
              icon="expand_more"
              className={`${styles[`d-arrow`]}`}
              style={
                !showDropDown
                  ? { transform: "rotate(0deg)" }
                  : { transform: "rotate(180deg)" }
              }
            />
          </Col>
        </Row>
        {showDropDown && (
          <div
            className={styles.dropdownContainer}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
            }}
            style={
              props.maxHeight
                ? { maxHeight: `${props.maxHeight}` }
                : { maxHeight: `400px` }
            }
          >
            <SpaceSelectorDropdownOptions
              options={props.dropdownOptions || []}
              indent={false}
              handleSpaceSelectionChange={handleSpaceSelectionChange}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default SpaceSelectorDropdown;
