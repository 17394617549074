import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyGetSpaceHierarchyQuery } from "../../../../redux/api/space/spaceAPI";
import AddUpdateDeviceModal from "../../../../screens/dashboard/space-clusters/components/models/add-update-device-modal/add-update-device-model";
import RemoveDeviceModal from "../../../../screens/dashboard/space-clusters/components/models/remove-device-modal/remove-device-modal";
import ViewDeviceModal from "../../../../screens/dashboard/space-clusters/components/models/view-device-modal/view-device-modal";
import { DeviceStatus } from "../../../oversight-core/enums/device-status";
import { IPowerConsumerView } from "../../../oversight-core/interfaces/entities/power-consumer";
import ActiveInactiveIndicator from "../../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../oversight-core/utils/findIcon";
import { ConnectionState } from "../../enums/connection-status";
import { ControllerType } from "../../enums/controller-type";
import ISmartDevice from "../../interfaces/smart-devices";
import { ISpaceClusterShallowHierarchyView } from "../../interfaces/space-hierarchy";
import AppDropDown from "../../ui-elements/app-dropdown/app-drop-down";
import AppToggle from "../../ui-elements/app-toggle/app-toggle";
import AcController from "../ac-controller/ac-controller";
import styles from "./list-card.module.scss";

export interface IExtendedSmartDevice extends ISmartDevice {
  clusterId: string;
  spaceId: string;
}

interface IProps {
  backgroundColor?: string;
  isDevicesOnly: boolean;
  deleteDevice: (clusterId: string, spaceId: string, deviceId: string) => void;
  updateCurrentState: () => void;
  device: IPowerConsumerView;
  mainSpaceClusterId: string;
  mainSpaceId: string;
  path?: ISpaceClusterShallowHierarchyView;
  showSocketInformation?: boolean;
  smartDevice?: IExtendedSmartDevice;
  isPowerConsumerUpdated?: boolean;
  setIsPowerConsumerUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  updateDeviceStatus?: (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => void;
  isTextTruncate?: boolean;
  isUpdateDeviceStateLoading?: boolean;
  onAcTemperatureIncrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  onAcTemperatureDecrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  isDirectly?: boolean;
}

const ListCard = (props: IProps) => {
  const {
    backgroundColor,
    isDevicesOnly,
    deleteDevice,
    updateCurrentState,
    device,
    mainSpaceClusterId,
    mainSpaceId,
    path,
    showSocketInformation,
    smartDevice,
    updateDeviceStatus,
    isTextTruncate = true,
    onAcTemperatureDecrease,
    onAcTemperatureIncrease,
    isDirectly = false,
  } = props;

  const [showRemoveDevice, setShowRemoveDevice] = useState(false);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [showViewDeviceModal, setShowViewDeviceModal] = useState(false);
  const [breadCrumbPath, setBreadCrumbPath] = useState("");
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    if (
      smartDevice?.dynamicState?.powerState === DeviceStatus.ON ||
      device.devicePowerState === DeviceStatus.ON
    ) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [smartDevice?.dynamicState?.powerState, device.devicePowerState]);

  const closeAddUpdateDeviceModal = () => {
    setShowAddUpdateModal(false);
  };

  const [triggerGetHierarchy] = useLazyGetSpaceHierarchyQuery();

  useEffect(() => {
    if (!path) {
      triggerGetHierarchy({
        clusterId: mainSpaceClusterId,
        spaceId: mainSpaceId,
      })
        .unwrap()
        .then((response) => {
          let paths = "";
          response.ancestorSpaces
            .slice()
            .reverse()
            .forEach((ancestor, index) => {
              paths +=
                index !== response.ancestorSpaces.length - 1
                  ? `${ancestor.name} > `
                  : `${ancestor.name}`;
            });

          setBreadCrumbPath(paths);
        })
        .catch((error) => {
          console.log(error);
          setBreadCrumbPath("");
        });
    } else {
      let paths = "";
      path.ancestorSpaces
        .slice()
        .reverse()
        .forEach((ancestor, index) => {
          paths +=
            index !== path.ancestorSpaces.length - 1
              ? `${ancestor.name} > `
              : `${ancestor.name}`;
        });

      setBreadCrumbPath(paths);
    }
  }, [triggerGetHierarchy, path]);

  const onIncrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureIncrease &&
      smartDevice &&
      smartDevice.dynamicState.temperature < 30
    ) {
      onAcTemperatureIncrease(
        mainSpaceClusterId,
        mainSpaceId,
        smartDevice.id,
        smartDevice.dynamicState?.temperature + 1
      );
    }
  };

  const onDecrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureDecrease &&
      smartDevice &&
      smartDevice.dynamicState.temperature > 16
    ) {
      onAcTemperatureDecrease(
        mainSpaceClusterId,
        mainSpaceId,
        smartDevice.id,
        smartDevice.dynamicState?.temperature - 1
      );
    }
  };

  const appToggle = smartDevice?.linkedPowerConsumer && (
    <AppToggle
      isOn={isOn}
      onSwitch={() => {
        if (smartDevice.deviceConnectionState === ConnectionState.CONNECTED) {
          updateDeviceStatus &&
            updateDeviceStatus(
              smartDevice.clusterId,
              smartDevice.spaceId,
              smartDevice.id,
              smartDevice.dynamicState?.powerState === DeviceStatus.ON
                ? DeviceStatus.OFF
                : DeviceStatus.ON
            );
        }
      }}
      size="sm"
      isDisable={
        smartDevice.deviceConnectionState === ConnectionState.CONNECTED
          ? false
          : true
      }
    />
  );

  const appDropdown = (
    <AppDropDown
      items={[
        {
          text: "Edit Device",
          onClick: () => {
            setShowAddUpdateModal(true);
          },
        },
        {
          text: "Remove Device",
          onClick: () => {
            setShowRemoveDevice(true);
          },
        },
        {
          text: " Move Device",
          onClick: () => {
            console.log();
          },
        },
        {
          text: "Disable Device",
          onClick: () => {
            console.log();
          },
        },
        {
          text: "Automation",
          onClick: () => {
            console.log();
          },
        },
        {
          text: "Share Access",
          onClick: () => {
            console.log();
          },
        },
      ]}
    />
  );

  const activeInactiveIndicator = (
    <ActiveInactiveIndicator
      isActive={
        smartDevice?.deviceConnectionState === ConnectionState.CONNECTED
          ? true
          : false
      }
    />
  );

  return (
    <>
      <div
        className={`${styles.listCardContainer} p-2 ps-4 cursor-pointer`}
        style={{ backgroundColor: backgroundColor }}
        onClick={() => setShowViewDeviceModal(true)}
      >
        <Row className="align-items-center">
          <Col className={`${device.deviceType} p-3 rounded col-auto mb-sm-2`}>
            <MaterialIcon icon={findIcon(device.deviceType)} />
          </Col>
          <Col className="mb-sm-2">
            <Row className="align-items-center">
              <Col
                className={`${styles.deviceName} ${
                  isTextTruncate && styles.textTruncate
                } ${isDevicesOnly ? `col-auto` : ``} pe-0`}
              >
                {device.name}
              </Col>
              {isDevicesOnly && (
                <Col>
                  <Row className="align-items-center flex-nowrap">
                    <Col className="font-size-12 text-light font-weight-400 pe-0 col-auto">
                      {device.powerUsageInWatt}W
                    </Col>
                    {smartDevice?.deviceConnectionState && (
                      <Col className="ps-1">{activeInactiveIndicator}</Col>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
            <Row>
              {isDevicesOnly && (
                <Col className="font-size-11 text-light font-weight-400">
                  {breadCrumbPath}
                </Col>
              )}
              {!isDevicesOnly && (
                <Col>
                  <Row className="align-items-center">
                    <Col className="font-size-12 text-light font-weight-400 pe-0 col-auto">
                      {device.powerUsageInWatt}W
                    </Col>
                    {smartDevice?.linkedPowerConsumer && (
                      <Col>{activeInactiveIndicator}</Col>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col className={`pe-4 col-12 col-sm-auto mt-3 mt-sm-0`}>
            <Row className="align-items-center gap-1 flex-nowrap">
              {showSocketInformation && (
                <Col className="col col-sm-auto">
                  <Row className="align-items-center flex-nowrap">
                    <Col className="col-auto pe-0">
                      <MaterialIcon icon="smart_outlet" size={12} />
                    </Col>
                    <Col className="font-size-12 text-light font-weight-400 ps-1">
                      Bed Room Outlet
                    </Col>
                  </Row>
                </Col>
              )}
              {smartDevice && (
                <>
                  {smartDevice?.smartDeviceType ===
                    ControllerType.AC_CONTROLLER &&
                    isDirectly && (
                      <Col className="col-auto">
                        <AcController
                          temperature={smartDevice.dynamicState?.temperature}
                          onIncrease={onIncrease}
                          onDecrease={onDecrease}
                        />
                      </Col>
                    )}
                  {(smartDevice.smartDeviceType !==
                    ControllerType.AC_CONTROLLER ||
                    isDirectly) && (
                    <>
                      <Col className="col-auto">{appToggle}</Col>
                      <Col className={`p-1 ${styles.dropdown} col-auto`}>
                        {appDropdown}
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
          </Col>
        </Row>
        {smartDevice?.smartDeviceType === ControllerType.AC_CONTROLLER &&
          !isDirectly && (
            <Row className="align-items-center">
              <Col className="mt-2">
                <AcController
                  temperature={smartDevice.dynamicState?.temperature}
                  onIncrease={onIncrease}
                  onDecrease={onDecrease}
                />
              </Col>
              <Col className="col-auto mt-2">
                <Row className="align-items-center">
                  {smartDevice?.linkedPowerConsumer && (
                    <Col className="col-auto">{appToggle}</Col>
                  )}
                  <Col className={`p-1 pe-4 ${styles.dropdown} col-auto`}>
                    {appDropdown}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
      </div>
      <ViewDeviceModal
        device={props.device}
        show={showViewDeviceModal && !props.isUpdateDeviceStateLoading}
        setShowAddUpdateModal={setShowAddUpdateModal}
        setShowRemoveDevice={setShowRemoveDevice}
        onClose={() => {
          setShowViewDeviceModal(false);
        }}
        icon={findIcon(props.device.deviceType)}
        smartDevice={smartDevice}
        isPowerConsumerUpdated={props.isPowerConsumerUpdated}
        setIsPowerConsumerUpdated={props.setIsPowerConsumerUpdated}
        spaceClusterId={mainSpaceClusterId}
        spaceId={mainSpaceId}
        updateDeviceStatus={updateDeviceStatus}
        onIncrease={onIncrease}
        onDecrease={onDecrease}
        smartDeviceType={
          smartDevice?.smartDeviceType || ControllerType.SMART_PLUG
        }
      />
      <AddUpdateDeviceModal
        show={showAddUpdateModal}
        onClose={() => setShowAddUpdateModal(false)}
        onCancel={() => setShowAddUpdateModal(false)}
        spaceClusterId={mainSpaceClusterId}
        spaceId={mainSpaceId}
        closeAddUpdateDeviceModal={closeAddUpdateDeviceModal}
        updateCurrentState={updateCurrentState}
        device={device}
        setIsPowerConsumerUpdated={props.setIsPowerConsumerUpdated}
      />
      <RemoveDeviceModal
        show={showRemoveDevice}
        deviceName={device.name}
        onClose={() => setShowRemoveDevice(false)}
        onCancel={() => setShowRemoveDevice(false)}
        onConfirm={() => {
          deleteDevice(mainSpaceClusterId, mainSpaceId, device.id);
          setShowRemoveDevice(false);
          updateCurrentState();
        }}
      />
    </>
  );
};

export default ListCard;
