import LoginLayout from "../../shared-components/login-layout/login-layout";
import ChangeEmailForm from "./change-email-form/change-email-form";

const ChangeEmail = () => {
  return (
    <LoginLayout
      title="Change Email"
      subTitle="Enter new email for the account."
      showBackButton={true}
    >
      <ChangeEmailForm />
    </LoginLayout>
  );
};

export default ChangeEmail;
