import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddAcControllerMutation,
  useAddControllerMutation,
  useLazyViewManufacturedDeviceBySerialKeyQuery,
  useMoveControllerMutation,
  useUnlinkAcControllerMutation,
  useUnlinkedLinkPowerConsumerMutation,
  useUpdateAcControllerMutation,
  useUpdateControllerMutation,
} from "../../../../../redux/api/controller/controllerAPI";
import {
  useLazyGetSpaceHierarchyQuery,
  useLazyGetSpacesQuery,
} from "../../../../../redux/api/space/spaceAPI";
import { DeviceStatus } from "../../../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../../../shared/oversight-core/enums/device-types";
import { OvstErrorCode } from "../../../../../shared/oversight-core/enums/ovst-error-codes";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { noSpecialCharsNoWhitespaceStartEndAndAllowCharacters } from "../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import serialKey from "../../../../../shared/oversight-general-core/assets/image/image 2.png";
import SpaceSelectorDropdown from "../../../../../shared/oversight-general-core/components/space-selector-dropdown/space-selector-dropdown";
import { ConnectionState } from "../../../../../shared/oversight-general-core/enums/connection-status";
import { ControllerType } from "../../../../../shared/oversight-general-core/enums/controller-type";
import { IRegisteringSmartDevice } from "../../../../../shared/oversight-general-core/interfaces/registering-smart-device";
import { ISelectedSpace } from "../../../../../shared/oversight-general-core/interfaces/selected-space";
import { ISpaceClusterSelectedPowerConsumerViews } from "../../../../../shared/oversight-general-core/interfaces/space-cluster-selected-power-consumer-views";
import { ISpaceClusterShallowHierarchyView } from "../../../../../shared/oversight-general-core/interfaces/space-hierarchy";
import LinkDeviceCard from "../../../../../shared/oversight-general-core/ui-elements/link-device-card/link-device-card";
import DeviceLinkModal from "../../device-link-modal/device-link-modal";
import styles from "./add-update-controllers.module.scss";

interface IFormInput extends Omit<IRegisteringSmartDevice, "linkedDeviceId"> {
  deviceType: Option | [];
}

const defaultFormValues: IFormInput = {
  name: "",
  deviceType: [],
  serialKey: "",
};

const defaultSelectedDevice: ISpaceClusterSelectedPowerConsumerViews = {
  spaceClusterId: "",
  spaceId: "",
  powerConsumer: {
    id: "",
    name: "",
    deviceType: EDeviceTypes.ALL_DEVICES,
    deviceCategory: "",
    powerUsageInWatt: 0,
    brand: "",
    modelNumber: "",
    serialKey: "",
    deviceConnectionState: ConnectionState.DISCONNECTED,
    devicePowerState: DeviceStatus.OFF,
  },
  path: {
    id: "",
    label: "",
    ancestorSpaces: [{ id: "", name: "", parentSpaceId: "" }],
  },
};

const AddUpdateController = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const controllerType: Option[] = [
    { value: ControllerType.SMART_PLUG, label: "General Controller" },
    {
      value: ControllerType.AC_CONTROLLER,
      label: "Specific (Must link with a device)",
    },
  ];

  const defaultControllerType = controllerType[0];
  const [selectedControllerType, setSelectedControllerType] = useState<Option>({
    ...defaultControllerType,
  });
  const [isLinkDevice, setIsLinkDevice] = useState(false);
  const [showDeviceLinkModal, setShowDeviceLinkModal] = useState(false);
  const [selectedDevice, setSelectedDevice] =
    useState<ISpaceClusterSelectedPowerConsumerViews>({
      ...defaultSelectedDevice,
    });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [isLinkedDeviceError, setIsLinkDeviceError] = useState(false);
  const [isSpaceError, setIsSpaceError] = useState(false);
  const [isDeviceUnlinkedOnce, setIsDeviceUnlinkedOnce] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    clusterId: "",
    id: "",
    name: "Select a Space",
  });
  const [serialKeyDeviceType, setSerialKeyDeviceType] =
    useState<ControllerType>();
  const [scheduleIdList, setScheduleIdList] = useState<string[]>([]);

  const [
    addController,
    { isSuccess: isSuccessAddController, isLoading: isLoadingAddController },
  ] = useAddControllerMutation();
  const [
    addAcController,
    {
      isSuccess: isSuccessAddAcController,
      isLoading: isLoadingAddAcController,
    },
  ] = useAddAcControllerMutation();
  const [
    updateController,
    {
      isSuccess: isSuccessUpdateController,
      isLoading: isLoadingUpdateController,
    },
  ] = useUpdateControllerMutation();
  const [
    updateAcController,
    {
      isSuccess: isSuccessUpdateAcController,
      isLoading: isLoadingUpdateAcController,
    },
  ] = useUpdateAcControllerMutation();
  const [
    moveController,
    { isSuccess: isSuccessMoveController, isLoading: isLoadingMoveController },
  ] = useMoveControllerMutation();
  const [unlinkedLinkPowerConsumer] = useUnlinkedLinkPowerConsumerMutation();
  const [unlinkAcController] = useUnlinkAcControllerMutation();
  const [
    triggerViewManufacturedDeviceBySerialKey,
    { isFetching: isFetchingViewManufacturedDeviceBySerialKey },
  ] = useLazyViewManufacturedDeviceBySerialKeyQuery();

  const [triggerGetSpaces] = useLazyGetSpacesQuery();
  const [triggerGetHierarchy, { isFetching: isFetchingHierarchy }] =
    useLazyGetSpaceHierarchyQuery();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const currentSerialKey = watch("serialKey", "");
  const currentName = useRef("");
  currentName.current = watch("name", "");

  useEffect(() => {
    reset({ ...defaultFormValues });
    if (state && state.smartDevice) {
      triggerGetHierarchy({
        clusterId: state.smartDevice.clusterId,
        spaceId: state.smartDevice.spaceId,
      })
        .unwrap()
        .then((res: ISpaceClusterShallowHierarchyView) => {
          setSelectedSpace({
            clusterId: res.id,
            id: res.ancestorSpaces[res.ancestorSpaces.length - 1].id,
            name: res.ancestorSpaces[res.ancestorSpaces.length - 1].name,
          });
          setValue("name", state.smartDevice.name);
          setValue("serialKey", state.smartDevice.serialKey);
          setSelectedControllerType(
            state.smartDevice.linkedPowerConsumer
              ? controllerType[1]
              : controllerType[0]
          );
          setIsLinkDevice(state.smartDevice.linkedPowerConsumer ? true : false);
          setSelectedDevice({
            spaceClusterId: state.smartDevice.clusterId,
            spaceId: state.smartDevice.spaceId,
            powerConsumer: {
              id: state.smartDevice.linkedPowerConsumer?.id,
              name: state.smartDevice.linkedPowerConsumer?.name,
              deviceType: state.smartDevice.linkedPowerConsumer?.deviceType,
              deviceCategory:
                state.smartDevice.linkedPowerConsumer?.deviceCategory,
              powerUsageInWatt:
                state.smartDevice.linkedPowerConsumer?.powerUsageInWatt,
              brand: "",
              modelNumber: "",
              serialKey: state.smartDevice.serialKey,
              deviceConnectionState: state.smartDevice.deviceConnectionState,
              devicePowerState: state.smartDevice.devicePowerState,
            },
            path: res,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [state?.smartDevice]);

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        let spaceClusterView: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          spaceClusterView.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        spaceClusterView = spaceClusterView.sort((a, b) =>
          a.name.trim().localeCompare(b.name.trim())
        );
        setSpaceClusters([...spaceClusterView]);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  useEffect(() => {
    if (
      selectedControllerType.value !== ControllerType.AC_CONTROLLER &&
      selectedSpace.clusterId &&
      selectedSpace.id
    ) {
      setIsSpaceError(false);
    }
  }, [selectedSpace.clusterId, selectedSpace.id]);

  const showControllerErrorMessage = (errorCode: OvstErrorCode) => {
    switch (errorCode) {
      case OvstErrorCode.OVST_CONS_0020:
        showErrorMessage("Smart device exist with given serial key.");
        break;
      case OvstErrorCode.OVST_CONS_0019:
        showErrorMessage("Smart device exist with given name.");
        break;
      case OvstErrorCode.OVST_CONS_0026:
        showErrorMessage("Serial key is invalid");
        break;
      case OvstErrorCode.OVST_0052:
        showErrorMessage("Smart device already registered in a space.");
        break;
      case OvstErrorCode.OVST_0053:
        showErrorMessage("Smart device already linked to a power consumer.");
        break;
      case OvstErrorCode.OVST_0054:
        showErrorMessage("Power consumer is already linked to a smart device.");
        break;
      case OvstErrorCode.OVST_SEC_0004:
        showErrorMessage("Sorry error in the system please try again later!");
    }
  };

  const onSubmit = (data: IFormInput) => {
    if (state && state.smartDevice) {
      if (
        selectedControllerType.value !== ControllerType.AC_CONTROLLER &&
        selectedSpace.clusterId &&
        selectedSpace.id
      ) {
        if (state.smartDevice.spaceId === selectedSpace.id) {
          updateController({
            spaceClusterId: selectedSpace.clusterId,
            spaceId: selectedSpace.id,
            smartPlugId: state.smartDevice.id,
            name: data.name,
            linkedPowerConsumerId: "",
          })
            .unwrap()
            .then(() => {
              navigate(AppRoute.CONTROLLERS);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          moveController({
            spaceClusterId: state.smartDevice.clusterId,
            spaceId: state.smartDevice.spaceId,
            smartPlugId: state.smartDevice.id,
            destinationSpaceId: selectedSpace.id,
          })
            .unwrap()
            .then(() => {
              navigate(AppRoute.CONTROLLERS);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (!selectedDevice.powerConsumer.id) {
          setIsLinkDeviceError(true);
          return;
        }

        setIsLinkDeviceError(false);

        if (serialKeyDeviceType === ControllerType.AC_CONTROLLER) {
          updateAcController({
            spaceClusterId: state.smartDevice.clusterId,
            spaceId: state.smartDevice.spaceId,
            acControllerId: state.smartDevice.id,
            name: data.name,
            linkedAcId: selectedDevice.powerConsumer.id,
          })
            .unwrap()
            .then(() => {
              navigate(AppRoute.CONTROLLERS);
            })
            .catch((error) => {
              console.log(error);
            });
          return;
        }

        updateController({
          spaceClusterId: state.smartDevice.clusterId,
          spaceId: state.smartDevice.spaceId,
          smartPlugId: state.smartDevice.id,
          name: data.name,
          linkedPowerConsumerId: selectedDevice.powerConsumer.id,
        })
          .unwrap()
          .then(() => {
            navigate(AppRoute.CONTROLLERS);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      if (
        selectedControllerType.value !== ControllerType.AC_CONTROLLER &&
        !selectedSpace.clusterId &&
        !selectedSpace.id
      ) {
        setIsSpaceError(true);
      }

      if (
        selectedControllerType.value !== ControllerType.AC_CONTROLLER &&
        selectedSpace.clusterId &&
        selectedSpace.id
      ) {
        addController({
          spaceClusterId: selectedSpace.clusterId,
          spaceId: selectedSpace.id,
          registeringSmartPlugs: [
            {
              name: data.name,
              serialKey: data.serialKey,
            },
          ],
        })
          .unwrap()
          .then(() => {
            navigate(AppRoute.CONTROLLERS);
          })
          .catch((error) => {
            showControllerErrorMessage(error.ovstErrorCode as OvstErrorCode);
          });
      } else {
        if (!selectedDevice.powerConsumer.id) {
          setIsLinkDeviceError(true);
          return;
        }

        setIsLinkDeviceError(false);

        if (serialKeyDeviceType === ControllerType.AC_CONTROLLER) {
          addAcController({
            spaceClusterId: selectedDevice.spaceClusterId,
            spaceId: selectedDevice.spaceId,
            registeringAcControllers: [
              {
                name: data.name,
                serialKey: data.serialKey,
                linkedAcId: selectedDevice.powerConsumer.id,
              },
            ],
          })
            .unwrap()
            .then(() => {
              navigate(AppRoute.CONTROLLERS);
            })
            .catch((error) => {
              showControllerErrorMessage(error.ovstErrorCode as OvstErrorCode);
            });

          return;
        }

        addController({
          spaceClusterId: selectedDevice.spaceClusterId,
          spaceId: selectedDevice.spaceId,
          registeringSmartPlugs: [
            {
              name: data.name,
              serialKey: data.serialKey,
              linkedDeviceId: selectedDevice.powerConsumer.id,
            },
          ],
        })
          .unwrap()
          .then(() => {
            navigate(AppRoute.CONTROLLERS);
          })
          .catch((error) => {
            showControllerErrorMessage(error.ovstErrorCode as OvstErrorCode);
          });
      }
    }
  };

  useEffect(() => {
    if (
      isSuccessAddController ||
      isSuccessUpdateController ||
      isSuccessMoveController ||
      isSuccessAddAcController ||
      isSuccessUpdateAcController
    ) {
      const message =
        serialKeyDeviceType === ControllerType.AC_CONTROLLER
          ? isSuccessAddAcController
            ? "AC Controller Added Successfully"
            : "AC Controller Updated Successfully"
          : isSuccessAddController
          ? "Smart Device Added Successfully"
          : "Smart Device Updated Successfully";
      showSuccessMessage(message);
      reset({ ...defaultFormValues });
    }
  }, [
    isSuccessAddController,
    isSuccessUpdateController,
    isSuccessAddAcController ||
      isSuccessUpdateAcController ||
      isSuccessMoveController,
    isSuccessAddAcController,
  ]);

  useEffect(() => {
    if (currentSerialKey.length > 7) {
      triggerViewManufacturedDeviceBySerialKey({
        serialKey: currentSerialKey,
      })
        .unwrap()
        .then((res) => {
          setSerialKeyDeviceType(res.smartDeviceType);
          setSelectedControllerType(
            state?.smartDevice?.linkedPowerConsumer ||
              res.smartDeviceType === ControllerType.AC_CONTROLLER
              ? controllerType[1]
              : controllerType[0]
          );
        })
        .catch(() => {
          setSerialKeyDeviceType(undefined);
        });
    }
  }, [currentSerialKey]);

  const unlinkDevice = (
    spaceClusterId: string,
    spaceId: string,
    smartPlugId: string,
    linkedPowerConsumerId: string,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    if (serialKeyDeviceType === ControllerType.AC_CONTROLLER) {
      unlinkAcController({
        spaceClusterId,
        spaceId,
        acControllerId: smartPlugId,
        linkedAcId: linkedPowerConsumerId,
        excludedSemiAutomatedSchedules,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device Unlinked Successfully");
          setIsLinkDevice(false);
          setSelectedDevice({
            ...defaultSelectedDevice,
          });
        })
        .catch(() => {
          showErrorMessage("Device Unlinked Unsuccessful");
        });

      return;
    }

    unlinkedLinkPowerConsumer({
      spaceClusterId,
      spaceId,
      smartPlugId,
      linkedPowerConsumerId,
      excludedSemiAutomatedSchedules,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Unlinked Successfully");
        setIsLinkDevice(false);
        setSelectedDevice({
          ...defaultSelectedDevice,
        });
      })
      .catch(() => {
        showErrorMessage("Device Unlinked Unsuccessful");
      });
  };

  return (
    <>
      <div className="container-white position-relative">
        <Row className="pb-xl-5">
          <Col className="col-lg-6 col-12">
            <AppInput
              className="font-size-14"
              label="Controller Name"
              placeholder="Controller Name"
              name="name"
              register={register("name", {
                required: "Controller name is required",
                maxLength: {
                  value: 25,
                  message:
                    "You have exceeded the maximum number of 25 characters in this field",
                },
                pattern: {
                  value: noSpecialCharsNoWhitespaceStartEndAndAllowCharacters,
                  message:
                    "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
                },
              })}
              errors={errors}
            />

            <AppInput
              className="mt-4 font-size-14"
              label="Controller Serial Key"
              placeholder="Controller Serial Key"
              name="serialKey"
              register={register("serialKey", {
                required: "Serial Key is required",
                maxLength: {
                  value: 25,
                  message:
                    "You have exceeded the maximum number of 25 characters in this field",
                },
              })}
              errors={errors}
              disabled={state?.smartDevice}
            />
            {!isFetchingViewManufacturedDeviceBySerialKey ? (
              <>
                {currentSerialKey.length > 7 &&
                  serialKeyDeviceType === ControllerType.SMART_PLUG && (
                    <AppSelect
                      className="mt-4 font-size-14"
                      labelFontSize="14"
                      selectedValue={{ ...selectedControllerType }}
                      label="Controller Type"
                      options={[...controllerType]}
                      onChangeOption={(selectedControllerOption) => {
                        if (
                          state &&
                          state.smartDevice &&
                          !state.smartDevice.linkedPowerConsumer
                        ) {
                          setIsLinkDevice(false);
                        }
                        setSelectedControllerType(selectedControllerOption);
                      }}
                      name="deviceType"
                      placeholder="Specific (Must link with a device)"
                      errors={errors}
                      isRequired
                      id="controller-type"
                    />
                  )}
                {currentSerialKey.length > 7 &&
                  (selectedControllerType.value !==
                    ControllerType.AC_CONTROLLER &&
                  serialKeyDeviceType === ControllerType.SMART_PLUG ? (
                    <div className="mt-4">
                      <SpaceSelectorDropdown
                        dropdownOptions={[
                          ...spaceClusters.map((space) => {
                            return {
                              clusterId: space.clusterId,
                              id: space.id,
                              name: space.name,
                              childSpaces: space.childSpaces,
                            };
                          }),
                        ]}
                        disabled={false}
                        selectedSubSpace={selectedSpace.name}
                        updateSelectedSubSpaceId={(
                          selectedSubSpaceId: string,
                          selectedSubSpaceName: string,
                          clusterId?: string
                        ) => {
                          setSelectedSpace({
                            id: selectedSubSpaceId,
                            name: selectedSubSpaceName,
                            clusterId: clusterId || "",
                          });
                        }}
                        label="Space"
                        className="font-weight-400 font-size-14 text-light"
                        maxHeight="165px"
                        isRequired
                      />
                      {isSpaceError && (
                        <Row className="">
                          <Col className="error">Please select a space</Col>
                        </Row>
                      )}
                    </div>
                  ) : (
                    serialKeyDeviceType !== undefined && (
                      <>
                        <div className="ms-1 mt-4">
                          <Row>
                            <Col className="font-weight-400 font-size-14">
                              Device<label className="asterisk">*</label>
                            </Col>
                          </Row>
                          <Row>
                            {isLinkDevice ? (
                              <Col className="px-4">
                                <LinkDeviceCard
                                  device={selectedDevice}
                                  icon="link_off"
                                  onUnlink={() => {
                                    if (state && !isDeviceUnlinkedOnce) {
                                      unlinkDevice(
                                        state.smartDevice.clusterId,
                                        state.smartDevice.spaceId,
                                        state.smartDevice.id,
                                        selectedDevice.powerConsumer.id,
                                        scheduleIdList
                                      );
                                      setIsDeviceUnlinkedOnce(true);
                                      return;
                                    }

                                    setIsLinkDevice(false);
                                  }}
                                  controllerName={currentName.current}
                                  setScheduleIdList={setScheduleIdList}
                                  isDeviceUnlinkedOnce={isDeviceUnlinkedOnce}
                                />
                              </Col>
                            ) : (
                              <Col className="px-4">
                                <Row
                                  className={`align-items-center py-2 cursor-pointer ${styles.unlinkCard}`}
                                >
                                  <Col
                                    xs={"auto"}
                                    onClick={() => {
                                      setShowDeviceLinkModal(true);
                                    }}
                                  >
                                    <MaterialIcon icon="link" color="#2F2A89" />
                                  </Col>
                                  <Col
                                    className="text-light font-size-14 font-weight-500 ps-0"
                                    onClick={() => {
                                      setShowDeviceLinkModal(true);
                                    }}
                                  >
                                    Link Device
                                  </Col>
                                </Row>
                                {isLinkedDeviceError && (
                                  <Row>
                                    <Col className="error">
                                      Please link a device
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            )}
                          </Row>

                          <Row className="mt-2">
                            {isLinkDevice ? (
                              <Col className="px-4 font-size-14 text-muted font-weight-400">
                                * Path of the device will be used as the path of
                                the Controller
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </div>
                      </>
                    )
                  ))}
              </>
            ) : (
              <Row className="mt-4 align-items-center">
                <Col className="col-auto">
                  <SpinnerModal
                    show={isFetchingViewManufacturedDeviceBySerialKey}
                    withOverlay={false}
                  />
                </Col>
                <Col className="text-light font-size-14 font-weight-400 px-0">
                  Verifying
                </Col>
              </Row>
            )}
          </Col>
          <Col className="col-lg-6 col-12 px-4 mt-4">
            <Row className={`py-3 ${styles.socketTypes}`}>
              <Col className="col-auto">
                <MaterialIcon icon="live_help" color=" #A7AA18" />
              </Col>
              <Col className="ps-0">
                <Row>
                  <Col className="font-size-14 text-muted font-weight-400">
                    Controller serial key can be found at the back of the
                    device.
                    <br />
                    ex:
                  </Col>
                </Row>
                <Row>
                  <Col className="ps-1">
                    <img src={serialKey} alt="Serial Key" />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="font-size-14">
                    <div className="font-weight-500">Controller Types:</div>
                    <div className="font-weight-400 text-light mt-1">
                      Specific-Controller must be connected with a device to be
                      functioned.
                      <br />
                      General - Controller can work alone without connecting to
                      device.
                    </div>
                    <div className="font-weight-400 text-light mt-3">
                      AC Controller must be connected with an Air Conditioner.
                      <br />
                      This will allow the AC Controller to control the linked
                      Air Conditioner if <br />
                      the Air Conditioner is of a supported brand.
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between mt-5">
          <Col xs="auto">
            <AppButton
              text="Cancel"
              variant="transparent"
              size="medium"
              className="px-2"
              onClick={() => {
                reset({ ...defaultFormValues });
                navigate(-1);
              }}
            />
          </Col>
          <Col xs="auto">
            <AppButton
              text="Confirm"
              variant={"blue"}
              size="medium"
              onClick={handleSubmit(onSubmit)}
              isLoading={
                isLoadingAddController ||
                isLoadingUpdateController ||
                isLoadingMoveController ||
                isLoadingAddAcController ||
                isLoadingUpdateAcController
              }
              id="add-controller-confirm"
            />
          </Col>
        </Row>
        <SpinnerModal show={isFetchingHierarchy} />
      </div>
      <DeviceLinkModal
        show={showDeviceLinkModal}
        onClose={() => {
          setShowDeviceLinkModal(false);
        }}
        onCancel={() => {
          setShowDeviceLinkModal(false);
        }}
        serialNumber={currentSerialKey}
        controllerName={currentName.current}
        sendSelectedDevice={(device) => {
          setSelectedDevice(device);
          setIsLinkDevice(true);
        }}
        onConfirm={() => {
          setShowDeviceLinkModal(false);
        }}
        deviceConnectionState={state && state.smartDevice.deviceConnectionState}
        serialKeyDeviceType={serialKeyDeviceType || ControllerType.SMART_PLUG}
      />
    </>
  );
};

export default AddUpdateController;
