import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  useLazyGetAllPowerGeneratorsQuery,
  useLazyGetEnergyGenerationDataQuery,
  useLazyGetMonthlyEnergyGenerationQuery,
  useLazyGetSolarPVQuery,
} from "../../../../redux/api/solar/solarAPI";
import AddUpdateSolarDeviceDTO from "../../../../shared/oversight-core/dtos/add-update-solar-device-dto";
import IPowerGeneratorView from "../../../../shared/oversight-core/interfaces/power-generator-view";
import ISimplifiedPowerGeneratorView from "../../../../shared/oversight-core/interfaces/simplified-power-generator-view";
import ViewEnergyGenerationResponseDTO from "../../../../shared/oversight-core/response-dto/view-energy-generation-response-dto";
import AppDatePicker from "../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import StatsView from "../../../../shared/oversight-core/ui-elements/stats-view/stats-view";
import StatsViewValueWithLabel from "../../../../shared/oversight-core/ui-elements/stats-view/stats-views-value-with-label";
import AppDropDown from "../../../../shared/oversight-general-core/ui-elements/app-dropdown/app-drop-down";
import AddUpdateSolarDeviceModal from "../components/modals/add-update-solar-device-modal/add-update-solar-device-modal";
import AddUpdateSolarGenerationDataModal from "../components/modals/add-update-solar-generation-data-modal/add-update-solar-generation-data-modal";
import RemoveSolarDeviceModal from "../components/modals/remove-solar-device-modal/remove-solar-device-modal";
import styles from "./stats-view-header.module.scss";

interface IProps {
  updateSolarData: (
    energyGeneration: ViewEnergyGenerationResponseDTO,
    isFetching: boolean
  ) => void;
  selectedDate?: Date;
  getAllPowerGenerators: () => void;
}

const defaultViewEnergyGeneration: ViewEnergyGenerationResponseDTO = {
  powerGeneratorId: "",
  year: 0,
  months: [""],
  generatedEnergy: [0],
};

const StatsViewHeader = (props: IProps) => {
  const [solarDetails, setSolarDetails] = useState<IPowerGeneratorView>();
  const [energyGenerationActualData, setEnergyGenerationActualData] =
    useState(0);
  const [hasInputGeneration, setHasInputGeneration] = useState(false);
  const [device, setDevice] = useState<AddUpdateSolarDeviceDTO>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [simplifiedPowerGenerator, setSimplifiedPowerGenerator] =
    useState<ISimplifiedPowerGeneratorView>();
  const [solarSpaceClusterList, setSolarSpaceClusterList] = useState<string[]>(
    []
  );
  const [updateId, setUpdateId] = useState("");
  const [
    showAddUpdateSolarGenerationModal,
    setShowAddUpdateSolarGenerationModal,
  ] = useState(false);

  const [showAddUpdateSolarDeviceModal, setShowAddUpdateSolarDeviceModal] =
    useState(false);
  const [showRemoveSolarDeviceModal, setShowRemoveSolarDeviceModal] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [
    triggerGetAllPowerGenerators,
    { data: solar, isSuccess: isSuccessSolar },
  ] = useLazyGetAllPowerGeneratorsQuery();
  const [
    triggerGetSolarPV,
    {
      data: getSolarPVData,
      isSuccess: isSuccessGetSolarPV,
      isFetching: isFetchingSolarPV,
    },
  ] = useLazyGetSolarPVQuery();
  const [
    triggerGetMonthlyEnergyGeneration,
    {
      data: GetMonthlyEnergyGeneration,
      isSuccess: isSuccessGetMonthlyEnergyGeneration,
      isFetching: isFetchingMonthlyEnergyGeneration,
      isError: isErrorGetMonthlyEnergyGeneration,
    },
  ] = useLazyGetMonthlyEnergyGenerationQuery();
  const [
    triggerEnergyGeneration,
    {
      data: getEnergyGeneration,
      isSuccess: isEnergyGenerationSuccess,
      isFetching: isFetchingEnergyGeneration,
    },
  ] = useLazyGetEnergyGenerationDataQuery();

  useEffect(() => {
    triggerGetAllPowerGenerators();
  }, [triggerGetAllPowerGenerators]);

  useEffect(() => {
    if (isSuccessSolar && solar) {
      const tempSolarSpaceClusterList =
        solar.spaceClusterWithPowerGeneratorViews.map((powerGenerator) => {
          return powerGenerator.id;
        });

      setSolarSpaceClusterList(tempSolarSpaceClusterList);

      const currentlyUpdatedSolar =
        solar.spaceClusterWithPowerGeneratorViews.find(
          (powerGenerator) => powerGenerator.powerGenerator.id === updateId
        );

      setSimplifiedPowerGenerator(
        updateId
          ? currentlyUpdatedSolar
          : solar.spaceClusterWithPowerGeneratorViews[0]
      );
    }
  }, [isSuccessSolar, solar]);

  useEffect(() => {
    if (simplifiedPowerGenerator) {
      triggerGetSolarPV({
        solarId: simplifiedPowerGenerator.powerGenerator.id,
      });
    }
  }, [triggerGetSolarPV, simplifiedPowerGenerator]);

  useEffect(() => {
    if (isSuccessGetSolarPV && getSolarPVData) {
      const solarData = getSolarPVData.solarPv;
      const inverters = solarData.inverters?.map((inverter) => {
        return inverter.capacity;
      });

      setDevice({
        id: solarData.id,
        name: solarData.name,
        panels: solarData.panels || [],
        inverterCapacities: inverters || [],
        locationName: solarData.location?.locationName || "",
        spaceClusterId: simplifiedPowerGenerator?.id || "",
      });
      setSolarDetails(solarData);
    }
  }, [isSuccessGetSolarPV, getSolarPVData]);

  useEffect(() => {
    const year = parseInt(format(selectedDate, "yyyy"));
    const month = parseInt(format(selectedDate, "M"));
    if (simplifiedPowerGenerator) {
      triggerGetMonthlyEnergyGeneration({
        powerGeneratorId: simplifiedPowerGenerator.powerGenerator.id,
        year,
        month,
      });
    }
  }, [
    triggerGetMonthlyEnergyGeneration,
    simplifiedPowerGenerator,
    selectedDate,
  ]);

  useEffect(() => {
    if (isSuccessGetMonthlyEnergyGeneration && GetMonthlyEnergyGeneration) {
      setEnergyGenerationActualData(GetMonthlyEnergyGeneration.generatedEnergy);
      setHasInputGeneration(true);
    }

    if (isErrorGetMonthlyEnergyGeneration) {
      setHasInputGeneration(false);
      setEnergyGenerationActualData(0);
    }
  }, [
    isSuccessGetMonthlyEnergyGeneration,
    GetMonthlyEnergyGeneration,
    isErrorGetMonthlyEnergyGeneration,
  ]);

  useEffect(() => {
    const year = parseInt(format(props.selectedDate || new Date(), "yyyy"));
    if (simplifiedPowerGenerator) {
      triggerEnergyGeneration({
        powerGeneratorId: simplifiedPowerGenerator.powerGenerator.id,
        year,
      });
    }
  }, [triggerEnergyGeneration, props.selectedDate, simplifiedPowerGenerator]);

  useEffect(() => {
    if (isEnergyGenerationSuccess && getEnergyGeneration) {
      if (!getEnergyGeneration.powerGeneratorId) {
        props.updateSolarData({ ...defaultViewEnergyGeneration }, false);
        return;
      }
      props.updateSolarData(getEnergyGeneration, isFetchingEnergyGeneration);
    }
  }, [
    isEnergyGenerationSuccess,
    getEnergyGeneration,
    isFetchingEnergyGeneration,
  ]);

  const closeAddUpdateSolarGenerationModal = () => {
    setShowAddUpdateSolarGenerationModal(false);
  };

  const closeAddUpdateSolarDeviceModal = () => {
    setShowAddUpdateSolarGenerationModal(false);
  };

  const updateMonthlyEnergyGeneration = () => {
    const year = parseInt(format(selectedDate, "yyyy"));
    const month = parseInt(format(selectedDate, "M"));
    if (simplifiedPowerGenerator) {
      triggerGetMonthlyEnergyGeneration({
        powerGeneratorId: simplifiedPowerGenerator.powerGenerator.id,
        year,
        month,
      });
    }
    if (simplifiedPowerGenerator && getEnergyGeneration) {
      triggerEnergyGeneration({
        powerGeneratorId: simplifiedPowerGenerator.powerGenerator.id,
        year,
      });
    }
  };

  const updateSolarPV = (id: string) => {
    setUpdateId(id);
    if (id && simplifiedPowerGenerator) {
      triggerGetSolarPV({
        solarId: simplifiedPowerGenerator.powerGenerator.id,
      });
    }
    triggerGetAllPowerGenerators();
    setShowAddUpdateSolarDeviceModal(false);
    setShowRemoveSolarDeviceModal(false);
    props.getAllPowerGenerators();
  };

  return (
    <>
      {hasInputGeneration && !isFetchingMonthlyEnergyGeneration && (
        <Row
          className={`align-items-center mb-3 py-2 px-4 ${styles.estimatedSolarGeneration}`}
        >
          <Col>
            <Row className="align-items-center">
              <Col className="col-auto px-1">
                <MaterialIcon icon="sunny" color=" #A7AA18" />
              </Col>
              <Col className="font-size-16 text-muted font-weight-500">
                Estimated Generation
              </Col>
            </Row>
          </Col>
          <Col className="font-size-16 text-muted font-weight-500 text-end">
            Next 30 Days
          </Col>
        </Row>
      )}
      <div className="container-white">
        <Row className="align-items-center g-2">
          <Col className="col-12 col-md mb-3">
            <Row className="align-items-center">
              <Col className="col-9 col-sm-10 col-xl-6 pe-0">
                <AppSelect
                  selectedValue={
                    simplifiedPowerGenerator
                      ? {
                          value: simplifiedPowerGenerator.powerGenerator.id,
                          label:
                            simplifiedPowerGenerator.label +
                            " | " +
                            simplifiedPowerGenerator.powerGenerator.name,
                          data: simplifiedPowerGenerator,
                        }
                      : []
                  }
                  placeholder="Select Solar"
                  options={
                    solar
                      ? solar.spaceClusterWithPowerGeneratorViews.map(
                          (powerGenerator: ISimplifiedPowerGeneratorView) => {
                            return {
                              value: powerGenerator.id,
                              label:
                                powerGenerator.label +
                                " | " +
                                powerGenerator.powerGenerator.name,
                              data: powerGenerator,
                            };
                          }
                        )
                      : []
                  }
                  onChangeOption={(selectedOption: Option) => {
                    setSimplifiedPowerGenerator(
                      selectedOption.data as ISimplifiedPowerGeneratorView
                    );
                  }}
                />
              </Col>
              <Col className="col-3 col-sm-2 col-lg-auto d-flex justify-content-end">
                <AppButton
                  text="Add"
                  iconName="add"
                  iconOnly
                  onClick={() => {
                    setShowAddUpdateSolarDeviceModal(true);
                    setIsEditMode(false);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-lg-3 text-end mb-3">
            <AppDatePicker
              selectedDate={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
              }}
              monthYearPicker={true}
              yearPicker={false}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col className={`${styles.spaceHeading} col-auto`}>
            {solarDetails?.name}
          </Col>
          <Col className="col-auto ps-0">
            <AppDropDown
              items={[
                {
                  text: "Edit",
                  onClick: () => {
                    setShowAddUpdateSolarDeviceModal(true);
                    setIsEditMode(true);
                  },
                },
                {
                  text: "Remove",
                  onClick: () => {
                    setShowRemoveSolarDeviceModal(true);
                  },
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col className={`${styles.spaces} col-auto`}>
            <Row xs="auto" className="align-items-center g-1">
              <Col>{simplifiedPowerGenerator?.label}</Col>
              <Col>
                <MaterialIcon icon="location_on" size={15} color="#f00" />
              </Col>
              <Col>{solarDetails?.location?.locationName || ""}</Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-end mt-4">
          <Col className="col-12 col-sm-6 col-xl-6 mb-2 mb-md-0">
            <Row className="g-2">
              {solarDetails?.panels?.map((p, index) => {
                return (
                  <Col
                    className="col-6 col-sm-12 col-xl-3 mb-2 mb-xl-0"
                    key={index}
                  >
                    <StatsViewValueWithLabel
                      labelValue={
                        (p.quantity + "x" + p.panelWattage + "W").toString() ||
                        ""
                      }
                      title="Panels"
                      variant="bg-icon-2"
                      isFetching={isFetchingSolarPV}
                    />
                  </Col>
                );
              })}
              <Col className="col-12 col-xl-auto mb-2 mb-xl-0">
                <StatsView
                  labelValue={
                    solarDetails?.inverters
                      ?.map((i) => i.capacity + "kW")
                      .join(", ") || ""
                  }
                  title="Inverter Capacity"
                  variant="bg-icon-2"
                  isFetching={isFetchingSolarPV}
                />
              </Col>
              <Col className="col-12 col-xl-auto mb-2 mb-xl-0 d-block d-md-none">
                <StatsView
                  labelValue={solarDetails?.location?.locationName || ""}
                  title="Location"
                  variant="bg-icon-2"
                  isFetching={isFetchingSolarPV}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-6 col-xl-6">
            <Row className="g-0 align-items-center justify-content-center justify-content-xl-end">
              {hasInputGeneration && energyGenerationActualData !== 0 && (
                <Col className="col-12 col-lg-9 col-xl-8 mb-2 mb-lg-0 pe-xl-3">
                  <StatsView
                    labelValue={
                      energyGenerationActualData
                        .toFixed(
                          Number.isInteger(energyGenerationActualData) ? 0 : 2
                        )
                        .toString() + "kWh"
                    }
                    title="Real Solar Generation"
                    variant="bg-icon-4"
                    fontStyle="normal"
                    isFetching={isFetchingMonthlyEnergyGeneration}
                  />
                </Col>
              )}
              <Col
                className={`col-8 col-sm-12 ${
                  hasInputGeneration && energyGenerationActualData !== 0
                    ? "col-lg-3"
                    : ""
                } col-xl-auto ps-lg-5 ps-xl-0`}
              >
                {hasInputGeneration && energyGenerationActualData !== 0 ? (
                  <>
                    <AppButton
                      text="Edit"
                      iconName="drive_file_rename_outline"
                      iconOnly
                      className="d-none d-lg-block mb-xs-0 mb-3 mb-lg-0"
                      size="medium"
                      onClick={() => setShowAddUpdateSolarGenerationModal(true)}
                    />
                    <AppButton
                      text="Edit"
                      iconName="drive_file_rename_outline"
                      className="d-lg-none mb-xs-0 mb-3 mb-lg-0"
                      size="medium"
                      onClick={() => setShowAddUpdateSolarGenerationModal(true)}
                    />
                  </>
                ) : (
                  <AppButton
                    text="Input Generation"
                    size="medium"
                    className="mb-xs-0 mb-3 mb-lg-0"
                    onClick={() => setShowAddUpdateSolarGenerationModal(true)}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <AddUpdateSolarGenerationDataModal
          closeAddUpdateSolarGenerationDataModal={
            closeAddUpdateSolarGenerationModal
          }
          onClose={() => setShowAddUpdateSolarGenerationModal(false)}
          onCancel={() => setShowAddUpdateSolarGenerationModal(false)}
          month={selectedDate}
          show={showAddUpdateSolarGenerationModal}
          powerGeneratorId={simplifiedPowerGenerator?.powerGenerator.id || ""}
          year={format(selectedDate, "yyyy")}
          updateMonthlyEnergyGeneration={updateMonthlyEnergyGeneration}
          actualData={energyGenerationActualData}
        />
        <AddUpdateSolarDeviceModal
          closeAddUpdateSolarDeviceModal={closeAddUpdateSolarDeviceModal}
          onClose={() => setShowAddUpdateSolarDeviceModal(false)}
          onCancel={() => setShowAddUpdateSolarDeviceModal(false)}
          show={showAddUpdateSolarDeviceModal}
          updateSolarPV={updateSolarPV}
          device={device}
          isEdit={isEditMode}
          solarSpaceClusterList={solarSpaceClusterList}
        />

        <RemoveSolarDeviceModal
          onClose={() => setShowRemoveSolarDeviceModal(false)}
          onCancel={() => setShowRemoveSolarDeviceModal(false)}
          show={showRemoveSolarDeviceModal}
          device={device}
          updateSolarPV={updateSolarPV}
        />
      </div>
    </>
  );
};

export default StatsViewHeader;
