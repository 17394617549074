import RegisteringDeviceRequestDTO from "../../../shared/oversight-core/dtos/add-device-dto";
import RegisteringDevicesResponseDTO from "../../../shared/oversight-core/dtos/registering-devices-dto";
import UpdateDeviceDTO from "../../../shared/oversight-core/dtos/update-device-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import IListPowerConsumersRequestDTO from "../../../shared/oversight-general-core/dtos/list-power-consumers-request-dto";
import {
  IListPowerConsumersGroupedResponseDTO,
  IListPowerConsumersResponseDTO,
} from "../../../shared/oversight-general-core/dtos/list-power-consumers-response-dto";
import { rootApi } from "../apiManager";

export const deviceAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addDevice: builder.mutation<
      RegisteringDevicesResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        registeringDevices: RegisteringDeviceRequestDTO[];
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/power-consumer/device/register`,
          method: "POST",
          body: {
            registeringDevices: data.registeringDevices,
          },
        };
      },
    }),
    editDevice: builder.mutation<
      RegisteringDevicesResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        registeringDevice: UpdateDeviceDTO;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/power-consumer/device/update`,
          method: "PUT",
          body: data.registeringDevice,
        };
      },
    }),
    removeDevice: builder.mutation<
      void,
      { clusterId: string; spaceId: string; deviceId: string }
    >({
      transformErrorResponse,
      query({ clusterId, spaceId, deviceId }) {
        return {
          url: `space-cluster/${clusterId}/space/${spaceId}/power-consumer/device/${deviceId}/unregister`,
          method: "DELETE",
        };
      },
    }),
    listPowerConsumers: builder.query<
      IListPowerConsumersResponseDTO & IListPowerConsumersGroupedResponseDTO,
      IListPowerConsumersRequestDTO
    >({
      transformErrorResponse,
      query(data: IListPowerConsumersRequestDTO) {
        return {
          url: `power-consumer/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddDeviceMutation,
  useEditDeviceMutation,
  useRemoveDeviceMutation,
  useLazyListPowerConsumersQuery,
} = deviceAPI;
