import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyUnLinkPowerConsumerListQuery } from "../../../../redux/api/controller/controllerAPI";
import { useGetSpacesQuery } from "../../../../redux/api/space/spaceAPI";
import { EDeviceTypes } from "../../../../shared/oversight-core/enums/device-types";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import ActiveInactiveIndicator from "../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect, {
  Option,
} from "../../../../shared/oversight-core/ui-elements/app-select/app-select";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../../../shared/oversight-core/utils/getDeviceType";
import Pagination from "../../../../shared/oversight-general-core/components/pagination/pagination";
import Search from "../../../../shared/oversight-general-core/components/search/search";
import SpaceSelectorDropdown from "../../../../shared/oversight-general-core/components/space-selector-dropdown/space-selector-dropdown";
import { ConnectionState } from "../../../../shared/oversight-general-core/enums/connection-status";
import { ControllerType } from "../../../../shared/oversight-general-core/enums/controller-type";
import { ISelectedSpace } from "../../../../shared/oversight-general-core/interfaces/selected-space";
import { ISpaceClusterSelectedPowerConsumerViews } from "../../../../shared/oversight-general-core/interfaces/space-cluster-selected-power-consumer-views";
import LinkDeviceCard from "../../../../shared/oversight-general-core/ui-elements/link-device-card/link-device-card";

interface IProps extends ModelContainerProps {
  show: boolean;
  controllerName: string;
  serialNumber: string;
  sendSelectedDevice: (device: ISpaceClusterSelectedPowerConsumerViews) => void;
  deviceConnectionState: ConnectionState;
  serialKeyDeviceType: ControllerType;
}

const ITEM_PER_PAGE = 3;
const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const DeviceLinkModal = (props: IProps) => {
  const {
    show,
    controllerName,
    serialNumber,
    deviceConnectionState,
    serialKeyDeviceType,
    sendSelectedDevice,
    ...rest
  } = props;
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    searchValue: "",
    pageNumber: 0,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...deviceTypes[0],
  });
  const [selectedDevice, setSelectedDevice] = useState<
    ISpaceClusterSelectedPowerConsumerViews | undefined
  >(undefined);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    clusterId: "",
    id: "",
    name: "All Spaces",
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [filteredDevice, setFilteredDevice] = useState<
    ISpaceClusterSelectedPowerConsumerViews[]
  >([]);

  const { data: spacesResponse } = useGetSpacesQuery();
  const [
    triggerUnlinkedPowerConsumerList,
    { isFetching: isLoadingUnlinkDeviceList },
  ] = useLazyUnLinkPowerConsumerListQuery();

  useEffect(() => {
    if (serialKeyDeviceType === ControllerType.AC_CONTROLLER) {
      setSelectedDeviceType(
        deviceTypes.find((dt) => dt.value === "Air Conditioner") ||
          deviceTypes[8]
      );
    }
  }, [serialKeyDeviceType]);

  useEffect(() => {
    if (spacesResponse) {
      let spaceClusterView: ISpaceView[] = [];
      for (const spaceCluster of spacesResponse.spaceClusters) {
        spaceClusterView.push({
          ...spaceCluster.rootSpace,
          tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
          accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
          accountNumberLabel: spaceCluster.serviceProviderAccount.label,
          clusterId: spaceCluster.id,
        });
      }
      spaceClusterView = spaceClusterView.sort((a, b) =>
        a.name.trim().localeCompare(b.name.trim())
      );
      setSpaceClusters([...spaceClusterView]);
    } else {
      setSpaceClusters([]);
    }
  }, [spacesResponse]);

  useEffect(() => {
    if (!selectedDeviceType) {
      if (selectedSpace.clusterId && selectedSpace.id) {
        triggerUnlinkedPowerConsumerList({
          spaceClusterId: selectedSpace.clusterId,
          subRootSpaceId: selectedSpace.id,
          pageNumber: filters.pageNumber,
          searchField: "name",
          searchValue: filters.searchValue,
          groupByType: true,
          ascending: true,
          pageSize: 3,
        })
          .unwrap()
          .then((res) => {
            setFilteredDevice(res.deviceList.elements);
            setTotalElements(res.deviceList.totalElements);
          })
          .catch((err) => {
            console.log(err);
            setFilteredDevice([]);
            setTotalElements(0);
          });
      } else {
        triggerUnlinkedPowerConsumerList({
          pageNumber: filters.pageNumber,
          searchField: "name",
          searchValue: filters.searchValue,
          groupByType: true,
          ascending: true,
          pageSize: 3,
        })
          .unwrap()
          .then((res) => {
            setFilteredDevice(res.deviceList.elements);
            setTotalElements(res.deviceList.totalElements);
          })
          .catch((err) => {
            console.log(err);
            setFilteredDevice([]);
            setTotalElements(0);
          });
      }
    } else {
      if (selectedSpace.clusterId && selectedSpace.id) {
        triggerUnlinkedPowerConsumerList({
          searchField: "name",
          searchValue: filters.searchValue,
          type: selectedDeviceType.value,
          pageNumber: filters.pageNumber,
          pageSize: 3,
          spaceClusterId: selectedSpace.clusterId,
          subRootSpaceId: selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((res) => {
            setFilteredDevice(res.deviceList.elements);
            setTotalElements(res.deviceList.totalElements);
          })
          .catch((err) => {
            console.log(err);
            setFilteredDevice([]);
            setTotalElements(0);
          });
      } else {
        triggerUnlinkedPowerConsumerList({
          searchField: "name",
          searchValue: filters.searchValue,
          type: selectedDeviceType.value,
          pageNumber: filters.pageNumber,
          pageSize: 3,
          ascending: true,
        })
          .unwrap()
          .then((res) => {
            setFilteredDevice(res.deviceList.elements);
            setTotalElements(res.deviceList.totalElements);
          })
          .catch((err) => {
            console.log(err);
            setFilteredDevice([]);
            setTotalElements(0);
          });
      }
    }
  }, [
    selectedSpace.clusterId,
    selectedSpace.id,
    filters,
    selectedDeviceType,
    show,
  ]);

  return (
    <ModalContainer
      {...rest}
      title={"Link Controller To"}
      show={show}
      size="modal-lg"
      confirmButtonText="Link"
      cancelButtonText="Back"
      cancelButtonVariant="transparentWithBorder"
      className="mt-0"
      onConfirm={() => {
        if (selectedDevice) {
          sendSelectedDevice(selectedDevice);
          if (rest.onConfirm) {
            rest.onConfirm();
          }
        }
      }}
    >
      <div>
        <Row>
          {(controllerName || serialNumber) && (
            <Col className="col-auto pe-0 mt-1">
              <MaterialIcon
                icon="smart_outlet"
                color="#30858A"
                className="bg-activeBg-4 rounded-1 p-2"
              />
            </Col>
          )}

          <Col>
            <Row>
              <Col className="font-size-16 font-weight-500 text-dark">
                {controllerName}
              </Col>
            </Row>
            <Row className="align-items-center g-1">
              <Col
                xs="auto"
                className="font-size-12 font-weight-400 text-light"
              >
                {serialNumber}
              </Col>
              {serialNumber && (
                <Col>
                  <ActiveInactiveIndicator
                    isActive={
                      deviceConnectionState === ConnectionState.CONNECTED
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="align-items-end justify-content-between mt-2">
          <Col xs={12} sm={5} className="mt-3 mt-sm-0">
            <Search
              placeholder="Search Device"
              onSearch={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  searchValue: searchInput,
                  pageNumber: 0,
                }));
              }}
              onChange={(input) => {
                setSearchInput(input);
                if (!input) {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    searchValue: input,
                    pageNumber: 0,
                  }));
                }
              }}
              value={searchInput}
              isWithOptions={false}
            />
          </Col>
          {serialKeyDeviceType !== ControllerType.AC_CONTROLLER && (
            <Col xs={12} sm={5} className="mt-3 mt-sm-3 mt-md-0">
              <AppSelect
                label="Device Type"
                options={[...deviceTypes]}
                selectedValue={selectedDeviceType}
                onChangeOption={setSelectedDeviceType}
                fontSize="12px"
                fontWeight="500"
                fontColor="#69768b"
                menuHeight="250px"
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          <Col>
            <SpaceSelectorDropdown
              dropdownOptions={[
                { clusterId: "", id: "", name: "All Spaces", childSpaces: [] },
                ...spaceClusters.map((space) => {
                  return {
                    clusterId: space.clusterId,
                    id: space.id,
                    name: space.name,
                    childSpaces: space.childSpaces,
                  };
                }),
              ]}
              disabled={false}
              selectedSubSpace={selectedSpace.name}
              updateSelectedSubSpaceId={(
                selectedSubSpaceId: string,
                selectedSubSpaceName: string,
                clusterId?: string
              ) => {
                setSelectedSpace({
                  id: selectedSubSpaceId,
                  name: selectedSubSpaceName,
                  clusterId: clusterId || "",
                });
              }}
              label="Space"
              className="font-weight-500 text-light"
              maxHeight={totalElements >= 1 ? "230px" : "180px"}
            />
          </Col>
        </Row>
        <Row className={`${totalElements < 1 && `py-5`} mt-2 mx-0`}>
          {!isLoadingUnlinkDeviceList ? (
            <>
              {filteredDevice.map((device, index) => (
                <div key={index} className="mt-2">
                  <LinkDeviceCard
                    device={device}
                    onChange={(device) => setSelectedDevice(device)}
                    selectedDevice={selectedDevice}
                    isPathReverse={true}
                    setScheduleIdList={Array.isArray}
                  />
                </div>
              ))}
            </>
          ) : (
            <SpinnerModal show={isLoadingUnlinkDeviceList} />
          )}
        </Row>
        <Pagination
          itemsPerPage={ITEM_PER_PAGE}
          length={totalElements}
          currentPage={filters.pageNumber + 1}
          updateCurrentPage={(pn) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              pageNumber: pn - 1,
            }));
          }}
        />
      </div>
    </ModalContainer>
  );
};

export default DeviceLinkModal;
