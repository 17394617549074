import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewSemiAutomationSchedulesByPowerConsumerQuery } from "../../../../../../redux/api/schedule/scheduleAPI";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import ViewSemiAutomationSchedulesByPowerConsumerResponseDTO from "../../../../../../shared/oversight-general-core/dtos/view-semi-automation-schedules-by-power-consumer-response-dto";
import { IExtendedShallowSemiAutomatedScheduleView } from "../../../../../../shared/oversight-general-core/interfaces/shallow-semi-automated-schedule-view";
import AppSwitch from "../../../../../../shared/oversight-general-core/ui-elements/app-switch/app-switch";
import ScheduleListCard from "../../../../../../shared/oversight-general-core/ui-elements/schedule-list-card/schedule-list-card";
import styles from "./device-unlink-modal.module.scss";

interface IProps extends ModelContainerProps {
  show: boolean;
  deviceName: string;
  controllerName: string;
  spaceClusterId: string;
  linkedPowerConsumerId: string;
  setScheduleIdList: React.Dispatch<React.SetStateAction<string[]>>;
}

const DeviceUnlinkModal = (props: IProps) => {
  const {
    show,
    deviceName,
    controllerName,
    spaceClusterId,
    linkedPowerConsumerId,
    setScheduleIdList,
    ...rest
  } = props;

  const [semiAutomationSchedule, setSemiAutomationSchedule] = useState<
    IExtendedShallowSemiAutomatedScheduleView[]
  >([]);
  const [selectAll, setSelectAll] = useState(false);
  const [triggerSemiAutomationSchedulesByPowerConsumer] =
    useLazyViewSemiAutomationSchedulesByPowerConsumerQuery();

  useEffect(() => {
    if (show) {
      triggerSemiAutomationSchedulesByPowerConsumer({
        spaceClusterId: spaceClusterId,
        linkedPowerConsumerId: linkedPowerConsumerId,
      })
        .unwrap()
        .then((res: ViewSemiAutomationSchedulesByPowerConsumerResponseDTO) => {
          setSemiAutomationSchedule(
            res.shallowSemiAutomatedScheduleViews.map((schedule) => {
              return { ...schedule, isSelected: false };
            })
          );
        })
        .catch(() => {
          setSemiAutomationSchedule([]);
        });
    }
  }, [show, spaceClusterId, linkedPowerConsumerId]);

  const updateScheduleState = (scheduleId: string, scheduleStatus: boolean) => {
    setSemiAutomationSchedule((ps) =>
      ps.map((schedule) => {
        if (schedule.id === scheduleId) {
          return { ...schedule, isSelected: scheduleStatus };
        } else {
          return { ...schedule };
        }
      })
    );

    if (scheduleStatus) {
      setScheduleIdList((ps) => [...ps, scheduleId]);
    } else {
      setScheduleIdList((ps) => ps.filter((id) => id != scheduleId));
    }
  };

  useEffect(() => {
    let isAllSelected = true;

    for (const schedule of semiAutomationSchedule) {
      if (!schedule.isSelected) {
        isAllSelected = false;
        break;
      }
    }

    setSelectAll(isAllSelected);
  }, [semiAutomationSchedule]);

  const handleSelectAll = (value: boolean) => {
    setSemiAutomationSchedule((ps) =>
      ps.map((schedule) => {
        return { ...schedule, isSelected: value };
      })
    );

    if (value) {
      const tempScheduleIdList = semiAutomationSchedule.map(
        (schedule) => schedule.id
      );
      setScheduleIdList(tempScheduleIdList);
      return;
    }

    setScheduleIdList([]);
  };

  return (
    <ModalContainer
      {...rest}
      title={"Unlink Device?"}
      show={show}
      size="modal-md"
      confirmButtonText="Unlink"
      confirmButtonVariant="red"
      cancelButtonText="Cancel"
    >
      <>
        <Row>
          <Col className="font-weight-500 font-size-14 text-light">
            Disable the Socket?
          </Col>
        </Row>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            Are you sure you want to Unlink the{" "}
            <span className="font-weight-600">{deviceName}</span> from{" "}
            <span className="font-weight-600">{controllerName}</span>? This will
            disable the socket until a device is linked or the socket converted
            to a general type.
          </Col>
        </Row>
        {semiAutomationSchedule.length > 0 && (
          <>
            <Row className="mt-3">
              <Col className="font-weight-500 font-size-14 text-light">
                Cancel Schedule?
              </Col>
            </Row>
            <Row>
              <Col className="font-weight-400 font-size-14 text-light">
                <div>
                  All the schedules related to this controller will be cancelled
                  due to the device being unlinked.
                </div>
                <div className="mt-3">
                  If you want to keep any schedules running for this controller,
                  please select them below.
                </div>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-end my-4">
              <Col className="col-auto font-weight-400 font-size-14 text-light">
                Select All
              </Col>
              <Col className="col-auto">
                <AppSwitch
                  isOn={selectAll}
                  size="sm"
                  onSwitch={(value: boolean) => {
                    handleSelectAll(value);
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <div className={`${styles.overflow}`}>
          {semiAutomationSchedule.map((schedule, index) => {
            return (
              <Row key={index}>
                <Col>
                  <ScheduleListCard
                    schedules={schedule}
                    updateScheduleState={updateScheduleState}
                  />
                </Col>
              </Row>
            );
          })}
        </div>
      </>
    </ModalContainer>
  );
};

export default DeviceUnlinkModal;
