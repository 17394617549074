import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectedSpace } from "../../../shared/oversight-general-core/interfaces/selected-space";
import ISmartControllersFilters from "../../../shared/oversight-general-core/interfaces/smart-controllers-filter";
import { RootState } from "../../store";

const defaultSmartControllersFilters: ISmartControllersFilters = {
  selectedSpace: { id: "", clusterId: "", name: "All Spaces" },
  selectedFilterSpace: {
    id: "",
    clusterId: "",
    name: "All Spaces",
  },
  searchText: "",
  selectedDeviceType: "",
  selectedGroupBy: "Space",
  selectedSearchTextType: "Controller",
  badgeValue: 0,
};

export const smartControllersFilterSlice = createSlice({
  name: "smartControllersFilter",
  initialState: { ...defaultSmartControllersFilters },
  reducers: {
    setSmartControllersFilter: (
      state,
      action: PayloadAction<
        Omit<ISmartControllersFilters, "selectedFilterSpace">
      >
    ) => {
      state.selectedSpace = { ...action.payload.selectedSpace };
      state.selectedGroupBy = action.payload.selectedGroupBy;
      state.searchText = action.payload.searchText;
      state.selectedDeviceType = action.payload.selectedDeviceType;
      state.selectedSearchTextType = action.payload.selectedSearchTextType;
      state.badgeValue = action.payload.badgeValue;
    },
    setSmartControllerFilterSelectedSpace: (
      state,
      action: PayloadAction<ISelectedSpace>
    ) => {
      state.selectedSpace = { ...action.payload };
    },
    setSelectedSmartControllerFilterSpace: (
      state,
      action: PayloadAction<ISelectedSpace>
    ) => {
      state.selectedSpace = { ...action.payload };
      state.selectedFilterSpace = { ...action.payload };
    },
  },
});

export const {
  setSmartControllersFilter,
  setSmartControllerFilterSelectedSpace,
  setSelectedSmartControllerFilterSpace,
} = smartControllersFilterSlice.actions;

export const selectSmartControllersFilter = (state: RootState) =>
  state.smartControllersFilter;

export default smartControllersFilterSlice.reducer;
