import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLazyResetPasswordQuery } from "../../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../../enums/ovst-error-codes";
import { AppRoute } from "../../../interfaces/app-routes";
import { IHttpError } from "../../../interfaces/http-errror";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import AppInput from "../../../ui-elements/input/app-input";
import { passwordRegex } from "../../../utils/regex";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";

interface ResetPassword {
  password: string;
  confirmPassword: string;
}

const defaultFormValues: ResetPassword = {
  password: "",
  confirmPassword: "",
};

const ResetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ResetPassword>({
    defaultValues: { ...defaultFormValues },
  });

  const navigate = useNavigate();
  const [triggerResetPassword, { isLoading: loadingResetPassword }] =
    useLazyResetPasswordQuery();

  const onSubmit = (data: ResetPassword) => {
    triggerResetPassword({ password: data.password })
      .then((response) => {
        const tempResponse = response.error as Response;
        const tempError = response.error as IHttpError;
        if (
          tempResponse &&
          tempResponse.status === 412 &&
          tempError &&
          tempError.ovstErrorCode === OvstErrorCode.OVST_0036
        ) {
          showErrorMessage(
            "Password must have 6-20 characters and include at least one lowercase letter, one uppercase letter, one numeric and one special character"
          );
        } else {
          showSuccessMessage("Password Reset Successfully");
          reset({ ...defaultFormValues });
          navigate(AppRoute.USER_PROFILE);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    reset({ ...defaultFormValues });
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="mt-4">
            <AppInput
              name="password"
              icon={!showPassword ? "visibility_off" : "visibility"}
              placeholder="New Password"
              onIconClick={() => setShowPassword(!showPassword)}
              label="New Password"
              type={showPassword ? "text" : "password"}
              register={register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must contain at least 6 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Password must contain less than 20 characters",
                },
                pattern: {
                  value: passwordRegex,
                  message:
                    "Password must have 6-20 characters and include at least one lowercase letter, one uppercase letter, one numeric and one special character",
                },
              })}
              errors={errors}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            <AppInput
              name="confirmPassword"
              icon={!showConfirmPassword ? "visibility_off" : "visibility"}
              placeholder="Confirm New Password"
              onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
              label="Confirm New Password"
              type={showConfirmPassword ? "text" : "password"}
              register={register("confirmPassword", {
                required: "Password confirmation is required",
                validate: (value, formValues) => {
                  if (value !== formValues.password) {
                    return "Password doesn't match";
                  }
                },
              })}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <AppButton
              text="Confirm"
              size="medium"
              type="submit"
              isLoading={loadingResetPassword}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
