import { EAcProtocolType } from "../enums/ac-protocol-type";

const getACProtocolType = (): {
  value: EAcProtocolType;
  label: string;
}[] => {
  const acProtocolType = [
    { value: EAcProtocolType.LG2, label: "LG2" },
    {
      value: EAcProtocolType.KELON,
      label: "KELON",
    },
    {
      value: EAcProtocolType.COOLIX,
      label: "COOLIX",
    },
    { value: EAcProtocolType.SONY, label: "SONY" },
    {
      value: EAcProtocolType.DAIKIN,
      label: "DAIKIN",
    },
    { value: EAcProtocolType.HAIER_AC, label: "HAIER_AC" },
    {
      value: EAcProtocolType.WHIRLPOOL_AC,
      label: "WHIRLPOOL_AC",
    },
  ];
  return acProtocolType;
};

export default getACProtocolType;
