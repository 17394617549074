import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useSwitchSmartControllerMutation,
  useSwitchSpaceMutation,
  useUpdateAcTemperatureMutation,
} from "../../../../redux/api/controller/controllerAPI";
import { useLazyGetSpaceQuery } from "../../../../redux/api/space/spaceAPI";
import {
  selectSmartControllersFilter,
  setSelectedSmartControllerFilterSpace,
  setSmartControllerFilterSelectedSpace,
  setSmartControllersFilter,
} from "../../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import { DeviceStatus } from "../../../../shared/oversight-core/enums/device-status";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import { ISpaceShallowHierarchyView } from "../../../../shared/oversight-core/interfaces/space-shallow-hierarchy-view";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import SmartControllersFilter from "../../../../shared/oversight-general-core/components/smart-controllers-filter/smart-controllers-filter";
import SpacePathViewBreadcrumb from "../../../../shared/oversight-general-core/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import { ISelectedSpace } from "../../../../shared/oversight-general-core/interfaces/selected-space";
import ControllerSpaceContainer from "../controller-space-container/controller-space-container";

const Controller = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [space, setSpace] = useState<ISpaceView[]>([]);
  const [spaceId, setSpaceId] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);

  const smartControllersFiltersStore = useSelector(
    selectSmartControllersFilter
  );

  const [
    triggerGetSpace,
    {
      data: spaceData,
      isSuccess: getSpaceSuccess,
      isFetching: isFetchingSpace,
    },
  ] = useLazyGetSpaceQuery();
  const [switchControllerState, { isLoading: isLoadingSwitchControllerState }] =
    useSwitchSmartControllerMutation();
  const [switchSpaceState, { isLoading: isLoadingSwitchSpaceState }] =
    useSwitchSpaceMutation();
  const [updateAcTemperature, { isLoading: isLoadingUpdateAcTemperature }] =
    useUpdateAcTemperatureMutation();

  useEffect(() => {
    if (
      smartControllersFiltersStore.selectedSpace.clusterId &&
      smartControllersFiltersStore.selectedSpace.id
    ) {
      setClusterId(smartControllersFiltersStore.selectedSpace.clusterId);
      setSpaceId(smartControllersFiltersStore.selectedSpace.id);
    }
  }, [smartControllersFiltersStore.selectedSpace]);

  const updateCurrentState = () => {
    triggerGetSpace({ clusterId, spaceId });
  };

  useEffect(() => {
    if (clusterId && spaceId) {
      updateCurrentState();
    }
  }, [clusterId, spaceId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clusterId && spaceId) {
        updateCurrentState();
        setIsFirstTime(false);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [clusterId, spaceId]);

  useEffect(() => {
    if (getSpaceSuccess && spaceData) {
      setSpace([
        {
          ...spaceData.subSpaceCluster.rootSpace,
          clusterId: spaceData.subSpaceCluster.id,
          accountNumber:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.accountNumber
              : "",
          accountNumberLabel:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.label
              : "",
        },
      ]);
    }
  }, [getSpaceSuccess, spaceData]);

  useEffect(() => {
    if (
      smartControllersFiltersStore.selectedSpace.id !==
      getSplitPath()[getSplitPath().length - 1]
    ) {
      navigate(AppRoute.CONTROLLERS);
    }
  }, [smartControllersFiltersStore]);

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setSmartControllerFilterSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const updateSmartControllersFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    selectedSearchTextType: string,
    badgeValue: number
  ) => {
    const currentClusterId =
      smartControllersFiltersStore.selectedSpace.clusterId;
    const currentSpaceId = smartControllersFiltersStore.selectedSpace.id;

    dispatch(
      setSelectedSmartControllerFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );

    dispatch(
      setSmartControllersFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        selectedSearchTextType: selectedSearchTextType,
        badgeValue: badgeValue,
      })
    );

    if (
      !selectedSpace.clusterId ||
      selectedSpace.clusterId !== currentClusterId ||
      selectedSpace.id !== currentSpaceId ||
      selectedDeviceType ||
      selectedGroupBy !== "Space"
    ) {
      navigate(AppRoute.CONTROLLERS);
    }
  };

  const updateControllerStatus = (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => {
    switchControllerState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      smartPlugId: smartDeviceId,
      switchingPowerState: status,
    })
      .unwrap()
      .then(() => {
        updateCurrentState();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSpaceStatus = (
    clusterId: string,
    spaceId: string,
    status: DeviceStatus
  ) => {
    switchSpaceState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      switchingPowerState: status,
    })
      .unwrap()
      .then(() => {
        updateCurrentState();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendSelectedSpace = (
    space: ISpaceView,
    breadcrumb?: ISpaceShallowHierarchyView[]
  ) => {
    dispatch(
      setSmartControllerFilterSelectedSpace({
        clusterId: space.clusterId,
        id: space.id,
        name: space.name,
      })
    );

    let path = "";

    breadcrumb?.forEach((bi) => {
      path += `${path}/${bi.id}`;
    });

    navigate(AppRoute.CONTROLLERS + path);
  };

  const onAcTemperatureIncrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };

  const onAcTemperatureDecrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };

  return (
    <>
      <SmartControllersFilter
        updateFilters={updateSmartControllersFilters}
        isLoading={
          (isFetchingSpace && isFirstTime) ||
          isLoadingSwitchControllerState ||
          isLoadingSwitchSpaceState ||
          isLoadingUpdateAcTemperature
        }
      />
      {space && (
        <div className="bg-white rounded-3 p-2 p-md-4 position-relative mt-4">
          <SpacePathViewBreadcrumb
            selectedSpace={{
              clusterId: smartControllersFiltersStore.selectedSpace.clusterId,
              spaceId: smartControllersFiltersStore.selectedSpace.id,
            }}
            sendSelectedSpace={sendSelectedSpace}
          />
          {(!isFetchingSpace && isFirstTime) ||
            (!spaceData && (
              <div className="container-dash mt-4">
                <Row>
                  <Col className="text-center text-light font-size-12">
                    Cannot find the space. Go to{" "}
                    <Link to={"/dashboard/controllers"}>
                      All Billing Spaces.
                    </Link>
                  </Col>
                </Row>
              </div>
            ))}
          {space.map((space) => {
            return (
              <ControllerSpaceContainer
                key={space.id}
                mainSpace={space}
                expandable={true}
                isShowIcon={true}
                size={16}
                updateCurrentState={updateCurrentState}
                collapsed={false}
                onSelectSpace={selectSpaceHandler}
                updateControllerStatus={updateControllerStatus}
                updateSpaceStatus={updateSpaceStatus}
                onAcTemperatureDecrease={onAcTemperatureDecrease}
                onAcTemperatureIncrease={onAcTemperatureIncrease}
              />
            );
          })}
          <SpinnerModal
            show={
              (isFetchingSpace && isFirstTime) ||
              isLoadingSwitchControllerState ||
              isLoadingSwitchSpaceState ||
              isLoadingUpdateAcTemperature
            }
          />
        </div>
      )}
    </>
  );
};

export default Controller;
