import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./app-timer.module.scss";

interface IProps {
  showTimer: boolean;
  offTimer: () => void;
}

const AppTimer = (props: IProps) => {
  const { showTimer, offTimer } = props;
  const [duration, setDuration] = useState<moment.Duration>();
  const [resendTimer, setResendTimer] = useState(90);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (resendTimer > 0 && showTimer) {
        const time = resendTimer - 1;
        setResendTimer(time);
        setDuration(moment.duration(time, "seconds"));
      } else {
        setResendTimer(90);
        offTimer();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [resendTimer, showTimer]);

  return (
    <>
      <Row>
        <Col className={`${styles.title} text-center`}>
          {duration && resendTimer > 0 && showTimer && (
            <>
              {duration.minutes()}m {duration.seconds()}s
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AppTimer;
