import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useLazyUnLinkSmartPlugListQuery,
  useUpdateAcControllerMutation,
  useUpdateControllerMutation,
} from "../../../../../../redux/api/controller/controllerAPI";
import { useLazyGetSpacesQuery } from "../../../../../../redux/api/space/spaceAPI";
import { AppRoute } from "../../../../../../shared/oversight-core/interfaces/app-routes";
import { IPowerConsumerView } from "../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import { ISpaceView } from "../../../../../../shared/oversight-core/interfaces/entities/space";
import ActiveInactiveIndicator from "../../../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppButton from "../../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../../../../shared/oversight-core/utils/findIcon";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";
import Pagination from "../../../../../../shared/oversight-general-core/components/pagination/pagination";
import Search from "../../../../../../shared/oversight-general-core/components/search/search";
import SpaceSelectorDropdown from "../../../../../../shared/oversight-general-core/components/space-selector-dropdown/space-selector-dropdown";
import { ConnectionState } from "../../../../../../shared/oversight-general-core/enums/connection-status";
import { ControllerType } from "../../../../../../shared/oversight-general-core/enums/controller-type";
import { ISelectedSpace } from "../../../../../../shared/oversight-general-core/interfaces/selected-space";
import { ISpaceClusterSmartPlugViews } from "../../../../../../shared/oversight-general-core/interfaces/space-cluster-smart-plug-views";
import SocketCard from "../../../../../../shared/oversight-general-core/ui-elements/socket-card/socket-card";
import styles from "./controller-link-modal.module.scss";
interface IProps extends ModelContainerProps {
  show: boolean;
  device: IPowerConsumerView;
  setIsPowerConsumerUpdated:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  deviceConnectionState: ConnectionState | undefined;
  smartDeviceType: ControllerType;
}

const ITEM_PER_PAGE = 3;

const ControllerLinkModal = (props: IProps) => {
  const navigate = useNavigate();

  const { deviceConnectionState, smartDeviceType, show, ...rest } = props;

  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    searchValue: "",
    pageNumber: 0,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [selectedController, setSelectedController] = useState<
    ISpaceClusterSmartPlugViews | undefined
  >(undefined);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    clusterId: "",
    id: "",
    name: "All Spaces",
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [filteredController, setFilteredController] = useState<
    ISpaceClusterSmartPlugViews[]
  >([]);

  const [triggerGetSpaces] = useLazyGetSpacesQuery();
  const [
    triggerUnlinkedSmartPlugList,
    { isFetching: isLoadingUnlinkSmartPlugList },
  ] = useLazyUnLinkSmartPlugListQuery();

  const [updateController, { isLoading: isLoadingUpdateController }] =
    useUpdateControllerMutation();

  const [updateAcController, { isLoading: isLoadingUpdateAcController }] =
    useUpdateAcControllerMutation();

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        let spaceClusterView: ISpaceView[] = [];

        for (const spaceCluster of response.spaceClusters) {
          spaceClusterView.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        spaceClusterView = spaceClusterView.sort((a, b) =>
          a.name.trim().localeCompare(b.name.trim())
        );
        setSpaceClusters([...spaceClusterView]);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  useEffect(() => {
    if (selectedSpace.clusterId && selectedSpace.id) {
      triggerUnlinkedSmartPlugList({
        searchField: "name",
        spaceClusterId: selectedSpace.clusterId,
        subRootSpaceId: selectedSpace.id,
        pageNumber: filters.pageNumber,
        searchValue: filters.searchValue,
        ascending: true,
        pageSize: 3,
        smartDeviceType,
      })
        .unwrap()
        .then((res) => {
          setFilteredController(res.smartDevices.elements);
          setTotalElements(res.smartDevices.totalElements);
        })
        .catch(() => {
          setFilteredController([]);
          setTotalElements(0);
        });
    } else {
      triggerUnlinkedSmartPlugList({
        searchField: "name",
        pageNumber: filters.pageNumber,
        searchValue: filters.searchValue,
        ascending: true,
        pageSize: 3,
        smartDeviceType,
      })
        .unwrap()
        .then((res) => {
          setFilteredController(res.smartDevices.elements);
          setTotalElements(res.smartDevices.totalElements);
        })
        .catch(() => {
          setFilteredController([]);
          setTotalElements(0);
        });
    }
  }, [selectedSpace.clusterId, selectedSpace.id, filters, show]);

  useEffect(() => {
    if (show) {
      setSelectedSpace({
        clusterId: "",
        id: "",
        name: "All Spaces",
      });
    }
  }, [show]);

  const linkHandler = () => {
    if (selectedController) {
      if (smartDeviceType === ControllerType.SMART_PLUG) {
        updateController({
          spaceClusterId: selectedController.spaceClusterId,
          spaceId: selectedController.spaceId,
          smartPlugId: selectedController.smartDevice.id,
          name: selectedController.smartDevice.name,
          linkedPowerConsumerId: props.device.id,
        })
          .unwrap()
          .then(() => {
            showSuccessMessage("Device Linked Successfully");
            props.onClose && props.onClose();
            props.setIsPowerConsumerUpdated &&
              props.setIsPowerConsumerUpdated((ps) => !ps);
          })
          .catch(() => {
            showErrorMessage("Device Linked Unsuccessful");
          });

        return;
      }

      updateAcController({
        spaceClusterId: selectedController.spaceClusterId,
        spaceId: selectedController.spaceId,
        acControllerId: selectedController.smartDevice.id,
        name: selectedController.smartDevice.name,
        linkedAcId: props.device.id,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device Linked Successfully");
          props.onClose && props.onClose();
          props.setIsPowerConsumerUpdated &&
            props.setIsPowerConsumerUpdated((ps) => !ps);
        })
        .catch(() => {
          showErrorMessage("Device Linked Unsuccessful");
        });
    }
  };

  return (
    <ModalContainer
      {...rest}
      title={"Link Device to"}
      show={show}
      isLoading={isLoadingUpdateController || isLoadingUpdateAcController}
      size="modal-lg"
      confirmButtonText="Link"
      cancelButtonText="Back"
      cancelButtonVariant="transparentWithBorder"
      className="mt-0"
      onConfirm={linkHandler}>
      <>
        <Row className="align-items-center mx-0">
          <Col className={`${props.device.deviceType} p-3 rounded`} xs={"auto"}>
            <MaterialIcon icon={findIcon(props.device.deviceType)} />
          </Col>
          <Col>
            <Row>
              <Col
                className="font-size-14 font-weight-500 text-dark"
                xs={"auto"}>
                {props.device.name}
              </Col>
            </Row>
            <Row className="align-items-center g-2">
              <Col
                className="font-size-12 font-weight-400 text-light"
                xs={"auto"}>
                {props.device.powerUsageInWatt}W
              </Col>
              {deviceConnectionState && (
                <Col>
                  <ActiveInactiveIndicator
                    isActive={
                      deviceConnectionState === ConnectionState.CONNECTED
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12} sm={8} md={6} className="mt-3 mt-sm-0">
            <Search
              placeholder="Search Controller"
              onSearch={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  searchValue: searchInput,
                  pageNumber: 0,
                }));
              }}
              onChange={(input) => {
                setSearchInput(input);
                if (!input) {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    searchValue: input,
                    pageNumber: 0,
                  }));
                }
              }}
              value={searchInput}
              isWithOptions={false}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {filteredController.length === 0 ? (
              <Row
                className={`mt-3 mx-1 justify-content-center align-items-center container-dash ${styles.containerDash}`}>
                <Col
                  xs="12"
                  sm="auto"
                  className="font-size-14 p-0 text-center text-light">
                  There are no available smart controllers.
                </Col>
                <Col xs="12" sm="auto" className="ps-1 text-center mt-0">
                  <AppButton
                    text="Add Controller"
                    variant="transparent"
                    size="medium"
                    onClick={() => navigate(AppRoute.ADD_CONTROLLER)}
                    className=" p-0"
                  />
                </Col>
              </Row>
            ) : (
              <SpaceSelectorDropdown
                dropdownOptions={[
                  {
                    clusterId: "",
                    id: "",
                    name: "All Spaces",
                    childSpaces: [],
                  },
                  ...spaceClusters.map((space) => {
                    return {
                      clusterId: space.clusterId,
                      id: space.id,
                      name: space.name,
                      childSpaces: space.childSpaces,
                    };
                  }),
                ]}
                disabled={false}
                selectedSubSpace={selectedSpace.name}
                updateSelectedSubSpaceId={(
                  selectedSubSpaceId: string,
                  selectedSubSpaceName: string,
                  clusterId?: string
                ) => {
                  setSelectedSpace({
                    id: selectedSubSpaceId,
                    name: selectedSubSpaceName,
                    clusterId: clusterId || "",
                  });
                }}
                label="Space"
                className="font-weight-500 text-light"
                maxHeight={"250px"}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-2 mx-0">
          {!isLoadingUnlinkSmartPlugList ? (
            <>
              {filteredController.map((controller, index) => (
                <div key={index} className="mt-2">
                  <SocketCard
                    controller={controller}
                    onChange={(controller) => setSelectedController(controller)}
                    selectedController={selectedController}
                  />
                </div>
              ))}
            </>
          ) : (
            <SpinnerModal show={isLoadingUnlinkSmartPlugList} />
          )}
        </Row>
        <Pagination
          itemsPerPage={ITEM_PER_PAGE}
          length={totalElements}
          currentPage={filters.pageNumber + 1}
          updateCurrentPage={(pn) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              pageNumber: pn - 1,
            }));
          }}
        />
      </>
    </ModalContainer>
  );
};

export default ControllerLinkModal;
