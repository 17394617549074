import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppButton from "../buttons/app-button/app-button";
import styles from "./search.module.scss";
interface IProps {
  handleSearchChange: (searchText: string) => void;
  placeholder: string;
  reset: boolean;
}

const Search = (props: IProps) => {
  const { handleSearchChange, placeholder, reset } = props;
  const [searchText, setSearchText] = useState("");
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (!searchText && !firstTime) {
      handleSearchChange(searchText);
      return;
    }

    setFirstTime(false);
  }, [searchText]);

  useEffect(() => {
    setSearchText("");
  }, [reset]);

  return (
    <div className={`${styles[`search-wrapper`]}`}>
      <Row className={`align-items-center`}>
        <Col className="pe-0">
          <input
            type="text"
            placeholder={placeholder}
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchText(e.target.value);
            }}
            className={`${styles[`search-input`]} ps-2 w-100`}
          />
        </Col>
        <Col className="col-auto px-0">
          <AppButton
            text="Search"
            iconName="search"
            iconOnly
            size="medium"
            onClick={() => {
              handleSearchChange(searchText);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Search;
