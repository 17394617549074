import { Col, Row } from "react-bootstrap";
import styles from "./app-toggle.module.scss";

interface IProps {
  isOn: boolean;
  trueValue?: string;
  falseValue?: string;
  onColor?: string;
  offColor?: string;
  onSwitch?: () => void;
  size?: "sm" | "md";
  isDisable?: boolean;
}

const AppToggle = (props: IProps) => {
  const {
    onSwitch,
    isOn,
    trueValue = "On",
    falseValue = "Off",
    onColor = "#40b44c",
    offColor = "#69768b",
    size = "md",
    isDisable = false,
  } = props;

  return (
    <Row
      className={`${styles.appToggle} ${styles[size]} p-1 align-items-center ${
        isDisable ? `cursor-disabled` : `cursor-pointer`
      }`}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onSwitch && onSwitch();
      }}
    >
      <Col
        className={`col-auto text-center font-size-12 font-weight-500 text-white ${
          isOn ? styles.end : styles.start
        } ${styles.button}`}
        style={
          isOn ? { backgroundColor: onColor } : { backgroundColor: offColor }
        }
      >
        {isOn ? trueValue : falseValue}
      </Col>
    </Row>
  );
};

export default AppToggle;
