import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppDatePicker from "../../../oversight-core/ui-elements/app-date-picker/app-date-picker";
import styles from "./app-date-range-selector.module.scss";

interface IProps {
  startDate: Date;
  endDate: Date;
  setSelectedDate: (startDate: Date, endDate: Date) => void;
}

const dateTypes = [
  ["Daily", "Monthly"],
  ["Yearly", "Custom"],
];

const AppDateRangeSelector = (props: IProps) => {
  const { startDate, endDate, setSelectedDate } = props;
  const [dateFormat, setDateFormat] = useState("dd-MMM-yyyy");
  const [selectedDateType, setSelectedDateType] = useState("Daily");

  useEffect(() => {
    switch (selectedDateType) {
      case "Monthly":
        setDateFormat("MMMM-yyyy");
        break;
      case "Yearly":
        setDateFormat("yyyy");
        break;
      default:
        setDateFormat("dd-MMM-yyyy");
        break;
    }
  }, [selectedDateType]);

  return (
    <>
      <label className="font-size-14">Time</label>
      {dateTypes.map((dateType, index) => {
        return (
          <Row key={index} className="justify-content-between px-3">
            {dateType.map((type, index) => {
              return (
                <Col
                  key={index}
                  onClick={() => {
                    setSelectedDateType(type);

                    if (type === "Custom") {
                      setSelectedDate(startDate, startDate);
                    }
                  }}
                  className={`col-6 mt-2 font-weight-500 font-size-12 py-2 cursor-pointer text-center ${
                    selectedDateType === type
                      ? styles.selected
                      : `${styles.button}`
                  }`}
                >
                  {type}
                </Col>
              );
            })}
          </Row>
        );
      })}
      <Row className="mt-2 flex-column">
        <Col>
          <Row className="flex-column">
            {selectedDateType === "Custom" && (
              <Col>
                <label className="font-size-14">From</label>
              </Col>
            )}
            <Col>
              <AppDatePicker
                selectedDate={startDate}
                onChange={(date: Date) => {
                  setSelectedDate(date, endDate);
                }}
                monthYearPicker={selectedDateType === "Monthly"}
                yearPicker={selectedDateType === "Yearly"}
                dateFormat={dateFormat}
                fontSize={12}
              />
            </Col>
          </Row>
        </Col>
        {selectedDateType === "Custom" && (
          <Col className="mt-2">
            <Row className="flex-column">
              <Col>
                <label className="font-size-14">To</label>
              </Col>
              <Col>
                <AppDatePicker
                  selectedDate={endDate}
                  onChange={(date: Date) => {
                    setSelectedDate(startDate, date);
                  }}
                  dateFormat={dateFormat}
                  fontSize={12}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default AppDateRangeSelector;
