import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DeviceStatus } from "../../../../../../../shared/oversight-core/enums/device-status";
import { IPowerConsumerView } from "../../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import ActiveInactiveIndicator from "../../../../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import MaterialIcon, {
  MtIcon,
} from "../../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import { ConnectionState } from "../../../../../../../shared/oversight-general-core/enums/connection-status";
import ISmartDevice from "../../../../../../../shared/oversight-general-core/interfaces/smart-devices";
import AppDropDown from "../../../../../../../shared/oversight-general-core/ui-elements/app-dropdown/app-drop-down";
import AppToggle from "../../../../../../../shared/oversight-general-core/ui-elements/app-toggle/app-toggle";
import styles from "./view-device-modal-header.module.scss";

interface IProps {
  device: IPowerConsumerView;
  icon: MtIcon;
  setShowAddUpdateModal: (show: boolean) => void;
  setShowRemoveDevice: (show: boolean) => void;
  smartDevice: ISmartDevice;
  updateDeviceStatus?: (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => void;
  spaceClusterId: string;
  spaceId: string;
}

const ViewDeviceModalHeader = (props: IProps) => {
  const {
    device,
    icon,
    smartDevice,
    updateDeviceStatus,
    spaceClusterId,
    spaceId,
  } = props;
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    if (
      smartDevice?.dynamicState?.powerState === DeviceStatus.ON ||
      device.devicePowerState === DeviceStatus.ON
    ) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [smartDevice?.dynamicState?.powerState, device.devicePowerState]);

  const generalDropDownList = [
    {
      text: "Edit Device",
      onClick: () => {
        props.setShowAddUpdateModal(true);
      },
    },
    { text: "Automation" },
    {
      text: "Remove Device",
      onClick: () => {
        props.setShowRemoveDevice(true);
      },
    },
  ];

  return (
    <>
      <div className={styles.large}>
        <Row className="align-items-center">
          <Col className="col-auto">
            <div
              className={`${styles["device-icon-large"]} ${styles["device-icon"]} ${device.deviceType}`}
            >
              <MaterialIcon icon={icon} />
            </div>
          </Col>
          <Col className={`${styles["device-name"]} col-auto pe-0`}>
            {device.name}
          </Col>
          {smartDevice?.linkedPowerConsumer ||
          smartDevice.deviceConnectionState ? (
            <Col className="col-auto ps-2">
              <ActiveInactiveIndicator
                isActive={
                  smartDevice?.deviceConnectionState ===
                  ConnectionState.CONNECTED
                    ? true
                    : false
                }
              />
            </Col>
          ) : (
            <></>
          )}
          {smartDevice?.linkedPowerConsumer ||
          smartDevice?.deviceConnectionState ? (
            <Col className="col-auto ms-2">
              <AppToggle
                isOn={isOn}
                onSwitch={() => {
                  if (
                    smartDevice.deviceConnectionState ===
                    ConnectionState.CONNECTED
                  ) {
                    updateDeviceStatus &&
                      updateDeviceStatus(
                        spaceClusterId,
                        spaceId,
                        smartDevice.id,
                        smartDevice.dynamicState?.powerState ===
                          DeviceStatus.ON ||
                          device.devicePowerState === DeviceStatus.ON
                          ? DeviceStatus.OFF
                          : DeviceStatus.ON
                      );
                  }
                }}
                size="sm"
                isDisable={
                  smartDevice.deviceConnectionState ===
                  ConnectionState.CONNECTED
                    ? false
                    : true
                }
              />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <AppDropDown items={generalDropDownList} />
          </Col>
        </Row>
      </div>
      <div className={styles.small}>
        <Row className="align-items-center">
          <Col className={`${styles["device-name"]} col-auto pe-0`}>
            {device.name}
          </Col>
          <Col className="ps-2">
            <ActiveInactiveIndicator />
          </Col>
        </Row>
        <Row className="align-items-center mt-1">
          <Col className="col-auto">
            <div
              className={`${styles["device-icon-small"]} ${styles["device-icon"]} ${device.deviceType}`}
            >
              <MaterialIcon icon={icon} />
            </div>
          </Col>
          <Col className="col-auto">
            <AppToggle
              isOn={isOn}
              trueValue="On"
              falseValue="Off"
              onSwitch={() => {
                setIsOn((ps) => !ps);
              }}
            />
          </Col>
          <Col>
            <AppDropDown items={generalDropDownList} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewDeviceModalHeader;
