import { OvstErrorCode } from "../enums/ovst-error-codes";
import { IHttpError } from "../interfaces/http-errror";

const isMobileNumberVerified = (error: IHttpError) => {
  return (
    error.status === 401 && error.ovstErrorCode === OvstErrorCode.OVST_SEC_0006
  );
};

export default isMobileNumberVerified;
