import { configureStore } from "@reduxjs/toolkit";
import { rootApi } from "./api/apiManager";
import { rtkQueryErrorLogger } from "./api/rtkQueryErrorLogger";
import authReducer from "./features/auth/auth-slice";
import billingSpaceFilterReducer from "./features/billing-space-filter/billing-space-filter-slice";
import filterReducer from "./features/filter/filter-slice";
import scheduleFilterReducer from "./features/schedule-filter/schedule-filter-slice";
import smartControllersFilterReducer from "./features/smart-controllers-filter/smart-controllers-filter-slice";
import usageGuideFilterReducer from "./features/usage-guide-filter/usage-guide-filter-slice";
import userReducer from "./features/user/user-slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    filter: filterReducer,
    billingSpaceFilter: billingSpaceFilterReducer,
    smartControllersFilter: smartControllersFilterReducer,
    scheduleFilter: scheduleFilterReducer,
    usageGuideFilter: usageGuideFilterReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([rootApi.middleware, rtkQueryErrorLogger]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
