export enum AppRoute {
  // public routes
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot_password",
  RESET_PASSWORD = "/reset_password",
  CHANGE_EMAIL = "/change_email",
  MOBILE_NUMBER_VERIFICATION = "/mobile_number_verification",
  REGISTER = "/register",

  // dashboard routes
  DASHBOARD = "/dashboard",
  HOME = "/dashboard/home",
  USAGE = "/dashboard/usage",
  ENERGY_USAGE_OLD = "/dashboard/old-energy-usage",
  SCHEDULE = "/dashboard/schedule",
  ADD_SCHEDULE = "/dashboard/schedule/add",
  EDIT_SCHEDULE = "/dashboard/schedule/edit",
  CONTROLLERS = "/dashboard/controllers",
  ADD_CONTROLLER = "/dashboard/controllers/add-controller",
  BILL_CALCULATOR = "/dashboard/bill-calculator",
  SPACE_CLUSTERS = "/dashboard/space-clusters",
  SOLAR = "/dashboard/solar",
  LIMITATION_USAGE_GUIDE = "/dashboard/limitation-usage-guide",
  SETTINGS = "/dashboard/settings",
  ENERGY_USAGE = "/dashboard/usage/energy-usage",
  HIGHEST_POWER_CONSUMPTION = "/dashboard/usage/devices-with-highest-power-consumption",
  NOT_FOUND = "/dashboard/*",
  USER_PROFILE = "/dashboard/user-profile",
  VERIFY_MOBILE_NUMBER_OTP = "/verify-mobile-number-otp",
  CHANGE_MOBILE_NUMBER = "/change-mobile-number",
  VERIFY_MOBILE_NUMBER = "/verify-mobile-number",
  CHANGE_PASSWORD = "/change-password",

  // admin routes
  ADMIN = "/",
  ADMIN_LOGIN = "/login",
  ADMIN_DASHBOARD = "/dashboard",
  ADMIN_USERS = "/users",
  ADMIN_USAGE_GUIDE = "/usage-guide",
  ADD_LIMITATION = "/usage-guide/add-limitation",
  EDIT_LIMITATION = "/usage-guide/edit-limitation",
  ADMIN_SETTINGS = "/settings",
  ADMIN_PROFILE = "/profile",
  ADMIN_NOT_FOUND = "/*",

  UNAUTHORIZED = "/unauthorized",
}
