import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import ViewWeeklyUsageGuideResponseDTO from "../../../shared/oversight-general-core/dtos/view-weekly-usage-guide-response-dto";
import { rootApi } from "../apiManager";

export const usageGuideAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewWeeklyUsageGuide: builder.query<
      ViewWeeklyUsageGuideResponseDTO,
      {
        startDate: string;
      }
    >({
      transformErrorResponse,
      query({ startDate }) {
        return {
          url: `power-usage-guide/weekly/view`,
          method: "GET",
          params: {
            startDate,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyViewWeeklyUsageGuideQuery } = usageGuideAPI;
