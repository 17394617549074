import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import { EUsageViewType } from "../../oversight-general-core/enums/usage-view-type";

interface IValueProps {
  title: string;
  value: number;
  subText?: string;
  trending?: "UP" | "DOWN" | "EQUAL";
}

const usageViewType: Option[] = [
  { label: "Actual", value: EUsageViewType.ACTUAL },
  { label: "Prediction", value: EUsageViewType.PREDICTION },
];

const EnergyUsageValue = (props: IValueProps) => {
  const [selectedUsageViewType, setSelectedUsageViewType] = useState<Option>({
    ...usageViewType[0],
  });

  return (
    <Row className="flex-column align-items-center">
      <Col>
        <Row className="flex-column">
          <Col className="font-size-16 font-weight-600 text-dark">
            {props.title}
          </Col>
          <Col className="ps-1">
            <AppSelect
              isWithOutBorder={true}
              options={usageViewType}
              className="p-0"
              fontWeight="400"
              fontColor="#69768b"
              selectedValue={selectedUsageViewType}
              onChangeOption={(selectOption) => {
                setSelectedUsageViewType(selectOption);
              }}
              fontSize="14px"
            />
          </Col>
        </Row>
      </Col>
      <Col className="jakarta font-weight-600 font-size-48 text-dark ps-2">
        {props.value}
        <span className="font-size-36">Units</span>
        {props.trending && props.trending === "UP" && (
          <MaterialIcon icon="trending_up" displayInline color="red" />
        )}
        {props.trending && props.trending === "DOWN" && (
          <MaterialIcon icon="trending_down" displayInline color="green" />
        )}
      </Col>
      {props.subText && (
        <Col className="text-light font-size-14 font-weight-500">
          {props.subText}
        </Col>
      )}
    </Row>
  );
};

export default EnergyUsageValue;
