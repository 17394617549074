import moment from "moment";
import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../enums/device-types";
import IExtendedEnergyConsumers from "../../../interfaces/extended-energy-consumers";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import findIcon from "../../../utils/findIcon";
import Helper from "../../../utils/helpers";
import styles from "./high-power-devices.module.scss";

interface IProps {
  consumers: IExtendedEnergyConsumers[] | null;
  title: string;
  isFetching?: boolean;
}

const HighPowerDevices = (props: IProps) => {
  const [data, setData] = useState<
    {
      name: string;
      id: string;
      units: number;
      type: EDeviceTypes;
      percentage: number;
      path: string;
    }[]
  >([]);

  useEffect(() => {
    if (props.consumers && props.consumers.length > 0) {
      const sorted = props.consumers.slice().sort((a, b) => b.units - a.units);
      const max = sorted[0].units;
      const _data = sorted.map((c) => {
        return {
          name: c.name,
          id: c.id,
          units: c.units,
          type: c.type,
          percentage: (c.units / max) * 100,
          path: c.path,
        };
      });

      setData(_data);
    } else {
      setData([]);
    }
  }, [props.consumers]);

  return (
    <div className={`${styles.container}  container-white h-100`}>
      {props.isFetching ? (
        <SpinnerModal withOverlay={false} show={props.isFetching} size="md" />
      ) : (
        <>
          {" "}
          <Row className="align-items-center">
            <Col>
              <label className={styles.title}>{props.title}</label>
            </Col>
          </Row>
          <Row>
            {props.consumers === null ? (
              <Col className={`${styles.subTitle} text-center`}>
                No power consumption on {moment(new Date()).format("MMM, YYYY")}
              </Col>
            ) : (
              <>
                {data.map((c, index) => (
                  <Col
                    key={index}
                    xs={12}
                    lg={6}
                    className={styles.contentRowLeft}
                  >
                    {c.units > 0 && <Device {...c} />}
                  </Col>
                ))}
              </>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

interface IDeviceProps {
  name: string;
  id: string;
  units: number;
  type: EDeviceTypes;
  percentage: number;
  path: string;
}

const Device = (props: IDeviceProps) => {
  return (
    <>
      {props.units > 0 && (
        <Row className={styles.deviceContainer}>
          <Col className={`${styles.left} ps-0`}>
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                <div className={`${props.type} ${styles.icon}`}>
                  <MaterialIcon size={16} icon={findIcon(props.type)} />
                </div>
              </Col>
              <Col className="ps-2 pe-0">
                <Tooltip
                  placement="bottom"
                  trigger={["hover"]}
                  overlayInnerStyle={{
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                  overlay={
                    <div>
                      <Row>
                        <Col>
                          <div className="text-dark font-weight-500 font-size-14">
                            {props.name}
                          </div>
                          <div className="text-light font-weight-400 font-size-12">
                            {props.path}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <label className="text-truncate" style={{ maxWidth: "40px" }}>
                    {props.name}
                  </label>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col className={`${styles.right} text-end p-0 pe-0`}>
            <Row className="align-items-center">
              <Col>
                <div className={styles.progress}>
                  <div
                    className={props.type}
                    style={{ width: `${props.percentage}%` }}
                  ></div>
                </div>
              </Col>
              <Col className="ps-0 col-4">
                <label>{`${Helper.roundTo2(props.units)} Units`}</label>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
export default HighPowerDevices;
