import { Col, Row } from "react-bootstrap";
import { ISpaceClusterSmartPlugViews } from "../../interfaces/space-cluster-smart-plug-views";
import styles from "./socket-card.module.scss";

interface IProps {
  controller: ISpaceClusterSmartPlugViews;
  onChange: (Controller: ISpaceClusterSmartPlugViews) => void;
  selectedController: ISpaceClusterSmartPlugViews | undefined;
}

const SocketCard = (props: IProps) => {
  const { controller, onChange, selectedController } = props;

  const selectedFontColor =
    selectedController?.smartDevice.id === controller.smartDevice.id &&
    styles.selectedFontColor;
  return (
    <>
      <Row
        className={`${styles["socket-card"]} ${
          selectedController?.smartDevice.id === controller.smartDevice.id &&
          styles.selected
        } cursor-pointer p-2 align-items-center`}
        onClick={() => onChange(controller)}
      >
        <Col>
          <Row>
            <Col className={`${styles[`socket-name`]} ${selectedFontColor}`}>
              {controller.smartDevice.name}
            </Col>
          </Row>
          <Row>
            <Col className={`${styles[`socket-id`]} ${selectedFontColor}`}>
              {controller.smartDevice.serialKey}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SocketCard;
