import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../../../../shared/oversight-core/enums/device-types";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../../../../shared/oversight-core/utils/findIcon";
import { TimeGridEvent } from "../../../../../../shared/oversight-general-core/components/calendar/models";
import { RepetitionMode } from "../../../../../../shared/oversight-general-core/enums/repetition-mode";
import { IScheduledShallowView } from "../../../../../../shared/oversight-general-core/interfaces/scheduled-shallow-view";
import styles from "./create-modal.module.scss";

interface IProps<ColumnIdType, T> {
  id: string;
  events: TimeGridEvent<ColumnIdType, T>[];
  onEditClick: (event: TimeGridEvent<ColumnIdType, T>) => void;
  onDelete: (event: TimeGridEvent<ColumnIdType, T>) => void;
  onClose: () => void;
}

const CreateModal = <ColumnIdType,>(
  props: IProps<ColumnIdType, IScheduledShallowView>
) => {
  const { events, onEditClick, onDelete, onClose } = props;

  const findSubtitle = (mode: string) => {
    switch (mode as RepetitionMode) {
      case RepetitionMode.DAILY:
        return `Daily`;
      case RepetitionMode.DO_NOT_REPEAT:
        return "Once";
      case RepetitionMode.MONTHLY:
        return "Monthly";
      case RepetitionMode.WEEKLY:
        return "Weekly";
      case RepetitionMode.YEARLY:
        return "Annually";
    }
  };
  return (
    <>
      <div
        className={`${styles.container} ${
          events.length > 1 ? styles.containerBoxShadow : ""
        } py-3 px-1`}
      >
        {events.length > 1 && (
          <Row className="align-items-center px-4 pt-2">
            <Col className="font-size-16 font-weight-500 text-dark">
              {events.length} Events
            </Col>
            <Col className="col-auto">
              <MaterialIcon
                icon="close"
                color="#383941"
                className="cursor-pointer"
                onClick={onClose}
              />
            </Col>
          </Row>
        )}
        <div className={styles.scrollableArea}>
          {events.map((event, eventIndex) => {
            const powerConsumers = event.data?.powerConsumers || [];
            return (
              <div
                key={eventIndex}
                className={`${events.length !== 1 ? "mt-4" : ""} px-4 py-2 ${
                  styles.eventContainer
                }`}
              >
                <Row className="align-items-center">
                  <Col className="font-size-16 font-weight-500 text-dark">
                    {event.title}
                  </Col>
                  <Col xs={"auto"}>
                    <MaterialIcon
                      icon="edit"
                      color="#383941"
                      className="cursor-pointer"
                      onClick={() => onEditClick(event)}
                    />
                  </Col>
                  <Col xs={"auto"}>
                    <MaterialIcon
                      icon={"delete"}
                      color="#CB343D"
                      className="cursor-pointer"
                      onClick={() => onDelete(event)}
                    />
                  </Col>
                  {events.length === 1 && (
                    <Col xs={"auto"}>
                      <MaterialIcon
                        icon="close"
                        color="#383941"
                        className="cursor-pointer"
                        onClick={onClose}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col className="font-size-12 font-weight-500 text-light">
                    {moment(event.date).format("ddd(DD), MMM, yyyy")}
                  </Col>
                </Row>
                <Row>
                  <Col className="font-size-12 font-weight-500 text-light">
                    {findSubtitle(
                      event.data?.repetitionMode || RepetitionMode.DO_NOT_REPEAT
                    )}
                  </Col>
                </Row>
                <Row className="align-items-center mt-4">
                  <Col xs={"auto"}>
                    <MaterialIcon icon={"schedule"} />
                  </Col>
                  <Col className="font-size-12 font-weight-400 text-light">
                    {moment(event.date)
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("hh:mm A")}{" "}
                    -{" "}
                    {moment(event.date)
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("hh:mm A")}
                  </Col>
                </Row>
                <Row className="mt-2">
                  {powerConsumers.map((powerConsumer) => {
                    return (
                      <Col key={powerConsumer.id} className="px-4 col-6 mt-2">
                        <Row
                          className={`${powerConsumer.deviceCategory} ${styles.deviceCategoryContainer} py-2 align-items-center justify-content-between h-100`}
                        >
                          <Col className="col-auto pe-0">
                            <MaterialIcon
                              icon={findIcon(
                                powerConsumer.deviceCategory as EDeviceTypes
                              )}
                            />
                          </Col>

                          <Col className="font-size-14 font-weight-400 text-light">
                            {powerConsumer.name}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CreateModal;
