import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import NotFoundImg from "./assets/not-found.png";
import styles from "./not-found.module.scss";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <div>
            <img src={NotFoundImg} className="img-fluid" alt="Not Found" />
          </div>
          <div className={[styles.notFoundHeading, "display-6"].join(" ")}>
            PAGE NOT FOUND
          </div>
          <p className={[styles.notFoundParagraph, " fs-6"].join(" ")}>
            We could not find the page you are looking for, The page
            <br />
            might have been removed/changed or the URL is wrong.
          </p>
          <div className="d-flex justify-content-center gap-2">
            {/* <div
              className={[
                styles.arrowBtton,
                "bg-primary align-items-center d-flex justify-content-center",
              ].join(" ")}
            >
              <MaterialIcon
                icon="arrow_left"
                className="text-white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(-1)
                }}
              />
            </div> */}
            <div>
              <AppButton
                text="Bring Me To Home Page"
                variant="blue"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
