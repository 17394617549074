import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../ui-elements/material-icon/material-icon";
import styles from "./user-profile-data-edit.module.scss";

interface IProps {
  title: string;
  text: string | undefined;
  showEditIcon: boolean;
  hasIcon?: boolean;
  isVerified?: boolean;
  handleEdit?: () => void;
}
const UserProfileDataEdit = (props: IProps) => {
  return (
    <>
      <Row
        className={`${styles.userProfile} mb-3 mx-0 align-items-center h-100`}
      >
        <Col className="px-3 py-2">
          <Row>
            <Col>
              <p className="mb-0 text-light">{props.title}</p>
            </Col>
          </Row>
          <Row>
            <Col className="mb-0 font-weight-500 text-dark">
              <Row className="g-1 align-items-center">
                <Col className="col-auto text-break">
                  {props.text}
                  {props.text ? (
                    <>
                      {props.hasIcon && (
                        <MaterialIcon
                          icon={"check_circle"}
                          color={props.isVerified ? `#4D7FDF` : `#69768B`}
                          className="mt-3 font-size-14"
                          displayInline={true}
                          fill={true}
                        />
                      )}
                    </>
                  ) : (
                    <>-</>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {props.showEditIcon && (
          <Col className="col-auto">
            <MaterialIcon
              icon={"border_color"}
              color={"#69768B"}
              style={{ cursor: "pointer" }}
              className="font-size-14"
              onClick={() => {
                if (props.handleEdit) {
                  props.handleEdit();
                }
              }}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default UserProfileDataEdit;
