import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSmartControllerFilterSelectedSpace } from "../../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import { DeviceStatus } from "../../../../shared/oversight-core/enums/device-status";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import { ConnectionState } from "../../../../shared/oversight-general-core/enums/connection-status";
import AppToggle from "../../../../shared/oversight-general-core/ui-elements/app-toggle/app-toggle";
import ControllerCard from "../../../../shared/oversight-general-core/ui-elements/controller-card/controller-card";
import styles from "./controller-space-container.module.scss";

interface IProps {
  isShowIcon: boolean;
  size: number;
  expandable: boolean;
  collapsed: boolean;
  mainSpace: ISpaceView;
  onSelectSpace: (space: ISpaceView) => void;
  updateCurrentState: () => void;
  updateControllerStatus: (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: DeviceStatus
  ) => void;
  updateSpaceStatus: (
    clusterId: string,
    spaceId: string,
    status: DeviceStatus
  ) => void;
  onAcTemperatureIncrease: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  onAcTemperatureDecrease: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
}

const ControllerSpaceContainer = (props: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    isShowIcon,
    size,
    expandable,
    collapsed,
    mainSpace,
    onSelectSpace,
    updateCurrentState,
    updateControllerStatus,
    updateSpaceStatus,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const [isOn, setIsOn] = useState(
    props.mainSpace.powerState === DeviceStatus.ON ? true : false
  );

  useEffect(() => {
    if (mainSpace.powerState === DeviceStatus.ON) {
      setIsOn(true);
      return;
    }

    setIsOn(false);
  }, [mainSpace.powerState]);

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setSmartControllerFilterSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const checkDeviceConnectionState = (space: ISpaceView): boolean => {
    const smartControllerWithSpaceCluster = space.smartDevices.some(
      (smartDevice) =>
        smartDevice.deviceConnectionState === ConnectionState.CONNECTED
    );

    if (smartControllerWithSpaceCluster) {
      return smartControllerWithSpaceCluster;
    } else {
      const smartControllerWithChildSpace = space.childSpaces
        .map((childSpace) => {
          return checkDeviceConnectionState(childSpace);
        })
        .some((smartDevice) => smartDevice === true);

      return smartControllerWithChildSpace;
    }
  };

  return (
    <div className={`${styles.controllerSpaceContainer} py-3 px-2 my-3`}>
      <Container fluid>
        <div
          onClick={() => onSelectSpace(mainSpace)}
          style={{ cursor: "pointer" }}
        >
          <Row className="align-items-center">
            <Col className={styles.pcHeader}>
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  {isShowIcon && (
                    <MaterialIcon icon={"apartment"} className="d-inline" />
                  )}
                </Col>
                <Col
                  style={{
                    color: isShowIcon ? `#383941` : `#69768B`,
                    fontSize: size,
                  }}
                  className="col-auto"
                >
                  {mainSpace.name}
                </Col>
                <Col className="col-auto">
                  <AppToggle
                    isOn={isOn}
                    onSwitch={() => {
                      if (checkDeviceConnectionState(mainSpace))
                        updateSpaceStatus(
                          mainSpace.clusterId,
                          mainSpace.id,
                          mainSpace.powerState === DeviceStatus.ON
                            ? DeviceStatus.OFF
                            : DeviceStatus.ON
                        );
                    }}
                    size="sm"
                    isDisable={!checkDeviceConnectionState(mainSpace)}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-auto">
              {expandable && (
                <MaterialIcon
                  icon="expand_more"
                  className={`cursor-pointer ${styles.dArrow}`}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setIsCollapsed(!isCollapsed);
                  }}
                  style={
                    isCollapsed
                      ? { transform: "rotate(0deg)" }
                      : { transform: "rotate(180deg)" }
                  }
                />
              )}
            </Col>
          </Row>
          <div
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
            }}
            style={{ cursor: "default" }}
          >
            <Row className={`${isCollapsed ? "d-none" : ""} mt-3`}>
              {mainSpace.smartDevices.map((smartDevice) => {
                return (
                  <Col key={smartDevice.id} className="py-2 col-12 col-xl-6">
                    <ControllerCard
                      smartDevice={{
                        ...smartDevice,
                        clusterId: mainSpace.clusterId,
                        spaceId: mainSpace.id,
                      }}
                      updateControllerStatus={updateControllerStatus}
                      onAcTemperatureDecrease={props.onAcTemperatureDecrease}
                      onAcTemperatureIncrease={props.onAcTemperatureIncrease}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <Row className={`${isCollapsed ? "d-none" : ""}`}>
          <Col>
            {props.mainSpace.childSpaces &&
              props.mainSpace.childSpaces.length > 0 && (
                <div>
                  {props.mainSpace.childSpaces.map((ss, index) => (
                    <ControllerSpaceContainer
                      key={index}
                      mainSpace={{
                        ...ss,
                        clusterId: props.mainSpace.clusterId,
                      }}
                      expandable={true}
                      isShowIcon={false}
                      size={14}
                      collapsed={true}
                      onSelectSpace={selectSpaceHandler}
                      updateCurrentState={updateCurrentState}
                      updateControllerStatus={updateControllerStatus}
                      updateSpaceStatus={updateSpaceStatus}
                      onAcTemperatureDecrease={props.onAcTemperatureDecrease}
                      onAcTemperatureIncrease={props.onAcTemperatureIncrease}
                    />
                  ))}
                </div>
              )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ControllerSpaceContainer;
