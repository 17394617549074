import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyGetSpaceClustersXYQuery,
  useLazyViewPowerConsumersWithHighConsumptionQuery,
  useLazyViewSpaceClusterConsumptionByYearAndMonthQuery,
} from "../../../redux/api/dashboard/dashboardAPI";
import { useLazyGetEnergyConsumptionQuery } from "../../../redux/api/energy-consumption/energyConsumptionAPI";
import { useLazyGetEnergyGenerationDataQuery } from "../../../redux/api/solar/solarAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { EConsumptionValueComparison } from "../../../shared/oversight-core/enums/consumption-comparison";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { ESortOrder } from "../../../shared/oversight-core/enums/sort-order";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ConsumptionDataView } from "../../../shared/oversight-core/interfaces/entities/consumption-data-view";
import IExtendedEnergyConsumers from "../../../shared/oversight-core/interfaces/extended-energy-consumers";
import IMonthlyEnergySummaryView from "../../../shared/oversight-core/interfaces/monthly-energy-summary-view";
import {
  ISpaceCluster,
  ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
  ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/response-dto/dashboard-api-dto";
import ViewEnergyGenerationResponseDTO from "../../../shared/oversight-core/response-dto/view-energy-generation-response-dto";
import HighPowerDevices from "../../../shared/oversight-core/shared-components/dashboard-widgets/high-power-devices/high-power-devices";
import Info from "../../../shared/oversight-core/shared-components/dashboard-widgets/info/info";
import Usage from "../../../shared/oversight-core/shared-components/dashboard-widgets/usage/usage";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { showErrorMessage } from "../../../shared/oversight-core/utils/toast";
import { EScheduleViewType } from "../../../shared/oversight-general-core/enums/schedule-view-type";
import { EUsageViewType } from "../../../shared/oversight-general-core/enums/usage-view-type";

interface ChartData {
  consumptionType: "UNITS" | "BILL";
  year: 0;
  months: string[];
  solar: number[];
  grid: number[];
}

const scheduleViewType = [
  {
    label: "Usage from Both Manual And Semi-Automated",
    value: EScheduleViewType.ALL,
  },
  {
    label: "Usage from Manual Schedule",
    value: EScheduleViewType.MANUAL,
  },
  {
    label: "Usage from Semi-Automated Schedule",
    value: EScheduleViewType.SEMI_AUTOMATED,
  },
];

const usageViewType: Option[] = [
  { label: "Actual", value: EUsageViewType.ACTUAL },
  { label: "Prediction", value: EUsageViewType.PREDICTION },
];

const defaultPredictionUsage = {
  bill: 0,
  unit: 0,
  comparisonWithLastMonth: EConsumptionValueComparison.NOT_MEASURABLE,
};

const defaultActualUsage = {
  bill: 0,
  unit: 0,
};

const Home = () => {
  const [selectedScheduleViewType, setSelectedScheduleViewType] =
    useState<Option>(scheduleViewType[0]);
  const [selectedUsageViewTypeThisMonth, setSelectedUsageViewTypeThisMonth] =
    useState<Option>(usageViewType[0]);
  const [selectedUsageViewTypeLastMonth, setSelectedUsageViewTypeLastMonth] =
    useState<Option>(usageViewType[0]);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [predictionThisMonth, setPredictionThisMonth] = useState({
    ...defaultPredictionUsage,
  });
  const [predictionLastMonth, setPredictionLastMonth] = useState({
    ...defaultPredictionUsage,
  });
  const [actualThisMonth, setActualThisMonth] = useState({
    ...defaultActualUsage,
  });
  const [actualLastMonth, setActualLastMonth] = useState({
    ...defaultActualUsage,
  });
  const [consumers, setConsumers] = useState<IExtendedEnergyConsumers[] | null>(
    []
  );
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chartData, setChartData] = useState<ChartData>({
    consumptionType: "UNITS",
    year: 0,
    months: [],
    solar: [],
    grid: [],
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [selectedSpace, setSelectedSpace] = useState<ISpaceCluster>();

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpace }] =
    useLazyGetSpaceClustersXYQuery();
  const [triggerGetActualUsage, { isFetching: isFetchingActualUsage }] =
    useLazyViewSpaceClusterConsumptionByYearAndMonthQuery();
  const [triggerGetPredictionUsage, { isFetching: isFetchingUsagePrediction }] =
    useLazyGetSpaceClusterEnergyWithComparisonQuery();
  const [
    triggerViewPowerConsumersWithHighConsumption,
    { isFetching: isFetchingHighestPowerConsumers },
  ] = useLazyViewPowerConsumersWithHighConsumptionQuery();
  const [
    triggerViewEnergyGenerationData,
    { isFetching: isFetchingEnergyGeneration },
  ] = useLazyGetEnergyGenerationDataQuery();
  const [
    triggerViewActualConsumptionByYearQuery,
    {
      data: getEnergyConsumption,
      isSuccess: isSuccessEnergyConsumption,
      isFetching: isFetchingActualConsumptionByYear,
    },
  ] = useLazyGetEnergyConsumptionQuery();

  useEffect(() => {
    triggerGetSpaceClusters()
      .then((res) => {
        setSpaceClusters(res.data?.spaceClusters || []);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (spaceClusters.length > 0) {
      setSelectedSpace(spaceClusters[0]);
    }
  }, [spaceClusters]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id && selectedDate) {
      if (selectedUsageViewTypeThisMonth.value === EUsageViewType.ACTUAL) {
        triggerGetActualUsage({
          spaceClusterId: billingSpaceFilter.spaceCluster.id,
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth() + 1,
        })
          .unwrap()
          .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
            setActualThisMonth({
              bill: res.energy.energyBill || 0,
              unit: res.energy.energyInUnits || 0,
            });
          })
          .catch((error) => {
            if (
              error.status !== 404 &&
              error.ovstErrorCode !== OvstErrorCode.OVST_0030
            ) {
              showErrorMessage("Sorry, An error occurred");
              return;
            }
          });
      } else if (
        selectedUsageViewTypeThisMonth.value === EUsageViewType.PREDICTION ||
        selectedUsageViewTypeLastMonth.value === EUsageViewType.PREDICTION
      ) {
        triggerGetPredictionUsage(billingSpaceFilter.spaceCluster.id)
          .unwrap()
          .then((res: ViewSpaceClusterEnergyWithComparisonResponseDTO) => {
            setPredictionThisMonth({
              bill: res.currentMonthEnergyView.energyBill || 0,
              unit: res.currentMonthEnergyView.energyInUnits || 0,
              comparisonWithLastMonth:
                res.comparedToPreviousMonthConsumedEnergy ||
                EConsumptionValueComparison.NOT_MEASURABLE,
            });
            setPredictionLastMonth({
              bill: res.previousMonthEnergyView.energyBill || 0,
              unit: res.previousMonthEnergyView.energyInUnits || 0,
              comparisonWithLastMonth:
                EConsumptionValueComparison.NOT_MEASURABLE,
            });
          })
          .catch((error) => {
            if (
              error.status !== 404 &&
              error.ovstErrorCode !== OvstErrorCode.OVST_0028
            ) {
              showErrorMessage("Sorry, An error occurred");
              return;
            }
          });
      }
    }
  }, [
    billingSpaceFilter.spaceCluster.id,
    selectedDate,
    selectedUsageViewTypeThisMonth.value,
    selectedUsageViewTypeLastMonth.value,
  ]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id && selectedDate) {
      if (selectedUsageViewTypeLastMonth.value === EUsageViewType.ACTUAL) {
        triggerGetActualUsage({
          spaceClusterId: billingSpaceFilter.spaceCluster.id,
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth(),
        })
          .unwrap()
          .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
            setActualLastMonth({
              bill: res.energy.energyBill || 0,
              unit: res.energy.energyInUnits || 0,
            });
          })
          .catch((error) => {
            if (
              error.status !== 404 &&
              error.ovstErrorCode !== OvstErrorCode.OVST_0030
            ) {
              showErrorMessage("Sorry, An error occurred");
              return;
            }
          });
      }
    }
  }, [
    billingSpaceFilter.spaceCluster.id,
    selectedDate,
    selectedUsageViewTypeLastMonth.value,
  ]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id && selectedDate) {
      const viewEnergyAs: EEnergyViewType =
        selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
      const viewConsumptionAs: EConsumptionViewTypes =
        EConsumptionViewTypes.CONSUMED;
      const viewScheduleAs: EScheduleViewType =
        selectedScheduleViewType.value as EScheduleViewType;
      setChartData((ps) => ({ ...ps, grid: [], solar: [] }));
      triggerViewActualConsumptionByYearQuery({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: selectedDate.getFullYear(),
        viewEnergyAs,
        viewConsumptionAs,
        viewScheduleAs,
      });

      if (
        selectedButton === 1 &&
        selectedSpace &&
        selectedSpace.powerGeneratorIds?.length > 0
      ) {
        triggerViewEnergyGenerationData({
          powerGeneratorId:
            (selectedSpace && selectedSpace.powerGeneratorIds[0]) || "",
          year: selectedDate.getFullYear(),
        })
          .unwrap()
          .then((res: ViewEnergyGenerationResponseDTO) => {
            setChartData((ps) => {
              ps.solar =
                res.generatedEnergy || Array.from({ length: 12 }, () => 0);
              ps.months = res.months || [];
              return { ...ps };
            });
          })
          .catch(() => {
            showErrorMessage("Sorry, An error occurred");
          });
      }
      setChartData((ps) => {
        ps.solar = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    } else {
      setChartData({
        consumptionType: "UNITS",
        year: 0,
        months: [],
        solar: [],
        grid: [],
      });
    }
  }, [
    billingSpaceFilter.spaceCluster.id,
    selectedSpace?.powerGeneratorIds,
    selectedDate,
    selectedButton,
    selectedScheduleViewType.value,
  ]);

  useEffect(() => {
    if (isSuccessEnergyConsumption && getEnergyConsumption) {
      const data: ConsumptionDataView =
        getEnergyConsumption.orderedMonthlyEnergySummary.reduce(
          (
            previousValue: ConsumptionDataView,
            currentValue: IMonthlyEnergySummaryView
          ) => {
            previousValue?.months?.push(currentValue.monthName);
            previousValue?.actualConsumption?.push(
              selectedButton === 1
                ? selectedScheduleViewType.value === EScheduleViewType.ALL
                  ? currentValue.energySummaryView?.consumedEnergySummary
                      ?.energyInUnits || 0
                  : selectedScheduleViewType.value === EScheduleViewType.MANUAL
                  ? currentValue.energySummaryView?.manualScheduleEnergySummary
                      ?.energyInUnits || 0
                  : (selectedScheduleViewType.value ===
                      EScheduleViewType.SEMI_AUTOMATED &&
                      currentValue.energySummaryView
                        ?.semiAutomatedScheduleEnergySummary?.energyInUnits) ||
                    0
                : selectedScheduleViewType.value === EScheduleViewType.ALL
                ? currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyBill || 0
                : selectedScheduleViewType.value === EScheduleViewType.MANUAL
                ? currentValue.energySummaryView?.manualScheduleEnergySummary
                    ?.energyBill || 0
                : (selectedScheduleViewType.value ===
                    EScheduleViewType.SEMI_AUTOMATED &&
                    currentValue.energySummaryView
                      ?.semiAutomatedScheduleEnergySummary?.energyBill) ||
                  0
            );

            return previousValue;
          },
          {
            months: [],
            predictedConsumption: [],
            actualConsumption: [],
          }
        );

      setChartData((ps) => {
        ps.grid = data.actualConsumption;
        ps.months = data.months;
        return { ...ps };
      });
    } else {
      setChartData((ps) => {
        ps.grid = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    }
  }, [
    isSuccessEnergyConsumption,
    getEnergyConsumption,
    selectedScheduleViewType.value,
  ]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      setConsumers([]);
      triggerViewPowerConsumersWithHighConsumption({
        clusterId: billingSpaceFilter.spaceCluster.id,
        sortingOrder: ESortOrder.ASC,
        limit: 6,
      })
        .unwrap()
        .then((res: ViewScheduledPowerConsumersSortedByEnergyResponseDTO) => {
          const extendedPowerConsumers =
            res.powerConsumers &&
            res.powerConsumers.map((powerConsumer) => {
              const pathObject = res?.devicePaths[powerConsumer.id];
              let path = ``;
              pathObject &&
                pathObject.ancestorSpaces
                  .slice()
                  .reverse()
                  .forEach((hierarchy, hierarchyIndex) => {
                    path +=
                      pathObject.ancestorSpaces.length - 1 === hierarchyIndex
                        ? `${hierarchy.name}`
                        : `${hierarchy.name} > `;
                  });
              return { ...powerConsumer, path };
            });
          setConsumers(extendedPowerConsumers || null);
        })
        .catch((error) => {
          if (
            error.status !== 404 &&
            error.ovstErrorCode !== OvstErrorCode.OVST_0028
          ) {
            showErrorMessage("Sorry, An error occurred");
            return;
          }
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  const monthsList = chartData.months.map(
    (m) => m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
  );

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 ? (
        <>
          <Row>
            <Col
              xs={12}
              lg={6}
              xl={3}
              className="order-1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="h-50 pb-2">
                <Usage
                  title="Usage this Month"
                  cost={
                    selectedUsageViewTypeThisMonth.value ===
                    EUsageViewType.ACTUAL
                      ? actualThisMonth.bill
                      : predictionThisMonth.bill
                  }
                  units={
                    selectedUsageViewTypeThisMonth.value ===
                    EUsageViewType.ACTUAL
                      ? actualThisMonth.unit
                      : predictionThisMonth.unit
                  }
                  comparisonWithLastMonth={
                    selectedUsageViewTypeThisMonth.value ===
                    EUsageViewType.PREDICTION
                      ? predictionThisMonth.comparisonWithLastMonth
                      : EConsumptionValueComparison.NOT_MEASURABLE
                  }
                  usageViewType={usageViewType}
                  selectedUsageViewType={selectedUsageViewTypeThisMonth}
                  setSelectedUsageViewType={setSelectedUsageViewTypeThisMonth}
                />
              </div>
              <div className="pt-2 h-50">
                <Usage
                  title="Usage Last Month"
                  cost={
                    selectedUsageViewTypeLastMonth.value ===
                    EUsageViewType.ACTUAL
                      ? actualLastMonth.bill
                      : predictionLastMonth.bill
                  }
                  units={
                    selectedUsageViewTypeLastMonth.value ===
                    EUsageViewType.ACTUAL
                      ? actualLastMonth.unit
                      : predictionLastMonth.unit
                  }
                  usageViewType={usageViewType}
                  selectedUsageViewType={selectedUsageViewTypeLastMonth}
                  setSelectedUsageViewType={setSelectedUsageViewTypeLastMonth}
                />
              </div>
            </Col>
            <Col
              xs={12}
              lg={12}
              xl={6}
              className="order-2 order-lg-3 order-xl-2 mt-3 mt-lg-3 mt-xl-0 "
            >
              <HighPowerDevices
                consumers={consumers || null}
                title="Devices with highest energy consumption"
                isFetching={isFetchingHighestPowerConsumers}
              />
            </Col>
            <Col
              xs={12}
              lg={6}
              xl={3}
              className="order-3 order-lg-2 order-xl-3 mt-3 mt-lg-0"
            >
              <Info />
            </Col>
          </Row>
          <div className="container-white mt-4">
            <Row className="align-items-center">
              <Col
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                className="col-12 col-lg-5 mt-3 mt-lg-0"
              >
                <AppSelect
                  options={scheduleViewType}
                  selectedValue={selectedScheduleViewType}
                  onChangeOption={(selectedUsageOption) => {
                    setSelectedScheduleViewType(selectedUsageOption);
                  }}
                  fontSize="12"
                  fontWeight="400"
                />
              </Col>
              <Col
                xs={{ order: 1 }}
                lg={{ order: 2 }}
                className="col-12 col-lg-7"
              >
                <Row className="align-items-center justify-content-md-end">
                  <Col className="col-12 col-sm-6 col-lg-5 col-xxl-3 mt-3 mt-sm-0">
                    <AppTabs
                      buttons={[
                        {
                          buttonName: "Units",
                          selectedColor: "purpleColor",
                          id: 1,
                        },
                        {
                          buttonName: "Bill",
                          selectedColor: "purpleColor",
                          id: 2,
                        },
                      ]}
                      onChange={(id) => setSelectedButton(id)}
                      selectedTabId={selectedButton}
                    />
                  </Col>
                  <Col className="col-12 col-sm-6 col-lg-6 col-xxl-4 mt-3 mt-sm-0">
                    <AppDatePicker
                      dateFormat="yyyy"
                      selectedDate={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      monthYearPicker={false}
                      yearPicker={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="position-relative mt-4">
              {selectedButton === 1 ? (
                <AreaChart
                  borderColor1="#18A1AA"
                  borderColor2="#29CC39"
                  backgroundColor1="#DFFDFB4D"
                  backgroundColor2="#29CC3917"
                  label1="Usage from Grid"
                  label2="Solar Generation"
                  yAxesUnit={"Units"}
                  labels={monthsList}
                  data1={chartData.grid}
                  data2={chartData.solar}
                  hasSecondDataSet={true}
                />
              ) : (
                <AreaChart
                  borderColor1="#18A1AA"
                  backgroundColor1="#DFFDFB4D"
                  label1="Usage from Grid"
                  yAxesUnit={"Rs."}
                  labels={monthsList}
                  data1={chartData.grid}
                  hasSecondDataSet={false}
                />
              )}
              <SpinnerModal
                show={
                  isFetchingEnergyGeneration ||
                  isFetchingActualConsumptionByYear
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {!isFetchingSpace && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>{" "}
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <SpinnerModal show={isFetchingSpace} />
    </div>
  );
};

export default Home;
