import AuthDTO from "../../../shared/oversight-core/dtos/auth-dto";
import LoginResponseDTO from "../../../shared/oversight-core/response-dto/login-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { setToken } from "../../features/auth/auth-slice";
import { rootApi } from "../apiManager";

export const authAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseDTO, AuthDTO>({
      query(data) {
        return {
          url: "login",
          method: "POST",
          body: data,
        };
      },
      transformErrorResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          localStorage.setItem("token", res.data.token);
          dispatch(setToken(res.data.token));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation } = authAPI;
