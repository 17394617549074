import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { IHttpError } from "../../shared/oversight-core/interfaces/http-errror";

export const rtkQueryErrorLogger: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = action.payload as IHttpError;
    switch (error.status) {
      case 500:
        console.log(error);
        break;
      case 401:
        console.log(error);
        // if (error.ovstErrorCode === OvstErrorCode.OVST_SEC_0001) {
        //   showErrorMessage(
        //     "Session Expired. Please Login Again",
        //     "session-expired"
        //   );
        //   localStorage.clear();
        //   api.dispatch(setToken(""));
        // }
        break;
      default:
        break;
    }
  }
  return next(action);
};
