import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../redux/api/auth/authAPI";
import AuthDTO from "../../../shared/oversight-core/dtos/auth-dto";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IHttpError } from "../../../shared/oversight-core/interfaces/http-errror";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import CheckBox from "../../../shared/oversight-core/ui-elements/check-box/check-box";
import AppInput from "../../../shared/oversight-core/ui-elements/input/app-input";
import isMobileNumberVerified from "../../../shared/oversight-core/utils/isMobileNumberVerified";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";
import CEB_IMG from "../assets/CEB.png";
import LECO_IMG from "../assets/LECO.png";
import OTHER_IMG from "../assets/OTHER.png";

const LoginForm = () => {
  const serviceProviderType: Option[] = [
    {
      value: "CEB",
      label: (
        <Row className="align-items-center ">
          <Col className="col-1 text-center pe-3 pe-sm-0">
            <img src={CEB_IMG} alt="CEB Icon" width={24} height={24} />
          </Col>
          <Col>CEB</Col>
        </Row>
      ),
    },
    {
      value: "LECO",
      label: (
        <Row className="align-items-center">
          <Col className="col-1 text-center pe-3 pe-sm-0">
            <img src={LECO_IMG} alt="LECO Icon" />
          </Col>
          <Col> LECO</Col>
        </Row>
      ),
    },
    {
      value: "OTHER",
      label: (
        <Row className="align-items-center ">
          <Col className="col-1 text-center pe-3 pe-sm-0">
            <img src={OTHER_IMG} alt="Other Icon" />
          </Col>
          <Col> Other</Col>
        </Row>
      ),
    },
  ];

  const defaultSelectedServiceProvider = serviceProviderType[0];

  const [selectedServiceProvider, setSelectedServiceProvider] =
    useState<Option>({ ...defaultSelectedServiceProvider });

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [errorStyle, setErrorStyle] = useState(false);
  const navigate = useNavigate();
  const [login, { isError, error, isSuccess, isLoading }] = useLoginMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthDTO>();

  const onSubmit = (data: AuthDTO) => {
    login({ ...data, rememberMe })
      .unwrap()
      .catch((error: IHttpError) => {
        if (isMobileNumberVerified(error)) {
          navigate(AppRoute.VERIFY_MOBILE_NUMBER);
        } else {
          setErrorStyle(true);
          showErrorMessage(error.message || "Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorStyle(false);
    } else {
      setErrorStyle(true);
    }
  }, [errors]);

  useEffect(() => {
    if (isSuccess) {
      const message = "Login Successfully";
      showSuccessMessage(message);
      setErrorStyle(false);
    }
  }, [isSuccess, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isError && error && "data" in error && (
        <div className="text-center error mt-2">
          Sorry, but the password or username you entered is incorrect. Please
          try again.
        </div>
      )}
      <div className="mt-5">
        <AppSelect
          className="mt-4"
          labelFontSize="14"
          selectedValue={{ ...selectedServiceProvider }}
          label="Service Provider"
          options={[...serviceProviderType]}
          onChangeOption={(selectedServiceProviderOption) => {
            setSelectedServiceProvider(selectedServiceProviderOption);
          }}
          name="serviceProvider"
        />
        <AppInput
          className="mt-4"
          name="username"
          placeholder={"Enter your username or email"}
          label={"Username or Email"}
          register={register("username", {
            required: "Username or Email is required",
          })}
          errors={errors}
          isError={isError}
        />
        <AppInput
          className="mt-4"
          name="password"
          icon={!showPassword ? "visibility_off" : "visibility"}
          placeholder=" Enter your password"
          onIconClick={() => setShowPassword(!showPassword)}
          label="Password"
          type={showPassword ? "text" : "password"}
          register={register("password", {
            required: "Password is required",
          })}
          errors={errors}
          isError={isError}
        />
      </div>

      <Row className="mt-4">
        <Col>
          <CheckBox
            label="Remember me"
            checked={rememberMe}
            onChange={(checked) => setRememberMe(checked)}
          />
        </Col>

        <Col className="text-end">
          <Link to={AppRoute.FORGOT_PASSWORD}>Forgot password</Link>
        </Col>
      </Row>
      <AppButton
        text="Sign in"
        className={`mt-4 ${errorStyle && `manual-border-shadow`}`}
        variant="blue"
        isLoading={isLoading}
      />
      <Row className="justify-content-center mt-4">
        <Col className="text-center">
          Don&apos;t have an account?{" "}
          <Link to={AppRoute.REGISTER}>Register</Link>
        </Col>
      </Row>
    </form>
  );
};

export default LoginForm;
