import React from "react";
import { ListSpace } from "../../../interfaces/list-space";
import styles from "./space-selector-dropdown-options.module.scss";

interface IProps {
  options: ListSpace[];
  indent: boolean;
  handleSpaceSelectionChange: (
    subSpaceId: string,
    subSpaceName: string,
    clusterId: string
  ) => void;
}

const SpaceSelectorDropdownOptions = (props: IProps) => {
  return (
    <div className={`${props.indent && styles.leftBorder}`}>
      {props.options.map((space: ListSpace, index) => {
        return (
          <div
            className={`${
              props.indent
                ? `ps-4`
                : props.options.length - 1 !== index && `mb-3`
            }`}
            key={index}
          >
            <p
              className={`mb-2 text-light font-weight-400 cursor-pointer font-size-12`}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                props.handleSpaceSelectionChange(
                  space.id,
                  space.name,
                  space.clusterId
                );
              }}
            >
              {space.name}
            </p>
            {space.childSpaces.length > 0 && (
              <SpaceSelectorDropdownOptions
                options={space.childSpaces.map((childSpaces) => {
                  return { ...childSpaces, clusterId: space.clusterId };
                })}
                indent={true}
                handleSpaceSelectionChange={props.handleSpaceSelectionChange}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SpaceSelectorDropdownOptions;
